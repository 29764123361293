import { Inject, Injectable } from '@angular/core';

import { Environments } from '../../../environments/env.enum';
import { APP_CONFIG } from '../data-model/app-config';
import { TechpubsConfig } from '../data-model/techpubs-config';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  public readonly configuration: TechpubsConfig;

  public ENVIRONMENTS = Environments;

  constructor(
    @Inject(APP_CONFIG) appConfig: TechpubsConfig
  ) {
    this.configuration = appConfig
  }

  getEnvironmentName(): string {
    return this.configuration.config.envName;
  }

  getEnvironment(): Environments {
    return this.configuration.config.current;
  }

  getBackendUrl(): string {
    return this.configuration.config.backend;
  }

  getLoginEndpoint(): string {
    return this.configuration.config.loginEndpoint;
  }
  getLogoutEndpoint(): string {
    return this.configuration.config.logoutEndpoint;
  }
  getUserProductEndpoint(): string {
    return this.configuration.config.userProductEndpoint;
  }
  getHybrisUrl(): string {
    return this.configuration.config.hybrisUrl;
  }

  getBuyTechPubsUrl(): string {
    return this.configuration.config.buytechpubsUrl;
  }

  getCommunityUrl(): string {
    return this.configuration.config.communityUrl;
  }

  getContactUsUrl(): string {
    return this.configuration.config.contactusUrl;
  }

  getSalesForceUrl(): string {
    return this.configuration.config.salesforceUrl;
  }

  getMarketplaceUrl(): string {
    return this.configuration.config.marketplaceUrl;
  }

  getAccountUrl(): string {
    return this.configuration.config.hybrisUrl + '/account';
  }

  getCompanyUrl(): string {
    return this.configuration.config.hybrisUrl + '/bell/en/USD/myCompany/companyPage';
  }

  getSalesForceW2C(): {url: string, orgId: string, reportDeficiencyRecordType: string, aogId: string} {
    return this.configuration.config.salesforceW2C;
  }

  getNetworkServiceEndpoint() {
    return this.configuration.config.networkServiceEndpoint;
  }

  getEnvironmentBaseUrl() {
    return this.configuration.config.baseHref;
  }

  getGoogleAnalyticsOnline(): string {
    return this.configuration.config.googleAnalyticsOnline;
  }

  getGoogleAnalyticsElectron(): string {
    return this.configuration.config.googleAnalyticsElectron;
  }

  getGoogleAnalyticsMaui(): string {
    return this.configuration.config.googleAnalyticsMaui;
  }

  getOfflineLocation(): string {
    return this.configuration.config.offlineLocation;
  }
  isGoogleAnalyticsEnabled(): boolean {
    return this.configuration.config.isGoogleAnalyticsEnabled;
  }
  getForcePHP(): boolean {
    return this.configuration.config.envName == 'local' ? this.configuration.config.forcePHP : false;
  }

  getIsWorkEnvironment(): boolean {
    return localStorage.getItem('isWorkEnvironment') === 'true';
  }

  setIsWorkEnvironment(value: boolean) {
    localStorage.setItem('isWorkEnvironment', `${value}`);
  }
}
