import { EventEmitter, Injectable, NgZone, OnDestroy } from '@angular/core';
import { InterruptEventManager } from './interrupt/interrupt-event-manager';
import { InterruptEventArgs } from './interrupt/interrupt-event-args';
import { InterruptSourceBaseEvent } from './base/interrupt-source-base-event';
import { IdleExpiryBaseService } from './base/idle-expiry-base.service';
import { IdleStorageExpiryService } from './storage/idle-storage-expiry.service';
import { AutoResume } from './model/idle-model';

@Injectable({
  providedIn: 'root'
})

export class IdleService implements OnDestroy {
  private idle: number = 20 * 60;
  private timeoutVal = 30;
  private autoResume: AutoResume = AutoResume.idle;
  private interrupts: Array<InterruptEventManager> = new Array();
  private running = false;
  private idling: boolean;
  private idleHandle: any;
  private timeoutHandle: any;
  private countdown: number;

  public onIdleStart: EventEmitter<any> = new EventEmitter();
  public onIdleEnd: EventEmitter<any> = new EventEmitter();
  public onTimeoutWarning: EventEmitter<number> = new EventEmitter<number>();
  public onTimeout: EventEmitter<number> = new EventEmitter<number>();
  public onInterrupt: EventEmitter<any> = new EventEmitter();

  [key: string]: any;

  constructor(
    private expiry: IdleExpiryBaseService,
    private zone: NgZone,
  ) {
    this.setIdling(false);
  }

  setIdleName(key: string): void {
    if (this.expiry instanceof IdleStorageExpiryService) {
      this.expiry.setIdleName(key);
    } else {
      throw new Error(
        'Cannot set expiry key name because no IdleStorageExpiryService has been provided.'
      );
    }
  }

  setIdle(seconds: number): number {
    if (seconds <= 0) {
      throw new Error("'seconds' must be greater zero");
    }

    return (this.idle = seconds);
  }

  setTimeout(seconds: number | boolean): number {
    if (seconds === false) {
      this.timeoutVal = 0;
    } else if (typeof seconds === 'number' && seconds >= 0) {
      this.timeoutVal = seconds;
    } else {
      throw new Error("'seconds' can only be 'false' or a positive number.");
    }

    return this.timeoutVal;
  }

  setInterrupts(sources: Array<InterruptSourceBaseEvent>): Array<InterruptEventManager> {
    this.clearInterrupts();

    const self = this;

    for (const source of sources) {
      const sub = new InterruptEventManager(source);
      sub.subscribe(
        (args: InterruptEventArgs) => {
          self.interrupt(args.force, args.innerArgs);
        }
      );

      this.interrupts.push(sub);
    }

    return this.interrupts;
  }

  clearInterrupts(): void {
    for (const sub of this.interrupts) {
      sub.pause();
      sub.unsubscribe();
    }

    this.interrupts.length = 0;
  }

  watch(skipExpiry ? : boolean): void {
    this.safeClearInterval('idleHandle');
    this.safeClearInterval('timeoutHandle');

    const timeout = !this.timeoutVal ? 0 : this.timeoutVal;

    if (!skipExpiry) {
      const value = new Date(
        this.expiry.now().getTime() + (this.idle + timeout) * 1000
      );
      this.expiry.last(value);
    }

    if (this.idling) {
      this.toggleState();
    }

    if (!this.running) {
      this.toggleInterrupts(true);
    }

    this.running = true;

    const watchFn = () => {
      this.zone.run(
        () => {
          const diff = this.getExpiryDiff(timeout);

          if (diff > 0) {
            this.safeClearInterval('idleHandle');
            this.setIdleIntervalOutsideOfZone(watchFn, diff);
          } else {
            this.toggleState();
          }
        }
      );
    };

    this.setIdleIntervalOutsideOfZone(watchFn, this.idle * 1000);
  }

  interrupt(force?: boolean, eventArgs?: any): void {
    if (!this.running) {
      return;
    }

    if (this.timeoutVal && this.expiry.isExpired()) {
      this.timeout();
      return;
    }

    this.onInterrupt.emit(eventArgs);

    if (force === true || this.autoResume === AutoResume.idle || (this.autoResume === AutoResume.notIdle && !this.expiry.idling())) {
      this.watch(force);
    }
  }

  timeout(): void {
    this.toggleInterrupts(false);

    this.safeClearInterval('idleHandle');
    this.safeClearInterval('timeoutHandle');

    this.setIdling(true);
    this.running = false;
    this.countdown = 0;

    this.onTimeout.emit(null);
  }

  setIdleIntervalOutsideOfZone(watchFn: () => void, frequency: number): void {
    this.zone.runOutsideAngular(
      () => {
        this.idleHandle = setInterval(watchFn, frequency);
      }
    );
  }

  stop(): void {
    this.toggleInterrupts(false);

    this.safeClearInterval('idleHandle');
    this.safeClearInterval('timeoutHandle');

    this.setIdling(false);
    this.running = false;

    this.expiry.last(null);
  }

  getTimeout(): number {
    return this.timeoutVal;
  }

  getIdle(): number {
    return this.idle;
  }

  getAutoResume(): AutoResume {
    return this.autoResume;
  }

  setAutoResume(value: AutoResume): AutoResume {
    return (this.autoResume = value);
  }

  getInterrupts(): Array<InterruptEventManager> {
    return this.interrupts;
  }

  isRunning(): boolean {
    return this.running;
  }

  isIdling(): boolean {
    return this.idling;
  }

  private setIdling(value: boolean): void {
    this.idling = value;
    this.expiry.idling(value);
  }

  private safeClearInterval(handleName: string): void {
    const handle = this[handleName];

    if (handle !== null && typeof handle !== 'undefined') {
      clearInterval(this[handleName]);
      this[handleName] = null;
    }
  }

  private toggleState(): void {
    this.setIdling(!this.idling);

    if (this.idling) {
      this.onIdleStart.emit(null);

      if (this.timeoutVal > 0) {
        this.countdown = this.timeoutVal;
        this.doCountdown();
        this.setTimoutIntervalOutsideZone(
          () => {
            this.doCountdownInZone();
          },
          1000
        );
      }
    } else {
      this.toggleInterrupts(true);
      this.onIdleEnd.emit(null);
    }

    this.safeClearInterval('idleHandle');
  }

  private doCountdown(): void {
    const diff = this.getExpiryDiff(this.timeoutVal);

    if (diff > 0) {
      this.safeClearInterval('timeoutHandle');
      this.interrupt(true);
      return;
    }

    if (!this.idling) {
      return;
    }

    if (this.countdown <= 0) {
      this.timeout();
      return;
    }

    this.onTimeoutWarning.emit(this.countdown);
    this.countdown--;
  }

  private getExpiryDiff(timeout: number): number {
    const now: Date = this.expiry.now();
    const last: Date = this.expiry.last() || now;
    return last.getTime() - now.getTime() - timeout * 1000;
  }

  private toggleInterrupts(resume: boolean): void {
    for (const interrupt of this.interrupts) {
      if (resume) {
        interrupt.resume();
      } else {
        interrupt.pause();
      }
    }
  }

  private setTimoutIntervalOutsideZone(intervalFn: () => void, frequency: number) {
    this.zone.runOutsideAngular(
      () => {
        this.timeoutHandle = setInterval(
          () => {
            intervalFn();
          },
          frequency
        );
      }
    );
  }

  private doCountdownInZone(): void {
    this.zone.run(
      () => {
        this.doCountdown();
      }
    );
  }

  ngOnDestroy(): void {
    this.stop();
    this.clearInterrupts();
  }
}
