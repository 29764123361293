import { Injectable } from '@angular/core';
import { Observable, Observer, BehaviorSubject } from 'rxjs';
import { IetmSnsData } from '../../../viewer/ietm/services/ietm-service/ietm-model';
import { OfflineService } from '../offline/offline.service';
import { filter } from 'rxjs/operators';
import { OfflineZipReaderService } from '../offline/offline-zip-reader.service';
import { IetmFault, IetmZipFault } from '../../data-model/ietm-fault';
import { DataService } from '../data-services/data.service';

@Injectable({
  providedIn: 'root'
})
export class IetmDataService {
  private loadedIetmSnsToc$: Record<string, BehaviorSubject<IetmSnsData>> = {};
  private loadedIetmManualToc$: Record<string, BehaviorSubject<any>> = {};
  private loadedIetm$: Record<string, BehaviorSubject<any>> = {};
  private loadedIetmFromFaultCode$: Record<string, BehaviorSubject<IetmFault>> = {};
  private loadedZipIetmFromFaultCode$: Record<string, BehaviorSubject<IetmZipFault[]>> = {};
  constructor(
    private dataService: DataService,
    private offlineService: OfflineService,
    private offlineZipReaderService: OfflineZipReaderService,
  ) { }

  public getIetmSnsToc(modelName: string): Observable<IetmSnsData> {
    return new Observable((observer: Observer<IetmSnsData>) => {
      this.getIetmSnsTocDataSubjects(modelName).pipe(filter(f => f !== undefined)).subscribe(data => {
        observer.next(data);
        observer.complete();
      });
    });
  }

  public getIetmManualToc(modelName: string, pmc: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.getIetmManualTocSubjects(modelName, pmc).pipe(filter(f => f !== undefined)).subscribe(data => {
        observer.next(data);
        observer.complete();
      });
    });
  }

  public getIetm(modelName: string, pmc: string, dmc: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.getIetmSubjects(modelName, pmc, dmc).pipe(filter(f => f !== undefined)).subscribe(data => {
        observer.next(data);
        observer.complete();
      });
    });
  }

  public getIetmFromFaultCode(modelName: string, code: string): Observable<IetmFault> {
    return new Observable((observer: Observer<IetmFault>) => {
      this.getIetmFromFaultCodeSubjects(modelName, code).pipe(filter(f => f !== undefined)).subscribe(data => {
        observer.next(data);
        observer.complete();
      });
    });
  }

  private getIetmSnsTocServerData(modelName: string) {
    if (this.loadedIetmSnsToc$[modelName]) {
      let ietmSnsToc$: Observable<IetmSnsData>;
      if (!this.offlineService.isOfflineCapable) {
        ietmSnsToc$ = this.dataService.getIetmSnsToc(modelName);
      } else {
        if (this.offlineService.isOffline) {
          ietmSnsToc$ = this.offlineZipReaderService.getIetmSnsToc(modelName);
        } else {
          ietmSnsToc$ = this.dataService.getIetmSnsToc(modelName);
        }
      }
      ietmSnsToc$.subscribe((data: IetmSnsData) => {
        this.loadedIetmSnsToc$[modelName].next(data);
      });
    }
  }

  private getIetmSnsTocDataSubjects(modelName: string): BehaviorSubject<IetmSnsData> {
    if (!this.loadedIetmSnsToc$[modelName]) {
      this.loadedIetmSnsToc$[modelName] = <BehaviorSubject<IetmSnsData>>new BehaviorSubject(undefined);
      this.getIetmSnsTocServerData(modelName);
      return this.loadedIetmSnsToc$[modelName];
    } else {
      return this.loadedIetmSnsToc$[modelName];
    }
  }

  private getIetmManualTocServerData(modelName: string, pmc: string) {
    if (this.loadedIetmManualToc$[`${modelName}-${pmc}`]) {
      let ietmManualToc$: Observable<any>;
      if (!this.offlineService.isOfflineCapable) {
        ietmManualToc$ = this.dataService.getIetmManualToc(modelName, pmc);
      } else {
        if (this.offlineService.isOffline) {
          ietmManualToc$ = this.offlineZipReaderService.getIetmManualToc(pmc);
        } else {
          ietmManualToc$ = this.dataService.getIetmManualToc(modelName, pmc);
        }
      }
      ietmManualToc$.subscribe((data: IetmSnsData) => {
        this.loadedIetmManualToc$[`${modelName}-${pmc}`].next(data);
      });
    }
  }

  private getIetmManualTocSubjects(modelName: string, pmc: string): BehaviorSubject<any> {
    if (!this.loadedIetmManualToc$[`${modelName}-${pmc}`]) {
      this.loadedIetmManualToc$[`${modelName}-${pmc}`] = <BehaviorSubject<any>>new BehaviorSubject(undefined);
      this.getIetmManualTocServerData(modelName, pmc);
      return this.loadedIetmManualToc$[`${modelName}-${pmc}`];
    } else {
      return this.loadedIetmManualToc$[`${modelName}-${pmc}`];
    }
  }

  private getIetmServerData(modelName: string, pmc: string, dmc: string) {
    const currentKey = `${modelName}-${pmc}${(dmc ? '-' + dmc : '')}`;
    if (this.loadedIetm$[currentKey]) {
      let ietm$: Observable<any>;
      if (!this.offlineService.isOfflineCapable) {
        ietm$ = this.dataService.getIetm(modelName, pmc, dmc);
      } else {
        if (this.offlineService.isOffline) {
          ietm$ = this.offlineZipReaderService.getIetm(pmc, dmc);
        } else {
          ietm$ = this.dataService.getIetm(modelName, pmc, dmc);
        }
      }
      ietm$.subscribe((data: IetmSnsData) => {
        this.loadedIetm$[currentKey].next(data);
      });
    }
  }

  private getIetmSubjects(modelName: string, pmc: string, dmc: string): BehaviorSubject<any> {
    const currentKey = `${modelName}-${pmc}${(dmc ? '-' + dmc : '')}`;
    if (!this.loadedIetm$[currentKey]) {
      this.loadedIetm$[currentKey] = <BehaviorSubject<any>>new BehaviorSubject(undefined);
      this.getIetmServerData(modelName, pmc, dmc);
      return this.loadedIetm$[currentKey];
    } else {
      return this.loadedIetm$[currentKey];
    }
  }

  private getIetmFromFaultCodeServerData(modelName: string, code: string) {
    if (this.loadedIetmFromFaultCode$[`${modelName}-${code}`]) {
      let ietmFromFaultCode$: Observable<IetmFault>;
      if (!this.offlineService.isOfflineCapable) {
        ietmFromFaultCode$ = this.dataService.getIetmFromFaultCode(modelName, code);
      } else {
        if (this.offlineService.isOffline) {
          ietmFromFaultCode$ = this.getZipFromFaultCode(modelName, code);
        } else {
          ietmFromFaultCode$ = this.dataService.getIetmFromFaultCode(modelName, code);
        }
      }
      ietmFromFaultCode$.subscribe((data: IetmFault) => {
        this.loadedIetmFromFaultCode$[`${modelName}-${code}`].next(data);
      });
    }
  }

  private getIetmFromFaultCodeSubjects(modelName: string, code: string): BehaviorSubject<any> {
    if (!this.loadedIetmFromFaultCode$[`${modelName}-${code}`]) {
      this.loadedIetmFromFaultCode$[`${modelName}-${code}`] = <BehaviorSubject<any>>new BehaviorSubject(undefined);
      this.getIetmFromFaultCodeServerData(modelName, code);
      return this.loadedIetmFromFaultCode$[`${modelName}-${code}`];
    } else {
      return this.loadedIetmFromFaultCode$[`${modelName}-${code}`];
    }
  }

  private getZipFromFaultCode(modelName: string, code: string): Observable<IetmFault> {
    return new Observable((observer: Observer<IetmFault>) => {
      this.getZipFromFaultCodeSubjects(modelName).pipe(filter(f => f !== undefined)).subscribe(data => {
        if (data && data.length > 0) {
          observer.next(data.find(d => d.code === code));
          observer.complete();
        } else {
          observer.next(null);
          observer.complete();
        }
      });
    });
  }

  private getZipFromFaultCodeServerData(modelName: string) {
    if (this.loadedZipIetmFromFaultCode$[modelName]) {
      this.offlineZipReaderService.getIetmFromFaultCode(modelName).subscribe((data: IetmZipFault[]) => {
        this.loadedZipIetmFromFaultCode$[modelName].next(data);
      });
    }
  }

  private getZipFromFaultCodeSubjects(modelName: string): BehaviorSubject<any> {
    if (!this.loadedZipIetmFromFaultCode$[modelName]) {
      this.loadedZipIetmFromFaultCode$[modelName] = <BehaviorSubject<any>>new BehaviorSubject(undefined);
      this.getZipFromFaultCodeServerData(modelName);
      return this.loadedZipIetmFromFaultCode$[modelName];
    } else {
      return this.loadedZipIetmFromFaultCode$[modelName];
    }
  }
}
