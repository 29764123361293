import { Injectable } from '@angular/core';
import { Router, Event, NavigationStart, ParamMap } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {
  private _currentSearchPageIndex = 0;
  private _searchBackURL: ParamMap;

  constructor(private router: Router) {
    this.router.events.subscribe(
      (event: Event) => {
        if (event instanceof NavigationStart) {
          this.searchLegacy(event.url);
          this.ccodeRedirect(event.url);
        }
      }
    );
  }

  /**
   * Looks for a navigation using old syntax of type /doc/:model/:publication/p1[manual;exact] and
   * changes the url to /doc/:model/:publication/p1?search=manual&wholeword=true before redirecting it
   */
  searchLegacy(url: string) {
    if (url.startsWith('/doc/')) {
      const searchStart = url.indexOf('%5B'); // '['
      const searchDelimiter = url.indexOf(';');
      if (searchStart !== - 1 && searchDelimiter !== - 1) {
        const search = url.slice(searchStart + 3, searchDelimiter); // +3 because '[' is written '%5B'
        const option = url.includes('contains') ? 'contains' : (url.includes('exact') ? 'exact' : undefined);
        if (search.length > 0 && option !== undefined) {
          url = `${url.slice(0, searchStart)}?search=${search}&option=${option}`;
          this.router.navigateByUrl(url);
        }
      }
    }
  }

  /**
   * Looks for a navigation using old syntax of C-Code the Code being the :ccode
   * changes the url to /ccode/:ccode before redirecting it
   */
  ccodeRedirect(url: string) {
    if (url.startsWith('/C-')) {
      const ccode = url.replace('C-', '');
      const ccodeUrl = 'ccode' + ccode;
      this.router.navigateByUrl(ccodeUrl);
    }
  }

  public getCurrentSearchPageIndex (): number {
    return this._currentSearchPageIndex;
  }

  public setCurrentSearchPageIndex(currentIndex: number) {
    this._currentSearchPageIndex = currentIndex;
  }

  public reSetCurrentSearchPageIndex() {
    this._currentSearchPageIndex = 0;
  }

  public getSearchUrlParams(): any {
    if (this._searchBackURL) {
      const urlParams = {};
      this._searchBackURL.keys.forEach((k) => {
        urlParams[k] = this._searchBackURL.get(k);
      });
      return urlParams;
    } else {
      return {};
    }
  }

  public setSearchUrlParam(paramMap: ParamMap) {
    this._searchBackURL = paramMap;
  }
}
