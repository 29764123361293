import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { OfflineFeedService } from 'src/app/shared/services/offline/offline-feed.service';
import { OfflineStatusService } from 'src/app/shared/services/offline/offline-status.service';
import { OfflineCount } from 'src/app/shared/data-model/idb';
import { OfflineStates } from 'src/app/shared/enums/offline-states';
import { OfflineService } from 'src/app/shared/services/offline/offline.service';
import { OfflineDeactivateModalComponent } from './offline-deactivate-modal/offline-deactivate-modal.component';
import { OfflineLibUpdateModalComponent } from './offline-lib-update-modal/offline-lib-update-modal.component';
import { PlatformService } from 'src/app/shared/services/offline/platform.service';
import { PlatformMethod } from 'src/app/shared/services/offline/platform-methods';

@Component({
  selector: 'bell-my-offline-lib',
  templateUrl: './my-offline-lib.component.html',
  styleUrls: ['./my-offline-lib.component.scss']
})

export class MyOfflineLibComponent implements OnInit, OnDestroy {
  public updateProgress = 0;
  public offlineCount: OfflineCount;
  OfflineStates: typeof OfflineStates = OfflineStates;
  public subscriptions: Subscription[] = [];
  public totalDocsAdded: string;
  public totalDocsDownloadTime$: number;
  public updateDialogRef: MatDialogRef<OfflineLibUpdateModalComponent>;
  public deactivateDialogRef: MatDialogRef<OfflineDeactivateModalComponent>;
  public additionalDocsAdded$: number;
  public additionalDeleteDocsAdded$: number;
  public docNotDownloadedFromError;
  public diskSpaceAvailable: string;
  public docsToDelete;
  isOfflineActivated = false;
  checkIsOfflineActivated = true;
  isLoading = false;
  isEnableUpdate = false;
  isLoadingAdditionalFiles = false;
  public libraryUpdateStarted = false;
  public countUpdateComplete = true;

  constructor(
    public offlineService: OfflineService,
    public offlineFeedService: OfflineFeedService,
    public offlineStatusService: OfflineStatusService,
    public offlineUpdateDialog: MatDialog,
    public offlineDeactivateDialog: MatDialog,
    private platformService: PlatformService
  ) { }

  ngOnInit() {
    if (this.offlineService.isOfflineCapable) {
      this.platformService.invokePlatformMethod<number>(PlatformMethod.getFreeSpace).subscribe(space => {
        this.offlineStatusService.sendDiskSpace(space);
        this.diskSpaceAvailable = this.handleDiskSpaceBytes(space);
      });
    }

    this.checkDownload();

    this.subscriptions.push(
      this.offlineStatusService.downloadTimeEstimate$.subscribe(
        (num) => {
          this.totalDocsDownloadTime$ = num;
          if(this.updateDialogRef?.componentInstance) {
            this.updateDialogRef.componentInstance.totalDocsDownloadTime = num;
          }
        }
      )
    );

    this.subscriptions.push(
      this.offlineStatusService.updatingDocCounts$.subscribe(
        () => {
         this.checkDownload();
        }
      )
    );

    this.subscriptions.push(
      this.offlineStatusService.resetOfflineCount$.subscribe(
        (dc) => {
          this.offlineCount = dc;
          if(this.updateDialogRef?.componentInstance) {
            this.updateDialogRef.componentInstance.totalSizeString = dc.totalSizeString;
          }
          this.setDownloadState();
        }
      )
    );

    this.subscriptions.push(
      this.offlineFeedService.errorDownloadingDoc$.subscribe(
        (docs) => {
          this.docNotDownloadedFromError = docs;
        }
      )
    );


    this.subscriptions.push(
      this.offlineStatusService.diskSpaceAvailable$.subscribe(
        (size) => {
          this.diskSpaceAvailable = size;
        }
      )
    );

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(
      (s) => {
        s.unsubscribe();
      }
    );
  }

  activate() {
    this.isLoading = true;
    this.offlineFeedService.updateLastCheck().subscribe(() => {
      this.isOfflineActivated = true;
      this.isLoading = false;
      this.checkIsOfflineActivated = false;
      this.offlineStatusService.resetAllIdxData = true;
    });
  }

  handleDiskSpaceBytes(a, b = 2) {
    if (0 === a) {
      return '0 Bytes';
    }
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + ' ' + ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d];
  }

  openOfflineDialog(): void {
    this.isLoading = true;
    if (this.offlineCount.added === 0) {
      this.totalDocsAdded = null;
      this.totalDocsDownloadTime$ = null;
    }
    this.updateDialogRef = this.offlineUpdateDialog.open(
      OfflineLibUpdateModalComponent, {
      data: {
        isActivated: this.isOfflineActivated,
        dataSize: this.offlineCount.totalSizeString,
        dataTime: this.totalDocsDownloadTime$,
        dataDelete: this.docsToDelete,
        docFromError: this.docNotDownloadedFromError,
        diskSpaceFree: this.diskSpaceAvailable,
        offlineCount: this.offlineCount
      }
    });
    const subscribeDialogActivate = this.updateDialogRef.componentInstance.activate.subscribe((data) => {
      this.activate();
    });
    this.updateDialogRef.afterClosed().subscribe(() => {
      subscribeDialogActivate.unsubscribe();
    });
    const subscribeDialogUpdate = this.updateDialogRef.componentInstance.syncContent.subscribe((data) => {
      this.isEnableUpdate = false;
      this.isLoading = false;
      this.countUpdateComplete = true;
      this.offlineStatusService.resetAllIdxData = true;
      this.checkDownload();
      if (this.docNotDownloadedFromError && this.docNotDownloadedFromError.length > 0) {
        this.openOfflineDialog();
      } else {
        this.libraryUpdateStarted = false;
      }
    });
    this.updateDialogRef.afterClosed().subscribe(() => {
      subscribeDialogUpdate.unsubscribe();
    });
    const subscribeDialogClose = this.updateDialogRef.componentInstance.cancelUpdate.subscribe((data) => {
      this.cancelUpdate();
    });
    this.updateDialogRef.afterClosed().subscribe(() => {
      subscribeDialogClose.unsubscribe();
    });
    const subscribeDialogResume = this.updateDialogRef.componentInstance.resumeUpdate.subscribe((data) => {
      this.resumeUpdate();
    });
    this.updateDialogRef.afterClosed().subscribe(() => {
      subscribeDialogResume.unsubscribe();
    });
    const subscribeDialogUpdateStarted = this.updateDialogRef.componentInstance.updateStarted.subscribe((data) => {
      this.updateStarted();
    });
    this.updateDialogRef.afterClosed().subscribe(() => {
      subscribeDialogUpdateStarted.unsubscribe();
    });
  }

  openDeactivateDialog(): void {
    this.deactivateDialogRef = this.offlineDeactivateDialog.open(
      OfflineDeactivateModalComponent, {
      data: {}
    });

    const subscribeDialogDeActivate = this.deactivateDialogRef.componentInstance.deactivate.subscribe((data) => {
      this.offlineFeedService.isActivated().subscribe((result) => {
        this.isOfflineActivated = result;
        this.checkIsOfflineActivated = false;
      });
    });
  }



  cancelUpdate() {
    if (this.libraryUpdateStarted === true) {
      this.isLoading = false;
      this.libraryUpdateStarted = false;
      this.countUpdateComplete = true;
    } else {
      this.isLoading = false;
    }
  }

  updateStarted() {
    this.libraryUpdateStarted = true;
    this.countUpdateComplete = false;
  }

  resumeUpdate() {
    if (this.docNotDownloadedFromError) {
      this.docNotDownloadedFromError = null;
      this.libraryUpdateStarted = false;
      this.offlineStatusService.getDownloadCounts().subscribe((value: OfflineCount) => {
        this.isEnableUpdate = true;
      });
    } else {
      this.isLoading = false;
    }
  }

  private checkDownload() {
    this.offlineCount = new OfflineCount(0, 0, 0, 0);
    this.offlineFeedService.isActivated().subscribe((data) => {
      this.isOfflineActivated = data;
      this.checkIsOfflineActivated = false;
      if (this.isOfflineActivated) {
        this.offlineStatusService.getDownloadCounts().subscribe((dc) => {
          this.offlineCount = dc;
          if(this.updateDialogRef?.componentInstance) {
            this.updateDialogRef.componentInstance.totalSizeString = dc.totalSizeString.toString();
          }
          this.setDownloadState();
        });
      }
    });
  }

  private setDownloadState() {
    if (this.offlineCount.added > 0 || this.offlineCount.willBeDeleted > 0 || this.offlineCount.willUpdate > 0) {
      this.isEnableUpdate = true;
      this.offlineService.setstateEvent(OfflineStates.UPDATE_READY);
    } else {
      this.isEnableUpdate = false;
      this.offlineService.setstateEvent(OfflineStates.UP_TO_DATE);
    }
    if (this.offlineCount.willBeDeleted > 0) {
      this.docsToDelete = this.offlineCount.willBeDeleted;
      this.offlineService.setstateEvent(OfflineStates.UPDATE_READY);
    }
  }

}
