import { Router } from '@angular/router';
import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { IetmPrintService } from 'src/app/viewer/ietm/ietm-print/ietm-print-service/ietm-print.service';
import { IcnDataService } from 'src/app/shared/services/icn/icn-data.service';

@Component({
  selector: 'bell-ietm-print-action',
  templateUrl: './ietm-print-action.component.html',
  styleUrls: ['./ietm-print-action.component.scss'],
})
export class IetmPrintActionComponent implements OnInit, OnDestroy {
  private printMainContainer: HTMLElement;
  private toPrintContainerElement: HTMLElement;

  public printTextContainer: HTMLElement;
  public printFigureContainer: HTMLElement;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private ptps: IetmPrintService,
    private icnDataService: IcnDataService
  ) { }

  ngOnInit() {
    this.setPrintMainDocument();
  }

  setPrintMainDocument() {
    const printing = this.ptps.ietmPrintModalData.docType;
    this.toPrintContainerElement = this.renderer.createElement('bell-document-to-print');
    this.printMainContainer = this.ptps.printMainContainer;

    if (printing === 'text') {
      this.printText();
    }

    if (printing === 'figure') {
      this.printFigures();
    }

    if (printing === 'both') {
      this.printBoth();
    }
  }

  printText() {
    this.printTextContainer = this.getText();
    this.toPrintContainerElement.appendChild(this.printTextContainer);
    this.printMainContainer.append(this.toPrintContainerElement);
    this.getReadyToPrint();
  }

  private getText(): HTMLElement {
    const printTextContainer = document.createElement('div');
    printTextContainer.innerHTML = this.ptps.ietmPrintModalData.dataModule.html;
    // set inline image src
    const inlineImg = printTextContainer.getElementsByClassName('ietm_symbol');
    for (const value of Object.keys(inlineImg)) {
      let icn = inlineImg[value].getAttribute('src');
      icn = icn.slice(icn.indexOf('(') + 1, icn.indexOf(')'));

      this.icnDataService.getIcnImage(this.ptps.ietmPrintModalData.model, icn).subscribe((data) => {
        if (data) {
          inlineImg[value].setAttribute('src', data);
        }
      });
    }

    this.printFaultTreeImage(printTextContainer);

    for (let i = 0; i < printTextContainer.childNodes.length; i++) {
      const nodeText = printTextContainer.childNodes[i];
      const nodeName = nodeText.nodeName;

      if (nodeName === '#text') {
        nodeText.parentNode.removeChild(nodeText);
      }
    }

    return printTextContainer;
  }

  printFaultTreeImage(printTextContainer: HTMLDivElement) {
    const faultTreeImg = printTextContainer.getElementsByClassName('ietm_fault_tree_png');
    const ietmIsolationSteps = printTextContainer.getElementsByClassName('ietm_isolationStep');
    const ietmFaultTree = printTextContainer.getElementsByClassName('ietm_fault_tree');
    const ietmPrelregs = printTextContainer.getElementsByClassName('ietm_prelreqs');

    for (let i = 0; i < faultTreeImg.length; i++) {
      faultTreeImg[i].setAttribute('width', '90%');
      faultTreeImg[i].setAttribute('data-title', faultTreeImg[i].children[0].getAttribute('title'));

      const faultImageDiv = document.createElement('div');
      faultImageDiv.appendChild(faultTreeImg[i]);
      ietmPrelregs[0].after(faultImageDiv);
    }

    for (let i = 0; i < ietmIsolationSteps.length; i++) {
      ietmIsolationSteps[i].setAttribute('style', 'display: none');
    }

    for (let i = 0; i < ietmFaultTree.length; i++) {
      ietmFaultTree[i].setAttribute('style', 'display: block');
    }

    for (let index = 0; index < faultTreeImg.length; index++) {
      const element = faultTreeImg[index];
    }
  }

  printFigures() {
    this.printFigureContainer = this.ptps.printFigureContainer;
    this.toPrintContainerElement.appendChild(this.printFigureContainer);
    this.printMainContainer.append(this.toPrintContainerElement);

    this.getFiguresReadyToPrint();
  }

  private getFiguresReadyToPrint() {
    const allStyles = document.styleSheets;
    const allFigures = this.ptps.allFigures;

    for (let i = 0; i < allFigures.length; i++) {
      const elementId = allFigures[i].elementId;
      const styleSheetId = elementId + '-SVG';

      for (let j = 0; j < allStyles.length; j++) {
        const styleSheet = allStyles[j] as CSSStyleSheet;

        if (styleSheetId === styleSheet.ownerNode['id']) {
          const cssRules = styleSheet.cssRules;

          for (let k = 0; k < cssRules.length; k++) {
            const rule = cssRules[k];
            const selectors = rule['selectorText'];
            if (selectors) {
              const newSelectors = selectors.replace(/[.]/g, '#' + elementId + ' .');

              rule['selectorText'] = newSelectors;
            }
          }
        }
      }
    }

    this.getReadyToPrint();
  }

  printBoth() {
    this.printTextContainer = this.getText();
    this.toPrintContainerElement.appendChild(this.printTextContainer);
    this.printMainContainer.append(this.toPrintContainerElement);
    this.printFigures();
  }

  private getReadyToPrint() {
    this.removeElements(this.printMainContainer, ['router-outlet', '.ietm_isolationStep', 'A']);
    this.ptps.onDataReady();
  }

  private removeElements(elContainer: HTMLElement, elQuerySelectors: Array<any>) {
    for (let i = 0; i < elQuerySelectors.length; i++) {
      const querySelector = elQuerySelectors[i];

      elContainer.querySelectorAll(querySelector).forEach(el => {
        if (querySelector === 'A') {
          el.href = '';
        } else {
          el.parentNode.removeChild(el);
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.toPrintContainerElement && this.toPrintContainerElement.parentNode) {
      this.toPrintContainerElement.parentNode.removeChild(this.toPrintContainerElement);
      this.router.navigateByUrl(this.ptps.originUrl);
    }
  }
}
