<ng-container *ngIf="cCode">
  <section id="ccode-container">
    <h1>C-CODE: {{cCode.codes}}</h1>
    <div #Nomen>
      <p>
        <strong>Nomenclature: </strong> <span [innerHTML]="cCode.nomen"></span>
      </p>
      <p>
        <ng-container *ngIf="itHasNotes">
          <strong>Notes: </strong> <span [innerHTML]="cCode.notes"></span>
        </ng-container>
      </p>
    </div>
    <div class="table table--auto">
      <table>
        <thead>
          <tr>
            <th>Specification</th>
            <th>Material</th>
            <th>CAGE Code</th>
            <th>Bell Order Number</th>
            <th>Order Quantity</th>
            <th>Additional Information</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of cCode.items">
            <tr>
              <td [innerHTML]="item.spec_desc"></td>
              <td [innerHTML]="item.material"></td>
              <td [innerHTML]="item.cage_code"></td>
              <td [innerHTML]="item.coop_nomen"></td>
              <td [innerHTML]="item.order_qty"></td>
              <td [innerHTML]="item.addition_info"></td>
              <td>
                <a class="addtocart" *ngIf="item.order_qty" (click)="openPartDialog(item)" title="Add to cart">
                  <mat-icon class="">shopping_cart</mat-icon>
                </a>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </section>
</ng-container>
