<ng-container *ngIf="!isTocLoaded">
  <p>
    Loading table of contents ...
  </p>
</ng-container>
<div class="tocsorting" *ngIf="false">
  <p>SORT BY</p>
  <mat-form-field appearance="outline">
    <mat-select [(value)]="sortOption" placeholder="Sort">
      <mat-option value="relevancy">RELEVANCY</mat-option>
      <mat-option value="date-n">DATE NEWER</mat-option>
      <mat-option value="date-o">DATE OLDER</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<ng-container *ngIf="isModelToc">
  <bell-toc-model (tocLoaded)="onTocLoaded($event)" [model]="modelName" [expirationDate]="expirationDate"></bell-toc-model>
</ng-container>
<ng-container *ngIf="isManualToc">
  <bell-toc-manual (tocLoaded)="onTocLoaded($event)" [model]="modelName" [manual]="manualName" [expirationDate]="expirationDate"></bell-toc-manual>
</ng-container>
