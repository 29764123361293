export class SearchResult {
  title: string | undefined;
  idManual: number | undefined;
  models: string[] | undefined;
  isVisible: boolean | undefined;
  name: string | undefined;
  code: string | undefined;
  momodel: string | undefined;
  s1000d: boolean | undefined;
  figure: string | undefined;
  ccode: string | undefined;
  ALL: string | undefined;
  lastUpdate: Date | undefined;
}
