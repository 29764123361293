import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PlatformService } from './offline/platform.service';
import { LoginService } from './user/login.service';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class GuardService implements CanActivate {
  previousRoute: string;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private platformService: PlatformService,
    private locationService: Location
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    // saving route before validating access to viewer, will use it for 'back to previous page' button
    this.previousRoute = this.retrievePreviousRoute();
    return this.loginService.processLoginRedirectAsync().pipe(map(result => {
      if (result) {
        this.locationService.replaceState(''); // remove access token from current location before navigating on
        const redirectUrl = sessionStorage.getItem('redirectUrl');
        if (redirectUrl && redirectUrl.length > 0) {
          this.router.navigateByUrl(redirectUrl);
          sessionStorage.removeItem('redirectUrl');
          return true;
        } else {
          if (this.platformService.isOfflineCapable()) {
            this.router.navigate(['update']);
            return true;
          }
          else {
            this.router.navigate(['']);
            return true;
          }
        }
      }
      if (this.loginService.isUserLoggedInOrOffline()) {
        return true;
      }
      if(state.url !== '/'){
        sessionStorage.setItem('redirectUrl', state.url);
      }
      //sessionStorage.setItem('redirectUrl', state.url);
      this.loginService.login();
      return false;
    }));
  }

  retrievePreviousRoute(): string {
    const url = this.router.routerState.snapshot.url;
    if (url) {
      // do not change previous url if called from legacy viewer
      if (!url.includes('doc') && !url.includes('dual')) {
        return url;
      }
      return this.previousRoute;
    }
    return undefined;
  }

  getPreviousRoute(): string {
    return this.previousRoute;
  }
}
