import { Injectable } from '@angular/core';
import { IetmRouteService } from './ietm-service/ietm-route.service';
import { S1000DBreadcrumb, IetmDataModule } from './ietm-service/ietm-model';

@Injectable({
  providedIn: 'root'
})
export class IetmBreadcrumbService {
  S1000DBreadcrumbs: Array<S1000DBreadcrumb> = [];
  constructor(
    private routeService: IetmRouteService
  ) {
  }

  clearHistory(dmc: string): void {
    if (this.S1000DBreadcrumbs.length > 0) {
      let idx = -1;
      for (let i = 0; i < this.S1000DBreadcrumbs.length; i++) {
        if (this.S1000DBreadcrumbs[i].dmc === dmc) {
          idx = i;
          break;
        }
      }
      if (idx < 0) {
        this.S1000DBreadcrumbs = [];
      } else {
        let cpt = 0;
        while (cpt <= idx) {
          this.S1000DBreadcrumbs.shift();
          cpt++;
        }
      }
    }
  }

  addHistory(data: IetmDataModule, istp?: string) {
    let idx = -1;
    for (let i = 0; i < this.S1000DBreadcrumbs.length; i++) {
      if (this.S1000DBreadcrumbs[i].dmc === data.dmc) {
        idx = i;
        break;
      }
    }
    // Remove the breadcrumbs that are located after
    if (idx > 0) {
      let cpt = 0;
      while (cpt < idx) {
        this.S1000DBreadcrumbs.shift();
        cpt++;
      }
      return;
    }

    this.S1000DBreadcrumbs.unshift({ //add to beginning of array
      model: data.model,
      pmc: data.pmc,
      dmc: data.dmc,
      icn: data.icn,
      istp: istp,
      title: data.subtitle && data.subtitle.length > 0 ? data.title + ' - ' + data.subtitle : data.title,
      tocView: this.routeService.tocView
    });
  }

  getBreadcrumbAddress(breadcrumb: S1000DBreadcrumb) {
    const params = ['/ietm', breadcrumb.model];
    if (breadcrumb && breadcrumb.pmc) {
      params.push(breadcrumb.pmc);
      if (breadcrumb.dmc) {
        params.push(breadcrumb.dmc);
        if (breadcrumb.icn) {
          params.push(breadcrumb.icn);
        }
      }
      return params;
    }
  }
  getBreadcrumbQueryParams(breadcrumb: S1000DBreadcrumb) {
    let params: any = {};
    let retParams = false;
    if (breadcrumb.istp) { params.istp = breadcrumb.istp; retParams = true; }
    if (breadcrumb.tocView) { params.tocView = breadcrumb.tocView; retParams = true; }
    return retParams ? params : null;
  }
}
