import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotFoundSnackbarComponent } from 'src/app/shared/components/not-found-snackbar/not-found-snackbar.component';
import { HttpFileDownloadService } from 'src/app/shared/services/http-progress-event/http-file-download.service';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'bell-binderlabels',
  templateUrl: './binderlabels.component.html',
  styleUrls: ['./binderlabels.component.scss'],
})
export class BinderLabelsComponent implements OnInit {
  selectedModel =   { model: '206A',
    link_2in: '9263', analyticLabel_2in: 'Binder Label 206A-9263',
    link_3in: '9264', analyticLabel_3in: 'Binder Label 206A-9264'};
  selected = false;
  visVis = {'visibility': 'visible', 'opacity': 1,
    'transition': 'visibility 0s linear 0s, opacity 0.5s'};
  visHide = {'visibility': 'hidden', 'opacity': 0};
  binderLabels = [
  { model: '206A',
    link_2in: '9263', analyticLabel_2in: 'Binder Label 206A-9263',
    link_3in: '9264', analyticLabel_3in: 'Binder Label 206A-9264'},
  { model: '206B',
    link_2in: '9451', analyticLabel_2in: 'Binder Label 206B-9451',
    link_3in: '9452', analyticLabel_3in: 'Binder Label 206B-9452'},
  { model: '206B3',
    link_2in: '9453', analyticLabel_2in: 'Binder Label 206B3-9453',
    link_3in: '9454', analyticLabel_3in: 'Binder Label 206B3-9454'},
  { model: '206L',
    link_2in: '9455', analyticLabel_2in: 'Binder Label 206L-9455',
    link_3in: '9456', analyticLabel_3in: 'Binder Label 206L-9456'},
  { model: '206L1',
    link_2in: '9457', analyticLabel_2in: 'Binder Label 206L1-9457',
    link_3in: '9458', analyticLabel_3in: 'Binder Label 206L1-9458'},
  { model: '206L3',
    link_2in: '9459', analyticLabel_2in: 'Binder Label 206L3-9459',
    link_3in: '9460', analyticLabel_3in: 'Binder Label 206L3-9460'},
  { model: '206L4',
    link_2in: '9461', analyticLabel_2in: 'Binder Label 206L4-9461',
    link_3in: '9462', analyticLabel_3in: 'Binder Label 206L4-9462'},
  { model: '212',
    link_2in: '9463', analyticLabel_2in: 'Binder Label 212-9463',
    link_3in: '9464', analyticLabel_3in: 'Binder Label 212-9464'},
  { model: '214B',
    link_2in: '10733', analyticLabel_2in: 'Binder Label 214B-10733',
    link_3in: '10734', analyticLabel_3in: 'Binder Label 214B-10734'},
  { model: '214B1',
    link_2in: '10735', analyticLabel_2in: 'Binder Label 214B1-10735',
    link_3in: '10736', analyticLabel_3in: 'Binder Label 214B1-10736'},
  { model: '214ST',
    link_2in: '10737', analyticLabel_2in: 'Binder Label 214ST-10737',
    link_3in: '10738', analyticLabel_3in: 'Binder Label 214ST-10738'},
  { model: '222',
    link_2in: '10743', analyticLabel_2in: 'Binder Label 222-10743',
    link_3in: '10744', analyticLabel_3in: 'Binder Label 222-10744'},
  { model: '222B',
    link_2in: '10742', analyticLabel_2in: 'Binder Label 222B-10742',
    link_3in: '10741', analyticLabel_3in: 'Binder Label 222B-10741'},
  { model: '222U',
    link_2in: '10740', analyticLabel_2in: 'Binder Label 222U-10740',
    link_3in: '10739', analyticLabel_3in: 'Binder Label 222U-10739'},
  { model: '407',
    link_2in: '9465', analyticLabel_2in: 'Binder Label 407-9465',
    link_3in: '9466', analyticLabel_3in: 'Binder Label 407-9466'},
  { model: '412',
    link_2in: '9467', analyticLabel_2in: 'Binder Label 412-9467',
    link_3in: '9468', analyticLabel_3in: 'Binder Label 412-9468'},
  { model: '427',
    link_2in: '9469', analyticLabel_2in: 'Binder Label 427-9469',
    link_3in: '9470', analyticLabel_3in: 'Binder Label 427-9470'},
  { model: '429',
    link_2in: '10105', analyticLabel_2in: 'Binder Label 429-10105',
    link_3in: '9741', analyticLabel_3in: 'Binder Label 429-9741'},
  { model: '430',
    link_2in: '9471', analyticLabel_2in: 'Binder Label 430-9471',
    link_3in: '9472', analyticLabel_3in: 'Binder Label 430-9472'},
  { model: 'common',
    link_2in: '9473', analyticLabel_2in: 'Binder Label common-9473',
    link_3in: '9474', analyticLabel_3in: 'Binder Label common-9474'}
  ];

  constructor(
    private httpFileDownloadService: HttpFileDownloadService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
  }

  changeModel(event) {
    this.selected = true;
    this.selectedModel = event.value;
  }

  getBinderLabelFile(event: any, fileId: any, label: any) {
    this.httpFileDownloadService.getPDF(fileId, label).subscribe(
      (data) => {
        if (data && data.type === HttpEventType.Response) {
          return data;
        }
      },
      (error) => {
        this.snackBar.openFromComponent(NotFoundSnackbarComponent, {
          data: 'Error - Unable to retrieve PDF file.'
        });
      }
    );
  }
}
