import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Release } from '../../data-model/release';
import { HttpUtilityService } from '../../utils/http-utility.service';
import { Observable } from 'rxjs-compat';
import { map } from 'rxjs/operators';
import { parse } from 'yaml';
import { EnvironmentService } from '../environment.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineLibService {
  private _releases: BehaviorSubject<Array<Release>> = new BehaviorSubject([]);
  public releases$: Observable<Array<Release>> = this._releases.asObservable();


  constructor(
    private http: HttpUtilityService,
    private env: EnvironmentService
  ) {
    this.getLatestVersionInfo();
  }

  getLatestVersionInfo() {
    this.http.getSvg(`${this.env.getOfflineLocation()}/latest.yml`).pipe(map(res => parse(res))).subscribe((yaml: {version: string, path: string, releaseDate: string}) => {
      let release: Release = {
        notes: [
          {
            date: new Date(yaml.releaseDate),
            label: 'Release',
            note: ''
          }
        ],
        label: yaml.version,
        path: `${this.env.getOfflineLocation()}/${yaml.path}`,
        target: '_blank'
      }
      this._releases.next([release]);
    });
  }
}
