<ng-container *ngIf="modelToc">
  <div class="offline-selection-wrapper" *ngIf="offlineService.isOfflineCapable" >
    <mat-checkbox class="offline-selection-all" labelPosition="before"
    name="all"
    (change)="selectAll($event)"
    [checked]="checkSelectAll()"
    [disabled]="offlineService.isOffline">Select Offline Content</mat-checkbox>
  </div>
  <div class="toc-header">
    <h3 class="model-title">
      {{modelToc.pageTitle}}
    </h3>
    <div class="expiration-date" *ngIf="expirationDate" title="Your access to this model's publications will expire on {{expirationDate}}.">Expires on {{expirationDate}}</div>
  </div>
  <ul class="manuals" [class.offline]="offlineService.isOffline">
    <li class="manual" *ngIf="modelToc.s1000d">
      <a class="manual-title doc" [routerLink]="modelToc.s1000d.url">{{modelToc.s1000d.title}}</a>
    </li>
    <ng-container *ngFor="let manual of modelToc.toc; let i = index">
      <hr *ngIf="(i != 0 && manual.grouping != modelToc.toc[i - 1].grouping) || (i == 0 && modelToc.s1000d)"/>
      <li class="manual">
          <a class="manual-title doc {{manual.downloadStatus}}" routerLink="/toc/{{manual.model}}/{{manual.toc}}"
          [class.isDisabled]="offlineService.isOffline && manual.usable === false">{{manual.manual}}</a>
        <mat-checkbox class="offline-selection"
          [checked]="manual.isChecked == OfflineContentStatus.Full"
          (change)="selectManual($event, manual)" *ngIf="showCheckbox(manual.toc)"
          [indeterminate]="manual.isChecked == OfflineContentStatus.Partial" [disabled]="offlineService.isOffline"></mat-checkbox>
      </li>
    </ng-container>
  </ul>
</ng-container>
