import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { RouteService } from 'src/app/shared/services/route.service';
import { Subscription } from 'rxjs';
import { NotFoundSnackbarComponent } from 'src/app/shared/components/not-found-snackbar/not-found-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OfflineService } from 'src/app/shared/services/offline/offline.service';
import { HttpFileDownloadService } from 'src/app/shared/services/http-progress-event/http-file-download.service';
import { HttpEventType } from '@angular/common/http';
import { GeneralInfoLinks } from './search-bar-links';
import { RedirectService } from 'src/app/shared/services/redirect.service';

@Component({
  selector: 'bell-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy {
  public dropdownCollapse = true;
  public currentModel: string;
  public searchQuery: string;

  public generalInfoLinks = GeneralInfoLinks;

  private routerSubscription: Subscription;

  constructor(
    public httpFileDownloadService: HttpFileDownloadService,
    public offlineService: OfflineService,
    private routeService: RouteService,
    private router: Router,
    private snackBar: MatSnackBar,
    private redirectService: RedirectService,
  ) { }

  ngOnInit() {
    this.trackRoute();
    this.routerSubscription = this.router.events.subscribe(
      (event: Event) => {
        if (event instanceof NavigationEnd) {
          this.trackRoute();
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  trackRoute(): void {
    this.currentModel = this.routeService.getParameter('model');
  }

  search(): void {
    if (this.searchQuery && this.searchQuery.trim() !== '') {
      this.searchQuery = this.searchQuery.trim();
      this.redirectService.reSetCurrentSearchPageIndex();
      // if we are on a model page, use this model as a search filter
      const model = this.currentModel ? this.currentModel : 'ALL';
      this.router.navigate(['search'], {
        queryParams: { q: this.searchQuery, option: 'contains', model: model }
      });
    }
  }

  openPDF(link: any) {
    if (!this.dropdownCollapse) {
      this.toggleDropdown();
    }

    if (link) {
      this.httpFileDownloadService.getPDF(link.path, link.label).subscribe(
        (data) => {
          if (data && data.type === HttpEventType.Response) {
            return data;
          }
        },
        (error) => {
          this.snackBar.openFromComponent(NotFoundSnackbarComponent, {
            data: 'Error - Unable to retrieve PDF file.'
          });
        }
      );
    }
  }

  toggleDropdown() {
    this.dropdownCollapse = !this.dropdownCollapse;
  }

  onResize(event) {
    if (!this.dropdownCollapse) {
      this.toggleDropdown();
    }
  }
}
