import { Injectable } from '@angular/core';
import { Observable, Subject, observable, Observer, BehaviorSubject } from 'rxjs';
import { ModelToc } from '../../data-model/model-toc';
import { OfflineService } from '../offline/offline.service';
import { ManualToc } from '../../data-model/manual-toc';
import { OfflineZipReaderService } from '../../services/offline/offline-zip-reader.service';
import { ManualFilter } from '../../data-model';
import { filter } from 'rxjs/operators';
import { DataService } from '../data-services/data.service';

@Injectable({
  providedIn: 'root'
})
export class TocService {
  private loadedModel$: Record<string, BehaviorSubject<ModelToc>> = {};
  private loadedManual$: Record<string, BehaviorSubject<ManualToc>> = {};
  private loadedAllManual$: Record<string, BehaviorSubject<ManualFilter[]>> = {};
  private allFilterKey = 'manualFilter';
  constructor(
    private offlineService: OfflineService,
    private dataService: DataService,
    private offlineZipReaderService: OfflineZipReaderService,
  ) { }

  getModelToc(modelName: string): Observable<ModelToc> {
    return new Observable((observer: Observer<ModelToc>) => {
      this.getModelDataSubjects(modelName).pipe(filter(f => f !== undefined)).subscribe(data => {
        observer.next(data);
        observer.complete();
      });
    });
  }

  getManualToc(modelName: string, manualName: string): Observable<ManualToc> {
    return new Observable((observer: Observer<ManualToc>) => {
      this.getManualDataSubjects(modelName, manualName).pipe(filter(f => f !== undefined)).subscribe(data => {
        observer.next(data);
        observer.complete();
      });
    });
  }

  getAllManuals(): Observable<ManualFilter[]> {
    return new Observable((observer: Observer<ManualFilter[]>) => {
      this.getManualFilterDataSubjects().pipe(filter(f => f !== undefined)).subscribe(data => {
        observer.next(data);
        observer.complete();
      });
    });
  }

  private getModelServerData(modelName: string) {
    if (this.loadedModel$[modelName]) {
      let modelToc$: Observable<ModelToc>;
      if (this.offlineService.isOffline) {
        modelToc$ = this.offlineZipReaderService.getModelToc(modelName);
      } else {
        modelToc$ = this.dataService.getModelToc(modelName);
      }
      modelToc$.subscribe((data: ModelToc) => {
        this.loadedModel$[modelName].next(data);
      });
    }
  }

  private getModelDataSubjects(modelName: string): BehaviorSubject<ModelToc> {
    if (!this.loadedModel$[modelName]) {
      this.loadedModel$[modelName] = <BehaviorSubject<ModelToc>> new BehaviorSubject(undefined);
      this.getModelServerData(modelName);
      return this.loadedModel$[modelName];
    } else {
      return this.loadedModel$[modelName];
    }
  }

  private getManualServerData(modelName: string, manualName: string) {
    if (this.loadedManual$[`${modelName}-${manualName}`]) {
      let modelToc$: Observable<ManualToc>;
      if (this.offlineService.isOffline) {
        modelToc$ = this.offlineZipReaderService.getManualToc(modelName, manualName);
      } else {
        modelToc$ = this.dataService.getManualToc(modelName, manualName);
      }
      modelToc$.subscribe((data: ManualToc) => {
        this.loadedManual$[`${modelName}-${manualName}`].next(data);
      });
    }
  }

  private getManualDataSubjects(modelName: string, manualName: string): BehaviorSubject<ManualToc> {
    if (!this.loadedManual$[`${modelName}-${manualName}`]) {
      this.loadedManual$[`${modelName}-${manualName}`] = <BehaviorSubject<ManualToc>> new BehaviorSubject(undefined);
      this.getManualServerData(modelName, manualName);
      return this.loadedManual$[`${modelName}-${manualName}`];
    } else {
      return this.loadedManual$[`${modelName}-${manualName}`];
    }
  }

  private getManualFilterServerData() {
    if (this.loadedAllManual$[this.allFilterKey]) {
      let manualFilter$: Observable<ManualFilter[]>;
      if (this.offlineService.isOffline) {
        manualFilter$ = this.offlineZipReaderService.getManualFilters();
      } else {
        manualFilter$ = this.dataService.getManualFilters();
      }
      manualFilter$.subscribe((data: ManualFilter[]) => {
        this.loadedAllManual$[this.allFilterKey].next(data);
      });
    }
  }

  private getManualFilterDataSubjects(): BehaviorSubject<ManualFilter[]> {
    if (!this.loadedAllManual$[this.allFilterKey]) {
      this.loadedAllManual$[this.allFilterKey] = <BehaviorSubject<ManualFilter[]>> new BehaviorSubject(undefined);
      this.getManualFilterServerData();
      return this.loadedAllManual$[this.allFilterKey];
    } else {
      return this.loadedAllManual$[this.allFilterKey];
    }
  }
}
