export class PmIcn {
  icn: string;
}

export class Pmc {
  rowid: number | undefined;
  pubCode: string | undefined;
  pubName: string | undefined;
  directory: string | undefined;
  titlePage: string | undefined;
  icns: PmIcn[];
}


