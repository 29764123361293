<div>
  <div mat-dialog-content>
    <p class="disable-text">You are about to <strong>delete all of the techpubs offline content</strong> from your
      library. Do you want to proceed?</p>
  </div>
  <section id="loadProgressBar" *ngIf="isDeactivating">
    <mat-progress-bar mode="indeterminate" *ngIf="isDeactivating"></mat-progress-bar>
  </section>
  <div class="buttons-group">
    <button mat-flat-button class="start-update" [disabled]="isDeactivating" (click)="yesDeactivate()">Yes</button>
    <button mat-flat-button class="start-update" (click)="cancelModal()" [disabled]="isDeactivating">Cancel</button>
  </div>
</div>
