<div class="header">
  <div class="tp-image">
    <bell-navigation>
      <ng-content></ng-content>
    </bell-navigation>
    <div class="tp-title" *ngIf="!offlineService.isOffline">
      <p>Technical Publications</p>
    </div>
    <div class="tp-offline" *ngIf="offlineService.isOffline">
      <p class="tp-title-offline">Technical Publications Offline</p>
    </div>
  </div>
  <bell-search-bar>
    <ng-content></ng-content>
  </bell-search-bar>
</div>
