import { Injectable } from '@angular/core';
import { DataService } from '../data-services/data.service';
import { Observable, of } from 'rxjs';
import { OfflineService } from '../offline/offline.service';
import { OfflineSearchService } from '../offline/offline-search.service';
import { SearchResult } from '../../data-model/search-results';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    private dataService: DataService,
    private offlineService: OfflineService,
    private offlineSearchService: OfflineSearchService,
  ) { }

  getSearchResults(query: string, option: string): Observable<SearchResult[]> {
    if (this.offlineService.isOffline) {
      return this.offlineSearchService.getSearchResults(query, option);
    } else {
      return this.dataService.getSearchResults(query, option);
    }
  }

  getDocumentSearchResults(query: string, option: string, path: string, filename: string): Observable<any> {
    if (this.offlineService.isOffline) {
      return this.offlineSearchService.getDocumentSearchResults(query, option, path, filename);
    } else {
      return this.dataService.getDocumentSearchResults(query, option, path, filename);
    }
  }
}
