import { Directive, ElementRef, Input, OnInit, OnChanges, SimpleChanges  } from '@angular/core';

@Directive({
  selector: '[bellPrintElement]'
})

export class PrintElementDirective implements OnInit, OnChanges {
  @Input() bellPrintElement: string;
  @Input() documentSection: string;
  @Input() sectionType: string;
  @Input() sectionText: string;

  public printEl;
  public documentSections = {
    header: {
      name: 'header',
      title: 'H1',
      subTitle: 'H2',
      p: 'P'
    },
    footer: {
      name: 'footer',
      p: 'P'
    },
    body: {
      name: 'body',
      sectionTitle: 'H3',
      sectionSubTitle: 'H4',
      p: 'P'
    }
  };

  constructor(printEl: ElementRef) {
    this.printEl = printEl;
  }

  ngOnInit() {
    this.printEl.nativeElement.dataset.print = 'true';

    this.setPrintSectionAndTag();
    this.setPrintContent();

    if (this.printEl.nativeElement.dataset.sectionText === '') {
      this.printEl.nativeElement.dataset.sectionType = this.printEl.nativeElement.tagName;
      this.printEl.nativeElement.removeAttribute('data-section-text');
    }    
  }

  setPrintSectionAndTag() {
    if (this.documentSections[this.documentSection]) {
      this.printEl.nativeElement.dataset.documentSection = this.documentSections[this.documentSection].name;

      if (this.documentSections[this.documentSection][this.sectionType]) {
        this.printEl.nativeElement.dataset.sectionType = this.documentSections[this.documentSection][this.sectionType];
      } else {
        this.printEl.nativeElement.dataset.sectionType = this.documentSections[this.documentSection].p;
      }
    } else {
      this.printEl.nativeElement.dataset.documentSection = this.documentSections.body.name;

      if (this.documentSections.body[this.sectionType]) {
        this.printEl.nativeElement.dataset.sectionType = this.documentSections.body[this.sectionType];
      } else {
        this.printEl.nativeElement.dataset.sectionType = this.documentSections.body.p;
      }
    }
  }

  setPrintContent() {
    if (this.sectionText) {
      this.printEl.nativeElement.dataset.sectionText = this.sectionText;
    } else {
      this.printEl.nativeElement.dataset.sectionText = this.printEl.nativeElement.textContent;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    Object.keys(changes).forEach(
      (propName) => {
        const changedProp = changes[propName];
        const changedValue = changedProp.currentValue;

        if (!changedProp.isFirstChange()) {
          this[propName] = changedValue;
          this.printEl.nativeElement.dataset[propName] = changedValue;
        }
      }
    );
  }
}
