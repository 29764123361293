import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, Event, Params, ActivatedRoute } from '@angular/router';
import { RouteService } from '../shared/services/route.service';
import { UserProductService } from '../shared/services/user/user-product.service';
import { Subscription, observable, Observable, combineLatest, Observer } from 'rxjs';
import { TocService } from '../shared/services/toc/toc.service';
import { map } from 'rxjs/operators';
import { SearchService } from '../shared/services/search/search.service';
import { SearchResult } from '../shared/data-model/search-results';
import { OfflineService } from '../shared/services/offline/offline.service';
import { SearchTypes } from '../shared/enums/search-type';
import { RedirectService } from '../shared/services/redirect.service';

@Component({
  selector: 'bell-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

  models: string[];
  modelsCount: any;
  modelFilters: string[];

  manuals: any[];
  manualsCount: any;
  manualsFilter: number;

  query: any;
  exact: boolean;
  filterOffline: boolean;
  tocView: any;

  results: SearchResult[];
  filteredResults: SearchResult[];

  isLoading = false;

  pageIndex = 0;
  pageSize = 30;

  previous = {
    query: undefined,
    exact: undefined
  };

  routerSubscription: Subscription;

  constructor(
    private searchService: SearchService,
    private routeService: RouteService,
    private productService: UserProductService,
    private tocService: TocService,
    private router: Router,
    public offlineService: OfflineService,
    public redirectService: RedirectService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    if (this.offlineService.isOffline) {
      this.filterOffline = true;
    }
    const allCalls$ = combineLatest([
      this.productService.getUserModels(),
      this.tocService.getAllManuals()
    ]);

    allCalls$.pipe(
      map(returns => ({ models: returns[0], manuals: returns[1] }))
    ).subscribe((data) => {
      this.models = data.models;
      this.manuals = data.manuals;
      this.doSearch();
      this.routerSubscription = this.router.events.subscribe(
        (event: Event) => {
          if (event instanceof NavigationEnd) {
            this.doSearch();
          }
        }
      );
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  doSearch() {
    this.isLoading = true;
    this.query = this.routeService.getQueryParameter('q');
    this.exact = this.routeService.getQueryParameter('option') === 'exact';
    this.tocView = this.routeService.getQueryParameter('tocView');
    const model = this.routeService.getQueryParameter('model');
    const manual = this.routeService.getQueryParameter('manual');

    if (this.query && model) {
      if (model === 'ALL') {
        this.modelFilters = [];
      } else {
        this.modelFilters = model.split(',');
      }
      if (manual && this.manuals) {
        this.manualsFilter = this.manuals.find(m => m.manual_identifier === manual).id_manual;
      } else {
        this.manualsFilter = undefined;
      }
      if (this.previous.query !== this.query || this.previous.exact !== this.exact) {
        this.getResults().subscribe(() => {
          this.countResults();
          this.filterResults();
          this.previous.query = this.query;
          this.previous.exact = this.exact;
          this.isLoading = false;
        });
      } else {
        this.countResults();
        this.filterResults();
        this.isLoading = false;
      }
    }
  }

  getResults(): Observable<void> {
    this.results = undefined;
    return new Observable<void>((observer: Observer<void>) => {
      if (this.query) {
        const option = this.exact ? 'exact' : 'contains';
        this.searchService.getSearchResults(this.query, option).subscribe((result) => {
          this.results = result;
          observer.next();
          observer.complete();
        });
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

  goToResult(result: SearchResult): void {
    if (!this.isDisabled(result)) {
      const option = this.exact ? 'exact' : 'contains';
      if (result.s1000d && (this.tocView === undefined || this.tocView === null)) {
        this.tocView = 'manual';
      }
      const params = this.buildQueryParams(this.query, option, this.tocView, result);
      const href = this.buildResultHref(result);

      this.redirectService.setSearchUrlParam(this.activatedRoute.snapshot.queryParamMap);

      this.router.navigate([href], {
        queryParams: params
      });
    }
  }

  buildResultHref(result: SearchResult): string {
    let href: string;
    const model = this.getModel(result);
    // convert string to number type for switch comparison
    let numIdManual = Number(result.idManual);
    switch (numIdManual) {
      case SearchTypes.CCODES:
        href = '/ccode/' + `${result.ccode}`;
        break;
      case SearchTypes.IPB_FIGURE:
        href = '/doc/' + `${model}/${result.name}/f${result.figure}/ipb`;
        break;
      default:
        if (result.s1000d) {
          href = '/ietm/' + `${result.momodel}/${result.code}/${result.name}`;
        } else {
          href = '/doc/' + `${model}/${result.name}`;
        }
    }
    return href;
  }

  buildQueryParams(query: any, option: string, tocView: string, result: SearchResult): Params {
    let params: Params;

    if (result.figure) {
      params = {
        searchIPB: query,
      };

      return params;
    } else {
      params = {
        search: query,
        option: option,
        tocView: tocView,
        view: 'toc'
      };

      return params;
    }
  }

  getModel(result: SearchResult): string {
    let model: string;
    const modelName = result.name;
    const modelReGex = /^(\d{3}\w*)-[\w\-]+/;

    if (modelReGex.test(modelName)) {
      model = modelReGex.exec(modelName)[1];
    }

    if (result.models.indexOf(model) < 0) {
      model = result.models[0];
    } else if (!model) {
      model = 'ALL';
    }

    return model;
  }

  changeSearchPage($event: any): void {
    this.redirectService.setCurrentSearchPageIndex($event.pageIndex);
    this.pageIndex = this.redirectService.getCurrentSearchPageIndex();
  }

  changeSearchOption(): void {
    const option = this.exact ? 'contains' : 'exact';
    this.router.navigate([], {
      queryParams: { option: option },
      queryParamsHandling: 'merge'
    });
  }

  changeOfflineOption(): void {
    this.filterOffline = !this.filterOffline;
    this.filterResults();
  }

  isDisabled(ietm: SearchResult): boolean {
    if (this.offlineService.isOffline && !ietm.ccode) {
      return (ietm.lastUpdate === null || ietm.lastUpdate === undefined);
    } else {
      return false;
    }
  }

  filterResults(): void {
    this.setPageIndex();
    if (this.modelFilters.length > 0) {
      this.filteredResults = this.results.filter(r => {
        for (let i = 0; i < this.modelFilters.length; i++) {
          if (r.models.includes(this.modelFilters[i])) {
            return true;
          }
        }
        return false;
      });
    } else {
      this.filteredResults = this.results;
    }
    if (this.manualsFilter) {
      this.filteredResults = this.filteredResults.filter(fr => fr.idManual == this.manualsFilter);
    }

    if (this.offlineService.isOffline && this.filterOffline) {
      this.filteredResults = this.filteredResults.filter(fr => (fr.ccode || fr.lastUpdate !== null && fr.lastUpdate !== undefined));
    }
  }

  private setPageIndex() {
    this.pageIndex = this.redirectService.getCurrentSearchPageIndex();
  }

  private reSetPageIndex() {
    this.redirectService.reSetCurrentSearchPageIndex();
    this.setPageIndex();
  }

  toggleManualFilter(manualIdentifier: number): void {
    this.reSetPageIndex();
    this.router.navigate([], {
      queryParams: { manual: this.manualsFilter ? undefined : manualIdentifier },
      queryParamsHandling: 'merge'
    });
  }

  toggleModelFilter(model: string): void {
    this.reSetPageIndex();
    if (this.modelFilters.includes(model)) {
      this.modelFilters = this.modelFilters.filter(mf => mf !== model);
      if (this.modelFilters.length === 0) {
        this.modelFilters.push('ALL');
      }
    } else {
      if (this.modelFilters.includes('ALL')) {
        this.modelFilters = [];
      }
      this.modelFilters.push(model);
    }
    this.router.navigate([], {
      queryParams: { model: this.modelFilters.join(',') },
      queryParamsHandling: 'merge'
    });
  }

  removeAllModelFilter(): void {
    this.reSetPageIndex();
    this.router.navigate([], {
      queryParams: { model: 'ALL' },
      queryParamsHandling: 'merge'
    });
  }

  removeManualFilter(): void {
    this.reSetPageIndex();
    this.router.navigate([], {
      queryParams: { manual: undefined },
      queryParamsHandling: 'merge'
    });
  }

  countResults(): void {
    this.modelsCount = [];
    if (this.results && this.models && this.models.length > 0) {
      this.models.forEach(m => {
        this.modelsCount[m] = this.results.filter(
          r => r.models.includes(m) && (!this.manualsFilter || r.idManual === this.manualsFilter)).length;
      });
    }
    this.manualsCount = [];
    if (this.results && this.manuals && this.manuals.length > 0) {
      this.manuals.forEach(m => {
        this.manualsCount[m.id_manual] = this.results.filter(r => r.idManual == m.id_manual).length;
      });
    }
  }

}
