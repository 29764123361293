import { Injectable } from '@angular/core';
import { UserProduct } from '../../data-model/user-product';
import { Observable, Observer, forkJoin } from 'rxjs';
import { OfflineService } from '../offline/offline.service';
import { GlobalDataService } from '../offline/dexie/global-data.service';
import { DataService } from '../data-services/data.service';

@Injectable({
  providedIn: 'root'
})
export class UserProductService {
  userProduct: UserProduct;
  userModels: string[];
  allModels: string[];
  userClassifications: string[];

  constructor(
    private dataService: DataService,
    private globalDataService: GlobalDataService,
    private offlineService: OfflineService,
  ) {
    this.userProduct = null;
    this.userModels = [];
    this.allModels = [];
  }

  getUserProduct(): Observable<UserProduct> {
    return new Observable((subscriber: Observer<UserProduct>) => {
      if (this.userProduct === null) {
        if (this.offlineService.isOffline) {
          this.globalDataService.getByKey('userProduct').subscribe((data) => {
            this.userProduct = data.data;
            subscriber.next(this.userProduct);
            subscriber.complete();
          });
        } else {
          this.dataService.getUserProduct().subscribe({
            next: data => {
              this.userProduct = data;
              subscriber.next(this.userProduct);
              subscriber.complete();
            }, error: (err) => { console.log(err); }
          });
        }
      } else {
        subscriber.next(this.userProduct);
        subscriber.complete();
      }
    });
  }

  getAllModels(): Observable<string[]> {
    return new Observable((observer: Observer<string[]>) => {
      if (this.offlineService.isOffline) {
        this.globalDataService.getByKey('allModels').subscribe(data => {
          if (data){
            data.data.forEach(m => {
              this.allModels.push(m.model);
            });
          }          
          observer.next(this.allModels);
          observer.complete();
        });
      } else {
        this.dataService.getRevisionStatus().subscribe({
          next: (data) => {
            data.models.forEach(m => {
              if (this.allModels.find(am => am === m.model) === undefined) {
                this.allModels.push(m.model);
              };
            });
            observer.next(this.allModels);
            observer.complete();
          }, error: (err) => { console.log(err); }
        });
      }
    })
  }

  getUserModels(): Observable<string[]> {
    return new Observable((observer: Observer<string[]>) => {
      if (!this.userModels || this.userModels.length <= 0) {
        forkJoin([this.getAllModels(), this.getUserProduct()]).subscribe(([am, up]) => {
          if (this.userProduct) {
            up.models.forEach(modelSeries => {
              modelSeries.models.forEach((model) => {
                //length == 0 here to account for all models not available offline
                if ((am.length == 0 || am.find(m => model === m) === model)
                   && this.userModels.find(userModel => userModel === model) === undefined) {
                  this.userModels.push(model);
                }
              });
            });
            this.userModels.sort();
            observer.next(this.userModels);
            observer.complete();
          }; 
        })
        } else {
        observer.next(this.userModels);
        observer.complete();
      } 
    });
  }

  getUserClassifications(): Observable<string[]> {
    return new Observable((observer: Observer<string[]>) => {
      if (!this.userClassifications || this.userClassifications.length <= 0) {
        this.getUserProduct().subscribe(data => {
          if (this.userProduct) {
            this.userProduct.classifications.forEach(classificationSeries => {
              classificationSeries.classifications.forEach((classification) => {
                if (this.userClassifications.find(userClassification => userClassification === classification) === undefined) {
                  this.userClassifications.push(classification);
                }
              });
            });
            this.userClassifications.sort();
          }
          observer.next(this.userClassifications);
          observer.complete();
        });
      } else {
        observer.next(this.userClassifications);
        observer.complete();
      }
    });
  }

  getModelExpirationDate(modelName: string): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      this.getUserProduct().subscribe(data => {
        let formattedDate: string;
        let date: Date;
        let newDate: Date;
        if (this.userProduct) {
          this.userProduct.models.forEach(model => {
            if (model.models.includes(modelName)) {
              newDate = new Date(model.modelExpiryDate);
              // if currently saved date is younger than this one, or if there is no saved date yet, save
              if ((date && date.getTime() < newDate.getTime()) || !date) {
                date = newDate;
              }
            }
          });
        }
        if (date) {
          const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
          formattedDate = date.toLocaleString('en-US', options);
        }
        observer.next(formattedDate);
        observer.complete();
      });
    });
  }

}
