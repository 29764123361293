import { Injectable } from '@angular/core';
import { IdleExpiryBaseService } from '../base/idle-expiry-base.service';
import { IdleStorageService } from './idle-storage.service';

@Injectable({
  providedIn: 'root'
})

export class IdleStorageExpiryService extends IdleExpiryBaseService {
  private idleName = 'main';

  constructor(
    private idleStorageStorage: IdleStorageService
  ) {
    super();
  }

  last(value?: Date): Date {
    if (value !== void 0) {
      this.setExpiry(value);
    }
    return this.getExpiry();
  }

  idling(value?: boolean): boolean {
    if (value !== void 0) {
      this.setIdling(value);
    }
    return this.getIdling();
  }

  getIdleName(): string {
    return this.idleName;
  }

  setIdleName(key: string): void {
    if (key) {
      this.idleName = key;
    }
  }

  private getExpiry(): Date {
    const expiry: string = this.idleStorageStorage.getItem(this.idleName + '.expiry');
    if (expiry) {
      return new Date(parseInt(expiry, 10));
    } else {
      return null;
    }
  }

  private setExpiry(value: Date) {
    if (value) {
      this.idleStorageStorage.setItem(
        this.idleName + '.expiry',
        value.getTime().toString()
      );
    } else {
      this.idleStorageStorage.removeItem(this.idleName + '.expiry');
    }
  }

  private getIdling(): boolean {
    const idling: string = this.idleStorageStorage.getItem(this.idleName + '.idling');
    if (idling) {
      return idling === 'true';
    } else {
      return false;
    }
  }

  private setIdling(value: boolean) {
    if (value) {
      this.idleStorageStorage.setItem(this.idleName + '.idling', value.toString());
    } else {
      this.idleStorageStorage.setItem(this.idleName + '.idling', 'false');
    }
  }
}
