<h3 class="page-title">RELATED LINKS</h3>
<ul class="links">
  <li *ngFor="let linkSection of linkSections">
    <a [href]="linkSection.path" class="link-section" *ngIf="linkSection.path">{{linkSection.label}}</a>
    <div class="section" *ngIf="!linkSection.path">{{linkSection.label}}</div>
    <ul>
      <li *ngFor="let link of linkSection.children">
        <a [href]="link.path">{{link.label}}</a>
      </li>
    </ul>
  </li>
</ul>
  <hr>
  <div>
    <p>To view PDF files on this site you must have the Adobe Reader installed on your computer. The link below will take you to Adobe's web site, where you can download the viewer.</p>
    <ul>
      <p><a href="http://www.adobe.com/go/getreader/" target="_blank" rel="noopener noreferrer"><img src="../../assets/get_adobe_reader.gif" alt="Get Adobe Reader" border="0"></a></p>
    </ul>
  </div>
