import { Injectable } from '@angular/core';
import { IetmRouteService } from './ietm-route.service';
import { IetmIcn, IetmIcnHotspot, IetmManual } from './ietm-model';
import { IetmManualService } from './ietm-manual.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { IpdItem } from '../../ietm-content/ietm-ipc/ietm-ipc.component';

@Injectable({
  providedIn: 'root'
})
export class IetmIcnService {
  private manual: IetmManual;

  private _icn: BehaviorSubject<IetmIcn> = new BehaviorSubject<IetmIcn>(null);
  public icn$: Observable<IetmIcn> = this._icn.asObservable();
  get icn(): IetmIcn { return this._icn.value; }

  private _selectedWireKey: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public selectedWireKey$: Observable<string> = this._selectedWireKey.asObservable();
  get selectedWireKey() { return this._selectedWireKey.value; }

  private _selectedHotspot: BehaviorSubject<IetmIcnHotspot> = new BehaviorSubject<IetmIcnHotspot>(null);
  public selectedHotspot$: Observable<IetmIcnHotspot> = this._selectedHotspot.asObservable();
  get selectedHotspot() { return this._selectedHotspot.value; }

  private _hoverHotspot: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public hoverHotspot$: Observable<string> = this._hoverHotspot.asObservable();
  get hoverHotspot() { return this._hoverHotspot.value; }

  private _ipdItems: BehaviorSubject<Array<IpdItem>> = new BehaviorSubject<Array<IpdItem>>(null);
  public ipdItems$: Observable<Array<IpdItem>> = this._ipdItems.asObservable();
  get ipdItems() { return this._ipdItems.value; }

  public status = {
    loading: false,
    error: false
  };

  constructor(
    private ietmRoute: IetmRouteService,
    private ietmManualService: IetmManualService
  ) {
    this.ietmManualService.manual$.subscribe(manual => this.handleManualChange(manual));
    this.ietmRoute.dmc$.subscribe(() => this.handleDMCChange());
    this.ietmRoute.icn$.subscribe(icn => this.handleIcnChange(this.ietmRoute.model, icn));
  }

  private handleManualChange(manual: IetmManual) {
    this.manual = manual;
    this.handleIcnChange(this.ietmRoute.model, this.ietmRoute.icn);
  }

  private handleDMCChange() {
    // reset selected hotspot
    this._selectedHotspot.next(undefined);
  }

  private async handleIcnChange(model, icn) {
    if (!this.manual || this.manual.pmc !== this.ietmRoute.pmc || !icn) {
      this._icn.next(undefined);
      // this will be called when the manual is up to date.
      return;
    }
    this.status = {loading: true, error: false};
    this._icn.next(this.getIetmIcn(model, icn));
    this.status.loading = false;
    this.status.error = !(this.icn);
  }

  getIetmIcn(model: string, icn: string): IetmIcn {
    if (!icn || !this.manual) {
      return undefined;
    }
    if (this.manual) {
      if (!this.manual.lof) {
        return undefined;
      }
    }
    if (this.icn && this.icn.model === model && this.icn.icn === icn) {
      return this.icn;
    }
    const dm = this.manual.lof.find(fig => fig.dmc === this.ietmRoute.dmc);
    if (dm && dm.figures) {
      for (let i = 0; i < dm.figures.length; i++) {
        const figure = dm.figures[i];
        if (figure.sheets) {
          for (let j = 0; j < figure.sheets.length; j++) {
            const graphic = figure.sheets[j];
            if (graphic.icn === icn) {
              return {
                icn: icn,
                model: model,
                currentFigure: i + 1,
                currentGraphic: graphic,
                sheets: figure.sheets,
                previousGraphic: (j > 0) ? figure.sheets[j - 1] : null,
                nextGraphic: (j < figure.sheets.length - 1) ? figure.sheets[j + 1] : null,
                previousFigure: (i > 0) ? dm.figures[i - 1] : null,
                nextFigure: (i < dm.figures.length - 1) ? dm.figures[i + 1] : null,
                title: figure.title,
                sheet: figure.nbSheets === '1' ? '' : ' (Sheet ' + graphic.position + ' of ' + figure.nbSheets + ')',
                video: (figure.videos && figure.videos.length >= 1) ? figure.videos[0].icn : null
              };
            }
          }
        }
      }
    }
    return undefined;
  }

  changeSelectedWireKey(key: string) {
    this._selectedWireKey.next(key);
  }

  changeSelectedHotspot(hotspot: string, icn?: string) {
    this._selectedHotspot.next({hotspot: hotspot, icn: icn});
    console.log('changed');
  }

  changeHoverHotspot(hotspot: string) {
    this._hoverHotspot.next(hotspot);
  }

  setIpdItems(ipdItems: Array<IpdItem>) {
    this._ipdItems.next(ipdItems);
  }

  getIpdItems(): Array<IpdItem> {
    return this._ipdItems.value;
  }

}
