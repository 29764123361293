<h2 class="side-nav-title">My Publications</h2>
<section class="side-nav-selects">
  <div class="model-selection">
    <h5 class="model-selection-title">Select your Model</h5>
    <h5 class="model-selection-title title-mini">Model</h5>
    <mat-form-field appearance="outline" floatLabel="never">
      <mat-select value="{{selectedModel}}" placeholder="Select a Model" (openedChange)="openModelChange($event)" (selectionChange)="changeModel($event)" [disabled]="!models || models.length == 0">
        <mat-select-trigger class="model-selection-trigger" *ngIf="selectedModel">
          {{selectedModel}}
        </mat-select-trigger>
        <mat-option class="model-selection-option" *ngFor="let model of models" [value]="model">
          {{model}}
          <ng-container *ngIf="offlineService.isOfflineCapable && isUsableOffline(model)">
            <mat-icon>check_circle</mat-icon>
          </ng-container>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="publication-selection">
    <h5 class="publication-selection-title">Select your Publication</h5>
    <h5 class="publication-selection-title title-mini">Publication</h5>
    <mat-form-field appearance="outline">
      <mat-select value="{{selectedManual ? selectedManual.toc: null}}" placeholder="All" [disabled]="selectedModel == null" (selectionChange)="changeManual($event)">
        <mat-option *ngFor="let manual of manuals" [value]="manual.toc">{{manual.manual}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <bell-offline-notification [class.online]="!offlineService.isOffline" *ngIf="offlineService.isOfflineCapable && !offlineService.isOffline">
  </bell-offline-notification>
  <div class="update-available" *ngIf="updatesAvailable">
    <mat-icon class="update-available-icon">update</mat-icon><div class="update-available-text">Application updates are available. Please <a href="./" (click)="reloadPage($event)" class="update-available-link">reload</a> to activate.</div>
  </div>
</section>
<bell-side-nav-buttons *ngIf="!offlineService.isOffline">
  <ng-content></ng-content>
</bell-side-nav-buttons>
