import { Injectable } from '@angular/core';
import { ServiceBase } from '../service-base.service';
import { Observable } from 'rxjs';
import { IcnLocation } from '../../data-model/icn-location';
import { IetmSnsData } from '../../../viewer/ietm/services/ietm-service/ietm-model';
import { IetmFault } from '../../data-model/ietm-fault';
import { IndexRoot } from '../../data-model/offline/';
import { Ipb } from '../../data-model/ipb';
import { ManualToc } from '../../data-model/manual-toc';
import { ManualFilter, PartsList, UserProduct } from '../../data-model';
import { Metadata } from '../../data-model/metadata';
import { ModelToc } from '../../data-model/model-toc';
import { Publication } from '../../data-model/publication';
import { RevisionStatus } from '../../data-model/revision-status';
import { SearchResult } from '../../data-model/search-results';
import { News } from 'src/app/shared/data-model/news';
import { SessionInfo } from '../../data-model/session-info';

@Injectable({
  providedIn: 'root'
})
export class DataService extends ServiceBase {

  constructor() {
    super();
  }

  public getSessionInfo(): Observable<SessionInfo> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.User.SessionInfo.get}`;
    return this.httpUtility.get(url);
  }

  public getCCodes(): Observable<any> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.CCode.get}`;
    return this.httpUtility.get(url);
  }

  public getIcn(model: string, icn: string): Observable<string> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Icn.get}${model}/${icn}`;
    return this.httpUtility.getSvg(url);
  }

  public getIcnImage(model: string, icn: string): Observable<Blob> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Icn.get}${model}/${icn}`;
    return this.httpUtility.getBlob(url);
  }

  public getIcnLocation(modelName: string): Observable<IcnLocation> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Ietm.icnloc.get}${modelName}`;
    return this.httpUtility.get(url);
  }

  public getIetmSnsToc(modelName: string): Observable<IetmSnsData> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Ietm.Toc.sns.get}${modelName}`;
    return this.httpUtility.get(url);
  }

  public getIetmManualToc(modelName: string, pmc: string): Observable<any> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Ietm.Toc.manual.get}${modelName}/${pmc}`;
    return this.httpUtility.get(url);
  }

  public getIetm(modelName: string, pmc: string, dmc: string): Observable<any> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Ietm.get}${modelName}/${pmc}${(dmc ? '/' + dmc : '')}`;
    return this.httpUtility.get(url);
  }

  public getIetmFromFaultCode(modelName: string, code: string): Observable<IetmFault> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Ietm.fault.get}${modelName}/${code}`;
    return this.httpUtility.get(url);
  }

  public getIndex(): Observable<IndexRoot> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Index.get}`;
    return this.httpUtility.get(url);
  }

  public getUpdateIndex(lastUpdate: Date, userModels: string, userClassifications: string, lastUpdateDateOnly = false): Observable<IndexRoot> {
    // tslint:disable-next-line: max-line-length
    const param = `?lastUpdate=${lastUpdate.toISOString()?.replace('T', ' ')}&updateModels=${userModels}&currentClassification=${userClassifications}` + (lastUpdateDateOnly ? `&lastUpdateDateOnly=${lastUpdateDateOnly}` : ``);
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Index.get}${param}`;
    return this.httpUtility.get(url);
  }

  public getIpb(filename: string, figure: string): Observable<Ipb> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Ipb.get}${filename}/${figure}`;
    return this.httpUtility.get(url);
  }

  public getManualToc(modelName: string, manualName: string): Observable<ManualToc> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Manual.Toc.get}${modelName}/${manualName}`;
    return this.httpUtility.get(url);
  }

  public getManualFilters(): Observable<ManualFilter[]> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Manual.Toc.getAll}`;
    return this.httpUtility.get(url);
  }

  public getMetadata(filename: string, figure?: string): Observable<Metadata> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Metadata.get}${filename}` + (figure ? `/${figure}` : '');
    return this.httpUtility.get(url);
  }

  public getModelToc(modelName: string): Observable<ModelToc> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Model.Toc.get}${modelName}`;
    return this.httpUtility.get(url);
  }

  public getPng(filename: string, path: string, page: number): Observable<Blob> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Png.get(filename, path, page.toString())}`;
    return this.httpUtility.getBlob(url);
  }

  public getPublication(filename: string): Observable<Publication> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Publication.get}${filename}`;
    return this.httpUtility.get(url);
  }

  public getRevisionStatus(): Observable<RevisionStatus> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Status.get}`;
    return this.httpUtility.get(url);
  }

  public getRevisionStatusByModel(model: string): Observable<RevisionStatus> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Status.getByModel(model)}`;
    return this.httpUtility.get(url);
  }

  public getThree(model: string, filetype: string, obj: string): Observable<Blob> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Ietm.three.get(model, filetype, obj)}`;
    return this.httpUtility.getBlob(url);
  }

  public getThreeRefdes(model: string): Observable<Array<string>> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Ietm.threeRefdes.get}${model}`;
    return this.httpUtility.get(url);
  }

  public getUserProduct(): Observable<UserProduct> {
    const url = `${this.environmentService.getBackendUrl()}${this.environmentService.getUserProductEndpoint()}`;
    return this.httpUtility.get(url);
  }

  public getWireData(icn: string): Observable<any> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Wire.Data.get}${icn}`;
    return this.httpUtility.get(url);
  }

  public getWireGroup(icn: string, id?: string): Observable<any> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Wire.Group.get}${icn}` + (id ? `/${id}` : '');
    return this.httpUtility.get(url);
  }

  public getWireTracing(model: string): Observable<any> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Wire.Tracing.get}${model}`;
    return this.httpUtility.get(url);
  }

  public getPinUid(model: string, icn: string, connector: string, pin: string): Observable<any> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Wire.PinUid.get}${model}/${icn}/${connector}/${pin}`;
    return this.httpUtility.get(url);
  }

  public getWireParam(id: string): Observable<any> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Wire.Param.get}${id}`;
    return this.httpUtility.get(url);
  }

  public getNews(): Observable<News> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.News.get}`;
    return this.httpUtility.get(url);
  }

  public getBanner(): Observable<any> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Banner.get}`;
    return this.httpUtility.get(url);
  }

  public getPartsList(partsListName: string): Observable<PartsList> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.PartsLists.base}/${partsListName}`;
    return this.httpUtility.get(url);
  }

  public postPartsList(partsList: PartsList): Observable<any> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.PartsLists.base}/${partsList.name}`;
    return this.httpUtility.post(url, partsList);
  }

  public importPartsList(data: FormData, partsListName: string): Observable<any> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.PartsLists.base}/${partsListName}/import`;
    return this.httpUtility.post(url, data);
  }

  public renamePartsList(oldName: string, newName: string) {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.PartsLists.base}/${oldName}/rename/${newName}`;
    return this.httpUtility.put(url, null);
  }

  public deletePartsList(partsListName: string) {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.PartsLists.base}/${partsListName}`;
    return this.httpUtility.delete(url);
  }

  public getPartsLists(): Observable<Array<PartsList>> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.PartsLists.base}`;
    return this.httpUtility.get(url);
  }

  getIndexes(): Observable<Blob> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Zip.Indexes.get}`;
    return this.httpUtility.getZipBlob(url);
  }

  getFaultCodes(): Observable<Blob> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Zip.FaultCodes.get}`;
    return this.httpUtility.getZipBlob(url);
  }

  getWiringData(): Observable<Blob> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Zip.WiringData.get}`;
    return this.httpUtility.getZipBlob(url);
  }

  getToc(): Observable<Blob> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Zip.Toc.get}`;
    return this.httpUtility.getZipBlob(url);
  }

  getDoc(fileName: string): Observable<Blob> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Zip.ZipDoc.get}${fileName}`;
    return this.httpUtility.getZipBlob(url);
  }

  getResource(resourceName: string): Observable<Blob> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Zip.ZipResource.get}${resourceName}`;
    return this.httpUtility.getZipBlob(url);
  }

  getWireReport(modelFamily: string): Observable<Object> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Wire.Report.get}${modelFamily}`;
    return this.httpUtility.get(url);
  }

  getSearchResults(query: string, option: string): Observable<SearchResult[]> {
    const route = `${this.apiUri.Search.get}${query}&option=${option}`;
    return this.httpUtility.get(`${this.environmentService.getBackendUrl()}${route}`);
  }

  getDocumentSearchResults(query: string, option: string, path?: string, filename?: string): Observable<any> {
    const route = `${this.apiUri.Search.get}${query}&option=${option}` + (path && filename ? `&path=${path}&filename=${filename}` : '');
    return this.httpUtility.get(`${this.environmentService.getBackendUrl()}${route}`);
  }

  public getFullText(filename: string, page: number): Observable<Blob> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Search.FullText.get(filename, page.toString())}`;
    return this.httpUtility.get(url);
  }
}
