import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { TocComponent } from './toc/toc.component';
import { NewsComponent } from './news/news.component';
import { CoreComponent } from './core/core.component';
import { OfflineLibComponent } from './offline/offline-lib/offline-lib.component';
import { CCodeComponent } from './ccode/ccode.component';
import { IetmFaultComponent } from './viewer/ietm/ietm-fault/ietm-fault.component';
import { SearchComponent } from './search/search.component';
import { GuardService } from './shared/services/guard.service';
import { PrintRoutingModule } from 'src/app/viewer/ietm/ietm-print/ietm-print-routing.module';
import { MyOfflineLibComponent } from './offline/my-offline-lib/my-offline-lib.component';
import { UnAuthorizedComponent } from './un-authorized/un-authorized.component';
import { LoginComponent } from './login/login.component';
import { CheckUpdateComponent } from './offline/check-update/check-update.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'update', component: CheckUpdateComponent, canActivate: [GuardService] },
  { path: '', component: CoreComponent, canActivate: [GuardService],
    children: [
      { path: '', component: NewsComponent },
      { path: 'toc/:model', component: TocComponent },
      { path: 'toc/:model/:manual', component: TocComponent },
      { path: 'offlinelib', component: OfflineLibComponent },
      { path: 'myofflinelib', component: MyOfflineLibComponent },
      { path: 'ccode/:ccode', component: CCodeComponent },
      { path: 'search', component: SearchComponent },
      { path: '', loadChildren: () => import('src/app/general-info/general-info.module').then(m => m.GeneralInfoModule) },
      { path: 'parts', loadChildren: () => import('src/app/parts-list/parts-list.module').then(m => m.PartsListModule) },
      { path: 'contact', loadChildren: () => import('src/app/contact/contact.module').then(m => m.ContactModule) },
      { path: 'about', loadChildren: () => import('src/app/about/about.module').then(m => m.AboutModule) }
    ]},
  { path: 'fault/:model/:code', component: IetmFaultComponent },
  { path: 'fault/:model/:code/:serial', component: IetmFaultComponent },
  { path: 'ietm', loadChildren: () => import('src/app/viewer/ietm/ietm.module').then(m => m.IetmModule), canActivate: [GuardService]},
  { path: 'doc/:model/:filename', loadChildren: () => import('src/app/viewer/legacy/legacy.module').then(m => m.LegacyModule) },
  { path: 'dual/:model/:filename', loadChildren: () => import('src/app/viewer/legacy/legacy.module').then(m => m.LegacyModule) },
  { path: 'unauthorized', component: UnAuthorizedComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    PrintRoutingModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
