import { Injectable } from '@angular/core';
import { IetmRouteService } from './ietm-route.service';
import { IetmManual, ManualTreeNode } from './ietm-model';
import { Observable, BehaviorSubject, Observer } from 'rxjs';
import { Router } from '@angular/router';
import { IetmDataService } from 'src/app/shared/services/ietm/ietm-data.service';

@Injectable({
  providedIn: 'root'
})
export class IetmManualService {
  private _manual: BehaviorSubject<IetmManual> = new BehaviorSubject<IetmManual>(null);
  public manual$: Observable<IetmManual> = this._manual.asObservable();
  get manual(): IetmManual { return this._manual.value; }

  public status = {
    loading: false,
    error: false
  };

  constructor(
    private ietmRoute: IetmRouteService,
    private ietmDataService: IetmDataService,
    private router: Router
  ) {
    this.ietmRoute.pmc$.subscribe(pmc => this.handleManualChange(this.ietmRoute.model, pmc));
  }

  private handleManualChange(model: string, manual: string) {
    const fromSearch = this.router.url.indexOf('search') >= 0;
    if (this.manual && this.manual.model === model && this.manual.pmc === manual) { return; }
    this.status = { loading: true, error: false };
    this.getManual(model, manual).subscribe((data) => {
      this._manual.next(data);
      this.status.loading = false;
      this.status.error = !(this.manual);
      if (!this.ietmRoute.dmc && this.manual && !fromSearch) {
        this.navigateToDefaultDmc();
      }
    });
  }

  getManual(model: string, manual: string): Observable<IetmManual> {
    return new Observable((observer: Observer<IetmManual>) => {
      if (this.manual && this.manual.pmc === manual) {
        observer.next(this.manual);
        observer.complete();
      } else if (!model || !manual || manual === 'sns' || manual === '3d') {
        observer.next(null);
        observer.complete();
      } else {
        this.ietmDataService.getIetmManualToc(model, manual).subscribe((data) => {
          if (data) {
            const dms: any[] = [];
            const tree = this.buildTree(data.pm, { dms: dms });
            observer.next({
              model: model, pmc: manual, title: data.pubName, dmList: dms,
              tree: tree, lof: data.pm[0].lof, lot: data.pm[0].lot
            });
          } else {
            observer.next(null);
            observer.complete();
          }
        });
      }
    });
  }

  private buildTree(entries, dmList: { dms: any[] }): ManualTreeNode[] {
    return entries.map(entry => {
      const childArray: ManualTreeNode[] = [];
      if (entry.dms) {
        entry.dms.map(dm => {
          childArray.push({
            type: 'dataModule',
            title: dm.dmTitle.techName,
            subtitle: dm.dmTitle.infoName,
            dmCode: dm.dmCode,
            applic: dm.applic,
            dm: dm
          });
          dmList.dms.push(dm.dmCode.dmc);
        });
      }
      if (entry.entries) {
        this.buildTree(entry.entries, dmList).forEach(childEntry => {
          childArray.push(childEntry);
        });
      }
      return { type: 'entry', title: entry.title, id: entry.id, children: childArray };
    });
  }

  private navigateToDefaultDmc() {
    const dmc = this._manual.value.dmList[0];
    this.router.navigate(['/ietm', this.ietmRoute.model, this.ietmRoute.pmc, dmc], { queryParamsHandling: 'merge', replaceUrl: true });
  }
}
