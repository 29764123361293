import { ViewChild, Component, OnInit } from '@angular/core';
import { DialogState } from '../shared/data-model/dialog-state';
import { OfflineService } from '../shared/services/offline/offline.service';
import { SessionTimeoutService } from '../shared/services/session-timeout/session-timeout.service';
import { EnvironmentService } from 'src/app/shared/services/environment.service';

@Component({
  selector: 'bell-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss']
})
export class CoreComponent implements OnInit {
  public isWorkEnv = false;

  constructor(
    public offlineService: OfflineService,
    public sessionTimeoutService: SessionTimeoutService,
    public envService: EnvironmentService,
  ) { }

  ngOnInit() {
    this.initSessionTimeout();
    this.isWorkEnv = this.envService.getIsWorkEnvironment();
  }

  initSessionTimeout() {
    if (!this.offlineService.isOffline) {
      this.sessionTimeoutService.initSessionTimeout();
    }
  }
}
