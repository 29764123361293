import { Injectable } from '@angular/core';
import { News } from '../../data-model/news';
import { Observable, Observer } from 'rxjs';
import { DataService } from '../data-services/data.service';
import { OfflineService } from '../offline/offline.service';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  newsData: News;

  constructor(
    private dataService: DataService,
    private offlineService: OfflineService
  ) {
    this.newsData = null;
  }

  getNews(): Observable<News> {
    return new Observable((observer: Observer<News>) => {
      if (!this.offlineService.isOffline) {
        this.dataService.getNews().subscribe((data) => {
          this.newsData = data;
          if (this.newsData) {
            this.newsData.lastUpdate = (new Date(data.lastUpdate)).toDateString();
          }
          observer.next(this.newsData);
          observer.complete();
        });
      } else {
        observer.next(this.newsData);
        observer.complete();
      }
    });
  }
}
