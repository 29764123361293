import { OnInit, Component, Inject, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'bell-print-preview',
  templateUrl: './print-preview.component.html',
  styleUrls: ['./print-preview.component.scss'],
})

export class PrintPreviewComponent implements OnInit {
  public printPreviewDocuments: HTMLElement;

  constructor(
    public printPreview: MatDialogRef < PrintPreviewComponent >,
    public renderer: Renderer2,
    @Inject(MAT_DIALOG_DATA) public modalData: HTMLElement
  ) { }

  ngOnInit() {
    this.printPreviewDocuments = document.getElementById('printPreviewDocuments');
    this.setPrintPreviewData();
  }

  setPrintPreviewData() {
    this.renderer.appendChild(this.printPreviewDocuments, this.modalData);
  }
}
