import { Injectable } from '@angular/core';
import { Observable, Observer, BehaviorSubject } from 'rxjs';
import { OfflineService } from '../offline/offline.service';
import { Metadata } from '../../data-model/metadata';
import { OfflineZipReaderService } from '../offline/offline-zip-reader.service';
import { filter } from 'rxjs/operators';
import { DataService } from '../data-services/data.service';

@Injectable({
  providedIn: 'root'
})
export class MetadataDataService {
  private loadedMetadata$: Record<string, BehaviorSubject<Metadata>> = {};

  constructor(
    private offlineService: OfflineService,
    private dataService: DataService,
    private offlineZipReaderService: OfflineZipReaderService,
  ) {}

  getMetadataData(filename: string, figure?: string): Observable<Metadata> {
    return new Observable((observer: Observer<Metadata>) => {
      this.getMetadataSubjects(filename, figure).pipe(filter(f => f !== undefined)).subscribe(data => {
        observer.next(data);
        observer.complete();
      });
    });
  }

  private getMetadataServerData(fileName: string, figure?: string) {
    const loadedKey = `${fileName}${figure ? '-' + figure : ''}`;
    if (this.loadedMetadata$[loadedKey]) {
      let metadata$: Observable<Metadata>;
      if (!this.offlineService.isOfflineCapable) {
        metadata$ = this.dataService.getMetadata(fileName, figure);
      } else {
        if (this.offlineService.isOffline) {
          metadata$ = this.offlineZipReaderService.getMetadata(fileName, figure);
        } else {
          metadata$ = this.dataService.getMetadata(fileName, figure);
        }
      }
      metadata$.subscribe((data) => {
        if (data) {
          this.loadedMetadata$[loadedKey].next(data);
        } else {
          this.loadedMetadata$[loadedKey].next(null);
        }
      });
    }
  }

  private getMetadataSubjects(fileName: string, figure?: string): BehaviorSubject<Metadata> {
    const loadedKey = `${fileName}${figure ? '-' + figure : ''}`;
    if (!this.loadedMetadata$[loadedKey]) {
      this.loadedMetadata$[loadedKey] = <BehaviorSubject<Metadata>> new BehaviorSubject(undefined);
      this.getMetadataServerData(fileName, figure);
      return this.loadedMetadata$[loadedKey];
    } else {
      return this.loadedMetadata$[loadedKey];
    }
  }
}
