export class TrafficHttpMethods {
  all = 0;
  get = 0;
  post = 0;
  delete = 0;
  put = 0;
  head = 0;
}

export enum ProgressType {
  'Start',
  'Upload',
  'ResponseHeader',
  'Download',
  'Done'
}

export class HttpProgressEventModalData {
  request: string;
  progressType: string;
  progress: number;
  error: any;
  response: any;
  title: string;
  textContent: string;
  buttonLabel = 'close';
  barProgress: number;
  barMode = 'determinate';
  finished = false;
  doneWithError = false;
}
