import { environment } from "@environments/environment";
import { ElectronPlatformService } from "src/app/electron/services/electron-platform.service";
import { MauiPlatformService } from "src/app/maui/services/maui-platform.service";
import { EnvironmentService } from "../environment.service";
import { PlatformService } from "./platform.service";

export const PlatformServiceFactory = (
  environmentService: EnvironmentService
): PlatformService => {
  if (environment.maui) {
    return new MauiPlatformService(environmentService);
  } else {
    return new ElectronPlatformService(environmentService);
  }
}
