<img src="assets/hero_sand_large.jpg" onload="this.style.opacity=1" class="background-image">
<div class="login-screen">
  <div class="login-modal">
    <div class="logo-container">
      <div class="bell-logo"></div>
    </div>
    <div class="login-header">
      <h1>TECHNICAL PUBLICATIONS</h1>
    </div>
    <div class="login-opts">
      <div class="login-opt">
        <button class="mybell login-opt-button" mat-flat-button color="primary" (click)="login(LoginType.MYBELL)"><div class="bell-logo"></div><div class="login-opt-button-text">Sign in with MyBell</div></button>
        <p class="login-opt-description">Log in to Bell Technical Publications with your MyBell Account</p>
      </div>
      <div class="login-opt" *ngIf="isInternal">
        <button class="ping login-opt-button" mat-flat-button color="accent" (click)="login(LoginType.ADFS)"><mat-icon>account_box</mat-icon><div class="login-opt-button-text">Employee Login</div></button>
        <p class="login-opt-description">Log in with a Bell Employee account.</p>
      </div>
      <div class="login-opt" *ngIf="offline.isOfflineCapable">
        <button class="offline login-opt-button" mat-flat-button color="accent" (click)="login(LoginType.OFFLINE)" [disabled]="!isLibraryActivated ? true : null"><mat-icon>cloud_off</mat-icon><div class="login-opt-button-text">Go Offline</div></button>
        <p *ngIf="!isLibraryActivated" class="login-opt-description">You must login to initialize your offline library before using it.</p>
        <p *ngIf="isLibraryActivated" class="login-opt-description">Use your offline library.</p>
      </div>
    </div>
    <div class="other-opts">
      <div class="other-opt" *ngIf="!offline.isOfflineCapable">
        <mat-checkbox [(ngModel)]="rememberPreference" class="other-opt-action">Remember my preference</mat-checkbox>
        <p class="other-opt-description">You will automatically sign in via the selected option above. To reset, simply sign out or clear your cache.</p>
      </div>
    </div>
  </div>
</div>
