import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange } from '@angular/core';
import { TocService } from 'src/app/shared/services/toc/toc.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotFoundSnackbarComponent } from 'src/app/shared/components/not-found-snackbar/not-found-snackbar.component';
import { IETM_TOC_VIEW_MODE } from 'src/app/viewer/ietm/ietm-modes/ietm-toc-view-mode.enum';
import { OfflineService } from 'src/app/shared/services/offline/offline.service';
import { ManualToc, ManualTocDetail, ManualDetail, ManualPublication, ManualFile } from 'src/app/shared/data-model/manual-toc';
import { HttpFileDownloadService } from 'src/app/shared/services/http-progress-event/http-file-download.service';
import { HttpEventType } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { OfflineStatusService } from 'src/app/shared/services/offline/offline-status.service';
import { OfflineContentStatus } from 'src/app/shared/enums/offline-content-status';
import { MatCheckboxChange } from '@angular/material/checkbox/checkbox';
import { DocumentTree } from 'src/app/shared/data-model/idb';
import { Router } from '@angular/router';
import { OfflineIconStatus } from 'src/app/shared/enums/offline-icon-status';
import { DocumentService } from 'src/app/shared/services/offline/dexie/document.service';
import { getNowUTC } from 'src/app/shared/utils/date-util';

@Component({
  selector: 'bell-toc-manual',
  templateUrl: './toc-manual.component.html',
  styleUrls: ['./toc-manual.component.scss']
})

export class TocManualComponent implements OnInit, OnChanges {
  @Input() model: string;
  @Input() manual: string;
  @Input() expirationDate: string;
  @Output() tocLoaded = new EventEmitter<boolean>(true);
  public OfflineContentStatus: any = OfflineContentStatus;
  public OfflineIconStatus: typeof OfflineIconStatus = OfflineIconStatus;
  private manualTree: DocumentTree[];
  manualToc: ManualTocDetail;
  manualChecked = false;
  manualDownloaded = OfflineContentStatus;
  isCheckboxVisible = false;

  constructor(
    private tocService: TocService,
    private snackBar: MatSnackBar,
    private documentService: DocumentService,
    private httpFileDownloadService: HttpFileDownloadService,
    public offlineService: OfflineService,
    public offlineStatusService: OfflineStatusService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.getTableOfContents();
    if (this.offlineService.isOfflineCapable) {
      this.isCheckboxVisible = (!/^LR|FORMS|OTHER|VIDEO|AUDIO|HELP$/.test(this.manual));
    }
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    Object.keys(changes).forEach(propName => {
      const changedProp = changes[propName];
      const to = changedProp.currentValue;
      if (!changedProp.isFirstChange() && propName !== 'expirationDate') {
        if (propName === 'model') {
          this.model = to;
        } else {
          this.manual = to;
        }
        this.getTableOfContents();
      }
    });
  }

  isArray(obj: any): boolean {
    if (Array.isArray(obj)) {
      return !!obj.length;
    } else {
      return false;
    }
  }

  isArrayFigure(obj: any): boolean {
    if (Array.isArray(obj)) {
      return !!obj.length;
    }
  }

  toggleFiles(manualIndex, pubIndex, target) {
    const spinner = target.parentNode.parentNode.getElementsByClassName('spinner-wrapper')[0];
    const publication = this.manualToc.manuals[manualIndex].publications[pubIndex];
    let timeout = 1000;
    let totalFiles = 0;
    let totalFigures = 0;

    if (!publication['isOpen']) {
      timeout = 0;
    } else {
      if (publication.hasOwnProperty('files') && publication.files.length) {
        totalFiles = publication.files.length;
        for (let i = 0; i < totalFiles; i++) {
          if (publication.files[i].hasOwnProperty('figures') && publication.files[i].figures.length) {
            totalFigures += publication.files[i].figures.length;
          }
        }
      }
      timeout = timeout + (5 * (totalFiles + totalFigures));
      spinner.classList.add('spinner-wrapper--show');
    }

    setTimeout(() => {
      publication['isOpen'] = !(!!publication['isOpen']);
      spinner.classList.remove('spinner-wrapper--show');
    }, timeout);
  }

  isOpen(manualIndex, pubIndex): boolean {
    return this.manualToc.manuals[manualIndex].publications[pubIndex]['isOpen'];
  }

  loadIpb(manualIndex, pubIndex, fileIndex, target) {
    const spinner = target.parentNode.parentNode.getElementsByClassName('spinner-wrapper')[0];
    const file = this.manualToc.manuals[manualIndex].publications[pubIndex].files[fileIndex];
    let timeout = 250;

    if (!file['isOpen']) {
      timeout = 0;
    } else {
      spinner.classList.add('spinner-wrapper--show');
      timeout = timeout + (5 * file.figures.length);
    }

    const requestIpb = this.requestIpb(file);

    setTimeout(() => {
      this.toggleIpb(file);
      spinner.classList.remove('spinner-wrapper--show');
    }, timeout);
  }

  requestIpb(file) {
    if (!file.figures.hasOwnProperty('hasIpb')) {
      return true;
    }
    return false;
  }

  toggleIpb(file) {
    if (file['isOpen'] === undefined) {
      file['isOpen'] = false;
    } else {
      file['isOpen'] = !(!!file['isOpen']);
    }
  }

  isOpenIpb(manualIndex, pubIndex, fileIndex) {
    return this.manualToc.manuals[manualIndex].publications[pubIndex].files[fileIndex]['isOpen'];
  }

  openPublicationPDF(publication: any) {
    if (publication.files.length > 0 && publication.files[0].url) {
      this.openPDF(publication.files[0], publication.title);
    }
  }

  getTocView(url: string) {
    if (url.toUpperCase().indexOf('IETM') >= 0 && url.toUpperCase().indexOf('PMC') >= 0) {
      return IETM_TOC_VIEW_MODE.MANUAL;
    }
    return null;
  }

  getTableOfContents() {
    const manualToc$ = this.tocService.getManualToc(this.model, this.manual);
    const getManualTree$ = this.offlineStatusService.getManualTree(this.model, this.manual);
    const documents$ = this.documentService.getAll();
    forkJoin([manualToc$, getManualTree$, documents$]).subscribe(results => {
      if (results[0] !== null) {
        this.manualToc = results[0].toc;
        this.manualTree = results[1];
        const downloadedDocStrings = results[1].filter((d) => d.usable === 'true' && d.doc && d.doc.length > 0).map(d => d.doc);
        if (downloadedDocStrings.length > 0) {
          const updatedNeededDocs = results[2].filter(d => downloadedDocStrings.indexOf(d.name) > -1);
          if (updatedNeededDocs.length > 0) {
            updatedNeededDocs.forEach(d => {
              if (getNowUTC(new Date(d.availableUpdate)) > d.lastUpdate) {
                this.setUpdateIcon(d.name);
              }
            });
          }
        }
        this.mapCheckedStatus();
        this.tocLoaded.emit(true);
      }
    });
  }

  openManualFile(file: any, manual: any) {
    file.isDownloading = true;

    if (file.pdf) {
      this.openPDF(file, file.title, manual);
    } else if (file.zip) {
      this.openZip(file, manual);
    }

    file.isDownloading = false;
  }
  goToFile(publicationId: string, url: string) {
    const currentView = this.getTocView(url);
    const navigateUrl = url + (currentView === null ? '' : '?tocView=' + currentView);
    this.router.navigateByUrl(navigateUrl);
  }

  selectManual($event) {
    this.setChckedStatus(`/${this.model}/${this.manual}`, $event.checked);
  }

  selectManualDetail($event: MatCheckboxChange, manualDetailId: string) {
    this.setChckedStatus(`/${this.model}/${this.manual}/${manualDetailId}`, $event.checked);
  }

  selectManualPublication($event: MatCheckboxChange, manualDetailId: string, publicationId: string) {
    this.setChckedStatus(`/${this.model}/${this.manual}/${manualDetailId}/${publicationId}`, $event.checked);
  }

  selectManualPublicationFiles($event: MatCheckboxChange, manualDetailId: string, publicationId: string, fileId: string) {
    this.setChckedStatus(`/${this.model}/${this.manual}/${manualDetailId}/${publicationId}/${fileId}`, $event.checked);
  }

  private setUpdateIcon(docName: string) {
    this.manualToc.manuals.forEach((manualDetail) => {
      manualDetail.publications.forEach((toc) => {
        const checkStr = toc.id;
        if (toc.files) {
          toc.files.forEach(file => {
            if (file.id === docName) {
              file.updatable = true;
              toc.updatable = true;
            }
          })
        }
        if (docName === checkStr) {
          // set updatable to true to use when setting icons
          toc.updatable = true;
        }

      });
    });
  }

  private setChckedStatus(path: string, isChecked: boolean) {
    this.offlineStatusService.setSelectionStatus(path, isChecked).subscribe((data) => {
      this.offlineStatusService.getManualTree(this.model, this.manual).subscribe((trees) => {
        this.manualTree = trees;
        this.mapCheckedStatus();
      });
    });
  }

  private mapCheckedStatus() {
    let tree: DocumentTree;
    tree = this.manualTree.find((t) => t.path === `/${this.model}/${this.manual}`);
    this.manualChecked = this.calculateChecked(tree);
    this.manualToc.isChecked = this.checkDownloadStatus(tree);
    this.manualToc.manuals.forEach((manualDetail) => {
      tree = this.manualTree.find((t) => t.path === `/${this.model}/${this.manual}/${manualDetail.id}`);
      manualDetail.isChecked = this.calculateChecked(tree);
      manualDetail.publicationChecked = this.checkDownloadStatus(tree);
      if (manualDetail.publications && manualDetail.publications.length > 0) {
        manualDetail.publications.forEach((publication) => {
          tree = this.manualTree.find((t) => t.path === `/${this.model}/${this.manual}/${manualDetail.id}/${publication.id}`);
          publication.isChecked = this.calculateChecked(tree);
          publication.publicationChecked = this.checkDownloadStatus(tree);
          if (tree && publication.isChecked) {
            if (tree.childCount > 0) {
              if (tree.usableChild === tree.childCount) {
                if (publication.updatable) {
                  publication.downloadStatus = OfflineIconStatus.Update;
                } else {
                  publication.downloadStatus = OfflineIconStatus.Available;
                }
              } else {
                publication.downloadStatus = OfflineIconStatus.Add;
              }
            } else {
              if (tree.childCount === 0 && tree.selected === 'true') {
                if (tree.usable === 'true') {
                  if (publication.updatable) {
                    publication.downloadStatus = OfflineIconStatus.Update;
                  } else {
                    publication.downloadStatus = OfflineIconStatus.Available;
                  }
                } else {
                  publication.downloadStatus = OfflineIconStatus.Add;
                }
              }
            }
          } else {
            if (tree === undefined || tree.usableChild !== tree.childCount) {
              publication.downloadStatus = OfflineIconStatus.None;
            } else {
              if (tree.childCount > 0) {
                publication.downloadStatus = OfflineIconStatus.Delete;
              } else {
                if (tree.usable === 'true' && tree.selected === 'false') {
                  publication.downloadStatus = OfflineIconStatus.Delete;
                } else {
                  publication.downloadStatus = OfflineIconStatus.None;
                }
              }
            }
          }
          if (publication.files && publication.files.length > 0) {
            publication.files.forEach((file) => {
              const filePath = `/${this.model}/${this.manual}/${manualDetail.id}/${publication.id}/${file.id}`;
              tree = this.manualTree.find((t) => t.path === filePath);
              file.isChecked = this.calculateChecked(tree);
              if (tree && file.isChecked && file.updatable) {
                if (tree.usable === 'true') {
                  file.downloadStatus = OfflineIconStatus.Update;
                } else {
                  file.downloadStatus = OfflineIconStatus.Add;
                }
              } else if (tree && file.isChecked) {
                if (tree.usable === 'true') {
                  file.downloadStatus = OfflineIconStatus.Available;
                } else {
                  file.downloadStatus = OfflineIconStatus.Add;
                }
              } else {
                if (tree === undefined || tree.usable === 'false') {
                  file.downloadStatus = OfflineIconStatus.None;
                } else {
                  file.downloadStatus = OfflineIconStatus.Delete;
                }
              }
            });
          }
        });
      }
    });
  }

  private calculateChecked(tree: DocumentTree): boolean {
    if (tree) {
      if (tree.selected === 'true') {
        return true;
      } else if (tree.childCount > 0) {
        if (tree.selectedChild > 0) {
          // return OfflineContentStatus.Partial;
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  private checkDownloadStatus(tree: DocumentTree): OfflineContentStatus {
    if (tree) {
      if (tree.selectedChild === tree.childCount || tree.childCount === tree.usableChild) {
        return OfflineContentStatus.Full;
      } else if (tree.childCount > 0) {
        if (tree.selectedChild > 0) {
          return OfflineContentStatus.Partial;
        } else {
          return OfflineContentStatus.None;
        }
      } else {
        return OfflineContentStatus.None;
      }
    } else {
      return OfflineContentStatus.None;
    }
  }

  private openPDF(file: any, title: string, manual?: any) {
    const fileTitle = file.title;
    if (file && file.url) {
      if (file.url.includes('action=getpdf')) {
        const pdfID = file.url.split('=').pop();
        this.getPDF(pdfID, title, fileTitle);
      } else if (file.url.includes('action=otherfile')) {
        const filename = file.url.split('file=').pop();
        const model = file.url.split('&file=').shift().split('model=').pop();
        this.openFile(model, manual, filename, fileTitle, true);
      }
    }
  }

  private openFile(model: string, manual: any, filename: string, fileTitle: string, otherFile = false) {
    if (filename && model && manual && manual.id) {
      this.getFile(model, manual.id, filename, fileTitle, otherFile);
    }
  }

  private openZip(file: any, manual: any) {
    const fileTitle = file.title;
    if (file && file.url) {
      const filename = file.url.split('file=').pop();
      const model = file.url.split('&file=').shift().split('model=').pop();
      const isOtherFile = file.url.includes('action=otherfile');
      this.openFile(model, manual, filename, fileTitle, isOtherFile);
    }
  }

  private getPDF(pdfID, title, fileTitle, otherFile = false) {
    this.httpFileDownloadService.getPDF(pdfID, title, otherFile).subscribe(
      (data) => {
        if (data && data.type === HttpEventType.Response) {
          return data;
        }
      },
      (error) => {
        if (fileTitle) {
          this.handleError(fileTitle);
        }
      }
    );
  }

  private getFile(model, manual, filename, fileTitle, otherFile = false) {
    this.httpFileDownloadService.getFile(model, manual, filename, otherFile).subscribe(
      (data) => {
        if (data && data.type === HttpEventType.Response) {
          return data;
        }
      },
      (error) => {
        if (fileTitle) {
          this.handleError(fileTitle);
        }
      }
    );
  }

  private handleError(fileTitle?) {
    const errorMsg = 'Error - Unable to retrieve file ';
    this.snackBar.openFromComponent(NotFoundSnackbarComponent, {
      data: errorMsg + fileTitle
    });
  }
}
