import { Injectable } from '@angular/core';
import { ZipService } from './zip/zip.service';
import { OfflineFileService } from './offline-file.service';
import { ZipEntry } from './zip/zip-entry ';
import { BehaviorSubject, Observable, Observer, of } from 'rxjs';
import { ModelToc, Publication, ManualFilter } from '../../data-model';
import { ManualToc } from '../../data-model/manual-toc';
import { Metadata } from '../../data-model/metadata';
import { filter, map, switchMap } from 'rxjs/operators';
import { Ipb } from '../../data-model/ipb';
import { IetmSnsData, IetmManual } from 'src/app/viewer/ietm/services/ietm-service/ietm-model';
import { IetmZipFault } from '../../data-model/ietm-fault';
import { PmcService } from './dexie/pmc.service';
import { ModelFilter } from '../../data-model/model-filter';
import { PlatformService } from './platform.service';
import { PlatformMethod } from './platform-methods';

@Injectable({
  providedIn: 'root'
})
export class OfflineZipReaderService {
  private loadedZip$: Record<string, BehaviorSubject<ZipEntry[]>> = {};
  constructor(
    private zipService: ZipService,
    private offlineFileService: OfflineFileService,
    private pmcService: PmcService,
    private platformService: PlatformService
  ) { }

  getCodes(): Observable<any> {
    return new Observable((observer: Observer<ModelToc>) => {
      this.getEntries('toc').pipe(filter(f => f !== undefined)).subscribe(entries => {
        const path = '/ccode';
        const fileEntry = entries.find((e) => e.filename === path);
        if (fileEntry) {
          const zipTask = this.zipService.getData(fileEntry);
          zipTask.data.subscribe((text) => {
            observer.next(JSON.parse(text));
            observer.complete();
          });
        }
      });
    });
  }
  getModelToc(modelName: string): Observable<ModelToc> {
    return new Observable((observer: Observer<ModelToc>) => {
      this.getEntries('toc').pipe(filter(f => f !== undefined)).subscribe(entries => {
        const path = `/${modelName}/toc`;
        const fileEntry = entries.find((e) => e.filename === path);
        if (fileEntry) {
          const zipTask = this.zipService.getData(fileEntry);
          zipTask.data.subscribe((text) => {
            observer.next(JSON.parse(text));
            observer.complete();
          });
        }
      });
    });
  }

  getManualToc(modelName: string, manualName: string): Observable<ManualToc> {
    return new Observable((observer: Observer<ManualToc>) => {
      this.getEntries('toc').pipe(filter(f => f !== undefined)).subscribe(entries => {
        const path = `/${modelName}/${manualName}/toc`;
        const fileEntry = entries.find((e) => e.filename === path);
        if (fileEntry) {
          const zipTask = this.zipService.getData(fileEntry);
          zipTask.data.subscribe((text) => {
            observer.next(JSON.parse(text));
            observer.complete();
          });
        }
      });
    });
  }

  getIetmSnsToc(modelName: string): Observable<IetmSnsData> {
    return new Observable((observer: Observer<IetmSnsData>) => {
      this.getEntries('toc').pipe(filter(f => f !== undefined)).subscribe(entries => {
        const path = `/sns/${modelName}/toc`;
        const fileEntry = entries.find((e) => e.filename === path);
        if (fileEntry) {
          const zipTask = this.zipService.getData(fileEntry);
          zipTask.data.subscribe((text) => {
            observer.next(JSON.parse(text));
            observer.complete();
          });
        }
      });
    });
  }

  // To Do: Add stornly typed as return type
  getIetmManualToc(pmc: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.pmcService.getByPmcCode(pmc).subscribe((pmcData) => {
        if (pmcData) {
          this.getEntries(pmcData.pubName).pipe(filter(f => f !== undefined)).subscribe(entries => {
            const path = `${pmcData.directory}toc`;
            const fileEntry = entries.find((e) => e.filename === path);
            if (fileEntry) {
              const zipTask = this.zipService.getData(fileEntry);
              zipTask.data.subscribe((text) => {
                observer.next(JSON.parse(text));
                observer.complete();
              });
            }
          });
        } else {
          observer.next(false);
          observer.complete();
        }
      });
    });
  }

  // To Do: Add stornly typed as return type
  getIetm(pmc: string, dmc: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.pmcService.getByPmcCode(pmc).subscribe((pmcData) => {
        if (pmcData) {
          this.getEntries(pmcData.pubName).pipe(filter(f => f !== undefined)).subscribe(entries => {
            const path = `${pmcData.directory}${dmc}`;
            const fileEntry = entries.find((e) => e.filename === path);
            if (fileEntry) {
              const zipTask = this.zipService.getData(fileEntry);
              zipTask.data.subscribe((text) => {
                observer.next(JSON.parse(text));
                observer.complete();
              });
            }
          });
        } else {
          observer.next(false);
          observer.complete();
        }
      });
    });
  }

  getIetmFromFaultCode(modelName: string): Observable<IetmZipFault[]> {
    return new Observable((observer: Observer<IetmZipFault[]>) => {
      this.getEntries('faultcodes').pipe(filter(f => f !== undefined)).subscribe(entries => {
        const path = `/FaultCodes/${modelName}/codes`;
        const fileEntry = entries.find((e) => e.filename === path);
        if (fileEntry) {
          const zipTask = this.zipService.getData(fileEntry);
          zipTask.data.subscribe((text) => {
            observer.next(JSON.parse(text));
            observer.complete();
          });
        }
      });
    });
  }

  getModelFilters(): Observable<ModelFilter[]> {
    return new Observable((observer: Observer<ModelFilter[]>) => {
      this.getEntries('toc').pipe(filter(f => f !== undefined)).subscribe(entries => {
        const path = `/Metadata/toc/modelFilters`;
        const fileEntry = entries.find((e) => e.filename === path);
        if (fileEntry) {
          const zipTask = this.zipService.getData(fileEntry);
          zipTask.data.subscribe((text) => {
            observer.next(JSON.parse(text));
            observer.complete();
          });
        }
      });
    });
  }

  getManualFilters(): Observable<ManualFilter[]> {
    return new Observable((observer: Observer<ManualFilter[]>) => {
      this.getEntries('toc').pipe(filter(f => f !== undefined)).subscribe(entries => {
        const path = `/Metadata/toc/allManual`;
        const fileEntry = entries.find((e) => e.filename === path);
        if (fileEntry) {
          const zipTask = this.zipService.getData(fileEntry);
          zipTask.data.subscribe((text) => {
            observer.next(JSON.parse(text));
            observer.complete();
          });
        }
      });
    });
  }

  getPublication(fileName: string): Observable<Publication> {
    return new Observable((observer: Observer<Publication>) => {
      this.getEntries(fileName).pipe(filter(f => f !== undefined)).subscribe(entries => {
        const path = `/Publication/${fileName}/document`;
        const fileEntry = entries.find((e) => e.filename === path);
        if (fileEntry) {
          const zipTask = this.zipService.getData(fileEntry);
          zipTask.data.subscribe((text) => {
            observer.next(JSON.parse(text));
            observer.complete();
          });
        }
      });
    });
  }

  getIcn(model: string, icn: string): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      this.platformService.invokePlatformMethod<{ modelName: string, icnName: string, fileContent: string }>(PlatformMethod.getIcn, model, icn).subscribe(value => {
        if(value.modelName === model && value.icnName === icn){
          observer.next(value.fileContent);
          observer.complete();
        }
      })
    });
  }

  getModelFiltersElectron(model: string): Observable<string> {
    let parsedModelFilters: ModelFilter[];
    return new Observable((observer: Observer<string>) => {
      this.platformService.invokePlatformMethod<{modelFilter: string}>(PlatformMethod.getModelFilters).subscribe(modelFilter => {
        parsedModelFilters = JSON.parse(modelFilter.modelFilter);
        observer.next(parsedModelFilters.filter(x => x.model === model)[0].model_family)
        observer.complete();
      })
    })
  }

  getIcnImage(model: string, icn: string): Observable<Blob> {
    return new Observable((observer: Observer<Blob>) => {
      this.getEntries(icn).pipe(filter(f => f !== undefined)).subscribe(entries => {
        const path = `ICN/${model}/`;
        const fileEntry = entries.find((e) => e.filename.startsWith(path));
        if (fileEntry) {
          const zipTask = this.zipService.getData(fileEntry);
          zipTask.data.subscribe((fileBlob) => {
            observer.next(fileBlob);
            observer.complete();
          });
        }
      });
    });
  }

  getPng(fileName: string, path: string, page: number): Observable<Blob> {
    return this.getFile(fileName, path, page, 'png');
  }

  getSecondLevelPng(zipFileName: string, fileName: string, path: string, page: number): Observable<Blob> {
    return new Observable((observer: Observer<Blob>) => {
      this.getEntries(zipFileName).pipe(filter(f => f !== undefined)).subscribe(entries => {
        const pageFileName = `${fileName}_P${page.toString().padStart(4, '0')}.png`;
        const pagePath = `${path}${pageFileName}`;
        const fileEntry = entries.find((e) => e.filename === pagePath);
        if (fileEntry) {
          const zipTask = this.zipService.getData(fileEntry);
          zipTask.data.subscribe((fileBlob) => {
            observer.next(fileBlob);
            observer.complete();
          });
        }
      });
    });
  }

  getFullText(fileName: string, path: string, page: number): Observable<Blob> {
    return this.getFile(fileName, path, page, 'txt');
  }

  getFile(fileName: string, path: string, page: number, ext: string): Observable<Blob> {
    return new Observable((observer: Observer<Blob>) => {
      let zipFileName = fileName;
      this.offlineFileService.isExists('Zip', `${fileName}.zip`).subscribe(exists => {
        let fileNameObs: Observable<string>
        if (exists) {
          fileNameObs = of(fileName);
        }
        if (!exists) {
          fileNameObs = this.offlineFileService.isExists('Zip', `${fileName.substring(0, fileName.lastIndexOf('-'))}.zip`).pipe(map(shortFileExists => {
            return shortFileExists ? `${fileName.substring(0, fileName.lastIndexOf('-'))}.zip` : fileName;
          }))
        }
        fileNameObs.subscribe(fn => {
          zipFileName = fn;
          this.getEntries(zipFileName).pipe(filter(f => f !== undefined)).subscribe(entries => {
            const pageFileName = `${fileName}_P${page.toString().padStart(4, '0')}.${ext}`;
            const pagePath = `${path}${pageFileName}`;
            const fileEntry = entries.find((e) => e.filename === pagePath);
            if (fileEntry) {
              const zipTask = this.zipService.getData(fileEntry);
              zipTask.data.subscribe((fileBlob) => {
                observer.next(fileBlob);
                observer.complete();
              });
            }
          });
        })
      })
    });
  }
  getMetadata(fileName: string, figureName?: string): Observable<Metadata> {
    if (fileName && figureName) {
      return this.getFigureMetaData(fileName, figureName);
    } else {
      return this.getFileMetadata(fileName);
    }
  }

  getIpbData(fileName: string, figureName: string): Observable<Ipb> {
    return new Observable((observer: Observer<Ipb>) => {
      this.getEntries(fileName).pipe(filter(f => f !== undefined)).subscribe(entries => {
        const path = `/Metadata/${fileName}/ipb-${figureName}`;
        const fileEntry = entries.find((e) => e.filename === path);
        if (fileEntry) {
          const zipTask = this.zipService.getData(fileEntry);
          zipTask.data.subscribe((text) => {
            observer.next(JSON.parse(text));
            observer.complete();
          });
        }
      });
    });
  }

  getIcnLocation(): Observable<any> {
    return new Observable((observer: Observer<Ipb>) => {
      this.getEntries('wiringdata').pipe(filter(f => f !== undefined)).subscribe(entries => {
        const path = `/WiringData/icnlocs`;
        const fileEntry = entries.find((e) => e.filename === path);
        if (fileEntry) {
          const zipTask = this.zipService.getData(fileEntry);
          zipTask.data.subscribe((text) => {
            observer.next(JSON.parse(text));
            observer.complete();
          });
        }
      });
    });
  }

  getWiring(modelName: string): Observable<any> {
    return new Observable((observer: Observer<Ipb>) => {
      this.getEntries('wiringdata').pipe(filter(f => f !== undefined)).subscribe(entries => {
        const path = `/WiringData/${modelName}/wiring`;
        const fileEntry = entries.find((e) => e.filename === path);
        if (fileEntry) {
          const zipTask = this.zipService.getData(fileEntry);
          zipTask.data.subscribe((text) => {
            observer.next(JSON.parse(text));
            observer.complete();
          });
        }
      });
    });
  }

  getWiringGroup(modelName: string): Observable<any> {
    return new Observable((observer: Observer<Ipb>) => {
      this.getEntries('wiringdata').pipe(filter(f => f !== undefined)).subscribe(entries => {
        const path = `/WiringData/${modelName}/groups`;
        const fileEntry = entries.find((e) => e.filename === path);
        if (fileEntry) {
          const zipTask = this.zipService.getData(fileEntry);
          zipTask.data.subscribe((text) => {
            observer.next(JSON.parse(text));
            observer.complete();
          });
        }
      });
    });
  }

  getTracing(modelName: string): Observable<any> {
    return new Observable((observer: Observer<Ipb>) => {
      this.getEntries('wiringdata').pipe(filter(f => f !== undefined)).subscribe(entries => {
        const path = `/WiringData/${modelName}/tracing`;
        const fileEntry = entries.find((e) => e.filename === path);
        if (fileEntry) {
          const zipTask = this.zipService.getData(fileEntry);
          zipTask.data.subscribe((text) => {
            observer.next(JSON.parse(text));
            observer.complete();
          });
        }
      });
    });
  }

  getWiringParam(modelName: string): Observable<any> {
    return new Observable((observer: Observer<Ipb>) => {
      this.getEntries('wiringdata').pipe(filter(f => f !== undefined)).subscribe(entries => {
        const path = `/WiringData/${modelName}/params`;
        const fileEntry = entries.find((e) => e.filename === path);
        if (fileEntry) {
          const zipTask = this.zipService.getData(fileEntry);
          zipTask.data.subscribe((text) => {
            observer.next(JSON.parse(text));
            observer.complete();
          });
        }
      });
    });
  }

  getWiringReport(modelName: string): Observable<any> {
    return new Observable((observer: Observer<Ipb>) => {
      this.getEntries('wiringdata').pipe(filter(f => f !== undefined)).subscribe(entries => {
        const path = `/WiringData/${modelName}/report`;
        const fileEntry = entries.find((e) => e.filename === path);
        if (fileEntry) {
          const zipTask = this.zipService.getData(fileEntry);
          zipTask.data.subscribe((text) => {
            observer.next(JSON.parse(text));
            observer.complete();
          });
        }
      });
    });
  }

  getIndexIPBDescriptions(): Observable<any> {
    return new Observable((observer: Observer<Ipb>) => {
      this.getEntries('indexes').pipe(filter(f => f !== undefined)).subscribe(entries => {
        const path = `/Indexes/ipb.json`;
        const fileEntry = entries.find((e) => e.filename === path);
        if (fileEntry) {
          const zipTask = this.zipService.getData(fileEntry);
          zipTask.data.subscribe((text) => {
            observer.next(JSON.parse(text));
            observer.complete();
          });
        }
      });
    });
  }

  getSearchIndex(fileName: string, type: string): Observable<any> {
    return new Observable((observer: Observer<Ipb>) => {
      this.getEntries('indexes').pipe(filter(f => f !== undefined)).subscribe(entries => {
        const path = `/Indexes/${type}/${fileName}.json`;
        const fileEntry = entries.find((e) => e.filename === path);
        if (fileEntry) {
          const zipTask = this.zipService.getData(fileEntry);
          zipTask.data.subscribe((text) => {
            observer.next(JSON.parse(text));
            observer.complete();
          });
        }
      });
    });
  }

  getDocumentSearchIndex(documentName: string): Observable<any> {
    return new Observable((observer: Observer<Ipb>) => {
      this.getEntries(documentName).pipe(filter(f => f !== undefined)).subscribe(entries => {
        const path = `/Metadata/${documentName}/index`;
        const fileEntry = entries.find((e) => e.filename === path);
        if (fileEntry) {
          const zipTask = this.zipService.getData(fileEntry);
          zipTask.data.subscribe((text) => {
            observer.next(JSON.parse(text));
            observer.complete();
          });
        }
      });
    });
  }

  private getFileMetadata(fileName: string): Observable<Metadata> {
    return new Observable((observer: Observer<Metadata>) => {
      this.getEntries(fileName).pipe(filter(f => f !== undefined)).subscribe(entries => {
        const path = `/Metadata/${fileName}/document`;
        const fileEntry = entries.find((e) => e.filename === path);
        if (fileEntry) {
          const zipTask = this.zipService.getData(fileEntry);
          zipTask.data.subscribe((text) => {
            observer.next(JSON.parse(text));
            observer.complete();
          });
        }
      });
    });
  }

  private getFigureMetaData(fileName: string, figureName: string): Observable<Metadata> {
    return new Observable((observer: Observer<Metadata>) => {
      this.getEntries(fileName).pipe(filter(f => f !== undefined)).subscribe(entries => {
        const path = `/Metadata/${fileName}/figure-${figureName}`;
        const fileEntry = entries.find((e) => e.filename === path);
        if (fileEntry) {
          const zipTask = this.zipService.getData(fileEntry);
          zipTask.data.subscribe((text) => {
            observer.next(JSON.parse(text));
            observer.complete();
          });
        }
      });
    });
  }

  private getZipEntries(fileName: string) {
    if (this.loadedZip$[fileName]) {
      console.log('zip loading file' + fileName);
      this.offlineFileService.readFile('zip', `${fileName}.zip`).subscribe(fileContent => {
        const fileBlob = new Blob([fileContent], { type: 'application/zip' });
        this.zipService.getEntries(fileBlob).subscribe((entries) => {
          console.log('zip file in memory' + fileName);
          this.loadedZip$[fileName].next(entries);
        });
      }, err => {
        console.log('zip file does not exist: ' + fileName);
        console.log(err);
        this.loadedZip$[fileName].next([]);
      });
    }
  }

  private getEntries(fileName: string): BehaviorSubject<ZipEntry[]> {
    if (!this.loadedZip$[fileName]) {
      this.loadedZip$[fileName] = <BehaviorSubject<ZipEntry[]>>new BehaviorSubject(undefined);
      this.getZipEntries(fileName);
      return this.loadedZip$[fileName];
    } else {
      console.log('zip file loaded from memory' + fileName);
      return this.loadedZip$[fileName];
    }
  }

  public checkIfDownloaded(fileName: string): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.offlineFileService.isExists('zip', `${fileName}.zip`).subscribe(exists => {
        observer.next(exists);
        observer.complete();
      }, err => {
        observer.next(false);
        observer.complete();
      });
    });
  }

  getIetmDocFileName(pmc: string, dmc: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.pmcService.getByPmcCode(pmc).subscribe((pmcData) => {
        if (pmcData) {
          observer.next(pmcData.pubName);
          observer.complete();
        } else {
          observer.next(false);
          observer.complete();
        }
      });
    });
  }

}
