import { Router } from "@angular/router";
import { ElectronLoginService } from "src/app/electron/services/electron-login.service";
import { MauiLoginService } from "src/app/maui/services/maui-login.service";
import { ApiUriService } from "../api-uri.service";
import { EnvironmentService } from "../environment.service";
import { OfflineService } from "../offline/offline.service";
import { PlatformService, PlatformType } from "../offline/platform.service";
import { LoginService } from "./login.service"
import { HttpClient } from "@angular/common/http";

export const LoginServiceFactory = (
  environmentService: EnvironmentService,
  apiUri: ApiUriService,
  offlineService: OfflineService,
  router: Router,
  platformService: PlatformService,
  http: HttpClient
): LoginService => {
  switch (platformService.platform) {
    case PlatformType.MAUI: return new MauiLoginService(environmentService, apiUri, offlineService, platformService, router, http);
    case PlatformType.ELECTRON: return new ElectronLoginService(environmentService, apiUri, offlineService, platformService, router, http);
    default: return new LoginService(environmentService, apiUri, router, http, offlineService);
  }
}
