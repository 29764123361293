import { Component, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { IcnDataService } from 'src/app/shared/services/icn/icn-data.service';
import { IetmPrintService } from '../ietm-print-service/ietm-print.service';

@Component({
  selector: 'bell-ietm-print-portal',
  templateUrl: './ietm-print-portal.component.html',
  styleUrls: ['./ietm-print-portal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class IetmPrintPortalComponent implements OnInit, OnDestroy { // ietm printing for maui platform; replicates IetmPrintActionComponent and IetmPrintComponent
  public printTextContainer: HTMLElement;
  public printFigureContainer: HTMLElement;

  private printMainContainer: HTMLElement;
  private toPrintContainerElement: HTMLElement;

  constructor(
    private ietmPrintService: IetmPrintService,
    private icnDataService: IcnDataService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.setPrintContainer();
    this.printByType();
  }

  private setPrintContainer() {
    if (!this.printMainContainer) {
      this.printMainContainer = this.createPrintMainContainer();
    }
    this.ietmPrintService.setPrintMainContainerElement(this.printMainContainer);
    this.toPrintContainerElement = this.renderer.createElement('bell-document-to-print');
  }

  private createPrintMainContainer() {
    const printPortalContainer = document.querySelector('bell-ietm-print-portal') as HTMLElement;
    const printMainContainer = printPortalContainer ? printPortalContainer : document.createElement('div');
    printMainContainer.setAttribute('id', 'printMainContainer');
    document.body.setAttribute('id', 'bodyPrintMainContainer');
    document.body.appendChild(printMainContainer);
    return printMainContainer;
  }

  private printByType() {
    const printing = this.ietmPrintService.ietmPrintModalData.docType;
    switch (printing) {
      case 'text':
        this.setTextContainer();
        break;
      case 'figure':
        this.setFigureContainer();
        break;
      case 'both':
        this.setTextContainer();
        this.setFigureContainer();
        break;
    }
    this.printMainContainer?.append(this.toPrintContainerElement);
    this.removeElements(this.printMainContainer, ['.ietm_isolationStep', 'A']);
    this.ietmPrintService.onDataReady();
  }

  private setTextContainer() {
    this.printTextContainer = this.createTextContainer();
    this.toPrintContainerElement.appendChild(this.printTextContainer);
  }

  private createTextContainer(): HTMLElement {
    const textContainer = document.createElement('div');
    textContainer.innerHTML = this.ietmPrintService.ietmPrintModalData.dataModule.html;

    // set inline image src
    const inlineImg = textContainer.getElementsByClassName('ietm_symbol');
    for (const value of Object.keys(inlineImg)) {
      let icn = inlineImg[value].getAttribute('src');
      icn = icn.slice(icn.indexOf('(') + 1, icn.indexOf(')'));

      this.icnDataService.getIcnImage(this.ietmPrintService.ietmPrintModalData.model, icn).subscribe((data) => {
        if (data) {
          inlineImg[value].setAttribute('src', data);
        }
      });
    }

    this.setFaultTreeElements(textContainer);

    for (let nodeText of textContainer.childNodes) {
      const nodeName = nodeText.nodeName;
      if (nodeName === '#text') {
        nodeText.parentNode.removeChild(nodeText);
      }
    }
    return textContainer;
  }

  private setFaultTreeElements(textContainer: HTMLDivElement) {
    const faultTreeImg = textContainer.getElementsByClassName('ietm_fault_tree_png');
    const ietmIsolationSteps = textContainer.getElementsByClassName('ietm_isolationStep');
    const ietmFaultTree = textContainer.getElementsByClassName('ietm_fault_tree');
    const ietmPrelregs = textContainer.getElementsByClassName('ietm_prelreqs');

    for (let img of faultTreeImg) {
      img.setAttribute('width', '90%');
      img.setAttribute('data-title', img.children[0].getAttribute('title'));

      const faultImageDiv = document.createElement('div');
      faultImageDiv.appendChild(img);
      ietmPrelregs[0].after(faultImageDiv);
    }

    for (let step of ietmIsolationSteps) {
      step.setAttribute('style', 'display: none');
    }

    for (let element of ietmFaultTree) {
      element.setAttribute('style', 'display: block');
    }
  }

  private setFigureContainer() {
    this.printFigureContainer = this.ietmPrintService.printFigureContainer;
    this.toPrintContainerElement.appendChild(this.printFigureContainer);
    setTimeout(() => this.prepareFigures());
  }

  private prepareFigures() {
    const allStyles = document.styleSheets;
    const allFigures = this.ietmPrintService.allFigures;

    for (let fig of allFigures) {
      const elementId = fig.elementId;
      const styleSheetId = elementId + '-SVG';

      for (let styleSheet of allStyles) {
        if (styleSheetId === styleSheet.ownerNode['id']) {
          const cssRules = styleSheet.cssRules;

          for (let rule of cssRules) {
            const selectors = rule['selectorText'];
            if (selectors) {
              const newSelectors = selectors.replace(/[.]/g, '#' + elementId + ' .');
              rule['selectorText'] = newSelectors;
            }
          }
        }
      }
    }
  }

  private removeElements(elContainer: HTMLElement, elQuerySelectors: Array<any>) {
    elQuerySelectors.forEach(querySelector => {
      elContainer?.querySelectorAll(querySelector).forEach(el => {
        if (querySelector === 'A') {
          el.href = '';
        } else {
          el.parentNode.removeChild(el);
        }
      });
    })
  }

  ngOnDestroy() { 
    this.toPrintContainerElement?.parentNode?.removeChild(this.toPrintContainerElement);
    this.printMainContainer?.parentNode?.removeChild(this.printMainContainer);
    document.body.setAttribute('id', '');
  }
}
