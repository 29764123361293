import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralInfoRoutingModule } from './general-info.module.routing';
import { BinderLabelsComponent } from './binderlabels/binderlabels.component';
import { RecordCardsComponent } from './record-cards/record-cards.component';
import { RevisionStatusComponent } from './revision-status/revision-status.component';
import { WeightBalanceComponent } from './weight-balance/weight-balance.component';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { RelatedLinksComponent } from './related-links/related-links.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    BinderLabelsComponent,
    RecordCardsComponent,
    RevisionStatusComponent,
    WeightBalanceComponent,
    RelatedLinksComponent,
  ],
  imports: [
    CommonModule,
    GeneralInfoRoutingModule,
    MatMenuModule,
    MatSelectModule,
    MatCardModule,
    MatExpansionModule,
    MatSnackBarModule,
    SharedModule
  ],
  exports: [
    MatExpansionModule,
    MatCardModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class GeneralInfoModule { }
