<div id="offline-lib-page">
  <h1>Bell Offline TechPubs</h1>
  <br>
  <div>
    <ng-container *ngFor="let link of offlineLibLinks">
      <p>The Bell Offline Techpubs allows you to view your technical manuals offline. Download and run the BellOffline.exe file then follow the <a href="{{link.path}}" target="{{link.target}}">{{link.label}}</a> to help you get started.</p>
    </ng-container>
    <p class="notes">Note - Once installed you will need to login with your MyBell credentials to activate.</p>
  </div>
  <div id="offline-lib">
    <ng-container *ngFor="let release of offlineLibReleases">
      <section>
        <header>
          <a href="{{release.path}}" target="{{release.target}}">Download latest {{release.label}}</a>
          <hr>
        </header>
        <section>
          <h2>Release notes:</h2>
          <hr>
          <ng-container *ngFor="let notes of release.notes">
            <div class="release-label">
              {{notes.label}}
              <div class="release-label__date">
                {{notes.date | date:'shortDate'}}
              </div>
            </div>
            <div class="release-description">
              {{notes.note}}
            </div>
            <hr>
          </ng-container>
        </section>
      </section>
    </ng-container>
  </div>
</div>
