import { Injectable } from '@angular/core';
import { DocumentNode } from '../../../data-model/idb/document-node';
import { TpubDbService } from './tpub-db.service';
import { documentStoreName } from '../../../constants/indexed-store-config';
import { Observable, Observer } from 'rxjs';
import { Dexie } from 'dexie';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  public table: Dexie.Table<DocumentNode>;

  constructor(private tpubDbService: TpubDbService) {
    this.table = this.tpubDbService.table(documentStoreName);
  }

  add(data: DocumentNode): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.table.add(data).then(result => {
        observer.next(true);
        observer.complete();
      }, (error) => {
        observer.error(error);
      });
    });
  }

  addBulk(data: DocumentNode[]): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (data && data.length > 0) {
        this.table.bulkAdd(data).then((result) => {
          observer.next(true);
          observer.complete();
        }, (error) => {
          observer.error(error);
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

  clear(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.table.clear().then(() => {
        observer.next(true);
        observer.complete();
      }, (err) => {
        observer.error(err);
      });
    });
  }

  getByName(name: string): Observable<DocumentNode> {
    return new Observable((observer: Observer<DocumentNode>) => {
      this.table.where('name').equalsIgnoreCase(name).first().then((data: DocumentNode) => {
        observer.next(data);
        observer.complete();
      }, (error) => {
        observer.error(error);
      });
    });
  }

  getAll(): Observable<DocumentNode[]> {
    return new Observable((observer: Observer<DocumentNode[]>) => {
      this.table.toArray().then((data: DocumentNode[]) => {
        observer.next(data);
        observer.complete();
      }, (error) => {
        observer.error(error);
      });
    });
  }

  remove(id: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.table.delete(id).then(() => {
        observer.next(true);
        observer.complete();
      }, (err) => {
        observer.error(err);
      });
    });
  }

  removeBulk(keys: number[]): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.table.bulkDelete(keys).then(() => {
        observer.next(true);
        observer.complete();
      }, (err) => {
        observer.error(err);
      });
    });
  }

  update(id: number, data: DocumentNode): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.table.update(id, data).then(() => {
        observer.next(true);
        observer.complete();
      }, (err) => {
        observer.error(err);
      });
    });
  }

  updateBulk(data: DocumentNode[]): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (data && data.length > 0) {
        this.table.bulkPut(data).then(() => {
          observer.next(true);
          observer.complete();
        }, (err) => {
          observer.error(err);
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }
}
