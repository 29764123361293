<div id="modalHttpEvent">
  <ng-container *ngIf="!data.progressType">
    <h2 mat-dialog-title id="inProgressTitle">In progress</h2>
  </ng-container>
  <ng-container *ngIf="data.progressType">
    <section id="progressTitle">
      <div class="icon-report-problem-triangle" *ngIf="data.doneWithError"></div>
      <h2 mat-dialog-title>{{data.title}}</h2>
    </section>
  </ng-container>
  <mat-dialog-content>
    <section id="textContent" *ngIf="data.textContent">
      {{data.textContent}}
    </section>
    <section id="loadProgressBar" *ngIf="data.progressType === 'Download' && !data.doneWithError">
      <mat-progress-bar [mode]="data.barMode" [value]="data.barProgress">
      </mat-progress-bar>
    </section>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="btn-container">
      <button mat-button [mat-dialog-close]="true" class="tp-button tp-button--cancel" *ngIf="data.finished">
        {{data.buttonLabel}}
      </button>
    </div>
  </mat-dialog-actions>
</div>
