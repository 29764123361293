import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { IetmSns, IetmManual, IetmDataModule, IetmIcn, IetmIcnHotspot } from './ietm-service/ietm-model';
import { IetmSnsService } from './ietm-service/ietm-sns.service';
import { IetmManualService } from './ietm-service/ietm-manual.service';
import { IetmDataModuleService } from './ietm-service/ietm-data-module.service';
import { IetmIcnService } from './ietm-service/ietm-icn.service';
import { IetmRouteService } from './ietm-service/ietm-route.service';
import { IetmEventService } from './ietm-service/ietm-event.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class IetmService {
  public sns$: Observable<IetmSns> = this.ietmSns.sns$;
  public manual$: Observable<IetmManual> = this.ietmManual.manual$;
  public dataModule$: Observable<IetmDataModule> = this.ietmDataModule.dataModule$;
  public icn$: Observable<IetmIcn> = this.ietmIcn.icn$;
  public selectedWireKey$: Observable<string> = this.ietmIcn.selectedWireKey$;
  public selectedHotspot$: Observable<IetmIcnHotspot> = this.ietmIcn.selectedHotspot$;
  public hoverHotspot$: Observable<string> = this.ietmIcn.hoverHotspot$;
  public router: Router = this.route.router;

  public questionFromFim$ = new BehaviorSubject<string>(null);
  public reloadIcn$ = new BehaviorSubject<string>(null);
  public pinsConnectorsFromFim$ = new BehaviorSubject<any>(null);

  constructor(
    private ietmSns: IetmSnsService,
    private ietmManual: IetmManualService,
    private ietmDataModule: IetmDataModuleService,
    private ietmIcn: IetmIcnService,
    public route: IetmRouteService,
    public events: IetmEventService
  ) { }

  getSns = this.ietmSns.getSns.bind(this.ietmSns);
  getManual = this.ietmManual.getManual.bind(this.ietmManual);
  getModule = this.ietmDataModule.getDataModule.bind(this.ietmDataModule);
  getIcn = this.ietmIcn.getIetmIcn.bind(this.ietmIcn);
  getIpd = this.ietmIcn.getIpdItems.bind(this.ietmIcn);

  changeSelectedWireKey = this.ietmIcn.changeSelectedWireKey.bind(this.ietmIcn);
  changeSelectedHotspot = this.ietmIcn.changeSelectedHotspot.bind(this.ietmIcn);
  changeHoverHotspot = this.ietmIcn.changeHoverHotspot.bind(this.ietmIcn);

  sendFimQuestion(value: string) {
    this.questionFromFim$.next(value);
  }

  sendFimIcn(value: string) {
    this.reloadIcn$.next(value);
  }

  sendFimPinConnector(value: any) {
    this.pinsConnectorsFromFim$.next(value);
  }

}
