import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PlatformService } from './platform.service';
import { Buffer } from 'buffer';

@Injectable({
  providedIn: 'root'
})
export class OfflineFileService {

  constructor(
    private platformService: PlatformService
  ) { }

  isExists(folderName: string, fileName: string): Observable<boolean> {
    return this.platformService.checkExists(folderName + '\\' + fileName);
  }

  readFile(folderName: string, fileName: string): Observable<Buffer> {
    return this.platformService.readFile(folderName + '\\' + fileName);
  }

  writeFile(folderName: string, fileName: string, fileContent: Blob): Observable<boolean> {
    return new Observable(observer => {
      this.platformService.createDirectory(folderName).subscribe(d => {
        if (d) {
          const fileReader = new FileReader();
          fileReader.onload = (e) => {
            if (fileReader.readyState === 2) {
              const buffer = Buffer.from(fileReader.result as ArrayBuffer);
              this.platformService.writeFile(folderName + fileName, buffer).subscribe(r => {
                observer.next(r);
                observer.complete();
              });
            }
          };
          fileReader.readAsArrayBuffer(fileContent);
        } else {
          observer.next(d);
          observer.complete();
        }
      });
    });

  }

  writeStringToFile(folderName: string, fileName: string, fileContent: string): Observable<boolean> {
    return new Observable(observer => {
      this.platformService.createDirectory(folderName).subscribe(d => {
        if (d) {
          const stringBuffer = Buffer.from(fileContent, 'utf8');
          this.platformService.writeFile(folderName + fileName, stringBuffer).subscribe(f => {observer.next(f); observer.complete();});
        }
      });
    })

  }

  deleteFile(folderName: string, fileName: string): Observable<boolean> {
    return this.platformService.removeFile(folderName + '\\' + fileName);
  }

  deleteUserDocumentDirectory(): Observable<boolean> {
    return this.platformService.removeDirectory('');
  }
}
