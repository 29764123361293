export class DocumentTree {
  rowid: number | undefined;
  path: string;
  parent: string | undefined;
  childCount: number | undefined;
  selected: string | undefined;
  selectedChild: number | undefined;
  usable: string | undefined;
  usableChild: number | undefined;
  updatable: string | undefined;
  updatableChild: number | undefined;
  remove: string | undefined;
  removeChild: number | undefined;
  download: string | undefined;
  downloadChild: number | undefined;
  doc: string | undefined;
  pendingAction: string | undefined;
  lastAction: string | undefined;
}
