<div class="unauth-screen">
    <div class="unauth-modal">
      <div class="unauth-header">
        <h1>TECHNICAL PUBLICATIONS</h1>
      </div>
      <div>
        <div class="unauth-header">
          <div class="unauth-form">
            <!-- <div class="unauth-text">Embargo Failure</div> -->
            <div class="unauth-text">You are not authorized to access the application</div>
            <p *ngIf="errorDetails">{{errorDetails}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
