import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpInterceptService } from './http-intercept.service';

@Injectable()

export class HttpEventInterceptor implements HttpInterceptor {

  constructor(
    private httpInterceptService: HttpInterceptService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.httpInterceptService.intercept(request, next);
  }

}
