export class DocumentNode {
    rowid: number | undefined;
    name: string;
    availableUpdate: Date;
    lastUpdate: Date;
    selectionCount: number;
    size: number;
    title: string;
    models: string;
    idFile: number;
    idManual: number;
    type: string;
}
