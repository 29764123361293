import { Injectable, Type, ViewContainerRef, Renderer2, ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { IetmRouteService } from './ietm-route.service';
import { IetmDataModule } from './ietm-model';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { IetmDataService } from 'src/app/shared/services/ietm/ietm-data.service';

@Injectable({
  providedIn: 'root'
})
export class IetmDataModuleService {
  private _dataModule: BehaviorSubject<IetmDataModule> = new BehaviorSubject<IetmDataModule>(null);
  public dataModule$: Observable<IetmDataModule> = this._dataModule.asObservable();
  get dataModule() { return this._dataModule.value; }

  public status = {
    loading: false,
    error: false
  };

  constructor(
    private ietmRoute: IetmRouteService,
    private ietmDataService: IetmDataService,
  ) {
    this.ietmRoute.dmc$.subscribe(dm => this.handleDataModuleChange(this.ietmRoute.model, this.ietmRoute.pmc, dm));
  }

  private handleDataModuleChange(model: string, manual: string, dm: string) {
    // check to see if the datamodule is already loaded.
    if (this.dataModule && this.dataModule.model === model && this.dataModule.pmc === manual && this.dataModule.dmc === dm) { return; }
    this.status = { loading: true, error: this.status.error };  // error: keep the previous error state
    this.getDataModule(model, manual, dm).subscribe((data) => {
      if (data) {
        data.isWiringDiagram = (data.dmc) ? data.dmc.substring(23, 26) === '051' : false;
      }
      this._dataModule.next(data);
      this.status.loading = false;
      this.status.error = !((dm == null && this.dataModule == null) || (this.dataModule));
    });
  }

  getDataModule(model: string, manual: string, dm: string): Observable<IetmDataModule> {
    /** TODO: TEMP_FIX
     * This Section of the code should be TEMP. Models 407GX,407GXi share parent 407
     * and selecting 407GXi for data modules offline will cause an issue loading figures, and sheets.
     * This is TEMP until this is fix on the Consumption data layer.
     */
    model = this.tempFixModelSwitcher(model);

    return new Observable<IetmDataModule>((observer: Observer<IetmDataModule>) => {
      if (this.dataModule && this.dataModule.dmc === dm) {
        observer.next(this.dataModule);
        observer.complete();
      } else if (!model || !manual || (!dm && manual !== 'sns')) {
        observer.next(null);
        observer.complete();
      } else {
        this.ietmDataService.getIetm(model, manual, dm).subscribe((ietm) => {
          if (ietm) {
            observer.next({
              dmc: dm,
              pmc: manual,
              model: model,
              pageTitle: ietm['pageTitle'],
              title: ietm['title'],
              subtitle: ietm['subtitle'],
              html: ietm['ietm']['module']
            });
            observer.complete();
          } else {
            observer.next(null);
            observer.complete();
          }
        });
      }
    });
  }

  private tempFixModelSwitcher(model: string): string {
    if (model === null || model === undefined) {
      return model
    }
    const modelSwitcherList = ['407GX','407GXi'];
    if (modelSwitcherList.includes(model)) {
      return '407';
    }
    return model;
  }
}
