import { Injectable } from '@angular/core';
import { GlobalData } from '../../../data-model/idb/global-data';
import { TpubDbService } from './tpub-db.service';
import { globalStoreName } from '../../../constants/indexed-store-config';
import { Observable, Observer } from 'rxjs';
import { Dexie } from 'dexie';

@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {


  private table: Dexie.Table<GlobalData>;

  constructor(private tpubDbService: TpubDbService) {
   this.table = this.tpubDbService.table(globalStoreName);
  }

  add(data: GlobalData): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.table.add(data).then(result => {
        observer.next(true);
        observer.complete();
      }, (error) => {
        observer.error(error);
      });
    });
  }

  addBulk(data: GlobalData[]): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.table.bulkAdd(data).then((result) => {
        observer.next(true);
        observer.complete();
      }, (error) => {
        observer.error(error);
      });
    });
  }

  clear(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.table.clear().then(() => {
        observer.next(true);
        observer.complete();
      }, (err) => {
        observer.error(err);
      });
    });
  }

  getByKey(dataKey: string): Observable<GlobalData> {
    return new Observable((observer: Observer<GlobalData>) => {
      this.table.where('key').equalsIgnoreCase(dataKey).first().then(data => {
        observer.next(data);
        observer.complete();
      }, err => {
        observer.error(err);
      });
    });
  }
  getAll(): Observable<GlobalData[]> {
    return new Observable((observer: Observer<GlobalData[]>) => {
      this.table.toArray().then((data: GlobalData[]) => {
        observer.next(data);
        observer.complete();
      }, (error) => {
        observer.error(error);
      });
    });
  }

  remove(id: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.table.delete(id).then(() => {
        observer.next(true);
        observer.complete();
      }, (err) => {
        observer.error(err);
      });
    });
  }

  update(id: number, data: GlobalData): Observable<boolean>  {
    return new Observable((observer: Observer<boolean>) => {
      this.table.update(id, data).then(() => {
        observer.next(true);
        observer.complete();
      }, (err) => {
        observer.error(err);
      });
    });
  }
}
