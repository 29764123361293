import { Inject, Component } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'bell-session-timeout-modal',
  templateUrl: './session-timeout-modal.component.html',
  styleUrls: ['./session-timeout-modal.component.scss'],
})
      
export class SessionTimeoutModalComponent  {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

}
