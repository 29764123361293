import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { OfflineFeedService } from 'src/app/shared/services/offline/offline-feed.service';
import { PlatformService } from 'src/app/shared/services/offline/platform.service';

@Component({
  selector: 'bell-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor(
    private platformService: PlatformService,
    private router: Router,
    private offlineFeedService: OfflineFeedService
  ) { }

  ngOnInit() {
    this.setLandingPage();
  }

  setLandingPage() {

    const excludedPaths = ["/parts/edit", "/toc/"]
    const containsExcludedPath = (element) => window.location.href.includes(element);

    if (this.platformService.isOfflineCapable() && !excludedPaths.some(containsExcludedPath)) {
      this.offlineFeedService.isActivated().subscribe(result => {
        if (!result) {
          this.router.navigate(['/myofflinelib']);
        }
      });
    }
  }
}
