<h3 class="page-title">WEIGHT AND BALANCE</h3>
<div id="content-body">
  <div id="hrc-page">
    <div class="weight-balance-description">
      <p>The following weight and balance are provided for your convenience.</p>
    </div>
    <ul class="publications">
      <ng-container *ngFor="let link of weightBalanceLinks; let i = index">
        <li class="pub">
          <div class="publication-icon icon-download-arrow icon" (click)="getPdf(link)"></div>
          <a class="pub-title" (click)="getPdf(link)">{{link.label}}</a>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
