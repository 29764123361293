import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OfflineFeedService } from 'src/app/shared/services/offline/offline-feed.service';

@Component({
  selector: 'bell-offline-deactivate-modal',
  templateUrl: './offline-deactivate-modal.component.html',
  styleUrls: ['./offline-deactivate-modal.component.scss']
})
export class OfflineDeactivateModalComponent implements OnInit {
  deactivate: EventEmitter<any> = new EventEmitter();
  isDeactivating = false;
  constructor(
    public dialogRef: MatDialogRef<OfflineDeactivateModalComponent>,
    private offlineFeedService: OfflineFeedService,
  ) {
    dialogRef.disableClose = true;
   }

  ngOnInit(): void {
  }

  yesDeactivate() {
    this.isDeactivating = true;
    this.offlineFeedService.deActivate().subscribe(() => {
      this.deactivate.emit(true);
      this.isDeactivating = false;
      this.dialogRef.close();
    });
  }

  cancelModal() {
    this.dialogRef.close();
  }

  closeXModal() {
    this.dialogRef.close();
  }

}
