<section class="breadcrumb">
  <a class="nav-text tp-text" routerLink="/">
    TECHNICAL PUBLICATIONS
  </a>
  <ng-container *ngIf="isIetm && ietmSns">
    <span class="nav-text">/</span>
    <a class="nav-text" [routerLink]="['/toc',ietmSns.model]">
      {{ietmSns.model}}
    </a>
    <ng-container *ngIf="ietmManual">
      <span class="nav-text">/</span>
      <a class="nav-text" [routerLink]="['/toc', ietmManual.model, ietmManual.title.substr(4)]">
        {{ietmManual.title}}
      </a>
      <mat-form-field *ngIf="ietmSns.data.manuals" class="manual-select" appearance="outline">
        <mat-select [value]="manual">
          <mat-option *ngFor="let m of manuals" routerLink="/toc/{{m.model}}/{{m.toc}}">{{m.manual}}</mat-option>
        </mat-select>
      </mat-form-field>
      <ng-container *ngIf="ietmDataModule">
        <span class="nav-text pub-name">/</span>
        <a class="nav-text pub-name" [routerLink]="['/ietm', ietmDataModule.model, ietmDataModule.pmc, ietmDataModule.dmc]" *ngIf="ietmDataModule.pmc && ietmDataModule.dmc">
          {{ietmDataModule.title}}
        </a>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!ietmManual && ietmDataModule && ietmDataModule.pmc == 'sns'">
      <span class="nav-text">/</span>
      <a class="nav-text">
        Standard Numbering System
      </a>
      <mat-form-field *ngIf="ietmSns.data.manuals" class="manual-select" appearance="outline">
        <mat-select [value]="ietmManual">
          <mat-option *ngFor="let man of ietmSns.data.manuals" [value]="man" routerLink="/toc/{{ietmSns.model}}/{{man.name.substr(4)}}">{{man.name + ' - ' + man.title}}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!isIetm">
    <ng-container *ngIf="modelName && modelName != 'ALL'">
      <span class="nav-text">/</span>
      <a class="nav-text" routerLink="/toc/{{modelName}}">
        {{modelName}}
      </a>
    </ng-container>
    <ng-container *ngIf="modelName && modelName != 'ALL' && manual && manual.manual && manual.toc">
      <span class="nav-text">/</span>
      <a class="nav-text" routerLink="/toc/{{modelName}}/{{manual.toc}}">
        {{manual.manual}}
      </a>
      <mat-form-field *ngIf="manuals" class="manual-select" appearance="outline">
        <mat-select [value]="manual">
          <mat-option *ngFor="let m of manuals" routerLink="/toc/{{m.model}}/{{m.toc}}">{{m.manual}}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="modelName && manual && docName">
      <span class="nav-text pub-name">/</span>
      <span class="nav-text pub-name">
        {{docName}}
      </span>
    </ng-container>
  </ng-container>
</section>
<div class="expiration-date {{isDual ? 'dual' : ''}}" *ngIf="expirationDate" title="Your access to this model's publications will expire on {{expirationDate}}.">
  Expires on {{expirationDate}}
</div>
<a class="expiration-icon" *ngIf="expirationDate" (click)="openExpirationDateDialog()">
  <mat-icon>info</mat-icon>
</a>
