import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { retryStrategy } from './errorRetry';
import { EnvironmentService } from '../services/environment.service';

@Injectable({
  providedIn: 'root'
})

export class HttpUtilityService {

  getHttpHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers.set('Cache-Control', 'no-cache');
    headers.set('Pragma', 'no-cache');
    headers.set('Expires', 'Thu, 01 Jan 1970 00:00:00 GMT');
    headers.set('If-Modified-Since', '0');
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');

    if (this.environmentService.getIsWorkEnvironment()) {
      headers = headers.set('Db-Source', 'work')
    }
    if (this.environmentService.getForcePHP()) {
      headers = headers.set('Consumption-Endpoint', 'PHP');
    }
    return headers;
  }

  getZipHttpHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers.set('Cache-Control', 'no-cache');
    headers.set('Pragma', 'no-cache');
    headers.set('Expires', 'Thu, 01 Jan 1970 00:00:00 GMT');
    headers.set('If-Modified-Since', '0');
    headers.set('isZipDownload', 'true');
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');

    if (this.environmentService.getIsWorkEnvironment()) {
      headers = headers.set('db-source', 'work');
    }
    return headers;
  }

  getHttpOptions(isSvg?: boolean) {
    const headers = this.getHttpHeaders();
    return {
      headers: headers,
      withCredentials: true,
    };
  }

  constructor(
    private httpClient: HttpClient,
    private environmentService: EnvironmentService
  ) { }

  getNoResult(url: string): Observable<any> {
    return this.httpClient
      .get<any>(url, this.getHttpOptions())
      .pipe(catchError((res: HttpErrorResponse) => this.handleError(res)));
  }

  get<T>(url: string): Observable<T> {
    return this.httpClient
      .get<T>(url, this.getHttpOptions())
      .pipe(catchError((res: HttpErrorResponse) => this.handleError(res)));
  }

  getSvg(url: string): Observable<string> {
    return this.httpClient
      .get(url, {
        headers: this.getHttpHeaders(),
        responseType: 'text',
        withCredentials: true,
      })
      .pipe(catchError((res: HttpErrorResponse) => this.handleError(res)));
  }

  postNoResult(url: string, body: any): Observable<any> {
    return this.httpClient
      .post<any>(url, JSON.stringify(body), this.getHttpOptions())
      .pipe(catchError((res: HttpErrorResponse) => this.handleError(res)));
  }

  post<T>(url: string, body: any): Observable<T> {
    return this.httpClient
      .post<T>(url, body, this.getHttpOptions())
      .pipe(catchError((res: HttpErrorResponse) => this.handleError(res)));
  }

  put<T>(url: string, body: any): Observable<T> {
    return this.httpClient
      .put<T>(url, JSON.stringify(body), this.getHttpOptions())
      .pipe(catchError((res: HttpErrorResponse) => this.handleError(res)));
  }

  putNoResult(url: string, body: any): Observable<any> {
    return this.httpClient
      .put<any>(url, JSON.stringify(body), this.getHttpOptions())
      .pipe(catchError((res: HttpErrorResponse) => this.handleError(res)));
  }

  postForDelete<T>(url: string): Observable<T> {
    return this.httpClient
      .post<T>(url, this.getHttpOptions())
      .pipe(catchError((res: HttpErrorResponse) => this.handleError(res)));
  }

  delete<T>(url: string): Observable<T> {
    return this.httpClient
      .delete<T>(url, this.getHttpOptions())
      .pipe(catchError((res: HttpErrorResponse) => this.handleError(res)));
  }

  getDownloadFile(url: string): Observable<any> {
    return this.httpClient.get<any>(url, {
      headers: this.getHttpHeaders(),
      reportProgress: true,
      responseType: 'blob' as 'json',
      observe: 'events',
      withCredentials: true,
    }).pipe(
      catchError((res: HttpErrorResponse) => this.handleError(res)),
      retryWhen(retryStrategy())
    );
  }

  getBlob<Blob>(url: string): Observable<Blob> {
    return this.httpClient
      .get<Blob>(url, {
        headers: this.getHttpHeaders(),
        responseType: 'blob' as 'json',
        withCredentials: true,
      })
      .pipe(
        catchError((res: HttpErrorResponse) => this.handleError(res)),
        retryWhen(retryStrategy())
      );
  }

  getZipBlob<Blob>(url: string): Observable<Blob> {
    return this.httpClient
      .get<Blob>(url, {
        headers: this.getZipHttpHeaders(),
        responseType: 'blob' as 'json',
        withCredentials: true
      })
      .pipe(
        catchError((res: HttpErrorResponse) => this.handleError(res)),
        retryWhen(retryStrategy())
      );
  }

  private handleError(error: HttpErrorResponse | any) {
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      const err = error.error || JSON.stringify(error);
      let httpErrorMsg: string;
      if (error.message) {
        httpErrorMsg = ` - ${error.message} `;
      } else {
        httpErrorMsg = '';
      }
      errMsg = `${error.status} - ${error.statusText || ''} ${httpErrorMsg} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }
}
