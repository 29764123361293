import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginType } from '../shared/enums/login-types';
import { OfflineFeedService } from '../shared/services/offline/offline-feed.service';
import { OfflineService } from '../shared/services/offline/offline.service';
import { LoginService } from '../shared/services/user/login.service';
import { NetworkService } from '../shared/services/user/network.service';
import { EnvironmentService } from '../shared/services/environment.service';
import { Environments } from '@environments/env.enum';

@Component({
  selector: 'bell-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  public selectedLoginType: LoginType = null;
  public LoginType: typeof LoginType = LoginType;
  public isInternal = false;
  public isLibraryActivated = false;
  public loginOptions = [];
  public rememberPreference = false;
  constructor(
    public offline: OfflineService,
    private offlineFeed: OfflineFeedService,
    private loginService: LoginService,
    private networkService: NetworkService,
    private env: EnvironmentService,
    private router: Router,
  ) { }

  ngOnInit() {
    if (this.env.getEnvironment() === Environments.LOCAL) {
      this.isInternal = true;
    } else {
      this.networkService.isInternal().subscribe(val => this.isInternal = val);
    }

    this.offlineFeed.isOfflineActivated.subscribe(val => this.isLibraryActivated = val);
  }

  public login(type: LoginType): void {
    this.selectedLoginType = type;
    if (this.rememberPreference) {
      this.loginService.setPreferredLogin(type);
    }
    this.loginService.login(type);
    if (type === LoginType.OFFLINE) {
      this.router.navigateByUrl('/');
    }
  }
}
