import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { PartsList } from '../../data-model/cart';
import { Part } from '../../data-model/part';
import { PartsListFileType } from './parts-list-file-type.enum';
import { HttpFileDownloadService } from '../http-progress-event/http-file-download.service';
import { HttpEventType } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { DataService } from '../data-services/data.service';

@Injectable({
  providedIn: 'root'
})
export class PartsListService {
  private _userPartsLists: PartsList[];
  private _activeIndex = 0;
  activeSnackBar: MatSnackBarRef<any>;

  public exportPartsListResult: any;

  constructor(
    private dataService: DataService,
    private snackBar: MatSnackBar,
    private httpFileDownloadService: HttpFileDownloadService
  ) {
    this.userPartsLists = [{name: 'default', items: []}];
    this.activeIndex = 0;
    this.getPartsLists().subscribe();
  }

  /**
   * Requests the user's parts lists from the gateway.
   * @returns an observable with the parts lists.
   */
  getPartsLists(): Observable<Array<PartsList>> {
    return new Observable((observer: Observer<Array<PartsList>>) => {
      this.dataService.getPartsLists().subscribe((data) => {
        this.userPartsLists = data;
        if (this.userPartsLists === null || this.userPartsLists.length === 0) {
          this.userPartsLists = [{name: 'default', items: []}];
        }
        observer.next(this.userPartsLists);
        observer.complete();
      });
    });
  }

  /**
   * The index at which the currently active parts list resides in userPartsLists
   *
   */
  get activeIndex(): number { return this._activeIndex; }
  set activeIndex(val: number) { this._activeIndex = val; }

  /**
   * A list of all user parts lists.
   *
   */
  get userPartsLists(): PartsList[] { return this._userPartsLists; }
  set userPartsLists(value) { this._userPartsLists = value; }

  /**
   * The active parts list where parts can be added, merged, or deleted.
   *
   */
  get activePartsList(): PartsList { return this._userPartsLists[this._activeIndex]; }
  set activePartsList(value) { this._userPartsLists[this._activeIndex] = value; }

  /**
   * Adds a part to the active parts list
   * @param part The part to add.
   * @returns the id of the new part.
   */
  addPart(part: Part): number {
    let id = Math.max.apply(Math, this.activePartsList.items.map(function (o) { return o.id; })) + 1;
    id = Math.max(id, 0);
    this._userPartsLists[this._activeIndex].items.push({ id, part });
    return id;
  }

  /**
   * Searches the active parts list for a part with a matching part number.
   * @param partNumber The part number of the part.
   * @returns The part object if found, otherwise null.
   */
  getPart(partNumber: string) {
    const item = this.activePartsList.items.find(i => i.part.partNumber === partNumber);
    if (item) {
      return item.part;
    }
    return null;
  }

  /**
   * Merges a part into the active parts list by adding the quantity but leaving the description alone.
   * @param part The part to merge in.
   * @returns the id of the part it was merged to in the parts list.
   */
  mergePart(part: Part) {
    let id: number;
    if (this.getPart(part.partNumber)) {
      this._userPartsLists[this.activeIndex].items.forEach(item => {
        if (part.partNumber === item.part.partNumber) {
          item.part.quantity += part.quantity;
          id =  item.id;
          return id;
        }
      });
    } else {
      id = this.addPart(part);
    }
    return id;
  }

  /**
   * Creates a cart and adds it to the user's parts lists.
   * @param cartName The new cart name.
   */
  addPartsList(cartName: string) {
    this._activeIndex = this._userPartsLists.push({ name: cartName, items: [] }) - 1;
    this.savePartsList().subscribe();
  }

  /**
   * Gets a parts list based on the name.
   * @param cartName The name of the parts list.
   */
  getPartsList(cartName: string) {
    return this.userPartsLists.find(cart => cart.name === cartName);
  }

  /**
   * Deletes a part from the active parts list.
   * @param id The id of the part to delete.
   */
  deletePart(id: number) {
    this._userPartsLists[this._activeIndex].items = this.activePartsList.items.filter(item => item.id !== id);
  }

  /**
   * Deletes the active parts list.
   */
  deletePartsList() {
    if (this.activeSnackBar) {
      this.activeSnackBar.dismiss();
    }
    this.dataService.deletePartsList(this.activePartsList.name).subscribe(() => {
      this.getPartsLists().subscribe(() => {
        this.activeSnackBar = this.snackBar.open('Parts list deleted', 'Close', {duration: 10000});
        this._activeIndex = 0;
      });
    });
  }

  /**
   * Merges a cart into the currently active cart. Quantities are summed for identical part numbers.
   * The description of the active cart is used if there is a conflict. The merge cart is unchanged.
   * @param cartName - the cart that is being merged in to the currently active cart.
   */
  mergePartsList(cartName: string) {
    const fromPartsList = this.userPartsLists.find(cart => cart.name === cartName);
    fromPartsList.items.forEach(item => {
      this.mergePart(item.part);
    });
    this.savePartsList().subscribe();
  }

  /**
   * Saves the current parts list and notifies the user upon success
   *
   */
   savePartsList(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      if (this.activeSnackBar) {
        this.activeSnackBar.dismiss();
      }

      this.dataService.postPartsList(this.activePartsList).subscribe(() => {
        this.activeSnackBar = this.snackBar.open('Parts list changes saved.', 'Close', {duration: 10000});
      });
      observer.next(null);
      observer.complete();
    });
  }

  /**
   * Discards all local edits to the parts lists and notifies the user when all local edits are finished.
   *
   */
  refresh() {
    // refresh the lists from the server.
    if (this.activeSnackBar) {
      this.activeSnackBar.dismiss();
    }
    this.getPartsLists().subscribe(() => {
      this.activeSnackBar = this.snackBar.open('Parts lists refreshed.', 'Close', {duration: 2000});
    });
  }

  /**
   * Renames the active parts list.
   *
   */
  renamePartsList(newName) {
    if (this.activeSnackBar) {
      this.activeSnackBar.dismiss();
    }
    this.dataService.renamePartsList(this.activePartsList.name, newName).subscribe(() => {
      this.activeSnackBar = this.snackBar.open('Parts list changes saved.', 'Close', {duration: 10000});
      this.getPartsLists().subscribe();
    });
  }

  /**
   * Removes all items on the active parts list
   *
   */
  clearPartsList() {
    this._userPartsLists[this._activeIndex].items = [];
    this.savePartsList().subscribe();
  }

  /**
   *Exports the current parts list
   *
   */
  exportPartsList(filetype: PartsListFileType) {
    return this.httpFileDownloadService.exportPartsList(this.activePartsList.name, filetype)
      .subscribe(
        (data) => {
          if (data && data.type === HttpEventType.Response) {
            this.exportPartsListResult = data;
          }
        },
        (error) => {
          const snackBarMsg = 'Unable to export parts list. Please try again.';

          if (this.activeSnackBar) {
            this.activeSnackBar.dismiss();
          }

          this.activeSnackBar = this.snackBar.open(snackBarMsg, 'Close', {
            duration: 10000
          });
        }
      );
  }

  importPartsList(data: FormData, partsListName: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.dataService.importPartsList(data, partsListName)
      .subscribe((result) => {
        result = JSON.stringify(result);

        if (this.activeSnackBar) {
          this.activeSnackBar.dismiss();
        }
        if (result && result.indexOf(partsListName)) {
          this.activeSnackBar = this.snackBar.open('Parts list imported successfully.', 'Close', {duration: 10000});
        } else {
          this.activeSnackBar = this.snackBar.open(
            'Errors during parts list import. Please verify the import data and try again.', 'Close');
        }
        this.getPartsLists().subscribe();
        const index = this._userPartsLists.findIndex(partsList => partsList.name === partsListName);
        if (index !== -1) {
          this._activeIndex = index;
        }
      });
      observer.next(null);
      observer.complete();
    });
  }

  /**
   * Checks if the proposed cart name is valid.
   *
   * @returns The error message if applicable.
   */
  validateName(name: string): string {
    if (name.length <= 0) {
      return 'Name is required';
    } else if (!(/^[A-Za-z0-9\_]+$/.test(name))) {
      return 'Invalid character';
    } else if (name.length > 25) {
      return 'Parts list name is too long';
    } else if (this.getPartsList(name)) {
      return 'Parts list name is already used.';
    } else {
      return '';
    }
  }
}
