<div id="modalSessionTimeout">
  <section id="sessionTimeoutTitle">
    <h2 mat-dialog-title>Your session is about to expire.</h2>
  </section>
  <mat-dialog-content>
    <section id="sessionTimeoutText">
      {{data.idleState}}
    </section>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="btn-container">
      <button mat-button [mat-dialog-close]="false" class="tp-button tp-button--cancel">
        Logout
      </button>
      <button *ngIf="data.continue" mat-button [mat-dialog-close]="true" class="tp-button tp-button--primary">
        Continue
      </button>
    </div>
  </mat-dialog-actions>
</div>
