import { Component, OnInit } from '@angular/core';
import { ResolveStart, Router, RouterEvent } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs/operators';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import { PlatformService } from './shared/services/offline/platform.service';

@Component({
  selector: 'bell-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'TechPubs-Web';
  displayApp = false;
  displayLogin = false;

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private platformService: PlatformService,
    private swUpdateService: SwUpdate,
    private router: Router
  ) {
    this.googleAnalyticsService.setUp();
  }

  ngOnInit() {
    if (!this.platformService.isOfflineCapable()) {
      this.swUpdateService.versionUpdates.pipe(
        filter((evt: any): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map((evt: any) => {
          this.router.events.subscribe((e: RouterEvent) => {
            // resolvestart events are not triggered by queryparam changes
            if (e instanceof ResolveStart) {
              // build target uri
              const targetUri = `${document.baseURI}${e.url.substring(1)}`;
              // prevent navigation, and instead load the page freshly
              this.router.navigateByUrl(this.router.getCurrentNavigation().initialUrl).then(() => {window.location.href = targetUri;});
            }
          })
        }),
      );
    }

    // display ui only if platform is initialized (eg maui .net instance is retrieved)
    this.platformService.platformInitialized$.subscribe(isPlatformInitialized => {
      this.displayApp = isPlatformInitialized;
      console.log('isPlatformInitialized', isPlatformInitialized);
    });
  }

}
