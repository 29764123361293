import { Component, OnInit, Inject, Injector, InjectionToken } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export const CCODE_FROM_DIALOG = new InjectionToken<string>('cCodeFromDialog', { providedIn: 'root',  factory: () => null } );

@Component({
  selector: 'bell-ccode-dialog',
  templateUrl: './ccode-dialog.component.html',
  styleUrls: ['./ccode-dialog.component.scss'],
})

export class CCodeDialogComponent implements OnInit {
  cCodeSelectedDialog: string;
  cCodeOutletInjector: any;
  cCodeComponentInjector: Injector;
  cCodeFromDialog = CCODE_FROM_DIALOG;

  constructor(
    private cCodeInjector: Injector,
    public cCodeDialog: MatDialogRef < CCodeDialogComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.cCodeOutletInjector = this.data.component;
    this.cCodeSelectedDialog = this.data.ccode;
    this.cCodeComponentInjector = this.cCodeDialogInput(this.cCodeSelectedDialog, this.cCodeFromDialog, this.cCodeInjector);
  }

  cCodeDialogInput(cCodeSelectedDialog, cCodeFromDialog, cCodeInjector) {
    return Injector.create(
      {
        providers: [
          {
            provide: cCodeFromDialog,
            useValue: cCodeSelectedDialog
          }
        ],
        parent: cCodeInjector
      }
    );
  }

  onNoClick(): void {
    this.cCodeDialog.close();
  }
}
