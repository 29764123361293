<section class="side-nav-buttons" *ngIf="!isMobile">
  <div class="add-publications" *ngIf="hasMarketPlaceAccess">
    <h4>Add Publications</h4>
    <p>To add a manual to your library, please click below and purchase an ePub or Paper publication from the Marketplace.</p>
    <button mat-raised-button color="accent">Add a publication</button>
  </div>
</section>
<section class="side-nav-buttons-mobile" *ngIf="isMobile && currentUrl == '/'">
  <button mat-raised-button color="accent" class="add-publications-button" *ngIf="hasMarketPlaceAccess">Add a publication</button>
</section>
