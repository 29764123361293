import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { PrintComponent } from './print.component';
import { PrintPreviewComponent } from './print-preview/print-preview.component';
import { PrintElementDirective } from './print-direcive/print-element.directive';

@NgModule({
    declarations: [
        PrintComponent,
        PrintPreviewComponent,
        PrintElementDirective
    ],
    exports: [
        PrintComponent,
        PrintElementDirective
    ],
    imports: [
        CommonModule,
        MatDialogModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class PrintModule { }
