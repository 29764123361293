export abstract class IdleExpiryBaseService {
  protected idValue: any;
  protected idlingValue: boolean;

  constructor() {
    this.idValue = new Date();
    this.idlingValue = false;
  }

  id(value ? : any): any {
    if (value !== void 0) {
      if (!value) {
        throw new Error('A value must be specified for the ID.');
      }

      this.idValue = value;
    }

    return this.idValue;
  }

  abstract last(value ? : Date): Date;

  idling(value ? : boolean): boolean {
    if (value !== void 0) {
      this.idlingValue = value;
    }

    return this.idlingValue;
  }

  now(): Date {
    return new Date();
  }

  isExpired(): boolean {
    const expiry = this.last();
    return expiry != null && expiry <= this.now();
  }
}
