<nav class="navbar" (window:resize)="onResize($event)" id="tpNavigationBar" #tpNavigationBar
  (mouseenter)="navbarNotHover=false" (mouseleave)="navbarNotHover=true" [ngClass]="{'navbar__not-hover':
  navbarNotHover}">
  <div class="navbar__container"
    [ngClass]="{'bell-tab-header': !isScreenWidthSmall, 'bell-mobile-header': (isScreenWidthSmall && !menuCollapsed),
    'active': (!menuCollapsed || !iconMenuCollapsed), 'hide': scrollHideNav, 'offline': offlineService.isOffline,
    'show': scrollShowNav}"
    (window:scroll)="onScroll($event)">
    <div class="navbar__topnav" [ngClass]="{'active': !menuCollapsed}">
      <div class="navbar__header">
        <div class="navbar__content">
          <div class="nav__section nav__section--mobile">
            <div class="logo__wrapper {{ offlineService.isOffline ? ' offline' : '' }}">
              <a class="logo">
                <img class="logo__img" routerLink="/" src="assets/bell-logo-color.png" alt="Bell Flight logo">
              </a>
            </div>
          </div>
          <div class="nav__section nav__section--mobile nav__section--border-left" *ngIf="!offlineService.isOffline">
            <div class="button__wrapper">
              <div class="button__menu">
                <button type="button" value="hideMenu" [ngClass]="{'active': !menuCollapsed}"
                  [attr.aria-expanded]="!menuCollapsed" (click)="toggleMenu()">
                  <div class="icon__menu tp-icon tp-icon__hamburger"
                    [ngClass]="{'tp-icon__hamburger--active': !menuCollapsed}">
                    <div class="spin"></div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="navbar__content">
        <div class="nav__section">
          <ul class="menu" *ngIf="!offlineService.isOffline && !shouldHideExternalLinks">
            <li *ngFor="let link of navLinks; index as i;" class="menu__link bell-color-focus {{link.color}}" [ngClass]="{'active': activeTab === link}">
              <a *ngIf="!(link.children)" (onClick)="activateTab(link)" href="{{link.path}}" target="{{link.target}}">{{link.label}}</a>
              <div class="dropdown" *ngIf="link.children" [ngClass]="{'active': link.expanded}">
                <div class="dropdown__button">
                  <button mat-flat-button [disableRipple]="true" class="dropdown__label link" [matTooltip]="link.tooltip" (click)="toggleDropdown(i)">
                    <div class="link__label" *ngIf="!link.icon">{{link.label}}</div>
                    <div [class]="'link__icon icon-' + link.icon" *ngIf="link.icon"></div>
                  </button>
                </div>
                <div class="dropdown__content" [ngClass]="{'dropdown__right': link.rightAlign}" (mouseleave)="toggleDropdown(i,0)" (mouseenter)="toggleDropdown(i,1)">
                  <ul class="dropdown__options" [attr.aria-expanded]="link.expanded">
                    <ng-container *ngFor="let childLink of link.children">
                      <li class="dropdown__item">
                        <a mat-menu-item [matTooltip]="childLink.tooltip" matTooltipPosition="left" [href]="childLink.path" [target]="link.target" (click)="toggleDropdown(i,0)">
                          {{childLink.label}}
                        <mat-icon class="dropdown__icon small-icon">call_made</mat-icon>
                        </a>
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </li>

          </ul>
        </div>

        <div class="nav__section"
          [ngClass]="{'offline': offlineService.isOffline, 'nav__section--tablet': !offlineService.isOffline}">
          <ul class="menu-el">
            <li class="menu-el__container">
              <div class="dropdown" [ngClass]="{'active': !iconMenuCollapsed}">
                <div class="dropdown__button">
                  <div class="icon__button" (click)="toggleIconMenu()">
                    <i class="bell-icon bell-icon-profile icon__click"></i>
                  </div>
                </div>
                <div class="dropdown__content" *ngIf="!offlineService.isOffline" (mouseleave)="iconMenuCollapsed=true">
                  <ul class="dropdown__options" [attr.aria-expanded]="!iconMenuCollapsed">
                    <li class="dropdown__item bell-color-focus" *ngIf="!shouldHideExternalLinks">
                      <a [attr.href]="myAccountLink">
                        <span class="myaccount-link" title="My Account">{{userFirstName}}</span>'S ACCOUNT
                      </a>
                      <div class="dropdown__icon">
                        <i class="bell-icon-arrow-right"></i>
                      </div>
                    </li>
                    <li class="dropdown__item bell-color-focus" *ngIf="isAdmin && !shouldHideExternalLinks">
                      <a [attr.href]="myCompanyLink" class="my-company-link" title="My Company">MY COMPANY</a>
                      <div class="dropdown__icon">
                        <i class="bell-icon-arrow-right"></i>
                      </div>
                    </li>
                    <li class="dropdown__item" *ngIf="!shouldHideExternalLinks">
                      <a href="https://bellhelicopter.force.com/helpcenter/s/" target="_blank" rel="noopener noreferrer"
                        title="Help">HELP
                        <mat-icon class="dropdown__icon small-icon">call_made</mat-icon>
                      </a>
                    </li>
                    <li class="dropdown__item bell-color-focus" *ngIf="hasPublishingAccess && !shouldHideExternalLinks">
                      <a>Environment:
                        <mat-button-toggle-group class="env-toggle" (change)="setWorkEnvironment($event.value)">
                          <mat-button-toggle [value]="true">Work</mat-button-toggle>
                          <mat-button-toggle [value]="false">Production</mat-button-toggle>
                        </mat-button-toggle-group>
                      </a>
                    </li>
                    <li class="dropdown__item bell-color-focus" (click)="logout()">
                      <a class="logout" title="Logout">SIGN OUT </a>
                      <div class="dropdown__icon">
                        <i class="bell-icon-arrow-right"></i>
                      </div>
                    </li>
                    <li class="dropdown__item bell-color-focus"
                      *ngIf="offlineService.isOfflineCapable && !offlineService.isOffline">
                      <a class="offline" title="Go Offline" [class.disabled]="!offlineService.isLibraryActivated"
                        (click)="goOffline()">Go offline</a>
                      <div class="dropdown__icon">
                        <i class="bell-icon-arrow-right"></i>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="dropdown__content" *ngIf="offlineService.isOffline" (mouseleave)="iconMenuCollapsed=true">
                  <ul class="dropdown__options" [attr.aria-expanded]="!iconMenuCollapsed">
                    <li class="dropdown__item bell-color-focus bell-khaki"
                      [ngClass]="{'active': (currentUrl != '/myofflinelib')}">
                      <a [routerLink]="['/']">Technical Publications</a>
                      <div class="dropdown__icon">
                        <i class="bell-icon-arrow-right"></i>
                      </div>
                    </li>
                    <li class="dropdown__item bell-color-focus bell-grey"
                      [ngClass]="{'active': (currentUrl == '/myofflinelib')}">
                      <a [routerLink]="['/myofflinelib']">My Library</a>
                      <div class="dropdown__icon">
                        <i class="bell-icon-arrow-right"></i>
                      </div>
                    </li>
                    <li class="dropdown__item bell-color-focus bell-blue">
                      <a [routerLink]="['/login']">Login</a>
                      <div class="dropdown__icon">
                        <i class="bell-icon-arrow-right"></i>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="navbar__container--mobile" [ngClass]="{'active': !menuCollapsed}">
    <div class="mobile__content">
      <ul class="mobile__menu" *ngIf="!offlineService.isOffline && !menuCollapsed">
        <li>
          <div class="accordion" [ngClass]="{'active': !subIconMenuCollapsed}">
            <div class="accordion__button">
              <button class="accordion__trigger" type="button" (click)="toggleSubIconMenu()">
                <span><i class="bell-icon bell-icon-profile icon__click icon__click--mobile"></i></span>
                <span>Profile</span>
                <span class="accordion__icon">
                  <div class="tp-icon tp-icon__plus" [ngClass]="{'tp-icon__plus--open': !subIconMenuCollapsed}"></div>
                </span>
              </button>
            </div>
            <div class="accordion__panel" [ngClass]="{'active': !subIconMenuCollapsed}"
              [attr.aria-expanded]="!subIconMenuCollapsed">
              <ul class="accordion__options" *ngIf="!offlineService.isOffline">
                <li *ngIf="!shouldHideExternalLinks">
                  <a [attr.href]="myAccountLink">
                    <span class="myaccount-link" title="My Account">{{userFirstName}}</span>'s Account
                  </a>
                </li>
                <li *ngIf="isAdmin && !shouldHideExternalLinks">
                  <a [attr.href]="myCompanyLink" class="my-company-link" title="My Company">My Company</a>
                </li>
                <li *ngIf="!shouldHideExternalLinks">
                  <a href="https://bellhelicopter.force.com/helpcenter/s/" target="_blank" rel="noopener noreferrer"
                    title="Help">Help</a>
                </li>
                <li (click)="logout()">
                  <a class="logout" title="Logout">Sign Out </a>
                </li>
                <li *ngIf="offlineService.isOfflineCapable && !offlineService.isOffline">
                  <a class="offline" title="Go Offline" [class.disabled]="!offlineService.isLibraryActivated"
                    (click)="goOffline()">Go offline</a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <div *ngIf="!shouldHideExternalLinks">
          <li *ngFor="let link of navLinks; index as i" class=" bell-color-focus" [ngClass]="{'active': activeTab === link}">
            <a *ngIf="!(link.children)" class="mobile__link" (onClick)=" activateTab(link)" href="{{link.path}}"
              target="{{link.target}}">{{link.label}}</a>
            <div class="accordion" [ngClass]="{'active': link.expanded}" *ngIf="link.children">
              <div class="accordion__button">
                <button class="accordion__trigger" type="button" (click)="toggleDropdown(i)">
                  <span><i class="bell-icon bell-icon-profile icon__click icon__click--mobile"></i></span>
                  <span>{{link.label}}</span>
                  <span class="accordion__icon">
                    <div class="tp-icon tp-icon__plus" [ngClass]="{'tp-icon__plus--open': link.expanded}"></div>
                  </span>
                </button>
              </div>
              <div class="accordion__panel" [ngClass]="{'active': link.expanded}" [attr.aria-expanded]="link.expanded">
                <ul class="accordion__options">
                  <li *ngFor="let childLink of link.children">
                    <a [attr.href]="childLink.path" [target]="childLink.label" [title]="childLink.label">{{childLink.label}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </div>
      </ul>
    </div>
  </div>
</nav>
