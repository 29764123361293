<h3 class="selection-title">Library Document Selection</h3>
<p class="selection-text">Checking a box will make <strong>every</strong> document for that model available for
  offline use. Also, clicking a model's name will bring you to its table of contents.</p>
<strong class="selection-text-oc">Select Offline Content</strong>
<section id="loadProgressBar" *ngIf="isSelecting">
  <mat-progress-bar mode="indeterminate" *ngIf="isSelecting"></mat-progress-bar>
</section>
<div class="selection-grid">
  <div class="selection-grid-element" *ngFor="let model of models">
    <a class="selection-model" routerLink="/toc/{{model.model}}"
      title="Explore model {{model.model}}'s documents.">{{model.model}}</a>
    <mat-checkbox class="selection-box" [checked]="model.selected == OfflineContentStatus.Full"
      (change)="selectModel($event, model)"
      [indeterminate]="model.selected === OfflineContentStatus.Partial" [disabled]="offlineService.isOffline">
    </mat-checkbox>
  </div>
</div>
<div class="buttons-group selection-buttons">
  <button mat-flat-button class="select-all" [disabled]="offlineService.isOffline" (click)="selectAll()">
    Select all
  </button>
  <button mat-flat-button class="unselect-all" [disabled]="offlineService.isOffline" (click)="unSelectAll()">
    Unselect all
  </button>
  <button mat-flat-button class="apply-selection" [disabled]="offlineService.isOffline" (click)="applySelection()">
    Apply selection
  </button>
  <button mat-flat-button class="cancel-selection" [disabled]="offlineService.isOffline" (click)="cancelSelection()">
    Cancel selection
  </button>
</div>
