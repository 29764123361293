export class OfflineCount {
  constructor(uptoDate: number, willUpdate: number, willBeDeleted: number, added: number) {
    this.uptoDate = uptoDate;
    this.willUpdate = willUpdate;
    this.willBeDeleted = willBeDeleted;
    this.added = added;
  }

  uptoDate: number | 0;
  willUpdate: number | 0;
  willBeDeleted: number | 0;
  added: number | 0;
  additionalFiles: number | 0;
  totalSize: number | 0;
  totalSizeString: string | 0;
}
