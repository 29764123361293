import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { BannerComponent } from './banner.component';
import { ErrorService } from 'src/app/shared/services/error.service';
@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    BannerComponent
  ],
  declarations: [
    BannerComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ErrorService
  ]
})
export class BannerModule { }
