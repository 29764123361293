<div class="search-page">
  <div class="search-results">
    <h1 class="search-results-title">Search results <span class="search-terms" *ngIf="query">'{{query}}'</span></h1>
    <ng-container *ngIf="results && !isLoading">
      <p class="results-count">
        <ng-container *ngIf="modelFilters.length > 0 || manualsFilter || offlineService.isOffline">
          <span class="results-count-number">{{filteredResults.length}}</span> results filtered |
        </ng-container>
        <span class="results-count-number">{{results.length}}</span> results in entire site
      </p>
      <ng-container *ngFor="let result of filteredResults; let idx = index">
        <ng-container *ngIf="idx >= pageIndex * pageSize && idx < pageSize + pageIndex * pageSize">
          <a class="search-result" (click)="goToResult(result)"  [class.isDisabled]="isDisabled(result)">{{result.title}}</a>
        </ng-container>
      </ng-container>
      <mat-paginator class="search-paginator" *ngIf="filteredResults.length > pageSize"
        length="{{filteredResults.length}}"
        pageSize="{{pageSize}}"
        pageIndex="{{pageIndex}}"
        (page)="changeSearchPage($event)">
      </mat-paginator>
    </ng-container>
    <ng-container *ngIf="isLoading">
      <p class="in-progress">Search in progress...</p>
    </ng-container>
    <p class="no-result" *ngIf="!isLoading && !results">No results found</p>
  </div>
  <div class="search-filter" *ngIf="results && !isLoading">
    <h1>Search</h1>
    <mat-checkbox class="search-checkbox" name="search-checkbox" [checked]="exact" (change)="changeSearchOption()" ngDefaultControl>
      Whole words only
    </mat-checkbox>
    <mat-checkbox *ngIf="offlineService.isOffline" class="offline-checkbox" name="offline-checkbox" [checked]="filterOffline" (change)="changeOfflineOption()" ngDefaultControl>
      Offline library only
    </mat-checkbox>
    <div class="model-filters" *ngIf="models">
      <hr/>
      <h1>Model</h1>
      <span class="remove-filter" *ngIf="modelFilters.length > 0" (click)="removeAllModelFilter()">[Remove All Model Filter]</span>
      <ng-container *ngFor="let model of models">
        <a class="filter-link" ngClass="{{modelFilters.includes(model) ? 'selected' : ''}}" *ngIf="modelsCount[model] >= 0 || modelFilters.includes(model)" (click)="toggleModelFilter(model)">
          {{model}}<span class="filter-count"> ({{modelsCount[model]}})</span>
        </a>
      </ng-container>
    </div>
    <div class="manual-filters" *ngIf="manuals">
      <hr/>
      <h1>Manual</h1>
      <span class="remove-filter" *ngIf="manualsFilter" (click)="removeManualFilter()">[Remove Manual Filter]</span>
      <ng-container *ngFor="let manual of manuals">
        <a class="filter-link" ngClass="{{manualsFilter == manual.id_manual ? 'selected' : ''}}"
         *ngIf="(manualsFilter && manualsFilter == manual.id_manual) || (!manualsFilter && manualsCount[manual.id_manual])"
         (click)="toggleManualFilter(manual.manual_identifier)">
          {{manual.publication_type}}<span class="filter-count"> ({{manualsCount[manual.id_manual]}})</span>
        </a>
      </ng-container>
    </div>
  </div>
</div>
