import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CCodeService } from '../../shared/services/ccode/ccode.service';
import { Part } from '../../shared/data-model/part';
import { AddPartDialogComponent } from '../../viewer/shared/add-part-dialog/add-part-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'bell-ccode-selected',
  templateUrl: './ccode-selected.component.html',
  styleUrls: ['./ccode-selected.component.scss'],
})

export class CCodeSelectedComponent implements OnInit, OnChanges {
  @Input() cCodeSelected: string;
  @Output() cCodeLoaded = new EventEmitter<boolean>(true);
  @ViewChild('Nomen') nomenElement: ElementRef;
  itHasNotes: boolean;
  cCode: any;

  constructor(
    private cCodeService: CCodeService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.itHasNotes = false;
  }

  ngOnInit() {
    this.getCCodesObj();
  }

  ngOnChanges(changes: {
    [propKey: string]: SimpleChange
  }) {
    Object.keys(changes).forEach(propName => {
      const changedProp = changes[propName];
      const cCodeProp = changedProp.currentValue;
      if (!changedProp.isFirstChange()) {
        this.cCodeSelected = cCodeProp;
        this.getCCodesObj();
      }
    });
  }

  getCCodesObj() {
    this.cCodeService.getCCodes(this.cCodeSelected).subscribe(cCodesObj => {
      if (cCodesObj !== null) {
        this.cCodeLoaded.emit(true);
        this.cCode = cCodesObj;
        setTimeout(() => this.processNomenLinks());
        if (this.cCode.notes !== '') {
          this.itHasNotes = true;
        } else {
          this.itHasNotes = false;
        }
      }
    });
  }

  processNomenLinks() {
    if (typeof this.nomenElement?.nativeElement !== "undefined") {
      let element: Element = this.nomenElement.nativeElement;
      let links = Array.from(element.getElementsByTagName('A'));
      for (let link of links) {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          const href = link.getAttribute('href');
          this.router.navigate([href]);
        })
      }

    }

  }

  openPartDialog(item) {
    const part: Part = {
      partNumber: item.coop_nomen,
      description: item.material,
      quantity: parseInt(item.order_qty, 10)
    };
    this.dialog.open(AddPartDialogComponent, {
      data: part
    });
  }
}
