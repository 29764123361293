<h2>OFFLINE LIBRARY</h2>
<div class="check-is-activated-spinner" *ngIf="checkIsOfflineActivated">
  <mat-progress-spinner diameter=80 mode='indeterminate'></mat-progress-spinner>
</div>
<div *ngIf="!isOfflineActivated && !checkIsOfflineActivated">
  <hr />
  <h2>Step 1 of 3 - Activation</h2>
  <p class="doc-status-text">Click on the button below to activate the Offline Library</p>
  <div class="buttons-group">
    <button mat-flat-button (click)="openOfflineDialog()" [disabled]="isLoading">
      Activate Offline
    </button>
  </div>
  <hr />
  <h2>Step 2 of 3 - Select your publications</h2>
  <p>Select the publications from <strong>My Library</strong> or from each model's table of contents.</p>
  <hr />
  <h2>Step 3 of 3 - Download your publications</h2>
  <p>Click <strong>My Library</strong> then <strong>Start Update</strong> to download the publications.</p>
</div>
<mat-tab-group class="offline-lib-tabs" animationDuration="0ms" *ngIf="isOfflineActivated && !checkIsOfflineActivated">
  <mat-tab>
    <ng-template mat-tab-label>Library</ng-template>
    <div class="offline-warning" *ngIf="offlineService.isOffline">
      <mat-icon class="offline-warning-icon">info</mat-icon>
      <div class="offline-warning-text">
        <strong class="offline-warning-line">You are offline.</strong>
        Please <span class="login-link" [routerLink]="['../login']">log in online</span> to update your offline library.
      </div>
    </div>
    <div class="offline-warning"
      *ngIf="!offlineService.isOffline && offlineService.state == OfflineStates.UPDATE_READY">
      <mat-icon class="offline-warning-icon">warning</mat-icon>
      <div class="offline-warning-text">
        <strong class="offline-warning-line">Per the status shown below, your library needs to be updated!</strong>
        Please review the estimated download size before clicking the "Start Update" button.
      </div>
    </div>
    <div *ngIf="isOfflineActivated">
      <p class="doc-status-text">The current status of your offline library is as follows:</p>
      <mat-list>
        <mat-list-item>
          <span class="doc-icon doc-available" matLine>{{offlineCount.uptoDate}} documents are up to date</span>
        </mat-list-item>
        <mat-list-item>
          <span class="doc-icon doc-update" matLine>{{offlineCount.willUpdate}} documents will be updated</span>
        </mat-list-item>
        <mat-list-item>
          <span *ngIf="this.additionalDeleteDocsAdded$" class="doc-icon doc-delete add-files"
            matLine>{{offlineCount.willBeDeleted}}
            documents will be deleted</span>
          <span *ngIf="!this.additionalDeleteDocsAdded$" class="doc-icon doc-delete" matLine>{{offlineCount.willBeDeleted}}
            documents
            will be deleted</span>
          <span *ngIf="this.additionalDeleteDocsAdded$ && offlineCount.willBeDeleted" class="doc-icon" matLine> (with
            {{ this.additionalDeleteDocsAdded$ }}
            additional files)</span>
        </mat-list-item>
        <mat-list-item>
          <span class="doc-icon doc-add add-files" matLine>{{offlineCount.added}}
            documents will be added</span>
          <span *ngIf="offlineCount.additionalFiles && offlineCount.additionalFiles.length > 0" class="doc-icon doc-add" matLine>{{offlineCount.added}} documents
            will be added</span>
          <span *ngIf="offlineCount.added" class="doc-icon"
            matLine> (with {{ offlineCount.additionalFiles }}
            additional files)</span>
        </mat-list-item>
        <mat-list-item>
          <span class="doc-icon doc" matLine>These documents are not currently available offline</span>
        </mat-list-item>
      </mat-list>
      <div class="update">
        <div *ngIf="offlineCount.added">
          <p>Estimated Download Size: {{ offlineCount.totalSizeString }}. It will take approximately
            {{ totalDocsDownloadTime$ * 1000 | date: 'H:mm:ss':'UTC' }} (h:mm:ss) to download.</p>
        </div>
        <mat-progress-spinner *ngIf="isLoadingAdditionalFiles && !libraryUpdateStarted && (offlineCount.added || offlineCount.willBeDeleted)"
          diameter="37" mode="indeterminate"></mat-progress-spinner>
        <p>Free Space on Disk: {{ diskSpaceAvailable }}</p>
        <div class="buttons-group update-buttons" *ngIf="!libraryUpdateStarted && countUpdateComplete">
          <button mat-flat-button class="start-update"
            [disabled]="(isLoading || !isEnableUpdate || isLoadingAdditionalFiles && offlineCount.added)"
            (click)="openOfflineDialog()">
            Start Update
          </button>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>Selection</ng-template>
    <bell-offline-selection></bell-offline-selection>
  </mat-tab>
  <mat-tab *ngIf="false">
    <ng-template mat-tab-label>3D Content</ng-template>
    <p class="three-d-text">Checking a box will make every 3D object for that model available for offline use.</p>
    <p class="three-d-text">Choose 3D data for offline:</p>
  </mat-tab>
  <mat-tab *ngIf="false">
    <ng-template mat-tab-label>Import</ng-template>
    <h3 class="import-title">Import documents from your computer</h3>
    <p class="import-text">This feature allows you to easily import document files provided by Bell Helicopter into your
      Offline Library.</p>
    <p class="import-text">To copy the offline data from one computer to another, follow the "Exporting Data" procedure
      from the Help file.</p>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>Disable Library</ng-template>
    <div class="buttons-group delete-buttons">
      <button mat-flat-button class="delete-content" [disabled]="!isOfflineActivated" (click)="openDeactivateDialog()">
        Disable and delete offline content
      </button>
    </div>
  </mat-tab>
</mat-tab-group>
