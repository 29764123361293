import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { EnvironmentService } from './environment.service';
import { PlatformService, PlatformType } from './offline/platform.service';

// declare gives Angular app access to ga function
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  private trackingId = '';

  constructor(
    private environmentService: EnvironmentService,
    private platformService: PlatformService,
    private router: Router,
  ) { }

  setUp() {
    if (this.environmentService.isGoogleAnalyticsEnabled()) {
    const gaScript = document.createElement('script');
    switch (this.platformService.platform) {
      case PlatformType.MAUI:
        this.trackingId = this.environmentService.getGoogleAnalyticsMaui();
        break;
      case PlatformType.ELECTRON: 
        this.trackingId = this.environmentService.getGoogleAnalyticsElectron();
        break;
      default:
        this.trackingId = this.environmentService.getGoogleAnalyticsOnline();
        break;
    }
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${ this.trackingId }`);

    const gaScript2 = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${ this.trackingId }');`;
    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log(event.urlAfterRedirects);
        this.logPageRedirect(event.urlAfterRedirects);
      }
    });
    }
  }

  private logPageRedirect(url: string) {
    if (this.environmentService.isGoogleAnalyticsEnabled()) {
      gtag('config', this.trackingId, {'page_path': url});
    }
  }
}
