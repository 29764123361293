import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'bell-un-authorized',
  templateUrl: './un-authorized.component.html',
  styleUrls: ['./un-authorized.component.scss']
})
export class UnAuthorizedComponent implements OnInit {
  errorDetails: string;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    let errorType = this.route.snapshot.queryParamMap.get("error");
    switch(errorType) {
      case "USER_INACTIVE":     this.errorDetails = "Your account has been marked inactive";
                                break;
      case "COMPANY_INACTIVE":  this.errorDetails = "Your organization has been marked inactive";
                                break;
    }
  }
}
