import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Part } from 'src/app/shared/data-model/part';
import { PartsListService } from 'src/app/shared/services/parts-list/parts-list.service';

@Component({
  templateUrl: './add-part-dialog.component.html',
  styleUrls: [
    '../../../parts-list/edit-parts-list/edit-parts-list.component.scss',
    './add-part-dialog.component.scss'
  ]
})
export class AddPartDialogComponent implements OnInit {
  oldQty: number;
  constructor(
    public dialogRef: MatDialogRef<AddPartDialogComponent>,
    public partsList: PartsListService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public part: Part
  ) { }

  ngOnInit() {
    if (this.part === null) {
      this.part = {partNumber: '', description: '', quantity: 0};
    }
  }

  get oldQuantity() {
    const plPart = this.partsList.getPart(this.part.partNumber);
    if (plPart) {
      return plPart.quantity;
    } else {
      return 0;
    }
  }
  set oldQuantity(value) {
    // this method is used to allow oldQuantity to be bound in html
  }
  addToPartsList(redirect?: boolean) {
    this.part.quantity = this.part.quantity - this.oldQuantity;
    this.partsList.mergePart(this.part);
    this.dialogRef.close();
    this.partsList.savePartsList();
    if (redirect) {
      this.router.navigate(['parts/edit']);
    }
  }

  selectPartsList(index: number) {
    this.partsList.activeIndex = index;
  }
}
