import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { Subscriber } from 'rxjs';
import { tap, catchError, concatMap, takeUntil } from 'rxjs/operators';
import { ProgressType, TrafficHttpMethods } from '../data-model/http-utility';
import { HttpCancelService } from '../services/http-cancel/http-cancel.service';
import { HttpFileDownloadService } from '../services/http-progress-event/http-file-download.service';


const THROTTLE_LIMIT = 200;
@Injectable({
  providedIn: 'root'
})
export class HttpInterceptService {
  private totalRequests: TrafficHttpMethods = new TrafficHttpMethods();
  private pendingRequest: TrafficHttpMethods = new TrafficHttpMethods();
  private zipDowloadsCount = 0;
  private reqURLs = [];
  private reqObs: { [key: string]: Subscriber<any> } = {};

  constructor(
    private httpFileDownloadService: HttpFileDownloadService,
    private router: Router,
    private httpCancelService: HttpCancelService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('/zip/') > -1) {
      this.handleIncomingZipCalls(request.url);
      return this.handleZipDownload(request, next);
    } else {
      this.httpTrafficHandler(HttpEventType.Sent, request);

      if (request.reportProgress) {
        // THIS INTERCEPTS THE REQUEST WITH REPORTPROGRESS SET TO TRUE,
        return next.handle(request).pipe(
          tap(
            (event: HttpEvent<any>) => {
              this.httpEventHandler(event, request);
            }
          ),
          catchError(
            (error) => {
              if (error instanceof HttpErrorResponse) {
                this.handleErrorRequest(error);
              }
              this.httpTrafficHandler(HttpEventType.Response, request);
              if (error instanceof HttpErrorResponse) {
                this.httpFileDownloadService.hanldeHttpErrorResponse(error);
              }
              return throwError(error);
            }
          )
        );
      } else {
        return next.handle(request).pipe(
          tap({
            error: (error) => {
              if (error instanceof HttpErrorResponse) {
                this.handleErrorRequest(error);
              }
              this.httpTrafficHandler(HttpEventType.Response, request);
            },
            complete: () => {
              this.httpTrafficHandler(HttpEventType.Response, request);
            }
          })
        );
      }
    }
  }

  reset() {
    this.totalRequests = new TrafficHttpMethods();
    this.pendingRequest = new TrafficHttpMethods();
    this.zipDowloadsCount = 0;
    this.reqURLs = [];
    this.reqObs = {};
  }

  private handleErrorRequest(error: any) {
    if (error.status === 401) {
      if (error.error === 'Access Forbidden') {
        this.router.navigate(['unauthorized'], {});
      } else {
        this.httpCancelService.cancelPendingRequests();
        sessionStorage.setItem('redirectUrl', this.router.routerState.snapshot.url);
        this.router.navigate(['login'], {});
      }
    }
  }

  private handleZipDownload(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.zipDowloadsCount < THROTTLE_LIMIT) {
      this.zipDowloadsCount++;

      return next.handle(request).pipe(
        tap({
          error: (error) => {
            if (error instanceof HttpErrorResponse) {
              this.handleErrorRequest(error);
            }
            this.handlePendingZipCalls();
          },
          complete: () => {
            this.handlePendingZipCalls();
          }
        }),
        takeUntil(this.httpCancelService.onCancelPendingRequests())
      );
    } else {
      this.zipDowloadsCount++;
      this.reqURLs.push(request.url);
      this.reqObs[request.url] = null;
      const obs = new Observable(ob => {
        this.reqObs[request.url] = ob;
      });

      return obs.pipe(
        concatMap(_ => {
          return next.handle(request).pipe(
            tap({
              error: (error) => {
                if (error instanceof HttpErrorResponse) {
                  this.handleErrorRequest(error);
                }
                this.handlePendingZipCalls();
              },
              complete: () => {
                this.handlePendingZipCalls();
              }
            }),
            takeUntil(this.httpCancelService.onCancelPendingRequests())
          );
        }),
        takeUntil(this.httpCancelService.onCancelPendingRequests())
      );
    }
  }

  private httpEventHandler(event: HttpEvent<any>, request: HttpRequest<any>): any {
    switch (event.type) {
      case HttpEventType.Sent:
        this.httpFileDownloadService.handleHttpRequest(request);
        break;

      case HttpEventType.DownloadProgress:
        this.httpFileDownloadService.handleHttpProgressType(ProgressType[event.type]);
        const downProgress = Math.round(100 * event.loaded / event.total);
        this.httpFileDownloadService.handleHttpProgressEvent(downProgress);
        break;

      case HttpEventType.UploadProgress:
        this.httpFileDownloadService.handleHttpProgressType(ProgressType[event.type]);
        const upProgress = Math.round(100 * event.loaded / event.total);
        this.httpFileDownloadService.handleHttpProgressEvent(upProgress);
        break;

      case HttpEventType.Response:
        this.httpTrafficHandler(HttpEventType.Response, request);
        break;

      default:
        return false;
    }
  }

  private httpTrafficHandler(eventType, request) {
    const httpMethodOrResponse = request.method;
    const url = request.url;

    switch (eventType) {
      case HttpEventType.Sent:
        this.trafficStartRequest(httpMethodOrResponse, url);
        break;
      case HttpEventType.Response:
        this.trafficEndRequest(httpMethodOrResponse);
        break;
      default:
        return false;
    }
  }

  private trafficStartRequest(httpMethod, url) {
    httpMethod = httpMethod.toLowerCase();
    this.totalRequests.all++;
    this.totalRequests[httpMethod]++;
    this.pendingRequest.all++;
    this.pendingRequest[httpMethod]++;
  }

  private trafficEndRequest(httpMethod) {
    httpMethod = httpMethod.toLowerCase();
    this.pendingRequest.all--;
    this.pendingRequest[httpMethod]--;

    if (this.pendingRequest[httpMethod] < 0) {
      this.rehandlePendingRequest(httpMethod);
    }
  }

  private handleIncomingZipCalls(url) {
    const urlExist = this.reqURLs.indexOf(url);

    if (urlExist > -1) {
      console.log('url exists for' + url);
      this.reqURLs.splice(urlExist, 1);
      const observer = this.reqObs[url];

      observer.error('Call failed for: ' + url);
      delete this.reqObs[url];

      if (this.zipDowloadsCount > 0) {
        this.zipDowloadsCount--;
      }
    }
  }

  private handlePendingZipCalls() {
    if (this.zipDowloadsCount > 0) {
      this.zipDowloadsCount--;
    }

    if (this.reqURLs.length > 0) {
      const url = this.reqURLs[0];
      const observer = this.reqObs[url];

      observer.next('done!');
      observer.complete();
      this.reqURLs = this.reqURLs.slice(1);
      delete this.reqObs[url];
    }
  }

  private rehandlePendingRequest(negativeMethod) {
    const methods = [
      'all',
      'get',
      'post',
      'delete',
      'put',
      'head',
    ];

    let negativePending = Math.abs(this.pendingRequest[negativeMethod]);
    this.pendingRequest[negativeMethod] = 0;

    for (let i = 0; i < methods.length; i++) {
      const method = methods[i];

      if (negativePending && this.pendingRequest[method]) {
        this.pendingRequest[method] -= negativePending;

        if (this.pendingRequest[method] < 0) {
          negativePending = Math.abs(this.pendingRequest[method]);
          this.pendingRequest[method] = 0;
        } else {
          negativePending = 0;
        }
      }
    }
  }
}
