import { Component, OnInit } from '@angular/core';
import { Release } from 'src/app/shared/data-model/release';
import { OfflineLibService } from 'src/app/shared/services/offline/offline-lib.service';

@Component({
  selector: 'bell-offline-lib',
  templateUrl: './offline-lib.component.html',
  styleUrls: ['./offline-lib.component.scss']
})
export class OfflineLibComponent implements OnInit {
  offlineLibLinks = [{
    label: 'BellOffline Instructions',
    path: 'https://bellhelicopter.force.com/helpcenter/s/article/Tech-Pub-Offline-Viewer',
    target: '_blank'
  }];
  offlineLibReleases: Release[] = [];

  constructor(
    private offlineLibService: OfflineLibService,
  ) {
  }
  ngOnInit() {
    this.offlineLibService.releases$.subscribe(r => {
      this.offlineLibReleases = r;
    })
  }
}
