export class Icn {
  rowid: number | undefined;
  name: string | undefined;
  type: string | undefined;
  availableUpdate: Date | undefined;
  size: number | undefined;
  lastUpdate: Date | undefined;
  selectionCount: number | undefined;
  selectedBy: string[] | undefined;
}
