import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from 'src/app/shared/services/environment.service';
import { OfflineService } from 'src/app/shared/services/offline/offline.service';
import { LoginService } from 'src/app/shared/services/user/login.service';

@Component({
  selector: 'bell-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isAdmin = false;
  userFirstName = '';
  myAccountLink = '';
  myCompanyLink = '';

  constructor(
    private envService: EnvironmentService,
    public offlineService: OfflineService,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    this.myAccountLink = this.envService.getAccountUrl();
    this.myCompanyLink = this.envService.getCompanyUrl();
    this.isAdmin = this.loginService.getSessionInfo()?.admin ?? false;
    this.userFirstName = this.loginService.getSessionInfo()?.givenName ?? '';
  }

}
