import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PlatformMethod } from './platform-methods';

export enum PlatformType {
  'WEB' = 'WEB',
  'ELECTRON' = 'ELECTRON',
  'MAUI' = 'MAUI'
}

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  protected _platform: PlatformType = PlatformType.WEB;
  protected _platformInitialized: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public platformInitialized$: Observable<boolean> = this._platformInitialized.asObservable();

  public get platform(): PlatformType {
    return this._platform;
  }

  public isOfflineCapable(): boolean {
    // TODO: re-add maui as offline capable for ios release 2
    return this.platform === PlatformType.ELECTRON;
  }

  // TODO: remove for ios release 2
  public shouldHideOffline(): boolean {
    return this.platform === PlatformType.MAUI;
  }

  invokePlatformMethod<T>(method: PlatformMethod, ...args: any): Observable<T> {
    throw new Error('Not Implemented');
  }

  /**
   *
   * @param filePath The relative filepath to the file to write. Forward slashes are recommended.
   * @param fileBuffer A file buffer to write to the specified path.
   */
  writeFile(filePath: string, fileBuffer: Buffer): Observable<boolean> {
    throw new Error('Not Implemented');
  }

  /**
   *
   * @param filePath The relative filepath to the file to read. Forward slashes are recommended.
   */
  readFile(filePath: string): Observable<Buffer> {
    throw new Error('Not Implemented');
  }

  /**
   *
   * @param filePath The relative filepath to the file/folder to verify. Forward slashes are recommended.
   */
  checkExists(filePath: string): Observable<boolean> {
    throw new Error('Not Implemented');
  }

  /**
   *
   * @param filePath The relative filepath to the file to remove. Forward slashes are recommended.
   */
  removeFile(filePath: string): Observable<boolean> {
    throw new Error('Not Implemented');
  }

  /**
   *
   * @param folderPath The relative path to the folder to create. Forward slashes are recommended.
   */
  createDirectory(folderPath: string): Observable<boolean> {
    throw new Error('Not Implemented');
  }

  /**
   *
   * @param folderPath The relative path to the folder to recursively delete. Forward slashes are recommended.
   */
  removeDirectory(folderPath: string): Observable<boolean> {
    throw new Error('Not Implemented');
  }
}
