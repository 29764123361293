export class IdleState {
  default = 'Not started.';
  onIdleEnd = 'No longer idle.';
  onSignOut = 'Signing out.';
  onTimeout = 'Timed out.';
  onIdleStart = 'You\'ve gone idle.';
  onIdleStartSignOut = 'Your session will expire soon.';
  onTimeoutWarning(countdown): string {
    const minutes = new Date(countdown * 1000).toISOString().substr(14, 5);
    return 'You will be sign out in ' + minutes + '.';
  }
}

export class SessionTimeoutModalData {
  idleState: string;
  continue: boolean;
}
