<ng-container *ngIf="!isCCodeLoaded">
  <p>
    Loading C-Code Specifications...
  </p>
</ng-container>
<div id="ccode-page">
  <ng-container *ngIf="isCCode">
    <bell-ccode-selected (cCodeLoaded)="onCCodeLoaded($event)" [cCodeSelected]="cCodeSelected"></bell-ccode-selected>
  </ng-container>
  <section>
    <ng-container *ngFor="let link of cCodeNoteLinks">
      <ng-container *ngFor="let PSE of link.PSE">
        <p><strong>NOTE</strong> - The following Consumable Information may list multiple Consumable Material
          Products. If a document or procedure calls for the use of a specific Consumable Material Product, you must
          use the specified product. Do not deviate from the specified document or procedural callout. If you have a
          question concerning Consumable Materials, please contact <a href="{{PSE.path}}"
            target="{{PSE.target}}">{{PSE.label}}</a>.</p>
      </ng-container>
      <ng-container *ngFor="let MSDS of link.MSDS">
        <p>For any information that pertains to the safety precautions, definition, application, and use of these
          consumables, refer to the manufacturers Technical Data Sheet, Material Safety Data Sheet
          (MSDS), or any other applicable documentation available through Bell Textron or the manufacturer of the product.
        </p>
      </ng-container>
    </ng-container>
  </section>
</div>
