import { Injectable } from '@angular/core';
import { IdleStorageAltService } from './idle-storage-alt.service';

@Injectable({
  providedIn: 'root'
})

export class IdleStorageService {
  private storage: Storage;

  constructor() {
    this.storage = this.getStorage();
  }

  private getStorage(): Storage {
    try {
      const storage = localStorage;
      storage.setItem('tpIdleStorage', '');
      storage.removeItem('tpIdleStorage');
      return storage;
    } catch (err) {
      return new IdleStorageAltService();
    }
  }

  getItem(key: string): string | null {
    return this.storage.getItem('tpIdle.' + key);
  }

  removeItem(key: string): void {
    this.storage.removeItem('tpIdle.' + key);
  }

  setItem(key: string, data: string): void {
    this.storage.setItem('tpIdle.' + key, data);
  }

  _wrapped(): Storage {
    return this.storage;
  }
}
