import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginType } from 'src/app/shared/enums/login-types';
import { ApiUriService } from 'src/app/shared/services/api-uri.service';
import { EnvironmentService } from 'src/app/shared/services/environment.service';
import { OfflineService } from 'src/app/shared/services/offline/offline.service';
import { PlatformMethod } from 'src/app/shared/services/offline/platform-methods';
import { PlatformService } from 'src/app/shared/services/offline/platform.service';
import { LoginService } from 'src/app/shared/services/user/login.service';

@Injectable({
  providedIn: 'root'
})
export class MauiLoginService extends LoginService {

  constructor(
    environmentService: EnvironmentService,
    apiUri: ApiUriService,
    offlineService: OfflineService,
    private platformService: PlatformService,
    router: Router,
    http: HttpClient
  ) {
    super(environmentService, apiUri, router, http, offlineService)
  }

  public override login(type?: LoginType) {
    if (!type) {
      this.router.navigate(['login']);
      return;
    }
    this.offlineService.changeIsOffline(type === LoginType.OFFLINE);
    if (type === LoginType.OFFLINE) {
      this.offlineService.changeIsOffline(true);
      return;
    }
    this.loginMaui(type);
  }

  public override logout(): void {
    this.processLogout();
    this.platformService.invokePlatformMethod<boolean>(PlatformMethod.logout).subscribe(isLoggedOut => {
      if(isLoggedOut) {
        this.router.navigateByUrl('/login');
      }
    });
  }

  private loginMaui(type: LoginType) {
    this.platformService.invokePlatformMethod<string>(PlatformMethod.login, type).subscribe(isAuthenticated => {
      if (isAuthenticated) {
        this.processLoginRedirectAsync(true).subscribe(() => {
          this.router.navigateByUrl('/');
        });
      }
      this.router.navigateByUrl('/');
    });

  }
}
