import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ServiceBase } from '../service-base.service';
import { HttpProgressEventModalData } from '../../data-model/http-utility';
import { HttpEventModalComponent } from '../../components/http-event-modal/http-event-modal.component';

@Injectable({
  providedIn: 'root'
})

export class HttpEventModalService extends ServiceBase {
  private progressMax = Math.pow(100, 2);
  private modalHttpEventRef: any;

  constructor(
    private modalHttpEvent: MatDialog
  ) {
    super();
  }

  public handleHttpEventModal(data: HttpProgressEventModalData): any {
    if (!this.modalHttpEventRef) {
      this.modalHttpEventRef = this.modalHttpEvent.open(
        HttpEventModalComponent, {
        data: data,
        disableClose: true
      }
      );
    } 
    else {
      if (data.response || data.progressType == 'Download') {
        this.handleDownloadProgress(data);
      }
      else {
        this.handleNoneProgress(data);
      }
    }

    this.modalHttpEventRef.afterClosed().subscribe((closed: any) => {
      this.modalHttpEventRef = undefined;
    });
  }

  private handleDownloadProgress(data: any) {
    this.modalHttpEventRef.componentInstance.data.title = 'Downloading';
    this.modalHttpEventRef.componentInstance.data.textContent = 'Your request is in progress.';

    if (data.progress) {
      this.modalHttpEventRef.componentInstance.data.barProgress = this.hanldeProgress(data.progress);
    }

    if (data.error) {
      this.modalHttpEventRef.componentInstance.data.textContent = 'There seems to be an issue.';
      this.modalHttpEventRef.componentInstance.data.barMode = 'indeterminate';
    }

    if (data.doneWithError) {
      this.modalHttpEventRef.componentInstance.data.title = 'Error';
      this.modalHttpEventRef.componentInstance.data.textContent = 'Try again later or contact an admin.';
      this.modalHttpEventRef.componentInstance.data.buttonLabel = 'Close';
      this.modalHttpEventRef.componentInstance.data.finished = true;
    }

    if (data.response) {
      this.modalHttpEventRef.componentInstance.data.textContent = '';
      this.modalHttpEventRef.componentInstance.data.buttonLabel = 'Done';
      this.modalHttpEventRef.componentInstance.data.finished = this.handleResponse(data);
      setTimeout(() => {
        this.modalHttpEvent.closeAll();
      }, 2500);
    }
  }

  private handleNoneProgress(data: any) {
    if (data.doneWithError) {
      this.modalHttpEventRef.componentInstance.data.textContent = 'There seems to be an issue.';
      this.modalHttpEventRef.componentInstance.data.buttonLabel = 'Close';
      this.modalHttpEventRef.componentInstance.data.finished = true;
    }
  }

  private hanldeProgress(progress: number): number {
    return Math.pow(progress, 2);
  }

  private handleResponse(data: any) {
    if (data.response.status === 200 && data.barProgress === this.progressMax) {
      return true;
    }
  }
}
