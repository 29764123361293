import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { concat, Observable } from 'rxjs';
import { isArray } from 'rxjs/internal/util/isArray';
import { switchMap, toArray } from 'rxjs/operators';
import { DocumentTree } from 'src/app/shared/data-model/idb/document-tree';
import { ModelSelect } from 'src/app/shared/data-model/model-select';
import { OfflineContentStatus } from 'src/app/shared/enums/offline-content-status';
import { OfflineStatusService } from 'src/app/shared/services/offline/offline-status.service';
import { OfflineService } from 'src/app/shared/services/offline/offline.service';
import { UserProductService } from 'src/app/shared/services/user/user-product.service';

@Component({
  selector: 'bell-offline-selection',
  templateUrl: './offline-selection.component.html',
  styleUrls: ['./offline-selection.component.scss']
})
export class OfflineSelectionComponent implements OnInit {
  models: ModelSelect[] = [];
  public OfflineContentStatus: any = OfflineContentStatus;
  private modelTree: DocumentTree[];
  public isSelecting = false;

  constructor(
    private productService: UserProductService,
    public offlineService: OfflineService,
    private offlineStatusService: OfflineStatusService,
  ) { }

  ngOnInit(): void {
    this.getModels();
  }

  cancelSelection() {
    this.models = [];
    this.getModels();
  }

  selectAll() {
    this.models.forEach((m) => {
      if (m.selected !== OfflineContentStatus.Full) {
        m.selected = OfflineContentStatus.Full;
        m.isDirty = true;
      }
    });
  }

  unSelectAll() {
    this.models.forEach((m) => {
      if (m.selected !== OfflineContentStatus.None) {
        m.isDirty = true;
      }
      m.selected = OfflineContentStatus.None;
    });
  }

  applySelection() {
    const setStatus$: Observable<boolean>[] = [];
    this.models.forEach((m) => {
      if (m.selected !== OfflineContentStatus.Partial && m.isDirty) {
        setStatus$.push(this.offlineStatusService.setModelSelectionStatus('/' + m.model, m.selected === OfflineContentStatus.Full));
      }
    });

    if (setStatus$.length > 0) {
      this.isSelecting = true;
      setStatus$.reduce((o1$, o2$) => o1$.pipe(switchMap(o1 => o2$))).subscribe((value) => {
        this.isSelecting = false;
        this.offlineStatusService.updatingDocCounts$.next(true);
      });
    }
  }

  selectModel($event: MatCheckboxChange, model: ModelSelect) {
    if ($event.checked) {
      model.selected = OfflineContentStatus.Full;
    } else {
      model.selected = OfflineContentStatus.None;
    }
    model.isDirty = true;
  }

  private mapCheckedStatus(model: string) {
    const tree = this.modelTree.find((t) => t.path === `/${model}`);
    if (tree) {
      if (tree.selectedChild === tree.childCount || tree.usableChild === tree.childCount) {
        return OfflineContentStatus.Full;
      } else if (tree.childCount > 0) {
        if (tree.selectedChild > 0) {
          return OfflineContentStatus.Partial;
        } else {
          return OfflineContentStatus.None;
        }
      } else {
        return OfflineContentStatus.None;
      }
    } else {
      return OfflineContentStatus.None;
    }
  }

  getModels() {
    this.productService.getUserModels().subscribe((data) => {
      if (data && isArray(data)) {
        this.offlineStatusService.getModelsTree(data).subscribe((trees) => {
          this.modelTree = trees.filter(item => item !== undefined);
          data.forEach((m) => {
            const modelSelect = new ModelSelect();
            modelSelect.model = m;
            modelSelect.selected = this.mapCheckedStatus(m);
            modelSelect.isDirty = false;
            this.models.push(modelSelect);
          });
        });
      }
    });
  }
}
