import { Injectable, NgZone } from '@angular/core';
import { ServiceBase } from '../service-base.service';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class NetworkService extends ServiceBase {

  constructor() {
    super();
  }

  isInternal(): Observable<boolean> {
    return this.httpUtility.get(this.environmentService.getNetworkServiceEndpoint()).pipe(
      map(res => {return true}),
      catchError(err => { return of(false); })
    );
  }
}
