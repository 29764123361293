export const modelStoreName = 'model';
export const globalStoreName = 'global';
export const dmStoreName = 'dm';
export const documentStoreName = 'document';
export const documentTreeStoreName = 'documentTree';
export const icnStoreName = 'icn';
export const offline3dStoreName = 'offline3d';
export const pmcStoreName = 'pmc';
export const refdesStoreName = 'refdes';

