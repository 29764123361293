import { Injectable } from '@angular/core';
import { DataService } from 'src/app/shared/services/data-services/data.service';
import { Banner } from '../../data-model/banner';
import { OfflineService } from '../offline/offline.service';

@Injectable({
  providedIn: 'root'
})
export class BannerService {
  banner: Banner = {
    styles: { visibility: 'hidden'},
    show: false,
    message: ''
  };
  dismissed = true;
  constructor(
    private dataService: DataService,
    private offlineService: OfflineService
  ) {
    this.getBanner();
  }

  getBanner() {
    if (!this.offlineService.isOffline) {
      this.dataService.getBanner().subscribe((data) => {
        this.banner = data;
        if (this.banner.styles) {
          try {
            this.banner.styles = JSON.parse(this.banner.styles);
          } catch (e) {
            this.banner.styles = {}; // reset styles
          }
        }
        this.dismissed = !this.banner.show;
      });
    }
  }

  dismiss(): void {
    this.dismissed = true;
    this.banner.styles.visibility = 'hidden';
  }
}
