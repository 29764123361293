import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderModule } from './header/header.module';

import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

import { FooterComponent } from './footer/footer.component';
import { BannerModule } from './banner/banner.module';
import { CoreRoutingModule } from './core-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    MatMenuModule,
    MatButtonModule,
    CommonModule,
    CoreRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    CommonModule,
    CoreRoutingModule,
    HeaderModule,
    MatSelectModule,
    MatDividerModule,
    FooterComponent,
    BannerModule,
  ],
  declarations: [
    FooterComponent,
  ]
})
export class CoreModule { }
