<div *ngIf="isActivated">
  <div class="notification-item up-to-date" *ngIf="offlineService.state == OfflineStates.UP_TO_DATE">
    <mat-icon class="notification-icon">check_circle_outline</mat-icon>
    <p>
      Your<a class="library-link" [routerLink]="['/myofflinelib']">library</a>is up-to-date.
    </p>
  </div>
  <div class="notification-item update-check" *ngIf="offlineService.state == OfflineStates.CHECK_UPDATE">
    <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
    Checking
  </div>
  <div class="notification-item update-needed" *ngIf="offlineService.state == OfflineStates.UPDATE_READY">
    <mat-icon class="notification-icon">warning</mat-icon>
    <p>
      Your<a class="library-link" [routerLink]="['/myofflinelib']">library</a>needs updating!
    </p>
  </div>
  <div class="notification-item updating" *ngIf="offlineService.state == OfflineStates.UPDATING">
    <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
    Updating
    <mat-progress-bar mode="determinate" [value]="offlineService.updateProgress"></mat-progress-bar>
  </div>
</div>
