import { Injectable } from '@angular/core';
import { Observable, Observer, forkJoin } from 'rxjs';
import { UserProduct } from '../../data-model';
import { Dm, DocumentNode, DocumentTree, Icn } from '../../data-model/idb';
import { Pmc, PmIcn } from '../../data-model/idb/pmc';
import { IndexDm, IndexDoc, IndexIcn, IndexPm, IndexPmIcn, IndexRoot, IndexTree } from '../../data-model/offline';
import { PropogateParam } from '../../data-model/offline/propagate-param';
import { DataService } from '../data-services/data.service';
import { DmService } from './dexie/dm.service';
import { DocumentTreesService } from './dexie/document-trees.service';
import { DocumentService } from './dexie/document.service';
import { GlobalDataService } from './dexie/global-data.service';
import { IcnService } from './dexie/icn.service';
import { PmcService } from './dexie/pmc.service';
import { OfflineFeedService } from './offline-feed.service';
import { OfflineFileService } from './offline-file.service';
import { OfflineZipDownloadService } from './offline-zip-download.service';
import { OfflineStatusService } from './offline-status.service';
import { getNowUTC } from '../../utils/date-util';

@Injectable({
  providedIn: 'root'
})
export class OfflineMergeService {

  public totalCalls = 0;
  public currentCalls = 0;
  private isDBUpdated = false;
  private userModels: string[] = [];
  private userClassifications: string[] = [];
  private lastUpdated: Date;
  private needChildRecount: Record<string, boolean> = {};
  private mergeResultTrees: IndexTree[];
  private mergeResultDocs: IndexDoc[];
  private mergePms: IndexPm[];
  private mergeIcns: IndexIcn[];
  private mergeDms: IndexDm[];
  private documentTreesFromDB: DocumentTree[];
  private documentsToDelete: DocumentNode[];
  private dmsToDelete: Dm[];
  private dmsFromDb: Dm[] = [];
  private icnsToDelete: Icn[];
  private pmsToDelete: Pmc[];
  private pmsToUpdate: Pmc[];
  private pmsToAdd: Pmc[];
  private documentsFromDb: DocumentNode[] = [];
  private icnsFromDb: Icn[] = [];
  private pmsFromDb: Pmc[] = [];

  constructor(
    private documentTreesService: DocumentTreesService,
    private documentService: DocumentService,
    private dmService: DmService,
    private icnService: IcnService,
    private pmcService: PmcService,
    private dataService: DataService,
    private globalDataService: GlobalDataService,
    private offlineFeedService: OfflineFeedService,
    private offlineFileService: OfflineFileService,
    private offlineZipDownloadService: OfflineZipDownloadService,
    private offlineStatusService: OfflineStatusService,
  ) { }

  checkUpdates() {
    this.totalCalls = 0;
    this.currentCalls = 0;
    this.totalCalls = this.totalCalls + 1;
    const lastCheck$ = this.globalDataService.getByKey('lastCheck');
    const userProductResult$ = this.getUserModelsAndClassifications();
    forkJoin([lastCheck$, userProductResult$]).subscribe(([lastCheckResult, userProductResult]) => {
      this.totalCalls = this.totalCalls + 1;
      this.currentCalls = this.currentCalls + 1;
      this.lastUpdated = lastCheckResult.data;
      this.dataService.getUpdateIndex(this.lastUpdated, this.userModels.toString(),
        this.userClassifications.toString()).subscribe((result) => {
          let isIndexUpdatable = true;
          if (result) {
            if (result.unchanged) {
              if (result.unchanged.length === 0) {
                isIndexUpdatable = false;
              }
            } else {
              isIndexUpdatable = false;
            }
          } else {
            isIndexUpdatable = false;
          }
          console.log(result);
          if (isIndexUpdatable) {
            this.totalCalls = this.totalCalls + 8;
            this.currentCalls = this.currentCalls + 1;
            this.mergeIndex(result);
          } else {
            this.currentCalls = this.currentCalls + 1;
          }
        });
    });
  }

  getProgressNumber(): Observable<number> {
    return new Observable((observer: Observer<number>) => {
      observer.next((this.currentCalls / this.totalCalls) * 100);
      observer.complete();
    });
  }

  private getUserModelsAndClassifications(): Observable<boolean> {
    this.totalCalls = this.totalCalls + 1;
    return new Observable((observer: Observer<boolean>) => {
      this.globalDataService.getByKey('userProduct').subscribe((result) => {
        if (result) {
          const userProduct: UserProduct = result.data;
          userProduct.models.forEach(modelSeries => {
            modelSeries.models.forEach((model) => {
              if (this.userModels.find(userModel => userModel === model) === undefined) {
                this.userModels.push(model);
              }
            });
          });
          this.userModels.sort();

          userProduct.classifications.forEach(classificationSeries => {
            classificationSeries.classifications.forEach((classification) => {
              if (this.userClassifications.find(userClassification => userClassification === classification) === undefined) {
                this.userClassifications.push(classification);
              }
            });
          });
          this.userClassifications.sort();
        }
        this.currentCalls = this.currentCalls + 1;
        observer.next(true);
        observer.complete();
      });
    });
  }

  private getInitialZipFiles() {
    this.totalCalls = this.totalCalls + 1;
    this.offlineZipDownloadService.getIndexes().subscribe((data) => {
      this.currentCalls = this.currentCalls + 1;
    });
    this.totalCalls = this.totalCalls + 1;
    this.offlineZipDownloadService.getFaultCodes().subscribe((data) => {
      this.currentCalls = this.currentCalls + 1;
    });
    this.totalCalls = this.totalCalls + 1;
    this.offlineZipDownloadService.getWiringData().subscribe((data) => {
      this.currentCalls = this.currentCalls + 1;
    });
    this.totalCalls = this.totalCalls + 1;
    this.offlineZipDownloadService.getToc().subscribe((data) => {
      this.currentCalls = this.currentCalls + 1;
    });
  }

  //#region Merge changes starts
  private mergeIndex(updatedIndex: IndexRoot) {
    this.needChildRecount = {};
    const documentTree$ = this.documentTreesService.getAll();
    const document$ = this.documentService.getAll();
    const dm$ = this.dmService.getAll();
    const icn$ = this.icnService.getAll();
    const pm$ = this.pmcService.getAll();
    this.mergeResultTrees = updatedIndex.tree;
    this.mergeResultDocs = updatedIndex.docs;
    this.mergePms = updatedIndex.pms;
    this.mergeIcns = updatedIndex.icns;
    this.mergeDms = updatedIndex.dms;
    this.totalCalls = this.totalCalls + 1;
    forkJoin([documentTree$, document$, dm$, icn$, pm$])
      .subscribe(([documentTreesToDelete, documentsToDelete, dmsToDelete, icnsToDelete, pmsToDelete]) => {
        this.currentCalls = this.currentCalls + 1;
        this.documentTreesFromDB = documentTreesToDelete;
        this.documentsToDelete = documentsToDelete;
        this.dmsToDelete = dmsToDelete;
        this.icnsToDelete = icnsToDelete;
        this.pmsToDelete = pmsToDelete;
        this.pmsFromDb.push(...pmsToDelete);
        this.icnsFromDb.push(...icnsToDelete);
        this.dmsFromDb.push(...dmsToDelete);
        this.documentsFromDb.push(...documentsToDelete);
        this.mergeUnChanged(updatedIndex);
        // this is a stop gap to not delete the whole tree, this a hack until we have a better startegy we need this
        if ((this.documentsToDelete.length / this.documentsFromDb.length) * 100 < 60) {
          this.mergeTreeItems().subscribe(tr => {
            this.currentCalls = this.currentCalls + 1;
            this.mergeDocItems().subscribe(dr => {
              this.currentCalls = this.currentCalls + 1;
              this.purgeDocument().subscribe(deleteDocResult => {
                this.currentCalls = this.currentCalls + 1;
                this.recountChildren().subscribe(recountResult => {
                  this.currentCalls = this.currentCalls + 1;
                  this.mergePmItem().subscribe(pmResult => {
                    this.currentCalls = this.currentCalls + 1;
                    this.mergeIcnItem().subscribe(icnResult => {
                      this.currentCalls = this.currentCalls + 1;
                      this.mergeDmItem().subscribe(dmResult => {
                        this.currentCalls = this.currentCalls + 1;
                        this.purgeItems().subscribe(purgeResult => {
                          this.currentCalls = this.currentCalls + 1;
                          if (this.isDBUpdated) {
                            this.isDBUpdated = false;
                            this.getInitialZipFiles();
                          }
                          this.offlineFeedService.updateLastCheck(new Date(updatedIndex.lastupdate)).subscribe();
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        } else {
          this.currentCalls = this.currentCalls + 8;
        }
      });
  }

  private mergeUnChanged(updatedIndex: IndexRoot) {
    let unChanged: string[] = [];
    if (updatedIndex.unchanged && updatedIndex.unchanged.length > 0) {
      if (updatedIndex.unchanged) {
        unChanged = updatedIndex.unchanged;
        while (unChanged.length > 0) {
          const docToKeep = unChanged.shift();
          const existingDoc = this.documentsToDelete.find((d) => d.name === docToKeep);
          if (existingDoc) {
            const existingDms = this.dmsToDelete.filter((dm) => dm.idFile === existingDoc.idFile && dm.idManual === existingDoc.idManual);
            if (existingDms && existingDms.length > 0) {
              existingDms.forEach((dm) => {
                this.dmsToDelete.splice(this.dmsToDelete.indexOf(dm), 1);
              });
            }

            const existingPms = this.pmsToDelete.filter(p => p.pubName === docToKeep);
            if (existingPms.length > 0) {
              existingPms.forEach(existingPm => {
                if (existingPm) {
                  if (existingPm.icns && existingPm.icns.length > 0) {
                    existingPm.icns.forEach(existingPmIcn => {
                      const existingIcn = this.icnsToDelete.find(icn => icn.name === existingPmIcn.icn);
                      this.icnsToDelete.splice(this.icnsToDelete.indexOf(existingIcn), 1);
                    });
                  }
                }
                this.pmsToDelete.splice(this.pmsToDelete.indexOf(existingPm), 1);
              });
            }
            this.documentsToDelete.splice(this.documentsToDelete.indexOf(existingDoc), 1);
          }
        }
      }
    }
  }

  private mergeTreeItems(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      const treesToUpdate: DocumentTree[] = [];
      const treesToAdd: DocumentTree[] = [];

      this.mergeResultTrees.forEach((tItem) => {
        this.mergeTreeItem(tItem, treesToUpdate, treesToAdd);
      });

      this.documentTreesService.updateBulk(treesToUpdate).subscribe(updateTreeResult => {
        this.documentTreesService.addBulk(treesToAdd).subscribe(addTreeResult => {
          if (treesToUpdate.length > 0 || treesToAdd.length > 0) {
            this.isDBUpdated = true;
          }
          observer.next(true);
          observer.complete();
        });
      });
    });
  }

  private mergeDocItems(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (this.mergeResultDocs && this.mergeResultDocs.length > 0) {
        // Get the tree items again from db to get the updated trees again.
        this.documentTreesService.getAll().subscribe(trees => {
          this.documentTreesFromDB = trees;
          const documentsToUpdate: DocumentNode[] = [];
          const documentsToAdd: DocumentNode[] = [];
          const documentTreesToUpdate: DocumentTree[] = [];

          this.mergeResultDocs.forEach(d => {
            this.mergeDocItem(d, documentsToUpdate, documentsToAdd, documentTreesToUpdate);
          });
          this.documentService.updateBulk(documentsToUpdate).subscribe(docUpdateResult => {
            this.documentService.addBulk(documentsToAdd).subscribe(docAddResult => {
              this.documentTreesService.updateBulk(documentTreesToUpdate).subscribe(docTreeUpdateResult => {
                if (documentsToUpdate.length > 0 || documentsToAdd.length > 0 || documentTreesToUpdate.length > 0) {
                  this.isDBUpdated = true;
                }
                observer.next(true);
                observer.complete();
              });
            });
          });
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

  private purgeDocument(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      const docsNames: string[] = [];
      const docKeys: number[] = [];
      if (this.documentsToDelete && this.documentsToDelete.length > 0) {
        this.documentsToDelete.forEach(d => {
          docsNames.push(d.name);
          docKeys.push(d.rowid);
          const docIndex = this.documentsFromDb.findIndex(ld => ld.rowid === d.rowid);
          if (docIndex !== -1) {
            this.documentsFromDb.splice(docIndex, 1);
          }
        });
        const drs = this.documentTreesFromDB.filter(dt => docsNames.indexOf(dt.doc) !== -1);
        const treeIds: number[] = [];
        if (drs.length > 0) {
          drs.forEach(dt => {
            treeIds.push(dt.rowid);
            if (dt.parent !== '/' && this.needChildRecount[dt.parent] === undefined) {
              this.needChildRecount[dt.parent] = true;
            }
            const tIndex = this.documentTreesFromDB.findIndex(ldt => ldt.rowid === dt.rowid);
            if (tIndex !== -1) {
              this.documentTreesFromDB.splice(tIndex, 1);
            }
          });
          const docService$ = this.documentService.removeBulk(docKeys);
          const docTreeService$ = this.documentTreesService.removeBulk(treeIds);
          forkJoin([docService$, docTreeService$]).subscribe(results => {
            if (docKeys.length > 0 || treeIds.length > 0) {
              this.isDBUpdated = true;
            }
            observer.next(true);
            observer.complete();
          });
        }
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

  private recountChildren(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      const allTrees: DocumentTree[] = [];
      const deletedKeys: DocumentTree[] = [];
      const parentKeys: string[] = [];
      const parentParentKeys: string[] = [];
      if (this.needChildRecount && Object.keys(this.needChildRecount).length > 0) {
        this.recountChildrenRecursive(allTrees, deletedKeys, this.getCurrentParentKeys(), parentParentKeys);
        this.documentTreesService.updateBulk(allTrees).subscribe(updateResult => {
          const deletedTreeIds: number[] = [];
          deletedKeys.forEach(dt => deletedTreeIds.push(dt.rowid));
          this.documentTreesService.removeBulk(deletedTreeIds).subscribe(deleteResult => {
            observer.next(true);
            observer.complete();
          });
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

  private mergePmItem(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.pmsToAdd = [];
      this.pmsToUpdate = [];
      if (this.mergePms.length > 0) {
        this.mergePms.forEach(pm => {
          const deletePmIndex = this.pmsToDelete.findIndex(pmd => pmd.pubName === pm.pubName);
          const updatePmIndex = this.pmsFromDb.findIndex(pmd => pmd.pubName === pm.pubName);
          if (deletePmIndex !== -1) {
            this.pmsToDelete.splice(deletePmIndex, 1);
          }

          // Remove all ICNs from the delete list if they are refered by the PM
          for (let i = 0; i < pm.icns.length; i++) {
            const icn = pm.icns[i].icn;
            const deleteIcnIndex = this.icnsToDelete.findIndex(dIcn => dIcn.name === icn);
            if (deleteIcnIndex !== -1) {
              this.icnsToDelete.splice(deleteIcnIndex, 1);
            }
          }

          if (updatePmIndex !== -1) {
            const dbPm = this.pmsFromDb[updatePmIndex];
            dbPm.directory = pm.directory;
            dbPm.titlePage = pm.titlePage;
            dbPm.icns = [];
            pm.icns.forEach((mergedIcn) => {
              dbPm.icns.push(this.mapIndexPmIcn(mergedIcn));
            });
            this.pmsToUpdate.push(dbPm);
          } else {
            this.pmsToAdd.push(this.mapIndexPmc(pm));
          }
        });

        this.pmcService.updateBulk(this.pmsToUpdate).subscribe(updateResult => {
          this.pmcService.addBulk(this.pmsToAdd).subscribe(addResult => {
            if (this.pmsToUpdate.length > 0 || this.pmsToAdd.length > 0) {
              this.isDBUpdated = true;
            }
            observer.next(true);
            observer.complete();
          });
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

  private mergeIcnItem(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.pmsToUpdate.forEach(pm => {
        pm.icns.forEach((dIcn: PmIcn) => {
          const icnToDeleteIndex = this.icnsToDelete.findIndex(icn => icn.name = dIcn.icn);
          if (icnToDeleteIndex !== -1) {
            this.icnsToDelete.splice(icnToDeleteIndex, 1);
          }
        });
      });

      this.pmsToAdd.forEach(pm => {
        pm.icns.forEach((dIcn: PmIcn) => {
          const icnToDeleteIndex = this.icnsToDelete.findIndex(icn => icn.name = dIcn.icn);
          if (icnToDeleteIndex !== -1) {
            this.icnsToDelete.splice(icnToDeleteIndex, 1);
          }
        });
      });

      if (this.mergeIcns.length > 0) {
        const icnsToUpdate: Icn[] = [];
        const icnsToAdd: Icn[] = [];

        this.mergeIcns.forEach(icn => {
          const icnAvailableUpdate = getNowUTC(new Date(icn.availableUpdate));
          if (this.lastUpdated < icnAvailableUpdate) {
            this.lastUpdated = icnAvailableUpdate;
          }
          const dbIcn = this.icnsFromDb.find(dIcn => dIcn.name === icn.name);
          if (dbIcn) {
            const dbIcnAvailableUpdate = getNowUTC(new Date(dbIcn.availableUpdate));
            if (dbIcnAvailableUpdate !== icnAvailableUpdate) {
              dbIcn.availableUpdate = icn.availableUpdate;
              dbIcn.size = icn.size;
              icnsToUpdate.push(dbIcn);
            }
          } else {
            icnsToAdd.push(this.mapIndexIcn(icn));
          }
        });

        this.icnService.updateBulk(icnsToUpdate).subscribe(updateResult => {
          this.icnService.addBulk(icnsToAdd).subscribe(addResult => {
            if (icnsToUpdate.length > 0 || icnsToAdd.length > 0) {
              this.isDBUpdated = true;
            }
            observer.next(true);
            observer.complete();
          });
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

  private mergeDmItem(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (this.mergeDms.length > 0) {
        const dmsToUpdate: Dm[] = [];
        const dmsToAdd: Dm[] = [];

        this.mergeDms.forEach(dm => {
          const dbDm = this.dmsFromDb.find(dDm => dDm.idDm === dm.idDm);
          if (dbDm) {
            dbDm.title = dm.title;
            dbDm.idFile = dm.idFile;
            dbDm.idManual = dm.idManual;
            dmsToUpdate.push(dbDm);
          } else {
            dmsToAdd.push(this.mapIndexDm(dm));
          }
        });

        this.dmService.updateBulk(dmsToUpdate).subscribe(updateResult => {
          this.dmService.addBulk(dmsToAdd).subscribe(addResult => {
            if (dmsToUpdate.length > 0 || dmsToAdd.length > 0) {
              this.isDBUpdated = true;
            }
            observer.next(true);
            observer.complete();
          });
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

  private purgeItems(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.pmcService.removeBulk(this.pmsToDelete).subscribe(pmResult => {
        this.icnService.removeBulk(this.icnsToDelete).subscribe(icnResult => {
          let deletions$ = [];
          if (this.icnsToDelete.length > 0) {
            this.icnsToDelete.forEach(icn => {
              deletions$.push(this.offlineFileService.deleteFile(`\\zip\\`, `${icn.name}.zip`));
            });
          }
          deletions$.push(this.dmService.removeBulk(this.dmsToDelete));
          forkJoin(deletions$).subscribe(() => {
            observer.next(true);
            observer.complete();
          });
        });
      });
    });
  }

  private mergeTreeItem(item: IndexTree, treesToUpdate: DocumentTree[], treesToAdd: DocumentTree[]) {
    const dbItem = this.documentTreesFromDB.find(dt => dt.path === item.path);
    if (!dbItem) {
      const parentItem = this.documentTreesFromDB.find(dt => dt.path === item.parent);
      const rootItem = this.documentTreesFromDB.find(dt => dt.path === '/'+item.path.split('/')[1])
      if (parentItem && rootItem) {
        if (parentItem.selected) {
          item.selected = 'true';
          if(rootItem.selected){
            item.download = 'true';
            item.selectedChild = isNaN(item.selectedChild) ? 1 : item.selectedChild  + 1;
            parentItem.childCount = parentItem.childCount + 1;
            parentItem.selectedChild = parentItem.selectedChild + 1;
            parentItem.usableChild = parentItem.usableChild + 1;
            treesToUpdate.push(parentItem);
          }
        }
        // Mark the parent for child recount
        if (!this.needChildRecount[parentItem.path]) {
          this.needChildRecount[parentItem.path] = true;
        }
      }else{
        if(rootItem.selected){
          item.selected = 'true';
          item.download = 'true';
        }
      }
      treesToAdd.push(this.mapIndexTree(item));
    } else {
      // S1000D publication converted to legacy publication
      // S1000D path to access a document as one less folder level
      // For example, in S1000D: 505/MM/MM/505MM-1
      // would be in legacy format: 505/MM/MM/505-MM-1/505-MM-1
      // Therefore if a legacy item matches an S1000D document path,
      // the legacy item will be a folder and have a child but no associated document
      if (item.childCount > 0 && dbItem.doc !== '') {
        dbItem.doc = undefined;
        treesToUpdate.push(dbItem);
      } else if (item.doc !== undefined && item.doc !== '' && dbItem.childCount > 0) {
        // Current item is a document but the matching database item is a folder:
        // item is S1000D and database item is legacy
        dbItem.doc = item.doc; // Set S1000D doc as new doc of database item
        dbItem.childCount = 0;
        dbItem.removeChild = dbItem.childCount;
        dbItem.selectedChild = 0;
        dbItem.updatableChild = 0;
        dbItem.usableChild = 0;

        // Item inehrits selected attribute from parent
        const parentItem = this.documentTreesFromDB.find(dt => dt.path === item.parent);
        const rootItem = this.documentTreesFromDB.find(dt => dt.path === '/'+item.path.split('/')[1])
        if (parentItem && rootItem) {
          if (parentItem.selected) {
            dbItem.selected = 'true'; // Select the database item so the S1000D content  will be downloaded
            if(rootItem.selected){
              dbItem.download = 'true';
              parentItem.childCount = parentItem.childCount + 1;
              parentItem.selectedChild = parentItem.selectedChild + 1;
              parentItem.usableChild = parentItem.usableChild + 1;
              treesToUpdate.push(parentItem);
            }
          }
        }else{
          if(rootItem.selected){
            dbItem.selected = 'true';
            dbItem.download = 'true';
          }
        }
        treesToUpdate.push(dbItem);
      }
    }
  }

  private mergeDocItem(item: IndexDoc, documentsToUpdate: DocumentNode[], documentsToAdd: DocumentNode[],
    documentTreesToUpdate: DocumentTree[]) {
      let itemAvailableUpdate: Date;
      if (item.availableUpdate) {
        itemAvailableUpdate = getNowUTC(new Date(item.availableUpdate));
      }
      if (this.lastUpdated < itemAvailableUpdate) {
        this.lastUpdated = itemAvailableUpdate;
      }
      const existingDoc = this.documentsToDelete.find(d => d.name === item.name);
      if (existingDoc) {
        this.documentsToDelete.splice(this.documentsToDelete.findIndex(d => d.name === item.name), 1);
      }
      const dbItem = this.documentsFromDb.find(d => d.name === item.name);
      if (dbItem) {
        //  Update its 'availableUpdate' attribute
        const dbItemAvailableUpdate = getNowUTC(new Date(dbItem.availableUpdate));
        const wasUpdatable = dbItemAvailableUpdate > dbItem.lastUpdate;
        if (dbItemAvailableUpdate !== itemAvailableUpdate) {
          dbItem.availableUpdate = item.availableUpdate;
          dbItem.size = item.size;
          dbItem.title = item.title;
          dbItem.models = item.models;
          dbItem.idManual = item.idManual;
          dbItem.type = item.type;
          // If the document has been downloaded (lastUpdate is thruthy), we propagate an 'updatable' flag.
          if (!wasUpdatable && dbItem.lastUpdate) {
            this.propagateDocuments(dbItem.name, documentTreesToUpdate);
          }
          documentsToUpdate.push(dbItem);
        }
      } else {
        const selectionCount = this.documentTreesFromDB.filter(dt => dt.doc === item.name && dt.selected === 'true').length;
        const newItem = this.mapIndexDocumentNode(item);
        newItem.selectionCount = selectionCount;
        documentsToAdd.push(newItem);
      }
  }

  private propagateDocuments(docName: string, documentTreesToUpdate: DocumentTree[]) {
    const docTrees = this.documentTreesFromDB.filter(dt => dt.doc === docName);
    const allTreePaths: string[] = [];
    const childTreePaths: string[] = [];
    if (docTrees.length > 0) {
      docTrees.forEach((tItem) => {
        if (tItem.parent !== '/') {
          childTreePaths.push(tItem.path);
          allTreePaths.push(tItem.path);
          allTreePaths.push(tItem.parent);
          let pathToSplit = (' ' + tItem.parent).slice(1);
          while (pathToSplit.length > 1) {
            pathToSplit = pathToSplit.substring(0, pathToSplit.lastIndexOf('/'));
            if (pathToSplit !== '/') {
              allTreePaths.push(pathToSplit);
            }
          }
        } else {
          allTreePaths.push(tItem.path);
        }
      });
      this.propagateDocumentCount(childTreePaths, allTreePaths, documentTreesToUpdate);
    }
  }

  private propagateDocumentCount(childTreePaths: string[], allTreePaths: string[],
    documentTreesToUpdate: DocumentTree[]) {
    if (allTreePaths.length > 0) {
      const allTrees = this.documentTreesFromDB.filter(dt => allTreePaths.indexOf(dt.path) !== -1);
      if (allTrees && allTrees.length > 0 && childTreePaths && childTreePaths.length > 0) {
        childTreePaths.forEach(currentPath => {
          const currentTree = allTrees.find(t => t.path === currentPath);
          allTrees.forEach(at => {
            if (documentTreesToUpdate.indexOf(at) === -1) {
              documentTreesToUpdate.push(at);
            }
          });
          this.propagateCount(currentTree, [{ add: true, attribute: 'updatable', increment: 0 }], allTrees);
        });
      }
    }
  }

  private propagateCount(item: DocumentTree, params: PropogateParam[], fullTrees: DocumentTree[]) {

    const newParams: PropogateParam[] = [];
    params.forEach(param => {
      const add = param.add;
      const attribute = param.attribute;
      const attributeChild = attribute + 'Child';
      let increment = param.increment || 0;
      let wasPlainState = false;
      let nowPlainState = false;
      let newIncrement = 0;

      increment = add ? increment : -increment;
      if (item.doc === 'HELP' || item.path.indexOf('HELP') !== -1) {
        return;
      }

      // Determine if the item was in plain state (all or none selected / usable) before the change
      wasPlainState = item[attributeChild] === 0 || item[attributeChild] === item.childCount;

      // Change the value and check for consistency
      item[attributeChild] += increment;
      if (item[attributeChild] < 0) { item[attributeChild] = 0; }
      if (item[attributeChild] > item.childCount) { item[attributeChild] = item.childCount; }

      // Determine if the item is in plain state (all or none selected / usable) after the change
      nowPlainState = item[attributeChild] === 0 || item[attributeChild] === item.childCount;

      if (item.childCount) {
        item[attribute] = item[attributeChild] === item.childCount;
      } else {
        item[attribute] = !!add;
      }
      
      let newParam: PropogateParam;
      // We only need to propagate if the item was or is in a plain state.
      // This only case excluded here is when the item was in an intermediate
      // state but the change didn't put it in a plain state
      if (item.parent !== '/' && (wasPlainState || nowPlainState)) {
        // Increment the parent by one if this item passed from a plain state to another
        // (possible with items with only one children)
        if (nowPlainState && wasPlainState) {
          newIncrement = 1;
          // Increment the parent by 0.5 if this item has changed state (from plain to intermediate or the opposite).
        } else {
          newIncrement = 0.5;
        }

        newParam = {
          add: add,
          attribute: attribute,
          increment: newIncrement
        };
        newParams.push(newParam);
      }
    });

    if (item.doc === 'HELP' || item.path.indexOf('HELP') !== -1) {
      item.selected = 'false';
      item.selectedChild = 0;
    }

    if (newParams.length > 0) {
      const parentItem = fullTrees.find(t => t.path === item.parent);
      if (parentItem) {
        this.propagateCount(parentItem, newParams, fullTrees);
      }
    }
  }

  private recountChildrenRecursive(allTrees: DocumentTree[], deletedKeys: DocumentTree[], parentKeys: string[],
    parentParentKeys: string[]) {
    if (parentKeys.length > 0) {
      parentKeys.forEach(key => {
        if (this.needChildRecount[key] === true) {
          // Get All the parent keys and push it to all here..
          let pathToSplit = (' ' + key).slice(1);
          while (pathToSplit.length > 1) {
            pathToSplit = pathToSplit.substring(0, pathToSplit.lastIndexOf('/'));
            if (pathToSplit !== '/' && !parentParentKeys.find(k => k === pathToSplit)) {
              parentParentKeys.push(pathToSplit);
            }
          }
        }
      });
      if (parentKeys.length > 0) {
        const parents = this.documentTreesFromDB.filter(dt => parentKeys.indexOf(dt.path) !== -1);
        const allChildrens = this.documentTreesFromDB.filter(dt => parentKeys.indexOf(dt.parent) !== -1);
        const parentParents = this.documentTreesFromDB.filter(dt => parentParentKeys.indexOf(dt.path) !== -1);
        

        this.checkAndAddDocumentTree(allTrees, parents);
        this.checkAndAddDocumentTree(allTrees, allChildrens);
        this.checkAndAddDocumentTree(allTrees, parentParents);
        this.removeDeletedItems(deletedKeys, allTrees);
        parents.forEach(item => {
          const params = [];
          const children = allChildrens.filter(t => t.parent === item.path);
          if (children && children.length > 0) {
            this.countItem(item, children, 'selected', params);
            this.countItem(item, children, 'usable', params);
            this.countItem(item, children, 'updatable', params);
            this.countItem(item, children, 'remove', params);
            item.childCount = children.length;
            

            if (params.length && item.parent !== '/') {
              this.propagateCount(item, params, allTrees);
            }
            this.needChildRecount[item.path] = false;
          } else {
            if (item.doc === '') {
              deletedKeys.push(item);
              this.needChildRecount[item.parent] = true;
            }
            if (item.parent !== '/' && !this.needChildRecount[item.parent]) {
              this.needChildRecount[item.parent] = true;
            }
            this.needChildRecount[item.path] = false;
          }
        });
        this.recountChildrenRecursive(allTrees, deletedKeys, this.getCurrentParentKeys(), parentParentKeys);
      }
    }
  }

  private getCurrentParentKeys(): string[] {
    const currentParentKeys: string[] = [];
    Object.keys(this.needChildRecount).forEach(key => {
      if (this.needChildRecount[key] === true) {
        currentParentKeys.push(key);
      }
    });
    return currentParentKeys;
  }

  private removeDeletedItems(deletedItems: DocumentTree[], allItems: DocumentTree[]) {
    deletedItems.forEach(di => {
      const currentIndex = allItems.indexOf(di);
      if (currentIndex !== -1) {
        allItems.splice(currentIndex, 1);
      }
    });
  }

  private checkAndAddDocumentTree(allTrees: DocumentTree[], treesToAdd: DocumentTree[]) {
    treesToAdd.forEach(t => {
      if (allTrees.findIndex(tf => tf.path === t.path) !== -1) {
        allTrees.push(t);
      }
    });
  }

  private countItem(item: DocumentTree, children: DocumentTree[], attribute: string, params) {
    const result: PropogateParam = { add: false, attribute: attribute, increment: 0 };
    let wasFull = false;
    let wasMidState = false;
    let nowEmpty = false;
    let nowFull = false;
    let nowMidState = false;
    const attributeChild = attribute + 'Child';
    result.attribute = attribute;

    wasFull = item[attributeChild] === item.childCount;
    wasMidState = item[attributeChild] !== 0 && item[attributeChild] !== item.childCount;

    let compte = 0;

    children.forEach((child) => {
      if (child[attribute] || child[attributeChild] > 0) {
        if (child[attribute] || child[attributeChild] >= child.childCount) {
          compte += 1;
        } else if (child[attributeChild] > 0) {
          compte += 0.5;
        }
      }
    });

    item[attributeChild] = compte;

    nowEmpty = item[attributeChild] === 0;
    nowFull = item[attributeChild] === children.length;
    nowMidState = item[attributeChild] !== 0 && item[attributeChild] !== children.length;

    if ((wasMidState && nowEmpty) || (wasFull && nowMidState) || (wasMidState && nowFull)) {
      result.increment = 0.5;
      result.add = false;
    }

    if (wasMidState && nowFull) {
      result.add = true;
    }

    if (result.increment) {
      params.push(result);
    }
  }
  //#endregion Merge changes ends

  private mapIndexDm(tree: IndexDm): Dm {
    const mappedDoc = new Dm();
    mappedDoc.name = tree.name;
    mappedDoc.code = tree.code;
    mappedDoc.title = tree.title;
    mappedDoc.momodel = tree.momodel;
    mappedDoc.idDm = tree.idDm;
    mappedDoc.idFile = tree.idFile;
    mappedDoc.idManual = tree.idManual;
    mappedDoc.s1000d = tree.s1000d;
    return mappedDoc;
  }

  private mapIndexTree(tree: IndexTree): DocumentTree {
    const mappedTree = new DocumentTree();
    mappedTree.path = tree.path;
    mappedTree.parent = tree.parent;
    mappedTree.childCount = tree.childCount;
    mappedTree.selected = tree.selected !== undefined ? tree.selected : 'false';
    mappedTree.selectedChild = tree.selectedChild !== 0 ? tree.selectedChild : 0;
    mappedTree.usable = 'false';
    mappedTree.usableChild = 0;
    mappedTree.updatable = 'false';
    mappedTree.updatableChild = 0;
    mappedTree.remove = 'false';
    mappedTree.removeChild = 0;
    mappedTree.download = tree.download !== undefined ? tree.download : 'false';
    mappedTree.downloadChild = 0;
    mappedTree.doc = tree.doc;
    mappedTree.pendingAction = 'false';
    mappedTree.lastAction = undefined;
    return mappedTree;
  }

  private mapIndexDocumentNode(tree: IndexDoc): DocumentNode {
    const mappedDoc = new DocumentNode();
    mappedDoc.name = tree.name;
    mappedDoc.availableUpdate = tree.availableUpdate;
    mappedDoc.selectionCount = 0;
    mappedDoc.size = tree.size;
    mappedDoc.title = tree.title;
    mappedDoc.models = tree.models;
    mappedDoc.idFile = tree.idFile;
    mappedDoc.idManual = tree.idManual;
    mappedDoc.type = tree.type;
    return mappedDoc;
  }

  private mapIndexPmc(tree: IndexPm): Pmc {
    const mappedDoc = new Pmc();
    mappedDoc.pubCode = tree.pubCode;
    mappedDoc.pubName = tree.pubName;
    mappedDoc.directory = tree.directory;
    mappedDoc.titlePage = tree.titlePage;
    mappedDoc.icns = [];
    tree.icns.forEach((icn) => {
      mappedDoc.icns.push(this.mapIndexPmIcn(icn));
    });
    return mappedDoc;
  }

  private mapIndexIcn(tree: IndexIcn): Icn {
    const mappedDoc = new Icn();
    mappedDoc.name = tree.name;
    mappedDoc.type = tree.type;
    mappedDoc.availableUpdate = tree.availableUpdate;
    mappedDoc.size = tree.size;
    return mappedDoc;
  }

  private mapIndexPmIcn(icn: IndexPmIcn): PmIcn {
    const mappedIcn = new PmIcn();
    mappedIcn.icn = icn.icn;
    return mappedIcn;
  }
}
