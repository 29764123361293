import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ServiceBase } from '../service-base.service';
import { SessionTimeoutModalComponent } from '../../components/session-timeout-modal/session-timeout-modal.component';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SessionTimeoutModalService extends ServiceBase {
  private _modalOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  public modalOpen$: Observable<boolean> = this._modalOpen.asObservable();

  get modalOpen(): boolean {
    return this._modalOpen.value;
  }

  public modalSessionTimeoutRef: any;

  constructor(
    private modalSessionTimeout: MatDialog
  ) {
    super();
    this._modalOpen.next(false);
  }

  public handleSessionTimeoutModal(data): any {
    if (!this.modalSessionTimeoutRef) {
      this.modalSessionTimeoutRef = this.modalSessionTimeout.open(
        SessionTimeoutModalComponent, {
          data: data,
          disableClose: true
        }
      );
      this._modalOpen.next(true);
      this.modalSessionTimeoutRef.afterClosed().subscribe(
        () => {
          this.modalSessionTimeoutRef = undefined;
          this._modalOpen.next(false);
        }
      );
    } else {
      this.modalSessionTimeoutRef.componentInstance.data.idleState = data.idleState;
      this.modalSessionTimeoutRef.componentInstance.data.continue = data.continue;
    }
  }
}
