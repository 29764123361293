<section *ngIf="manualToc" class="manual" [class.offline]="offlineService.isOffline">
  <div class="offline-selection-wrapper" *ngIf="isCheckboxVisible" >
    <mat-checkbox class="offline-selection-all" labelPosition="before"
    name="all"
    (change)="selectManual($event)"
    [checked]="manualChecked"
    [disabled]="offlineService.isOffline">Select Offline Content</mat-checkbox>
  </div>
  <ng-container *ngFor="let manual of manualToc.manuals; let isFirst = first; let manualIndex = index">
    <hr *ngIf="!isFirst"/>
    <div class="block__content block__content--manual">
      <div class="expiration-date-wrapper" *ngIf="expirationDate && isFirst">
        <div class="expiration-date" title="Your access to this model's publications will expire on {{expirationDate}}.">Expires on {{expirationDate}}</div>
      </div>
      <div class="block__title toc-header">
        <h3 class="title manual-title">{{manual.name}}</h3>
        <mat-checkbox class="offline-selection" *ngIf="!manual.id.includes('-PDF') && !manual.toc.includes('LR') && isCheckboxVisible"
        name="{{manual.id}}"
        [checked]="manual.isChecked"
        [indeterminate]="manual.publicationChecked == OfflineContentStatus.Partial"
        (change)="selectManualDetail($event, manual.id)"
        [disabled]="offlineService.isOffline"></mat-checkbox>
      </div>
      <div class="block__content">
        <div *ngIf="!manual.publications || manual.publications.length == 0">
          <p>
            No publications found for this manual.
          </p>
        </div>
        <div class="publication" *ngFor="let publication of manual.publications; let publicationIndex = index">
          <div *ngIf="publication.one_file_manual" class="block__title block__title--publication">
            <div class="publication-body">
              <div *ngIf="publication.files[0].pdf" class="publication__icon publication-icon-pdf icon-download-arrow icon" (click)="openPublicationPDF(publication)"></div>
              <div class="publication__title" [class.isDisabled]="offlineService.isOffline && publication.downloadStatus !== OfflineIconStatus.Available && publication.downloadStatus !== OfflineIconStatus.Delete && publication.downloadStatus !== OfflineIconStatus.Update">
                <a *ngIf="!publication.files[0].pdf" class="publication__link file-doc {{publication.downloadStatus}}"
                [class.isDisabled]="offlineService.isOffline && publication.downloadStatus !== OfflineIconStatus.Available && publication.downloadStatus !== OfflineIconStatus.Delete && publication.downloadStatus !== OfflineIconStatus.Update"
                  (click)="goToFile(publication.id, publication.files[0].url)"
                  [routerLink]="[]">
                  {{publication.title}}
                  <div class="publication__issue">
                    {{publication.revision}}
                  </div>
                </a>
                <a *ngIf="publication.files[0].pdf" class="pub-title pub-pdf" (click)="openPublicationPDF(publication)">
                  {{publication.title}}
                  <div class="publication__issue">
                    {{publication.revision}}
                  </div>
                </a>
              </div>
            </div>
            <mat-checkbox class="offline-selection" *ngIf="!publication.files[0].pdf && isCheckboxVisible"
            name="{{publication.id}}"
            [checked]="publication.isChecked"
            (change)="selectManualPublication($event, manual.id, publication.id)"
            [indeterminate]="false"
             [disabled]="offlineService.isOffline"></mat-checkbox>
          </div>
          <ng-container *ngIf="!publication.one_file_manual">
            <div *ngIf="!isArray(publication.files)" class="block__title block__title--publication">
              <div class="publication-body">
                <div class="publication__title publication__title--no-link">
                  <div class="publication__link file-doc {{publication.downloadStatus}}">
                    {{publication.title}}
                    <div class="publication__issue">File not available</div>
                  </div>
                </div>
              </div>
              <mat-checkbox class="offline-selection"
              name="{{publication.id}}"
              [checked]="publication.isChecked"
              (change)="selectManualPublication($event, manual.id, publication.id)"
              *ngIf="isCheckboxVisible"
              [indeterminate]="false"
              [disabled]="offlineService.isOffline"></mat-checkbox>
            </div>
            <ng-container *ngIf="isArray(publication.files)">
              <div class="block__title block__title--publication">
                <div class="publication__title publication__title--has-files" (click)="toggleFiles(manualIndex, publicationIndex, $event.target)">
                  <span class="publication__icon--link">
                    <div class="tp-icon tp-icon__plus" [ngClass]="{'tp-icon__plus--open': !isOpen(manualIndex, publicationIndex)}"></div>
                  </span>
                  <a class="publication__link file-doc doc" [class.isDisabled]="offlineService.isOffline && publication.downloadStatus !== OfflineIconStatus.Available && publication.downloadStatus !== OfflineIconStatus.Delete && publication.downloadStatus !== OfflineIconStatus.Update">
                    {{publication.title}}
                    <div class="publication__issue">
                      {{publication.revision}}
                    </div>
                    <div class="spinner-wrapper" [class.spinner-wrapper--show]="loading">
                      <mat-progress-spinner class="image-loading-spinner" diameter="20" mode="indeterminate">
                      </mat-progress-spinner>
                    </div>
                  </a>
                </div>
                <mat-checkbox class="offline-selection" *ngIf="!publication.files[0].pdf && isCheckboxVisible"
                name="{{publication.id}}"
                [indeterminate]="publication.publicationChecked == OfflineContentStatus.Partial"
                (change)="selectManualPublication($event, manual.id, publication.id)"
                [checked]="publication.isChecked"
                [disabled]="offlineService.isOffline"></mat-checkbox>
              </div>
              <div class="block__content block__content--publication" [ngClass]="{'block__content--open': !isOpen(manualIndex, publicationIndex)}">
                <ng-container *ngIf="!isOpen(manualIndex, publicationIndex)">
                  <div class="file" *ngFor="let file of publication.files; let fileIndex = index">
                    <div class="file__wrapper">
                      <div *ngIf="!file.pdf && !file.ipb && !file.zip && !file.video && !isArrayFigure(file.figures)" class="block__title block__title--file">
                        <div class="file__title">
                          <a class="file__link file-doc {{file.downloadStatus}}" (click)="goToFile(publication.id, file.url)" [routerLink]="[]"
                          [class.isDisabled]="offlineService.isOffline && file.downloadStatus !== OfflineIconStatus.Available && file.downloadStatus !== OfflineIconStatus.Delete && file.downloadStatus !== OfflineIconStatus.Update">
                            {{file.title}}
                          </a>
                        </div>
                      </div>
                      <div *ngIf="file.pdf || file.zip || file.video" class="block__title block__title--file pdf-file" title="Click to download file.">
                        <div class="publication__icon publication-icon-pdf icon-download-arrow icon" (click)="openManualFile(file, manual)"></div>
                        <div class="file__title">
                          <a class="file__link" (click)="openManualFile(file, manual)">
                            {{file.title}}
                          </a>
                        </div>
                        <mat-progress-spinner *ngIf="file.isDownloading" diameter="20" mode="indeterminate">
                        </mat-progress-spinner>
                      </div>
                      <ng-container *ngIf="isArrayFigure(file.figures)">
                        <div class="block__title block__title--file">
                          <div class="file__title file__title--has-figures">
                            <a class="file__link file-doc {{file.downloadStatus}}" (click)="loadIpb(manualIndex, publicationIndex, fileIndex, $event.target)"
                            [class.isDisabled]="offlineService.isOffline && file.downloadStatus !== OfflineIconStatus.Available && file.downloadStatus !== OfflineIconStatus.Delete && file.downloadStatus !== OfflineIconStatus.Update">
                              <span class="file__icon--link">
                                <div class="tp-icon tp-icon__plus" [ngClass]="{'tp-icon__plus--open': !isOpenIpb(manualIndex, publicationIndex, fileIndex) && isOpenIpb(manualIndex, publicationIndex, fileIndex) !== undefined}"></div>
                              </span>
                              {{file.title}}
                              <div class="spinner-wrapper">
                                <mat-progress-spinner class="image-loading-spinner" diameter="20" mode="indeterminate">
                                </mat-progress-spinner>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div class="block__content block__content--file" [ngClass]="{'block__content--open': !isOpenIpb(manualIndex, publicationIndex, fileIndex) && isOpenIpb(manualIndex, publicationIndex, fileIndex) !== undefined}">
                          <div *ngIf="!isOpenIpb(manualIndex, publicationIndex, fileIndex) && isOpenIpb(manualIndex, publicationIndex, fileIndex) !== undefined" class="figure__section">
                            <div class="block__title block__title--figure">
                              <a *ngIf="!file.ipb" class="figure__link-to-file" (click)="goToFile(publication.id, file.url)" [routerLink]="[]">
                                View Entire Chapter
                              </a>
                              <div class="figure__sub-title">View Individual Figures</div>
                            </div>
                            <div class="block__content block__content--figure" [ngClass]="{'block__content--open': !isOpenIpb(manualIndex, publicationIndex, fileIndex)}">
                              <div class="figure" *ngFor="let figure of file.figures;">
                                <div class="figure__icon icon-filter-picture icon"></div>
                                <div class="figure__title">
                                  <a class="figure__link" [routerLink]="file.ipb ? [figure.url, 'p1', 'ipb'] : [figure.url, 'p1']"
                                  [class.isDisabled]="offlineService.isOffline && file.downloadStatus !== OfflineIconStatus.Available && file.downloadStatus !== OfflineIconStatus.Delete && file.downloadStatus !== OfflineIconStatus.Update">
                                    {{figure.title}}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      </div>
                    <mat-checkbox class="offline-selection"
                    name="{{file.id}}"
                    [checked]="file.isChecked"
                    (change)="selectManualPublicationFiles($event, manual.id, publication.id, file.id)"
                    *ngIf="!file.pdf && isCheckboxVisible"
                    [indeterminate]="false"
                    [disabled]="offlineService.isOffline"></mat-checkbox>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</section>
