import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { TpubDbService } from './tpub-db.service';
import { documentTreeStoreName } from '../../../constants/indexed-store-config';
import { DocumentTree } from '../../../data-model/idb/document-tree';
import { Observable, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentTreesService {
  public table: Dexie.Table<DocumentTree>;

  constructor(private tpubDbService: TpubDbService) {
    this.table = this.tpubDbService.table(documentTreeStoreName);
  }

  add(data: DocumentTree): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.table.add(data).then(result => {
        observer.next(true);
        observer.complete();
      }, (error) => {
        observer.error(error);
      });
    });
  }

  addBulk(data: DocumentTree[]): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (data && data.length > 0) {
        this.table.bulkAdd(data).then((result) => {
          observer.next(true);
          observer.complete();
        }, (error) => {
          observer.error(error);
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

  clear(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.table.clear().then(() => {
        observer.next(true);
        observer.complete();
      }, (err) => {
        observer.error(err);
      });
    });
  }

  getByKey(dataKey: string): Observable<DocumentTree> {
    return new Observable((observer: Observer<DocumentTree>) => {
      this.table.where('path').equalsIgnoreCase(dataKey).first().then(data => {
        observer.next(data);
        observer.complete();
      }, err => {
        observer.error(err);
      });
    });
  }

  getAll(): Observable<DocumentTree[]> {
    return new Observable((observer: Observer<DocumentTree[]>) => {
      this.table.toArray().then((data: DocumentTree[]) => {
        observer.next(data);
        observer.complete();
      }, (error) => {
        observer.error(error);
      });
    });
  }

  remove(id: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.table.delete(id).then(() => {
        observer.next(true);
        observer.complete();
      }, (err) => {
        observer.error(err);
      });
    });
  }

  removeBulk(ids: number[]): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (ids && ids.length > 0) {
        this.table.bulkDelete(ids).then(() => {
          observer.next(true);
          observer.complete();
        }, (err) => {
          observer.error(err);
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

  update(id: number, data: DocumentTree): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.table.update(id, data).then(() => {
        observer.next(true);
        observer.complete();
      }, (err) => {
        observer.error(err);
      });
    });
  }

  updateBulk(data: DocumentTree[]): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (data && data.length > 0) {
        this.table.bulkPut(data).then(() => {
          observer.next(true);
          observer.complete();
        }, (err) => {
          observer.error(err);
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }
}
