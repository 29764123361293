import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { IetmSns, IetmManual, IetmDataModule } from 'src/app/viewer/ietm/services/ietm-service/ietm-model';
import { IetmManualService } from 'src/app/viewer/ietm/services/ietm-service/ietm-manual.service';
import { IetmSnsService } from 'src/app/viewer/ietm/services/ietm-service/ietm-sns.service';
import { IetmDataModuleService } from 'src/app/viewer/ietm/services/ietm-service/ietm-data-module.service';
import { Publication } from '../../data-model/publication';
import { Metadata } from '../../data-model/metadata';
import { Manual } from '../../data-model/manual';
import { RouteService } from '../../services/route.service';
import { TocService } from '../../services/toc/toc.service';
import { UserProductService } from '../../services/user/user-product.service';
import { ExpirationDateDialogComponent } from '../expiration-date-dialog/expiration-date-dialog.component';
import { PublicationDataService } from '../../services/publication-data/publication-data.service';
import { MetadataDataService } from '../../services/metadata-data/metadata-data.service';

@Component({
  selector: 'bell-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})

export class BreadcrumbComponent implements OnInit, OnDestroy {
  public publication: Publication;
  public metadata: Metadata;
  public manual: Manual;
  public ietmSns: IetmSns;
  public ietmManual: IetmManual;
  public ietmDataModule: IetmDataModule;

  public modelName: string;
  public docName: string;
  public fileName: string;
  public expirationDate: string;

  public isIetm: boolean;
  public isDual: boolean;

  public manuals: any[];
  public subscriptions: Subscription[] = [];

  constructor(
    private routeService: RouteService,
    private dialog: MatDialog,
    private router: Router,
    private tocService: TocService,
    private ietmSnsService: IetmSnsService,
    private ietmManualService: IetmManualService,
    private ietmDataModuleService: IetmDataModuleService,
    private userProductService: UserProductService,
    private metadataService: MetadataDataService,
    private publicationService: PublicationDataService
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.ietmSnsService.sns$.subscribe(
        (sns) => {
          this.snsChange(sns);
        }
      )
    );

    this.subscriptions.push(
      this.ietmManualService.manual$.subscribe(
        (manual) => {
          this.ietmManualChange(manual);
        }
      )
    );

    this.subscriptions.push(
      this.ietmDataModuleService.dataModule$.subscribe(
        (dm) => {
          this.ietmModuleChange(dm);
        }
      )
    );

    this.isIetm = (this.routeService.getParameter('pmc') !== null);
    this.getPathinfo();

    this.subscriptions.push(
      this.router.events.subscribe(
        (event: Event) => {
          if (event instanceof NavigationEnd) {
            this.isIetm = (this.routeService.getParameter('pmc') !== null);

            if (!this.isIetm) {
              this.getPathinfo();
            }
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(
      (s) => {
        if (s) {
          s.unsubscribe();
        }
      }
    );
  }

  snsChange(sns: IetmSns) {
    this.ietmSns = sns;
    if (this.ietmSns) {
      this.userProductService.getModelExpirationDate(this.ietmSns.model).subscribe(
        (data) => {
          this.expirationDate = data;
        }
      );
    }
  }

  async ietmManualChange(manual: IetmManual) {
    this.ietmManual = manual;
  }

  async ietmModuleChange(ietmModule: IetmDataModule) {
    this.ietmDataModule = ietmModule;
  }

  async getPathinfo() {
    const modelName = this.routeService.getParameter('model');
    const manualName = this.routeService.getParameter('manual');
    const fileName = this.routeService.getParameter('filename');
    this.isDual = !!this.routeService.getParameter('pageleft');

    await this.processManualName(manualName, fileName);

    this.processModelName(modelName);
    this.userProductService.getModelExpirationDate(this.modelName).subscribe(
      (data) => {
        this.expirationDate = data;
      }
    );
  }

  processModelName(modelName: string) {
    // only change and process model if different from previous
    if (modelName && modelName !== this.modelName) {
      this.modelName = modelName;

      // load the model's manuals for the manuals selection arrow
      this.tocService.getModelToc(this.modelName).subscribe(
        (data) => {
          this.manuals = data.toc;
        }
      );
    }
  }

  async processManualName(manualName: string, fileName: string): Promise<void> {
    if (manualName) {
      this.publication = undefined;
      this.tocService.getAllManuals().subscribe((allManuals) => {
        if (allManuals) {
          this.manual = allManuals.find(m => m.toc === manualName);
          if (!this.manual) {
            this.manual = this.getCustomManual(manualName);
          }
        }
      });
      // load data only if new or changed
    } else if (fileName && fileName !== this.fileName) {
      this.publicationService.getPublicationData(fileName).subscribe(
        (publication) => {
          if (publication) {
            this.publication = publication;
            this.tocService.getAllManuals().subscribe((allManuals) => {
              if (allManuals) {
                this.manual = allManuals.find(m => m.id_manual === this.publication.id_manual);
              }
            });
          }
        }
      );

      this.metadataService.getMetadataData(fileName).subscribe(
        (metadata) => {
          if (metadata) {
            this.processMetadata(metadata);
          }
        }
      );
    }

    if (!manualName && !fileName) {
      this.manual = undefined;
    }

    this.fileName = fileName;
  }

  processMetadata(metadata: Metadata) {
    if (metadata) {
      this.metadata = metadata;
      if (this.metadata.breadcrumb && this.metadata.breadcrumb.length > 0) {
        this.docName = this.metadata.breadcrumb.pop().title;
      }
    } else {
      this.docName = undefined;
    }
  }

  getCustomManual(manualName: string): Manual {
    let manualTitle = 'Unknown';

    switch (manualName) {
      case 'OTHER':
        manualTitle = 'Software Downloads';
        break;
      case 'VIDEO':
        manualTitle = 'Video';
        break;
      case 'AUDIO':
        manualTitle = 'Audio';
        break;
      default:
        break;
    }

    return { manual: manualTitle, toc: manualName } as Manual;
  }

  openExpirationDateDialog(): void {
    this.dialog.open(
      ExpirationDateDialogComponent,
      {
        data: {
          expirationDate: this.expirationDate
        }
      }
    );
  }
}
