import { Injectable } from '@angular/core';
import { observable, Observable, of, Subscriber } from 'rxjs';
import { PlatformMethod, platformMethodImplementations } from 'src/app/shared/services/offline/platform-methods';
import { PlatformService, PlatformType } from 'src/app/shared/services/offline/platform.service';
import { ipcRenderer, webFrame } from 'electron';
import * as fs from 'fs';
import { EnvironmentService } from 'src/app/shared/services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class ElectronPlatformService extends PlatformService {
  private ipcRenderer: typeof ipcRenderer;
  private fs: typeof fs;
  private methodCallCount: {[key in PlatformMethod]?: number} = {};

  webFrame: typeof webFrame;
  // childProcess: typeof childProcess;
  defaultUserDocumentsFolder: string;
  paths: {documents: string, appData: string};
  constructor(
    private environmentService: EnvironmentService,
  ) {
    super();
    const win = this.getWindow();
    this._platform = navigator.userAgent.toLowerCase().indexOf(' electron/') > -1 ? PlatformType.ELECTRON : PlatformType.WEB;
    if (this.isOfflineCapable() && this.platform === PlatformType.ELECTRON) {
      this.ipcRenderer = win.require('electron').ipcRenderer;
      this.webFrame = win.require('electron').webFrame;
      //this.paths = win.shellapi.paths;
      // this.childProcess = win.require('child-process');
      this.fs = win.require('fs');
      let remote = win.require('electron').remote;
      if (remote) {
        console.log('Warning: Old version of electron in use. Please update to the newest offline viewer.');
        this.paths = {
          documents: remote.app.getPath('documents'),
          appData: remote.app.getPath('appData')
        }
      } else {
        this.paths = this.ipcRenderer.sendSync('app-paths');
      }
      this.createBaseFolders();
    }
    this._platformInitialized.next(true);
  }

  private async createBaseFolders() {
    const win = this.getWindow();


    if (win.process.platform === 'win32') {
      const bellAppDataDirectory = `${this.getLocalAppDataPath(this.paths.appData)}\\Bell`;

      if (!this.fs.existsSync(bellAppDataDirectory)) {
        this.fs.mkdirSync(bellAppDataDirectory);
      }

      let techPubsAppDataDocumentsDirectory = `${bellAppDataDirectory}\\TechPubs`;

      if (!this.fs.existsSync(techPubsAppDataDocumentsDirectory)) {
        this.fs.mkdirSync(techPubsAppDataDocumentsDirectory);
      }


      if (this.environmentService.getEnvironmentName().toString().toUpperCase() !== 'PROD') {
        // tslint:disable-next-line: max-line-length
        techPubsAppDataDocumentsDirectory = `${bellAppDataDirectory}\\TechPubs\\${this.environmentService.getEnvironmentName().toString()}`;

        if (!this.fs.existsSync(techPubsAppDataDocumentsDirectory)) {
          this.fs.mkdirSync(techPubsAppDataDocumentsDirectory);
        }
      }

      // Expose a shorthand property for user-specific documents folder
      this.defaultUserDocumentsFolder = techPubsAppDataDocumentsDirectory;

    } else {
      const bellUserDocumentsDirectory = `${this.paths.documents}\\Bell`;

      if (!this.fs.existsSync(bellUserDocumentsDirectory)) {
        this.fs.mkdirSync(bellUserDocumentsDirectory);
      }

      let techPubsUserDocumentsDirectory = `${bellUserDocumentsDirectory}\\TechPubs`;

      if (!this.fs.existsSync(techPubsUserDocumentsDirectory)) {
        this.fs.mkdirSync(techPubsUserDocumentsDirectory);
      }

      if (this.environmentService.getEnvironmentName().toString().toUpperCase() !== 'PROD') {
        // tslint:disable-next-line: max-line-length
        techPubsUserDocumentsDirectory = `${bellUserDocumentsDirectory}\\TechPubs\\${this.environmentService.getEnvironmentName().toString()}`;

        if (!this.fs.existsSync(techPubsUserDocumentsDirectory)) {
          this.fs.mkdirSync(techPubsUserDocumentsDirectory);
        }
      }

      // Expose a shorthand property for user-specific documents folder
      this.defaultUserDocumentsFolder = techPubsUserDocumentsDirectory;
    }
    this.ipcRenderer.send('documents-folder', this.defaultUserDocumentsFolder);
  }

  private getLocalAppDataPath(appData: string) {
    let appDataLower = appData.toLowerCase();
    const roamingIndex = appDataLower.search('roaming');
    const localLowIndex = appDataLower.search('locallow');

    if (roamingIndex > 0) {
      appDataLower = appDataLower.slice(0, roamingIndex);
    } else if (localLowIndex > 0) {
      appDataLower = appDataLower.slice(0, localLowIndex);
    }

    return `${appDataLower}local`;
  }



  invokePlatformMethod<T>(method: PlatformMethod, ...args: any): Observable<T> {
    console.log('invoke platform method: ' + method)
    let implementation = platformMethodImplementations[method];
    this.methodCallCount[method] = this.methodCallCount[method] ? this.methodCallCount[method] + 1 : 1;
    if (method === PlatformMethod.getIcn) {
      return this.getIcnHandler(args[0], args[1]) as Observable<any>;
    }
    if(method === PlatformMethod.getModelFilters){
      return this.getModelFilterHandler() as Observable<any>;
    }
    return new Observable(observer => {
      if (implementation.electron.listen) {
        this.ipcRenderer.on(implementation.electron.listen,(e, arg: T) => {
          this.methodCallCount[method]--;
          observer.next(arg);
          observer.complete();
          if (this.methodCallCount[method] === 0) {
            this.ipcRenderer.removeAllListeners(implementation.electron.listen);
          }
        });
      } else {
        observer.next();
        observer.complete();
      }
      this.ipcRenderer.send(implementation.electron.send, ...args);
    })

  }


  // special case for getIcnHandler (for now);
  private getIcnHandler(model: string, icn: string): Observable<{modelName: string, icnName: string, fileContent: string}> {


    return new Observable((observer) => {
      this.ipcRenderer.on(platformMethodImplementations.getIcn.electron.listen, (e, modelName: string, icnName: string, fileContent: string) => {
        observer.next({modelName: modelName, icnName: icnName, fileContent: fileContent});
        observer.complete();
        this.methodCallCount.getIcn--;
        if (this.methodCallCount.getIcn === 0) {
          this.ipcRenderer.removeAllListeners(platformMethodImplementations.getIcn.electron.listen);
        }
      });
      this.ipcRenderer.send(platformMethodImplementations.getIcn.electron.send, model, icn);
    })
  }

  private getModelFilterHandler() : Observable<{modelFilter:string}> {
    return new Observable((observer) => {
      this.ipcRenderer.on(platformMethodImplementations.getModelFilters.electron.listen, (e, modelFilter : string) => {
        observer.next({modelFilter : modelFilter});
        observer.complete();
        this.methodCallCount.getModelFilters--;
        if(this.methodCallCount.getModelFilters === 0) {
          this.ipcRenderer.removeAllListeners(platformMethodImplementations.getModelFilters.electron.listen);
        }
      });
      this.ipcRenderer.send(platformMethodImplementations.getModelFilters.electron.send);
    })
  }

  /**
   *
   * @param filePath The relative filepath to the file to write. Forward slashes are recommended.
   * @param fileBuffer A file buffer to write to the specified path.
   */
  override writeFile(filePath: string, fileBuffer: Buffer): Observable<boolean> {
    return new Observable(observer => {
      this.fs.writeFile(this.getElectronPath(filePath), fileBuffer,(err) => this.electronBoolResponseHandler(observer, err));
    });
  }

  /**
   *
   * @param filePath The relative filepath to the file to read. Forward slashes are recommended.
   */
  override readFile(filePath: string): Observable<Buffer> {
    return new Observable(observer => {
      this.fs.readFile(this.getElectronPath(filePath),(err, data) => {
        if (err) {
          console.log(err);
          observer.error(err);
        } else {
          observer.next(data);
        }
        observer.complete();
      })
    });
  }

    /**
   *
   * @param path The relative filepath to the file to read. Forward slashes are recommended.
   */
    override checkExists(path: string): Observable<boolean> {
      // TODO: change logic to not depend on race condition, and remove this method
      return of(this.fs.existsSync(this.getElectronPath(path)));
    }

  /**
   *
   * @param filePath The relative filepath to the file to remove. Forward slashes are recommended.
   */
  override removeFile(filePath: string): Observable<boolean> {
    return new Observable(observer => {
      this.fs.unlink(this.getElectronPath(filePath), (err) => this.electronBoolResponseHandler(observer, err));
    });
  }

  /**
   *
   * @param folderPath The relative path to the folder to create. Forward slashes are recommended.
   */
  override createDirectory(folderPath: string): Observable<boolean> {
    return new Observable(observer => {
      this.fs.mkdir(this.getElectronPath(folderPath), {recursive: true}, (err) => this.electronBoolResponseHandler(observer, err));
    });
  }

  /**
   *
   * @param folderPath The relative path to the folder to recursively delete. Forward slashes are recommended.
   */
  override removeDirectory(folderPath: string): Observable<boolean> {

    return new Observable(observer => {
      this.fs.rmdir(this.getElectronPath(folderPath), {recursive: true}, (err) => this.electronBoolResponseHandler(observer, err))
    });
  }

  // This is needed to get the correct global object whether running under Chrome, Electron, or Karma-Electron
  private getWindow() {
    const win = (<any>window);
    if (win.process) {
      return win;
    } else {
      return win.top;
    }
  }

  private electronBoolResponseHandler(subject: Subscriber<boolean>, err?: NodeJS.ErrnoException) {
    if (err) {
      console.log(err);
      subject.error(err);
    } else {
      subject.next(true);
    }
    subject.complete();
  }

  private getElectronPath(path: string) {
    if (path.includes('/')) {
      path = path.replace('/', '\\');
    };
    return this.defaultUserDocumentsFolder + '\\' + path;
  }
}
