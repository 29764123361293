import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IetmFault } from '../../../../shared/data-model/ietm-fault';
import { IetmDataService } from 'src/app/shared/services/ietm/ietm-data.service';

@Injectable({
  providedIn: 'root'
})
export class IetmFaultService {

  constructor(
    private ietmDataService: IetmDataService,
  ) { }

  getIetmFromFaultCode(model: string, code: string): Observable<IetmFault> {
    return this.ietmDataService.getIetmFromFaultCode(model, code);
  }
}
