import { Injectable } from '@angular/core';
import { OfflineService } from '../offline/offline.service';
import { DataService } from '../data-services/data.service';
import { Observable, BehaviorSubject, Observer } from 'rxjs';
import { OfflineZipReaderService } from '../offline/offline-zip-reader.service';
import { filter, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IcnDataService {
  private loadedIcn$: Record<string, BehaviorSubject<string>> = {};
  private loadedIcnImage$: Record<string, BehaviorSubject<string>> = {};
  constructor(
    private offlineService: OfflineService,
    private dataService: DataService,
    private offlineZipReaderService: OfflineZipReaderService,
  ) { }

  getIcn(modelName: string, icn: string): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      this.getIcnDataSubjects(modelName, icn).pipe(filter(f => f !== undefined)).subscribe(data => {
        observer.next(data);
        observer.complete();
      });
    });
  }

  getIcnImage(modelName: string, icn: string): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      this.getIcnImageDataSubjects(modelName, icn).pipe(filter(f => f !== undefined)).subscribe(data => {
        observer.next(data);
        observer.complete();
      });
    });
  }

  private toBlobUrl(blob: Blob): any {
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
  }

  private getIcnServerData(modelName: string, icn: string) {
    if (this.loadedIcn$[`${modelName}-${icn}`]) {
      let icn$: Observable<string>;
      let modelFamily$: Observable<string>;
      if (!this.offlineService.isOfflineCapable) {
        icn$ = this.dataService.getIcn(modelName, icn);
      } else {
        if (this.offlineService.isOffline) {
          modelFamily$ = this.offlineZipReaderService.getModelFiltersElectron(modelName);
          icn$ = modelFamily$.pipe(switchMap(modelfamily => this.offlineZipReaderService.getIcn(modelfamily, icn))) 
        } else {
          icn$ = this.dataService.getIcn(modelName, icn);
        }
      }
      icn$.subscribe((data: string) => {
        this.loadedIcn$[`${modelName}-${icn}`].next(data);
      });
    }
  }

  private getIcnDataSubjects(modelName: string, icn: string): BehaviorSubject<string> {
    if (!this.loadedIcn$[`${modelName}-${icn}`]) {
      this.loadedIcn$[`${modelName}-${icn}`] = <BehaviorSubject<string>> new BehaviorSubject(undefined);
      this.getIcnServerData(modelName, icn);
      return this.loadedIcn$[`${modelName}-${icn}`];
    } else {
      return this.loadedIcn$[`${modelName}-${icn}`];
    }
  }

  private getIcnImageServerData(modelName: string, icn: string) {
    if (this.loadedIcnImage$[`${modelName}-${icn}`]) {
      let icn$: Observable<Blob>;
      let modelFamily$: Observable<string>;
      if (!this.offlineService.isOfflineCapable) {
        icn$ = this.dataService.getIcnImage(modelName, icn);
      } else {
        if (this.offlineService.isOffline) {
          modelFamily$ = this.offlineZipReaderService.getModelFiltersElectron(modelName);
          icn$ = modelFamily$.pipe(switchMap(modelfamily => this.offlineZipReaderService.getIcnImage(modelfamily, icn))) 
        } else {
          icn$ = this.dataService.getIcnImage(modelName, icn);
        }
      }

      icn$.subscribe((data) => {
        if (data) {
          this.loadedIcnImage$[`${modelName}-${icn}`].next(this.toBlobUrl(data));
        } else {
          this.loadedIcnImage$[`${modelName}-${icn}`].next(null);
        }
      });
    }
  }

  private getIcnImageDataSubjects(modelName: string, icn: string): BehaviorSubject<string> {
    if (!this.loadedIcnImage$[`${modelName}-${icn}`]) {
      this.loadedIcnImage$[`${modelName}-${icn}`] = <BehaviorSubject<string>> new BehaviorSubject(undefined);
      this.getIcnImageServerData(modelName, icn);
      return this.loadedIcnImage$[`${modelName}-${icn}`];
    } else {
      return this.loadedIcnImage$[`${modelName}-${icn}`];
    }
  }
}
