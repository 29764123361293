import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { ServiceBase } from '../service-base.service';
import { LoginService } from '../user/login.service';
import { SessionTimeoutModalService } from '../session-timeout-modal/session-timeout-modal.service';
import { IdleService } from '../idle/idle.service';
import { IdleState, SessionTimeoutModalData } from '../../data-model/session-timeout';
import { OfflineService } from '../offline/offline.service';

@Injectable({
  providedIn: 'root'
})

export class SessionTimeoutService extends ServiceBase {
  public sessionTimeoutModalData = new SessionTimeoutModalData();
  public idleState = new IdleState();
  public lastPing?: Date = null;
  public login: boolean;
  public timeout: number;
  public idleTimeout: number;

  public timeoutPeriod = 300;

  constructor(
    private idleService: IdleService,
    private modalSessionTimeoutService: SessionTimeoutModalService,
    private loginService: LoginService,
    private offlineService: OfflineService
  ) {
    super();
    this.offlineService.offlineEvent$.subscribe(
      (isOffline) => {
        if (isOffline) {
          this.stop();
        }
      }
    );

    this.offlineService.stateEvent$.subscribe(
      (state) => {
        if (state && this.offlineService.isOfflineCapable && !this.offlineService.isOffline) {
          this.reset();
        }
      }
    );

    this.modalSessionTimeoutService.modalOpen$.subscribe(
      (open) => {
        if (open) {
          this.modalSessionTimeoutService.modalSessionTimeoutRef.afterClosed().subscribe(
            (closed) => {
              if (closed) {
                this.continueSession();
              } else {
                this.logoutSession();
              }
            }
          );
        }
      }
    );

    this.idleService.onIdleEnd.subscribe(
      () => {
        this.sessionTimeoutModalData.idleState = this.idleState.onSignOut;
        this.reset();
        this.modalSessionTimeoutService.handleSessionTimeoutModal(this.sessionTimeoutModalData);
      }
    );

    this.idleService.onTimeout.subscribe(
      () => {
        this.sessionTimeoutModalData.idleState = this.idleState.onTimeout;
        this.modalSessionTimeoutService.handleSessionTimeoutModal(this.sessionTimeoutModalData);
        this.logoutSession();
      }
    );

    this.idleService.onIdleStart.subscribe(
      () => {
        this.sessionTimeoutModalData.idleState = this.idleState.onIdleStartSignOut;
        this.modalSessionTimeoutService.handleSessionTimeoutModal(this.sessionTimeoutModalData);
      }
    );

    this.idleService.onTimeoutWarning.subscribe(
      (countdown) => {
        if (this.idleService.getIdle() > 10) {
          this.sessionTimeoutModalData.continue = true;
        } else {
          this.sessionTimeoutModalData.continue = false;
        }

        if (countdown <= 10) {
          this.sessionTimeoutModalData.continue = false;
        }

        this.sessionTimeoutModalData.idleState = this.idleState.onTimeoutWarning(countdown);
        this.modalSessionTimeoutService.handleSessionTimeoutModal(this.sessionTimeoutModalData);
      }
    );
  }

  public initSessionTimeout() {
    this.setIdleService();
    this.reset();
  }

  public reset() {
    if (this.loginService.isUserLoggedInOrOffline()) {
      this.idleService.watch();
    } else {
      this.idleService.stop();
    }
  }

  public stop() {
    this.idleService.stop();
  }

  public continueSession() {
    this.initSessionTimeout();
  }

  public logoutSession() {
    this.reset();
    this.idleService.stop();
    this.loginService.logout();
  }

  private setIdleService() {
    this.timeout = this.getTimeoutFromToken();
    let idleTimeout = 0.01;
    let timeoutPeriod;

    if (this.timeout >= (this.timeoutPeriod * 2)) {
      timeoutPeriod = this.timeoutPeriod;
    }

    if (this.timeout < (this.timeoutPeriod * 2)) {
      if (this.timeout >= this.timeoutPeriod) {
        timeoutPeriod = this.timeoutPeriod / 5;
      }

      if (this.timeout < this.timeoutPeriod) {
        if (this.timeout >= (this.timeoutPeriod / 2)) {
          timeoutPeriod = this.timeoutPeriod / (5 * 2);
        }

        if (this.timeout < (this.timeoutPeriod / 2)) {
          if (this.timeout >= (this.timeoutPeriod / 4)) {
            timeoutPeriod = this.timeoutPeriod / (5 * 4);
          }

          if (this.timeout < (this.timeoutPeriod / 4)) {
            timeoutPeriod = this.timeoutPeriod / (5 * 6);
          }
        }
      }
    }

    if ((this.timeout - timeoutPeriod) > 0) {
      idleTimeout = this.timeout - timeoutPeriod;
    }

    this.idleService.setIdle(idleTimeout);
    this.idleService.setTimeout(timeoutPeriod);
  }

  private getTimeoutFromToken() {
    const toSeconds = 1000;
    const now = Date.now();
    let exp = this.loginService.getSessionInfo()?.expiration ?? 0;
    if (exp) {
      return Math.round(((exp * 1000) - now) / toSeconds)
    } else {
      return 1;
    }
  }
}
