import { Component, OnInit, ViewEncapsulation, Input, ViewChild, ElementRef } from '@angular/core';
import { Manual } from '../../shared/data-model/manual';
import { UserProductService } from 'src/app/shared/services/user/user-product.service';
import { RevisionStatusDataService } from 'src/app/shared/services/revision-status/revision-status-data.service';
import { RevisionStatusModel } from 'src/app/shared/data-model/revision-status';

@Component({
  selector: 'bell-revision-status',
  templateUrl: './revision-status.component.html',
  styleUrls: ['./revision-status.component.scss'],
})

export class RevisionStatusComponent implements OnInit {
  @ViewChild('revisionTable', { static: true }) private revisionTable: ElementRef;
  selectedModel: string;
  models: RevisionStatusModel[];
  manuals: Manual[];
  selected = false;
  visVis = {
    'visibility': 'visible',
    'opacity': 1,
    'transition': 'visibility 0s linear 0s, opacity 0.5s'
  };
  visHide = {
    'visibility': 'hidden',
    'opacity': 0
  };

  constructor(
    private productService: UserProductService,
    private revisionStatusDataService: RevisionStatusDataService
  ) { }

  ngOnInit() {
    this.getModels();
    this.models = [];
    this.manuals = [];
  }

  getModels() {
    this.revisionStatusDataService.getRevisionStatus()
      .subscribe(data => {
        this.models = data.models;
        this.manuals = data.revisionsStatus;
      });
  }

  getManuals(){
    this.revisionStatusDataService.getRevisionStatusByModel(this.selectedModel)
    .subscribe(data => {
      this.manuals = JSON.parse(JSON.stringify(data))["revisionStatus"];
    })
  }

  changeModel(event) {
    this.selected = true;
    this.selectedModel = event.value;
    this.manuals = [];
    this.getManuals();
  }
}
