import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { Subscription } from 'rxjs';
import { EnvironmentService } from 'src/app/shared/services/environment.service';
import { OfflineService } from 'src/app/shared/services/offline/offline.service';
import { PlatformService, PlatformType } from 'src/app/shared/services/offline/platform.service';
import { LoginService } from 'src/app/shared/services/user/login.service';
import { UserProductService } from 'src/app/shared/services/user/user-product.service';
import { getNavigationLinks, NavigationLink } from './navigation-links';

@Component({
  selector: 'bell-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {
  private hasMarketPlaceAccess = false;
  private hasCommunityAccess = false;
  public hasPublishingAccess = false;
  public isScreenWidthSmall = false;
  public menuCollapsed = false;
  public isAdmin = false;
  public scrollHideNav = false;
  public scrollShowNav = false;
  public navbarNotHover = false;
  public subIconMenuCollapsed = true;
  public iconMenuCollapsed = true;
  public shouldHideExternalLinks = false;

  public mqScreenWidth = {
    mobile: 768,
    tablet: 1200
  };

  public userFirstName = '';
  public myAccountLink = '';
  public myCompanyLink = '';
  public currentUrl = '';
  public hybrisUrl = '';

  public activeTab: NavigationLink;
  public scrollTop: any;
  public navLinks = [];
  public routerSubscription: Subscription;

  constructor(
    public offlineService: OfflineService,
    private render2: Renderer2,
    private loginService: LoginService,
    private router: Router,
    private envService: EnvironmentService,
    private userProductService: UserProductService,
    private platformService: PlatformService
  ) {}

  ngOnInit() {
    this.navLinks = getNavigationLinks(this.envService);
    this.scrollTop = document.getElementById('tpNavigationBar').scrollTop;
    this.menuCollapsed = true;
    this.isScreenWidthSmall = this.getIsScreenWidthSmall(window.innerWidth);
    this.shouldHideExternalLinks = this.platformService.platform === PlatformType.MAUI;

    if (this.offlineService.isOffline) {
      this.currentUrl = this.router.url;
      this.routerSubscription = this.router.events.subscribe(
        (event: Event) => {
          if (event instanceof NavigationEnd) {
            this.currentUrl = event.url;
          }
        }
      );
    } else {
      this.setAccountAccess()
    }
  }

  private setAccountAccess() {
    this.myAccountLink = this.envService.getAccountUrl();
    this.myCompanyLink = this.envService.getCompanyUrl();
    this.hybrisUrl = this.envService.getHybrisUrl();

    for (let i = 0; i < this.navLinks.length; i++) {
      if (this.navLinks[i].label === 'Technical Publications') {
        this.activeTab = this.navLinks[i];
        break;
      }
    }
    this.userFirstName = this.loginService.getSessionInfo()?.givenName ?? '';
    this.hasPublishingAccess = this.loginService.getSessionInfo()?.workViewer ?? false;

    this.userProductService.getUserProduct().subscribe(p => {
      this.isAdmin = p.adminAccess;
      this.hasMarketPlaceAccess = p.marketplaceTpAccess;
      this.hasCommunityAccess = p.salesforceCommunityAccess;
      if (!this.hasMarketPlaceAccess) {
        this.getPermissionAccess('Marketplace', this.hasMarketPlaceAccess);
      }
      if (!this.hasCommunityAccess) {
        this.getPermissionAccess('Community', this.hasCommunityAccess);
      }
    })
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  getPermissionAccess(permission: string, access: boolean) {
    if (!access) {
      for (let i = 0; i < this.navLinks.length; i++) {
        if (this.navLinks[i].label.toLowerCase() === permission.toLowerCase()) {
          this.navLinks.splice(i, 1);
        }
      }
    }
  }

  getSelectedIndex() {
    let selectedIndex: number;
    for (let i = 0; i < this.navLinks.length; i++) {
      if (this.navLinks[i].active) {
        selectedIndex = i;
      }
    }

    return selectedIndex;
  }

  toggleMenu() {
    this.menuCollapsed = !this.menuCollapsed;
    !this.menuCollapsed ?
      this.render2.setAttribute(document.body, 'style', 'overflow: hidden')  :
      this.render2.removeAttribute(document.body, 'style');
  }

  toggleIconMenu() {
    this.iconMenuCollapsed = !this.iconMenuCollapsed;
  }

  toggleSubIconMenu() {
    this.subIconMenuCollapsed = !this.subIconMenuCollapsed;
  }

  getIsScreenWidthSmall(screenWidth) {
    return (screenWidth <= this.mqScreenWidth.mobile);
  }

  activateTab(link) {
    this.activeTab = link;
  }

  onResize(event) {
    const isNewWidthSmall = this.getIsScreenWidthSmall(event.target.innerWidth);
    if (isNewWidthSmall !== this.isScreenWidthSmall) {
      this.isScreenWidthSmall = isNewWidthSmall;
    }

    if (!this.iconMenuCollapsed) {
      this.toggleIconMenu();
    }

    if (event.target.innerWidth >= this.mqScreenWidth.tablet) {
      if (!this.subIconMenuCollapsed) {
        this.toggleSubIconMenu();
      }

      if (!this.menuCollapsed) {
        this.toggleMenu();
      }
    }
  }

  onScroll(event) {
    const navHeight = document.getElementById('tpNavigationBar').offsetHeight;
    let scrolled: number;

    if (event.target.scrollingElement) {
      scrolled = event.target.scrollingElement.scrollTop;
    } else {
      scrolled = document.documentElement.scrollTop;
    }

    if (this.scrollTop !== scrolled) {
      if (this.scrollTop < scrolled) {
        if (scrolled >= navHeight) {
          if (scrolled <= (navHeight * 2)) {
            this.scrollShowNav = true;
          } else {
            this.scrollShowNav = false;
            this.scrollHideNav = true;
          }
        }

        this.scrollTop = scrolled;
      }

      if (this.scrollTop > scrolled) {
        if (scrolled <= navHeight) {
          this.scrollShowNav = false;
        } else {
          this.scrollShowNav = true;
        }

        this.scrollHideNav = false;
        this.scrollTop = scrolled;
      }
    }
  }

  goOffline(): void {
    this.offlineService.changeIsOffline(true);
  }

  logout(): void {
    this.loginService.logout();
  }

  toggleDropdown(i: number, set = -1) {
    if (set === -1) {
      this.navLinks[i].expanded = !(this.navLinks[i].expanded);
    } else {
      this.navLinks[i].expanded = (set !== 0);
    }
  }

  setWorkEnvironment(value: boolean) {
    this.envService.setIsWorkEnvironment(value);
    location.reload();
  }
}
