import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router, NavigationEnd, Event, Scroll } from '@angular/router';
import { RouteService } from 'src/app/shared/services/route.service';
import { IETM_VIEW_MODE } from '../../ietm-modes/ietm-view-mode.enum';
import { IETM_TOC_VIEW_MODE } from '../../ietm-modes/ietm-toc-view-mode.enum';
import { IETM_FIGURE_VIEW_MODE } from '../../ietm-modes/ietm-figure-view-mode.enum';

@Injectable({
  providedIn: 'root'
})
export class IetmRouteService {

  private _model:    BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _pmc:      BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _dmc:      BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _icn:      BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _istp:     BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _tocView:  BehaviorSubject<IETM_TOC_VIEW_MODE> = new BehaviorSubject<IETM_TOC_VIEW_MODE>(null);
  private _three:    BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _snsthree:    BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _filetype: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _refdes:   BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _scrollTarget:  BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _view:     BehaviorSubject<IETM_VIEW_MODE> = new BehaviorSubject<IETM_VIEW_MODE>(null);
  private _figureView:     BehaviorSubject<IETM_FIGURE_VIEW_MODE> = new BehaviorSubject<IETM_FIGURE_VIEW_MODE>(null);
  private _isThreeViewToLoad: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private props = ['model', 'pmc', 'dmc', 'icn', 'filetype', 'three', 'snsthree', 'istp', 'tocView', 'refdes', 'view', 'figureView'];
  private oldThree = '';
  private oldsnsThree = '';

  public model$:      Observable<string> = this._model.asObservable();
  public pmc$:        Observable<string> = this._pmc.asObservable();
  public dmc$:        Observable<string> = this._dmc.asObservable();
  public icn$:        Observable<string> = this._icn.asObservable();
  public istp$:       Observable<string> = this._istp.asObservable();
  public tocView$:    Observable<IETM_TOC_VIEW_MODE> = this._tocView.asObservable();
  public three$:      Observable<string> = this._three.asObservable();
  public snsthree$:      Observable<string> = this._snsthree.asObservable();
  public filetype$:   Observable<string> = this._filetype.asObservable();
  public refdes$:     Observable<string> = this._refdes.asObservable();
  public scrollTarget$: Observable<string> = this._scrollTarget.asObservable();
  public view$:       Observable<IETM_VIEW_MODE> = this._view.asObservable();
  public figureView$: Observable<IETM_FIGURE_VIEW_MODE> = this._figureView.asObservable();
  public isThreeViewToLoad$: Observable<boolean> = this._isThreeViewToLoad.asObservable();

  get model(): string { return this._model.value; }
  get pmc(): string { return this._pmc.value; }
  get dmc(): string { return this._dmc.value; }
  get icn(): string { return this._icn.value; }
  get istp(): string { return this._istp.value; }
  get tocView(): IETM_TOC_VIEW_MODE { return this._tocView.value; }
  get three(): string { return this._three.value; }
  get snsthree(): string { return this._snsthree.value; }
  get filetype(): string { return this._filetype.value; }
  get refdes(): string { return this._refdes.value; }
  get scrollTarget(): string { return this._scrollTarget.value; }
  get view(): IETM_VIEW_MODE { return this._view.value; }
  get figureView(): string { return this._figureView.value; }

  constructor(
    public router: Router,
    public routeService: RouteService
  ) {
    this.router.events.subscribe(value => this.handleRoute(value));
  }

  handleRoute(value: Event) {
    const isIetm = this.router.url.indexOf('ietm') >= 0;
    if (value instanceof Scroll) {
      if (value.anchor !==  this._scrollTarget.value) {
        this._scrollTarget.next(value.anchor);
      }
    }
    if (value instanceof NavigationEnd) {
      if (isIetm) {
        this.oldThree = this.three;
        this.oldsnsThree = this.snsthree;
        this.props.forEach(prop => {
          const newValue = this.getParam(prop);
          if (newValue !== this[`_${prop}`].value) {
            this[`_${prop}`].next(newValue);
          }
        });
        if ((this.three || this.snsthree) && (this.figureView === IETM_FIGURE_VIEW_MODE.SNSTHREE || this.figureView === IETM_FIGURE_VIEW_MODE.THREE)) {
          if (this._isThreeViewToLoad.value !== true || this.oldThree !== this.three || this.oldsnsThree !== this.snsthree) {
            this._isThreeViewToLoad.next(true);
          }
        } else {
          if (this._isThreeViewToLoad.value !== false) {
            this._isThreeViewToLoad.next(false);
          }
        }
      } else { // reset all value on navigation outside ietm
        this.props.forEach(prop => {
          this[`_${prop}`].next(undefined);
        });
      }
    }
  }

  private getParam(param: string) {
    let parameter = this.routeService.getParameter(param);
    if (!parameter) {
      parameter = this.routeService.getQueryParameter(param);
    }
    return parameter;
  }
}
