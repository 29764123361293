<section class="news">
  <ng-container *ngIf="news && news !== null && !offlineService.isOffline">
    <section class="news__header">
      <div class="news__title">
        <h1>COMMERCIAL PUBLICATIONS</h1>
      </div>
      <div class="news__sub-title">
        <h3 *ngIf="news.lastUpdate">
          LATEST UPDATES - {{news.lastCheck}}
        </h3>
        <p *ngIf="news.lastUpdate && news.lastCheck">
          (Previous update was done on {{news.previousUpdate}})
          <br> Last check on server: {{news.lastUpdate}}
        </p>
      </div>
    </section>
    <section class="news__content">
      <ng-container *ngIf="news.content && news.content.length !== 0">
        <div class="update" *ngFor="let content of news.content; let isLast = last">
          <div class="update__header">
            <a routerLink="/toc/{{content.model}}">Model: {{content.modelName}}</a>
          </div>
          <div class="update__content">
            <div class="publication" *ngFor="let publication of content.publications">
              <div class="publication__title">
                <a class="publication__link publication__link--icon doc"
                  routerLink="/{{publication.isS1000d ? 'ietm' : 'doc'}}/{{content.model}}/{{publication.filename}}"
                  [queryParams]="{ tocView: getTocViewParam(publication) }">
                  {{publication.name}} - {{publication.title}}
                  <div class="publication__issue">
                    {{publication.issue}}
                  </div>
                </a>
              </div>
            </div>
            <hr class="models-divider" *ngIf="!isLast" />
          </div>
        </div>
      </ng-container>

      <div class="news__no-recent" *ngIf="!news.content || news.content.length === 0">
        There are no recent documents posted for your models.
      </div>

    </section>
    <p class="news__footer" [innerHTML]="news.footer" *ngIf="news.footer"></p>
  </ng-container>

  <div class="news__title" *ngIf="offlineService.isOffline">
    <h1>COMMERCIAL PUBLICATIONS</h1>
  </div>
</section>
