import { IetmDataModule, IetmIcn } from '../services/ietm-service/ietm-model';

export class IetmPrintModalDataModel {
  printOptions: Array<string>;
  pageTitle: string;
  title: string;
  subtitle: string;
  model: string;
  docTittle: string;
  docType: string;
  isPrinting: boolean;
  hasFigures: boolean;
  textOnly: boolean;
  loadFail: boolean;
  loadSuccess: boolean;
  dataModule: IetmDataModule;
  icn: IetmIcn;

  constructor() {
    this.printOptions = [
      'Text Only',
      'Figure Only',
      'Both Text And Figure'
    ];
    this.isPrinting = false;
    this.hasFigures = false;
    this.textOnly = true;
  }
}
