import { Component, OnInit } from '@angular/core';
import { NotFoundSnackbarComponent } from 'src/app/shared/components/not-found-snackbar/not-found-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpFileDownloadService } from 'src/app/shared/services/http-progress-event/http-file-download.service';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'bell-weight-balance',
  templateUrl: './weight-balance.component.html',
  styleUrls: ['./weight-balance.component.scss']
})
export class WeightBalanceComponent implements OnInit {
  weightBalanceLinks = [
    { id: '10684', label: 'Report - Weight and Balance'},
    { id: '10685', label: 'Amendment - Weight and Balance'},
    { id: '10686', label: 'Addendum - Weight and Balance'},
    { id: '10687', label: 'Weight Change Sheet - Weight and Balance'},
  ];

  constructor(
    private httpFileDownloadService: HttpFileDownloadService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
  }

  getPdf(pdf: {id: string, label: string}) {
    this.httpFileDownloadService.getPDF(pdf.id, pdf.label).subscribe(
      (data) => {
        if (data && data.type === HttpEventType.Response) {
          return data;
        }
      },
      (error) => {
         this.snackBar.openFromComponent(NotFoundSnackbarComponent, {
           data: 'Error - Unable to retrieve PDF file.'
         });
      }
    );
  }
}
