export class IdleStorageAltService implements Storage {
  private storageMap: any = {};

  get length() {
    return Object.keys(this.storageMap).length;
  }

  clear(): void {
    this.storageMap = {};
  }

  getItem(key: string): string | null {
    if (typeof this.storageMap[key] !== 'undefined') {
      return this.storageMap[key];
    }
    return null;
  }

  key(index: number): string | null {
    return Object.keys(this.storageMap)[index] || null;
  }

  removeItem(key: string): void {
    this.storageMap[key] = undefined;
  }

  setItem(key: string, value: string): void {
    this.storageMap[key] = value;
  }

  [key: string]: any;
  [index: number]: string;
}
