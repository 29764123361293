import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouteService } from 'src/app/shared/services/route.service';
import { IetmFault } from 'src/app/shared/data-model/ietm-fault';
import { IETM_TOC_VIEW_MODE } from '../ietm-modes/ietm-toc-view-mode.enum';
import { IetmFaultService } from '../services/ietm-service/ietm-fault.service';

@Component({
  selector: 'bell-ietm-fault',
  template: ''
})

export class IetmFaultComponent implements OnInit {

  constructor(
    private ietmFaultService: IetmFaultService,
    private router: Router,
    private routeService: RouteService
  ) { }

  ngOnInit() {
    const model = this.routeService.getParameter('model');
    const code = this.routeService.getParameter('code');
    const serial = this.routeService.getParameter('serial');
    this.getIetmRoute(model, code, serial);
    // navigate to IETM either way, if no fault found.
    setTimeout(() => {
      this.navigateToIetm(model);
    });
  }

  navigateToIetm(model) {
    this.router.navigate(['ietm', model, 'sns']);
  }

  getIetmRoute(model: string, code: string, serial: string) {
    this.ietmFaultService.getIetmFromFaultCode(model, code)
      .subscribe((data: IetmFault) => {
        if (data) {
          this.router.navigate(['ietm', model, data.pmc, data.dmc], {
            queryParams: {
              'serial': serial,
              'fault': code,
              'tocView': IETM_TOC_VIEW_MODE.MANUAL
            }
          });
        }
      });
  }
}
