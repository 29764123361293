import { Subscription } from 'rxjs';
import { InterruptEventArgs } from './interrupt-event-args';
import { InterruptSourceBaseEvent } from '../base/interrupt-source-base-event';

export class InterruptEventManager {
  private sub: Subscription;

  constructor(
    public source: InterruptSourceBaseEvent
  ) {}

  subscribe(fn: (args: InterruptEventArgs) => void): void {
    this.sub = this.source.onInterrupt.subscribe(fn);
  }

  unsubscribe(): void {
    this.sub.unsubscribe();
    this.sub = null;
  }

  resume(): void {
    this.source.attach();
  }

  pause(): void {
    this.source.detach();
  }
}
