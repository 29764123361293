import { Inject, Component } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'bell-http-event-modal',
  templateUrl: './http-event-modal.component.html',
  styleUrls: ['./http-event-modal.component.scss'],
})
      
export class HttpEventModalComponent  {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

}
