<div class="search" (window:resize)="onResize($event)">
  <div class="search__bar">
    <section class="search__section search__breadcrumb">
      <bell-breadcrumb>
        <ng-content></ng-content>
      </bell-breadcrumb>
    </section>
    <section class="search__section menu">
      <section class="menu__section">
        <section class="general-info">
          <div class="dropdown" [ngClass]="{'active': !dropdownCollapse}">
            <div class="dropdown__button">
              <button mat-flat-button class="dropdown__label link" (click)="toggleDropdown()" *ngIf="!offlineService.isOffline">
                <div class="link__label">General Info</div>
                <i class="link__icon icon-infomation-circle"></i>
              </button>
            </div>
            <div class="dropdown__content" (mouseleave)="dropdownCollapse=true" (mouseenter)="dropdownCollapse=false">
              <ul class="dropdown__options" [attr.aria-expanded]="!dropdownCollapse">
                <ng-container *ngFor="let link of generalInfoLinks">
                   <li class="dropdown__item">
                     <a mat-menu-item [routerLink]="[link.path]" *ngIf="link.target === 'component'" (click)="toggleDropdown()">
                      {{link.label}}
                     </a>
                     <button mat-menu-item *ngIf="link.target === 'pdf'" (click)="openPDF(link)">
                      {{link.label}}
                     </button>
                   </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </section>
      </section>
      <section class="menu__section">
        <a mat-flat-button class="link" [routerLink]="['/offlinelib']" *ngIf="!offlineService.isOfflineCapable && !offlineService.shouldHideOffline">
          <div class="link__label">Offline Library</div>
          <div class="link__icon icon-cloud-download"></div>
        </a>
      </section>
      <section class="menu__section">
        <a mat-flat-button class="link" [routerLink]="['/myofflinelib']" *ngIf="offlineService.isOfflineCapable && !offlineService.isOffline">
          <div class="link__label">My Offline Library</div>
          <div class="link__icon icon-cloud-download"></div>
        </a>
      </section>
      <section class="menu__section">
        <a mat-flat-button class="link" [routerLink]="['/parts']">
          <div class="link__label">My Parts List</div>
          <div class="link__icon icon-clipboard-2"></div>
        </a>
      </section>
      <section class="menu__section">
        <section class="search__wrapper">
          <input class="tp-input search__input"  type="search" name="search-query" (keydown.enter)="search()" (search)="search()" placeholder="Search" [(ngModel)]="searchQuery" ngDefaultControl/>
          <a title="Search" ngClass="search__icon{{(searchQuery == '' || !searchQuery) ? ' disabled' : ''}}" (click)="search()">
            <i class="toolbar-search-icon bell-icon bell-icon-search"></i>
          </a>
        </section>
      </section>
    </section>
  </div>
</div>
