import { NgModule } from '@angular/core';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { ItemsFilterPipe } from './pipes/items-filter.pipe';
import { ItemsSortPipe } from './pipes/items-sort.pipe';
import { DuplicatesFilterPipe } from './pipes/duplicates-filter.pipe';
import { NotFoundSnackbarComponent } from './components/not-found-snackbar/not-found-snackbar.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ExpirationDateDialogComponent } from './components/expiration-date-dialog/expiration-date-dialog.component';
import { PrintModule } from './components/print/print.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { HttpEventModalComponent } from './components/http-event-modal/http-event-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SessionTimeoutModalComponent } from './components/session-timeout-modal/session-timeout-modal.component';
import { CompositeTableComponent } from './components/composite-table/composite-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [
        BreadcrumbComponent,
        NotFoundSnackbarComponent,
        ExpirationDateDialogComponent,
        SanitizeHtmlPipe,
        ItemsFilterPipe,
        DuplicatesFilterPipe,
        ItemsSortPipe,
        ModalDialogComponent,
        HttpEventModalComponent,
        SessionTimeoutModalComponent,
        CompositeTableComponent
    ],
    exports: [
        BreadcrumbComponent,
        NotFoundSnackbarComponent,
        ExpirationDateDialogComponent,
        SanitizeHtmlPipe,
        ItemsFilterPipe,
        ItemsSortPipe,
        DuplicatesFilterPipe,
        PrintModule,
        ModalDialogComponent,
        HttpEventModalComponent,
        SessionTimeoutModalComponent,
        CompositeTableComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatSelectModule,
        MatIconModule,
        MatSnackBarModule,
        PrintModule,
        MatProgressBarModule,
        MatDialogModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule
    ]
})
export class SharedModule { }
