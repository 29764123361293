<div class="header">
  <div class="tp-image">
    <div class="tp-title">
      <p>Technical Publications</p>
    </div>
  </div>
</div>
<div class="update-content">
  <h1 mat-dialog-title class="modal-title">UPDATING OFFLINE CONTENT</h1>
  <div mat-dialog-content>
    <section id="loadProgressBar">
      <mat-progress-bar mode="determinate" [value]="updateProgress"></mat-progress-bar>
    </section>
  </div>
</div>
