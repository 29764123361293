import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Event } from '@angular/router';
import { RouteService } from 'src/app/shared/services/route.service';
import { TocService } from 'src/app/shared/services/toc/toc.service';
import { UserProductService } from 'src/app/shared/services/user/user-product.service';
import { Subject, Subscription } from 'rxjs';
import { OfflineService } from 'src/app/shared/services/offline/offline.service';
import { Toc } from 'src/app/shared/data-model/toc';
import { OfflineStatusService } from 'src/app/shared/services/offline/offline-status.service';
import { DocumentTree } from 'src/app/shared/data-model/idb';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { EnvironmentService } from 'src/app/shared/services/environment.service';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bell-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy {
  manuals: Toc[];
  models: string[];
  selectedModel: string;
  selectedManual: any;
  modelsWithDownloadedContent: string[];
  updatesAvailable = false;

  routerSubscription: Subscription;
  updateSubscription: Subscription;

  private ngUnsubscribe$: Subject<boolean> = new Subject();

  constructor(
    private productService: UserProductService,
    private tocService: TocService,
    private routeService: RouteService,
    private route: ActivatedRoute,
    private router: Router,
    public offlineService: OfflineService,
    private offlineStatusService: OfflineStatusService,
    private updates: SwUpdate,
    private environmentService: EnvironmentService
  ) { }

  ngOnInit() {
    this.getModels();
    this.trackRoute();

    this.routerSubscription = this.router.events.subscribe(
      (event: Event) => {
        if (event instanceof NavigationEnd) {
          this.trackRoute();
        }
      }
    );
    // only show updates if not in prod
    if (this.offlineService.isOfflineCapable) {
      this.updateSubscription = this.updates.available.subscribe(() => {this.updatesAvailable = true});
      this.updateSubscription = this.updates.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')).subscribe(() => {this.updatesAvailable = true});
    }
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }

    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
  }

  trackRoute() {
    if (this.route.snapshot.children.length > 0) {
      const routeModelName = this.routeService.getParameter('model');
      const routeManualName = this.routeService.getParameter('manual');
      this.selectedModel = routeModelName;
      this.loadManuals(routeManualName);
    }
  }

  getModels() {
    this.productService.getUserModels()
    .subscribe(data => {
      this.models = data;
      if (this.offlineService.isOfflineCapable) {
        this.offlineStatusService.getModelsTree(this.models).subscribe(modelTree => {this.getDownloadedModels(modelTree)})
      }
    });
  }

  getDownloadedModels(docTree: DocumentTree[]) {
    this.modelsWithDownloadedContent = docTree.filter(model => {return model?.usable === 'true' && model?.parent === "/"}).map(m => {return m.path.replace("/","")});
  }

  isUsableOffline(model: string) {
    return this.modelsWithDownloadedContent && this.modelsWithDownloadedContent.some(m => m === model);
  }

  openModelChange(eventSelectOpen: boolean): void {
    if (eventSelectOpen && this.offlineService.isOfflineCapable) {
      this.offlineStatusService.getModelsTree(this.models)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(modelTree => {this.getDownloadedModels(modelTree)});
    }
  }

  changeModel(event) {
    this.selectedModel = event.value;
    this.loadManuals();
    this.router.navigate(['toc', this.selectedModel]);
  }

  loadManuals(manualName?: string) {
    this.manuals = undefined;
    this.selectedManual = undefined;
    if (this.selectedModel) {
      this.tocService.getModelToc(this.selectedModel)
      .subscribe((data) => {
        this.manuals = data.toc;
        if (manualName) {
          this.selectedManual = this.getManualFromManualList(manualName);
        }
      });
    }
  }

  changeManual(event) {
    this.selectedManual = this.getManualFromManualList(event.value);
    if (this.selectedManual) {
      this.router.navigate(['toc', this.selectedModel, this.selectedManual.toc]);
    }
  }

  private getManualFromManualList(toc: string): any {
    if (this.selectedModel && this.manuals) {
      for (let i = 0; i < this.manuals.length; i++) {
        if (this.manuals[i].toc === toc) {
          return this.manuals[i];
        }
      }
    }
    return null;
  }

  reloadPage($event) {
    $event.preventDefault();
    window.location.reload();
    this.updatesAvailable = false;
  }
}
