import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { OfflineService } from '../offline/offline.service';
import { RevisionStatus } from '../../data-model/revision-status';
import { DataService } from '../data-services/data.service';

@Injectable({
  providedIn: 'root'
})
export class RevisionStatusDataService {

  constructor(
    private dataService: DataService,
    private offlineService: OfflineService,
  ) { }

  getRevisionStatus(): Observable<RevisionStatus> {
    return this.dataService.getRevisionStatus();
  }

  getRevisionStatusByModel(model: string): Observable<RevisionStatus> {
    return this.dataService.getRevisionStatusByModel(model);
  }
}
