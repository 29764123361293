import { EnvironmentService } from 'src/app/shared/services/environment.service';


export function getNavigationLinks(env: EnvironmentService): Array<NavigationLink> {
  return [
    {
      label: 'MyBell',
      path: getEnviromentLink('hybrisUrl', env),
      target: '_blank',
      active: false,
      color: 'bell-blue'
    },
    {
      label: 'MarketPlace',
      expanded: false,
      rightAlign: true,
      children: [
        {
          label: 'Buy TechPUBS',
          path: getEnviromentLink('buytechpubsUrl', env),
          target: '_blank',
          tooltip: 'Add to your Technical Publications library'
        }
      ]
    },
    {
      label: 'Technical Publications',
      path: getEnviromentLink('self', env),
      active: true,
      target: '',
      color: 'bell-khaki'
    },
    {
      label: 'Resources',
      expanded: false,
      rightAlign: true,
      children: [
        {
          label: 'Bellflight.com',
          path: 'https://www.bellflight.com',
          target: '_blank',
          tooltip: 'Learn about Bell products'
        },
        {
          label: 'Bellcustomer.com',
          path: 'https://www.bellcustomer.com',
          target: '_blank',
          tooltip: 'Access Technical Bulletins, Alert Service Bulletins, and Instruction Letters'
        },
        {
          label: 'Bell Support and Services',
          path: 'https://www.bellflight.com/support/contact-support',
          target: '_blank',
          tooltip: 'Contact your Local Service Center or Download the Directory'
        },
        {
          label: 'Safran Customer Portal',
          path: 'https://tools.safran-helicopter-engines.com/pg/en/home',
          target: '_blank'
        },
        {
          label: 'Rolls Royce Customer Portal',
          path: 'https://shop.boeing.com/aviation-supply/login?targetUrl=https%3A%2F%2Fshop.boeing.com%2Faviation-supply%2F',
          target: '_blank'
        },
        {
          label: 'Pratt & Whitney Customer Portal',
          path: 'https://www.pwc.ca/en/',
          target: '_blank'
        }
      ]
    },
    {
      label: 'Training',
      expanded: false,
      rightAlign: true,
      children: [
        {
          label: 'Bell Training Academy Overview',
          path: 'https://www.bellflight.com/support/training',
          target: '_blank',
          tooltip: 'Learn more about our global training offerings'
        },
        {
          label: 'Registration',
          path: 'https://bellhelicopter.sabacloud.com/Saba/Web_wdk/PRODTNT083/index/prelogin.rdf',
          target: '_blank',
          tooltip: 'Register for a Bell Training Academy Course'
        }
      ]
    },
    {
      label: 'Community',
      path: getEnviromentLink('communityUrl', env),
      target: '_blank',
      active: false,
      color: 'bell-blue'
    },
    {
      label: 'Help',
      icon: 'help-circle',
      tooltip: 'Help',
      expanded: false,
      children: [
        {
          label: 'Self Help Articles',
          path: 'https://bellhelicopter.force.com/helpcenter/s/',
          target: '_blank',
          tooltip: 'Solutions to Frequently Asked Questions'
        },
        {
          label: 'Contact Us',
          path: getEnviromentLink('contactusUrl', env),
          target: '_blank',
          tooltip: 'Contact Bell for support'
        }
      ]
    }
  ];
}
export function getEnviromentLink(request, envServ: EnvironmentService): any {
  let requestPath;
  switch(request) {
    case 'self': return location.origin + ((envServ.getEnvironmentBaseUrl().toString()).length > 0 ? '/' + envServ.getEnvironmentBaseUrl().toString() : '');
    case 'maintenance': return 'http://bellflight.com/support-and-service/support';
    case 'training': return 'http://bellflight.com/support-and-service/training/online-account';
    case 'help': return 'https://bellhelicopter.force.com/helpcenter/s/';
    case 'environmentName': return envServ.getEnvironmentName();
    case 'environment': return envServ.getEnvironment();
    case 'backendUrl': return envServ.getBackendUrl();
    case 'hybrisUrl': return envServ.getHybrisUrl()
    case 'buytechpubsUrl': return envServ.getBuyTechPubsUrl()
    case 'communityUrl': return envServ.getCommunityUrl()
    case 'contactusUrl': return envServ.getContactUsUrl()
    case 'salesForceUrl': return envServ.getSalesForceUrl()
    case 'marketplaceUrl': return envServ.getMarketplaceUrl()
    case 'accountUrl': return envServ.getAccountUrl()
    case 'companyUrl': return envServ.getCompanyUrl()
    case 'salesForceW2C': return envServ.getSalesForceW2C()
    case 'networkServiceEndpoint': return envServ.getNetworkServiceEndpoint()
    default: return '';
  }
}

export class NavigationLink {
  path?: string;
  label: string;
  color?: string;
  active?: boolean;
  target?: string;
  tooltip?: string;
  icon?: string;
  expanded?: boolean;
  rightAlign?: boolean;
  children?: NavigationLink[]
}
