import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { Router, Event, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/shared/services/user/login.service';

@Component({
  selector: 'bell-side-nav-buttons',
  templateUrl: './side-nav-buttons.component.html',
  styleUrls: ['./side-nav-buttons.component.scss']
})
export class SideNavButtonsComponent implements OnInit, OnDestroy {
  @Input() isMobile: boolean;
  hasMarketPlaceAccess = false;
  currentUrl = '';
  routerSubscription: Subscription;

  constructor(
    private router: Router,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    this.hasMarketPlaceAccess = this.loginService.getSessionInfo()?.marketplaceAdmin ?? false;
    this.currentUrl = this.router.url;
    this.routerSubscription = this.router.events.subscribe(
      (event: Event) => {
        if (event instanceof NavigationEnd) {
          this.currentUrl = event.url;
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
