import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, ErrorHandler } from '@angular/core';

@Injectable()
export class ErrorService implements ErrorHandler {
    constructor() {}

    handleError(error) {
        // Write to console
        console.error(error);

        throw error;
    }

    handleObservableError(error: Response) {
        // Write to console
        console.error(error);

        // Throw error to the front end
        return observableThrowError(error || 'Server error');
    }
}
