import { Injectable } from '@angular/core';
import { OfflineZipReaderService } from './offline-zip-reader.service';
import { Observable, of, Observer, ObservableInput, forkJoin, observable } from 'rxjs';
import { DocumentService } from './dexie/document.service';
import { isArray } from 'util';
import { DocumentNode, Dm } from '../../data-model/idb';
import { SearchResult } from '../../data-model/search-results';
import { DmService } from './dexie/dm.service';
import { constants } from 'buffer';

@Injectable({
  providedIn: 'root'
})
export class OfflineSearchService {
  private ipbManual = -1;
  private cCodeManual = -2;
  private modelFilters: any[] = null;
  constructor(
    private offlineZipReader: OfflineZipReaderService,
    private documentService: DocumentService,
    private dmService: DmService,
  ) { }

  getSearchResults(query: string, option: string): Observable<SearchResult[]> {
    return new Observable((observer: Observer<SearchResult[]>) => {
      const words = query.split(';')[0].replace(/[^A-Za-z0-9\-\s/]/g, '');
      const wordList = words.split(' ');
      const manualSearch$ = this.searchManuals(wordList, option);
      const dmSearch$ = this.searchDMs(wordList, option);
      const ipbSearch$ = this.searchIPB(wordList, option);
      const cCodeSearch$ = this.searchCCodes(wordList, option);
      this.getModelFilters().subscribe(() => {
        forkJoin({ docs: manualSearch$, dms: dmSearch$, ipbDocs: ipbSearch$, cCodeResults: cCodeSearch$ }).subscribe((data) => {
          const allResults: SearchResult[] = [].concat(this.mapResults(data.docs, words, option),
            this.mapResults(data.dms, words, option), this.mapResults(data.ipbDocs, words, option), data.cCodeResults);
          observer.next(allResults);
        });
      });
    });
  }

  getDocumentSearchResults(query: string, option: string, path?: string, fileName?: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const words = query.split(';')[0].replace(/[^A-Za-z0-9\-\s/]/g, '');
      const wordList = words.split(' ');
      let mappedResults = [];
      this.offlineZipReader.getDocumentSearchIndex(fileName).subscribe((data) => {
        if (data && data !== null) {
          wordList.forEach(word => {
            mappedResults = mappedResults.concat(this.searchIndexForDocument(word, data.indexes, option));
          });
        }
        if (data && data.words) {
          observer.next(this.mapDocunentSearch(mappedResults, data.words));
          observer.complete();
        } else {
          observer.next({});
          observer.complete();
        }
      });
    });
  }

  private mapDocunentSearch(searchResults: any[], words: string[]): any {
    let previousResult, formatedResults, result, nextResult, page, formatedPage;
    const results = searchResults.map((value) => {
      const splitResult = value.split(':');
      return {
        page: parseInt(splitResult[0], 10),
        seq: splitResult[1].split(',').map((seq) => {
          return parseInt(seq, 10);
        })
      };
    });
    results.sort((a, b) => a.page - b.page);

    previousResult = {
      page: 'BEGIN'
    };
    formatedResults = {
      BEGIN: { previousPage: 'none', nextPage: 'END' },
      END: { nextPage: 'none' }
    };

    for (let i = 0; i < results.length; i++) {
      result = results[i];
      if (result.page !== previousResult.page) {
        nextResult = formatedResults[result.page] = {
          nextPage: 'END',
          page: result.page,
          previousPage: previousResult.page,
          seq: result.seq
        };
        formatedResults[previousResult.page].nextPage = result.page;
        previousResult = nextResult;
      } else {
        formatedResults[previousResult.page].seq = formatedResults[previousResult.page].seq.concat(result.seq);
      }
    }
    formatedResults.END.previousPage = previousResult.page;
    for (page in formatedResults) {
      if (formatedResults.hasOwnProperty(page) && page !== 'BEGIN' && page !== 'END') {
        formatedPage = formatedResults[page];
        formatedPage.seq.sort();
        formatedPage.firstSentence = this.buildFirstSentence(formatedPage.seq[0], words[page - 1]);
      }
    }
    return formatedResults;
  }

  private buildFirstSentence(firstSeq: any, words: any) {
    const maxWords = 6,
      startSeq = Math.max(firstSeq - maxWords, 0),
      endSeq = Math.min(firstSeq + maxWords, words.length);
    let firstSentence;
    if (firstSeq > 0) {
      firstSentence = words.slice(startSeq, firstSeq - 1).join(' ')
        + ' <span style="font-weight:bold;">' + words[firstSeq] + '</span> '
        + words.slice(firstSeq + 1, endSeq - 1).join(' ');
    } else {
      firstSentence = ' <span style="font-weight:bold;">' + words[firstSeq] + '</span> '
        + words.slice(firstSeq + 1, endSeq - 1).join(' ');
    }
    return firstSentence;
  }

  private searchManuals(words: string[], option: string): Observable<DocumentNode[]> {
    return new Observable((observer: Observer<any>) => {
      this.searchByType(words, 'Manuals', option).subscribe((results) => {
        this.documentService.table.where('idFile').anyOfIgnoreCase(results).toArray().then((docs) => {
          observer.next(docs);
          observer.complete();
        });
      });
    });
  }

  private searchIndexForDocument(keyWord: string, indexes: any, searchOption: string): any[] {
    let results: any[] = [];
    if (searchOption === 'exact') {
      if (indexes.hasOwnProperty(keyWord)) {
        results = indexes[keyWord];
      }
    } else {
      for (const [key, value] of Object.entries(indexes)) {
        if (key.indexOf(keyWord) !== -1) {
          if (Array.isArray(value)) {
            results = results.concat(value);
          }
        }
      }
    }
    return results;
  }

  private searchDMs(words: string[], option: string): Observable<any[]> {
    return new Observable((observer: Observer<any>) => {
      this.searchByType(words, 'DMs', option).subscribe((results) => {
        const getdms$ = this.dmService.getByIdDms(results);
        const docunent$ = this.documentService.getAll();
        forkJoin({ dms: getdms$, documents: docunent$ }).subscribe((data) => {
          observer.next(this.mapDMModels(data.dms, data.documents));
          observer.complete();
        });
      });
    });
  }

  private mapDMModels(dms: Dm[], documents: DocumentNode[]) {
    dms.forEach((dm) => {
      const doc = documents.filter((d) => d.idFile === dm.idFile && d.idManual === dm.idManual);
      if (doc && doc.length > 0) {
        dm['models'] = doc[0].models;
        dm.lastUpdate = doc[0].lastUpdate;
      } else {
        dm['models'] = '';
      }
    });
    return dms.filter((dm, index, dmResult) => dmResult.findIndex(result => (result.title === dm.title)) === index);
  }

  private searchIPB(words: string[], option: string): Observable<any[]> {
    return new Observable((observer: Observer<any>) => {
      const ipbDescriptions$ = this.offlineZipReader.getIndexIPBDescriptions();
      const search$ = this.searchByType(words, 'IPB', option);
      forkJoin({ descriptions: ipbDescriptions$, searchResults: search$ }).subscribe(data => {
        const docIdMaps: Record<string, any[]> = {};
        const docIds = this.getDocumentIdFromIpbId(data.searchResults, data.descriptions, docIdMaps);
        this.documentService.table.where('idFile').anyOfIgnoreCase(docIds).toArray().then((docs) => {
          observer.next(this.mapIPBDocs(docs, docIdMaps));
          observer.complete();
        });
      });
    });
  }

  private mapIPBDocs(docs: DocumentNode[], docIdMaps: Record<string, any[]>): any[] {
    const ipbDocs: any[] = [];
    docs.forEach((d) => {
      docIdMaps[d.idFile].forEach(ipbResult => {
        ipbDocs.push({
          rowid: d.rowid,
          title: ipbResult.title,
          figure: ipbResult.figure,
          idManual: this.ipbManual,
          name: d.name,
          availableUpdate: d.availableUpdate,
          lastUpdate: d.lastUpdate,
          selectionCount: d.selectionCount,
          size: d.size,
          models: d.models,
          idFile: d.idFile,
          type: d.type
        });
      });
    });
    return ipbDocs;
  }

  private getDocumentIdFromIpbId(ipbIds: string[], ipdDescriptions: any, docIdMaps: Record<string, any[]>): string[] {
    const docIds: string[] = [];
    ipbIds.forEach((ipdId) => {
      if (ipdDescriptions[ipdId]) {
        if (docIds.indexOf(ipdDescriptions[ipdId].file) === -1) {
          docIds.push(ipdDescriptions[ipdId].file);
          if (!docIdMaps[ipdDescriptions[ipdId].file]) {
            docIdMaps[ipdDescriptions[ipdId].file] = [{
              'title': ipdDescriptions[ipdId].title,
              'figure': ipdId.slice(ipdId.indexOf('-') + 1)
            }];
          }
        } else {
          const currentItem = docIdMaps[ipdDescriptions[ipdId].file];
          currentItem.push({ 'title': ipdDescriptions[ipdId].title, 'figure': ipdId.slice(ipdId.indexOf('-') + 1) });
          docIdMaps[ipdDescriptions[ipdId].file] = currentItem;
        }
      }
    });
    return docIds;
  }

  private searchCCodes(words: string[], option: string): Observable<SearchResult[]> {
    return new Observable((observer: Observer<any>) => {
      const cCodes$ = this.offlineZipReader.getCodes();
      const search$ = this.searchByType(words, 'CCodes', option);
      forkJoin({ cCodes: cCodes$, searchResults: search$ }).subscribe(data => {
        observer.next(this.mapCCodes(data.searchResults, data.cCodes));
        observer.complete();
      });
    });
  }

  private mapCCodes(cCodeIds: string[], cCodes: any): SearchResult[] {
    const results: SearchResult[] = [];
    cCodeIds.forEach((c) => {
      if (cCodes[c]) {
        const result = new SearchResult();
        const nomen = cCodes[c].nomen.replace(/<[^>]+>/g, '');
        result.title = c + ', ' + nomen;
        result.idManual = this.cCodeManual;
        result.ccode = c;
        result.models = ['ALL'];
        results.push(result);
      }
    });
    return results;
  }

  private searchByType(words: string[], type: string, option: string): Observable<string[]> {
    return new Observable((observer: Observer<any>) => {
      const mappedResults = {};
      const search$ = [];
      words.forEach((word) => {
        search$.push(this.searchOneKeyword(word.toLowerCase(), type, option, mappedResults));
      });

      forkJoin(search$).subscribe((searchStatuses: boolean[]) => {
        observer.next(Object.keys(mappedResults));
        observer.complete();
      });
    });
  }

  private searchOneKeyword(keyWord: string, type: string, searchOption: string, mappedResults: any): Observable<boolean> {
    return new Observable((observer: Observer<any>) => {
      if (keyWord.length >= 2) {
        let prefix = keyWord.slice(0, 2);
        // If prefix contains a special character, use the __ prefix instead.
        if (/[^\w]/.test(prefix)) {
          prefix = '__';
        }
        this.offlineZipReader.getSearchIndex(prefix, type).subscribe((indexes) => {
          this.searchIndex(keyWord, indexes, searchOption, mappedResults);
          observer.next(true);
          observer.complete();
        });
      }
    });
  }

  private searchIndex(keyWord: string, indexes: any, searchOption: string, mappedResults: any) {
    if (keyWord.length >= 2 && indexes !== undefined && indexes !== null) {

      if (searchOption === 'exact') {
        if (indexes.hasOwnProperty(keyWord)) {
          this.addAll(indexes[keyWord], mappedResults);
        }
      } else {
        for (const [key, value] of Object.entries(indexes)) {
          if (key.startsWith(keyWord)) {
            if (Array.isArray(value)) {
              this.addAll((value as Array<string>), mappedResults);
            }
          }
        }
      }
    }
  }

  private addAll(elements: string[], map: string[]) {
    elements.forEach(function (item) {
      map[item] = true;
    });
  }

  private getModelFilters(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (this.modelFilters === null) {
        this.offlineZipReader.getModelFilters().subscribe((data) => {
          this.modelFilters = data;
          observer.next(true);
          observer.complete();
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }
  private mapResults(docs: any[], words: string, option: string) {
    const searchResults: SearchResult[] = [];
    docs.forEach((data) => {
      const sr = new SearchResult();
      sr.title = data.title;
      sr.name = data.name;
      sr.lastUpdate = data.lastUpdate;
      sr.idManual = data.idManual;
      sr.s1000d = data.s1000d;
      sr.figure = data.figure;
      sr.momodel = data.momodel;
      sr.code = data.code;
      sr.models = this.mapModels(data.models);
      searchResults.push(sr);
    });
    return searchResults;
  }

  private mapModels(modelNumbers: string): string[] {
    const models: string[] = [];
    const modelNumberList = modelNumbers.split(',');
    if (modelNumberList && modelNumberList.length > 0) {
      modelNumberList.forEach(m => {
        const mfs = this.modelFilters.filter((mf) => mf.id_model === m);
        if (mfs && mfs.length > 0) {
          models.push(mfs[0].model);
        }
      });
    }
    return models;
  }
}
