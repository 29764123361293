import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bell-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  footLinks = [
    { path: 'http://www.bellflight.com/legal/terms-of-use', label: 'Terms and Conditions', target: '_blank' },
    // { path: '/contact', label: 'Help', target: '' },
    { path: '/contact', label: 'Contact Us', target: 'component' },
    { path: '/about', label: 'About Publications', target: 'component'}
  ];

  constructor() { }

  ngOnInit() {
  }

}
