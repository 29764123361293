import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginType } from 'src/app/shared/enums/login-types';
import { ApiUriService } from 'src/app/shared/services/api-uri.service';
import { DataService } from 'src/app/shared/services/data-services/data.service';
import { EnvironmentService } from 'src/app/shared/services/environment.service';
import { OfflineService } from 'src/app/shared/services/offline/offline.service';
import { PlatformMethod } from 'src/app/shared/services/offline/platform-methods';
import { PlatformService } from 'src/app/shared/services/offline/platform.service';
import { LoginService } from 'src/app/shared/services/user/login.service';

@Injectable({
  providedIn: 'root'
})
export class ElectronLoginService extends LoginService {
  displayLogin = false;

  constructor(
    environmentService: EnvironmentService,
    apiUri: ApiUriService,
    offlineService: OfflineService,
    private platformService: PlatformService,
    router: Router,
    http: HttpClient
  ) {
    super(environmentService, apiUri, router, http, offlineService)
  }

  public override login(type?: LoginType) {
    if (!type) {
      this.router.navigate(['login']);
      return;
    }
    this.offlineService.changeIsOffline(type === LoginType.OFFLINE);
    if (type === LoginType.OFFLINE) {
      this.offlineService.changeIsOffline(true);
      return;
    }
    if(this.offlineService.isOfflineCapable && type === LoginType.MYBELL) {
      this.loginNodeless(this.getLoginUrl(type));
    } else {
      window.location.assign(this.getLoginUrl(type));
    }
  }

  // MyBell login requires a window in electron that does not have node loaded
  private loginNodeless(loginURL: string) {
    if(this.offlineService.isOfflineCapable && !this.offlineService.isOffline) {
      this.platformService.invokePlatformMethod(PlatformMethod.login, loginURL, document.baseURI).subscribe();
    }
  }
}
