import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { TocComponent } from './toc.component';
import { TocModelComponent } from './model/toc-model.component';
import { TocManualComponent } from './manual/toc-manual.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [
    TocComponent,
    TocModelComponent,
    TocManualComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatSelectModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatCheckboxModule
  ],
  exports: [
    TocComponent
  ]
})
export class TocModule { }
