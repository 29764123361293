import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { OfflineZipReaderService } from '../offline/offline-zip-reader.service';
import { OfflineService } from '../offline/offline.service';
import { DataService } from '../data-services/data.service';

@Injectable({
  providedIn: 'root'
})

export class CCodeService {
  backEndUrl: string;
  allCCodes: any;

  constructor(
    private dataService: DataService,
    private offlineZipReaderService: OfflineZipReaderService,
    private offlineService: OfflineService,
  ) {
    this.allCCodes = {};
  }

  getCCodes(cCodeSelected): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      if (this.requestCCodes(cCodeSelected)) {
        let cCode$: Observable<any>;
        if (this.offlineService.isOffline) {
          cCode$ = this.offlineZipReaderService.getCodes();
        } else {
          cCode$ = this.dataService.getCCodes();
        }
        if (cCode$) {
          cCode$.subscribe((data) => {
            this.allCCodes = data;
            observer.next(this.allCCodes[cCodeSelected]);
            observer.complete();
          });
        } else {
          observer.next({});
          observer.complete();
        }
      } else {
        observer.next(this.allCCodes[cCodeSelected]);
        observer.complete();
      }
    });
  }

  private requestCCodes(cCodeSelected) {
    if (this.allCCodes === {}) {
      return true;
    }
    if (!this.allCCodes.hasOwnProperty(cCodeSelected)) {
      return true;
    }
    return false;
  }
}
