<div *ngIf="!isActivating && !docErrorMessage">
  <h1 mat-dialog-title class="modal-title">UPDATING OFFLINE CONTENT</h1>
  <hr>
  <div mat-dialog-content *ngFor="let estimate of updateEstimates">
    <p *ngIf="estimate.dataSize">Estimated Download Size: {{ estimate.dataSize }}</p>
    <p *ngIf="estimate.dataTime">Estimated Download Time: {{ estimate.dataTime * 1000 | date: 'H:mm:ss':'UTC' }}</p>
    <p *ngIf="estimate.diskSpaceFree !== 'NaN undefined'">Estimated Disk Space Available: {{ estimate.diskSpaceFree }}
    </p>
    <p *ngIf="estimate.dataDelete">Number of documents you are about to delete: {{ estimate.dataDelete }}</p>
    <p>Would you like to start the update?</p>
  </div>
  <div class="buttons-group">
    <button mat-flat-button class="start-update" [disabled]="isLoading" (click)="yesLibraryUpdate()">Yes</button>
    <button mat-flat-button class="start-update" (click)="cancelModal()" >Cancel</button>
  </div>
  <section id="loadProgressBar" *ngIf="isLoading">
    <mat-progress-bar mode="determinate" [value]="updateProgress">
    </mat-progress-bar>
  </section>
  <div *ngIf="isLoading" class="progress-description">
    <p *ngIf="totalSizeString && totalDocsDownloadTime">Approximately {{totalSizeString}} remaining</p>
    <p *ngIf="impatient">Downloading and processing large files - this may take a few minutes</p>
  </div>
</div>
<div *ngIf="isActivating">
  <button class="tp-button modal-close" (click)="cancelActivate()">
    <div class="icon-close"></div>
  </button>
  <h1 mat-dialog-title class="modal-title">ACTIVATE OFFLINE</h1>
  <hr class="activate-hr">
  <p>Would you like to activate your offline library?</p>
  <div class="buttons-group">
    <button mat-flat-button class="start-update" [disabled]="isLoading" (click)="yesActivate()" >Yes Activate</button>
    <button mat-flat-button class="start-update" (click)="cancelActivate()" >Cancel</button>
  </div>
  <section id="downloadProgressBar" *ngIf="isLoading">
    <mat-progress-bar mode="determinate" [value]="updateProgress">
    </mat-progress-bar>
  </section>
</div>
<div class="error error__modal" *ngIf="docErrorMessage">
  <div class="error__title">
    <h1 mat-dialog-title class="modal-title">DOWNLOAD ERROR</h1>
    <hr class="error__hr">
  </div>
  <div class="error__text">
    <p>There was an error downloading some or all of your documents.
    <span *ngIf="downloadErrorDocs.length > 1"> These documents did not download correctly:</span>
    <span *ngIf="downloadErrorDocs.length <= 1"> This document did not download correctly:</span></p>
  </div>
  <div class="error__list">
    <p class="error__doc-title" *ngFor="let err of downloadErrorDocs">
      <span *ngIf="err">{{ err.title }}</span>
    </p>
  </div>
  <div class="error__actions">
    <p class="error__text">Please click <strong>Start Update</strong> and try again.</p>
    <div class="error__btn">
      <button mat-flat-button class="start-update" (click)="closeXModal()">Ok</button>
    </div>
  </div>
</div>
