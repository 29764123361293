import { Component, OnInit } from '@angular/core';
import { News, NewsPublication } from '../shared/data-model/news';
import { NewsService } from '../shared/services/news/news.service';
import { OfflineService } from '../shared/services/offline/offline.service';
import { RouteService } from '../shared/services/route.service';

@Component({
  selector: 'bell-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  news: News;
  tocView = this.routeService.getQueryParameter('tocView');

  constructor(
    private newsService: NewsService,
    private routeService: RouteService,
    public offlineService: OfflineService
  ) {
    this.news = new News();
    this.news.content = [];
  }

  ngOnInit() {
    this.createNewsModels();
  }

  createNewsModels() {
    this.newsService.getNews().subscribe(data => {
      this.news = data;
    });
    if (this.news) {
      const header = (this.news.header) ? this.news.header : '';
      const footer = (this.news.footer) ? this.news.footer : '';
      if (header.length > 0) {
        const endOfFooter = header.split('-');
        const newFooter = footer + ' End of' + endOfFooter[1];
        this.news.footer = newFooter;
      }
    }
  }

  getTocViewParam(link: NewsPublication) {
    let tocView = this.routeService.getQueryParameter('tocView');
    if (link.isS1000d && (this.tocView === undefined || this.tocView === null)) {
      tocView = 'manual';
    }
    return tocView;
  }
}
