import { Observable, timer, throwError, of } from 'rxjs';
import { mergeMap, finalize } from 'rxjs/operators';

// Retry strategy pulled from link:
// https://www.learnrxjs.io/operators/error_handling/retrywhen.html

export const retryStrategy = ({
  maxRetryAttempts = 2,
  scalingDuration = 1000,
  excludedStatusCodes = [401, 403, 404]
}: {
  maxRetryAttempts?: number,
  scalingDuration?: number,
  excludedStatusCodes?: number[]
} = {}) => (attempts: Observable<any>) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;
      // if maximum number of retries have been met
      // or response is a status code we don't wish to retry, throw error
      if (retryAttempt > maxRetryAttempts ||
        excludedStatusCodes.find(e => e === error.status)) {
        return throwError(error);
      }
      // console.log(
      //   `Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`
      // );
      // retry after 1s, 2s, etc...
      return timer(retryAttempt * scalingDuration);
    }),
    finalize(() => {} /* console.log('We are done!') */)
  );
};
