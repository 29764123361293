import { ApplicationRef, ComponentFactoryResolver, Inject, Injectable, Injector } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IetmService } from 'src/app/viewer/ietm/services/ietm.service';
import { IetmPrintModalDataModel } from '../../ietm-data-model/ietm-print-modal-data';
import { IetmPrintFigureDataModel } from '../../ietm-data-model/ietm-print-figure-data';
import { IetmTocService } from '../../services/ietm-toc.service';
import { IetmRouteService } from '../../services/ietm-service/ietm-route.service';
import { IetmIcnService } from '../../services/ietm-service/ietm-icn.service';
import { IetmIcn } from '../../services/ietm-service/ietm-model';
import { IcnDataService } from 'src/app/shared/services/icn/icn-data.service';
import { Observable, Observer, forkJoin } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { PlatformService, PlatformType } from 'src/app/shared/services/offline/platform.service';
import { PlatformMethod } from 'src/app/shared/services/offline/platform-methods';
import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { IetmPrintPortalComponent } from '../ietm-print-portal/ietm-print-portal.component';

@Injectable({
  providedIn: 'root'
})
export class IetmPrintService {
  public ietmPrintModalData: IetmPrintModalDataModel;
  public originUrl: string;
  public printMainContainer: HTMLElement;
  public printFigureContainer: HTMLElement;
  public allFigures: Array<IetmPrintFigureDataModel>;
  public allIcn: Array<IetmIcn>;
  public allDmcIcn: Array<string>;

  private printPortal: ComponentPortal<IetmPrintPortalComponent>;
  private bodyPortalHost: DomPortalOutlet;

  private readonly window: Window;

  constructor(
    @Inject(DOCUMENT) document: Document,
    private router: Router,
    private ietmService: IetmService,
    private sanitizer: DomSanitizer,
    private ietmTocService: IetmTocService,
    private ietmRoute: IetmRouteService,
    private ietmIcnService: IetmIcnService,
    private icnDataService: IcnDataService,
    private platformService: PlatformService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {
    this.window = document.defaultView;
    this.bodyPortalHost = new DomPortalOutlet(
      document.body,
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );
    this.ietmService.dataModule$.subscribe(newDataModule => {
        this.handleDataModule(newDataModule);
      });
  }

  handleDataModule(newDataModule: any) {
    this.ietmPrintModalData = new IetmPrintModalDataModel();
    if (!newDataModule) {
      this.ietmPrintModalData.loadSuccess = false;
      this.ietmPrintModalData.loadFail = true;
      return;
    }

    this.ietmPrintModalData.dataModule = newDataModule;
    this.ietmPrintModalData.loadFail = false;
    this.ietmPrintModalData.pageTitle = newDataModule.pageTitle;
    this.ietmPrintModalData.docTittle = newDataModule.pageTitle.replace(/ - |\/| /g, '-').toLowerCase();
    this.ietmPrintModalData.title = newDataModule.title;
    this.ietmPrintModalData.subtitle = newDataModule.subtitle;
    this.ietmPrintModalData.model = newDataModule.model;
    this.ietmPrintModalData.loadSuccess = true;

    this.checkForIcn();
  }

  checkForIcn() {
    this.ietmTocService.getDmcIcn(this.ietmRoute.model, this.ietmRoute.pmc, this.ietmRoute.dmc).subscribe(
      (dmcIcn) => {
        if (dmcIcn && dmcIcn !== null) {
          if (dmcIcn.figures && dmcIcn.figures.length > 0) {
            this.allDmcIcn = [];
            const allFigures = dmcIcn.figures;

            for (let i = 0; i < allFigures.length; i++) {
              const icn = allFigures[i].sheets[0].icn;
              this.allDmcIcn.push(icn);
            }

            this.getAllIcnModules(this.ietmRoute.model);
          }
        }
      }
    );
  }

  private getAllIcnModules(model) {
    this.allIcn = [];

    for (let i = 0; i < this.allDmcIcn.length; i++) {
      const icnModule = this.ietmIcnService.getIetmIcn(model, this.allDmcIcn[i]);
      this.allIcn.push(icnModule);
    }

    if (this.allIcn[0] && this.allIcn[0].icn) {
      this.ietmPrintModalData.icn = this.allIcn[0];
      this.ietmPrintModalData.hasFigures = true;
      this.ietmPrintModalData.textOnly = false;
    }
  }

  private handleFigureSheets(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const model = this.ietmPrintModalData.icn.model;
      const figures = [];

      let icnSheets = [];
        for (let m = 0; m < this.allIcn.length; m++) {
          const sheets = this.allIcn[m].sheets;
          const icnTitle = this.allIcn[m].title;
          const totalSheets = this.allIcn[m].sheets.length;
          const currentFigure = this.allIcn[m].currentFigure;

          for (let n = 0; n < sheets.length; n++) {
            const sheet = sheets[n];
            sheet['title'] = icnTitle;
            sheet['totalSheets'] = totalSheets;
            sheet['currentFigure'] = currentFigure;
            icnSheets.push(sheet);
          }
        }

      const imageCalls$: Observable<boolean>[] = [];
      for (let sheet = 0; sheet < icnSheets.length; sheet++) {
        const figureSheet = icnSheets[sheet];
        const isIcnSvg = this.isIcnSvg(figureSheet.icn);
        const sheetOf = 'Sheet ' + figureSheet.position + ' of ' + figureSheet.totalSheets;
        const title = 'Figure ' + figureSheet.currentFigure + '. ' + figureSheet.title + ' (' + sheetOf + ')';
        figures[sheet] = new IetmPrintFigureDataModel();
        figures[sheet].model = model;
        figures[sheet].title = title;
        figures[sheet].sheet = sheetOf;
        figures[sheet].icn = figureSheet.icn;
        figures[sheet].position = figureSheet.position;
        figures[sheet].isIcnSvg = isIcnSvg;
        figures[sheet].elementId = this.icnElementIdGenerator();

        if (isIcnSvg) {
          imageCalls$.push(this.setSvg(figures[sheet], title, figureSheet.icn, model));
        } else {
          imageCalls$.push(this.setImage(figures[sheet], title, figureSheet.icn, model));
        }
      }

      if (imageCalls$.length > 0) {
        forkJoin(imageCalls$).subscribe((datas) => {
          observer.next(figures);
          observer.complete();
        });
      } else {
        observer.next(figures);
        observer.complete();
      }
    });
  }

  private setSvg(figure: any, title: string, icn: string, model: string): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.icnDataService.getIcn(model, icn).subscribe((data) => {
        var re = /SVGID/gi;
        var newSvg = data.replace(re, "SVGID"+figure.elementId);
        figure.icnSvgData = this.sanitizer.bypassSecurityTrustHtml(newSvg) as string;
        figure.svgContainer = this.setSvgContainer(figure.icnSvgData, figure.elementId, title);
        observer.next(true);
        observer.complete();
      });
    });
  }

  private isIcnSvg(icn): boolean {
    const isIcnSvg = icn.substring(31, 32) === 'A';
    return isIcnSvg;
  }

  private icnElementIdGenerator(): string {
    return '_' + Math.random().toString(36).substr(2, 9);
  }

  private setSvgContainer(svg: string, id: string, title: string): HTMLElement {
    const ietmFigureContent = this.setFigureContent(id);
    const ietmFigureTitle = this.setFigureTitle(title);

    ietmFigureContent.appendChild(ietmFigureTitle);

    const icnWrapper = document.createElement('div');

    icnWrapper.setAttribute('class', 'icn-wrapper');

    const svgWrapper = document.createElement('div');

    svgWrapper.setAttribute('class', 'svg-wrapper');
    svgWrapper.setAttribute('style', 'transform-origin: 0 0;');
    svgWrapper.style.transform = 'matrix(1,0,0,1,0,0)';
    svgWrapper.innerHTML = svg;

    const svgElement = svgWrapper.firstElementChild;

    svgElement.setAttribute('transform', 'matrix(1,0,0,1,0,0)');
    svgElement.setAttribute('bellIcnSvg', '');
    svgWrapper.innerHTML = '';

    const styleSheet = svgElement.querySelectorAll('style')[0];

    if (styleSheet) {
      const styleSheetId = id + '-SVG';
      styleSheet.setAttribute('id', styleSheetId);
    }

    svgWrapper.appendChild(svgElement);
    icnWrapper.appendChild(svgWrapper);
    ietmFigureContent.appendChild(icnWrapper);

    return ietmFigureContent;
  }

  private setImage(figure: any, title: string, icn: string, model: string): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.icnDataService.getIcnImage(model, icn).subscribe((data) => {
        if (data) {
          figure.imagePath = data //this.sanitizer.bypassSecurityTrustUrl(data) as string;
        } else {
          figure.imagePath = null;
        }
        figure.imageContainer = this.setImageContainer(figure.imagePath, figure.elementId, title);
        observer.next(true);
        observer.complete();
      });
    });
  }

  private setImageContainer(src: string, id: string, title: string): HTMLElement {
    const ietmFigureContent = this.setFigureContent(id);
    const ietmFigureTitle = this.setFigureTitle(title);
    ietmFigureContent.appendChild(ietmFigureTitle);
    const imgWrapper = document.createElement('div');
    imgWrapper.setAttribute('class', 'image-wrapper');
    const image = document.createElement('img');
    image.setAttribute('class', 'image');
    image.setAttribute('alt', 'image icn');
    image.setAttribute('style', 'width: 95%; height: auto;');
    image.src = src;
    imgWrapper.appendChild(image);
    ietmFigureContent.appendChild(imgWrapper);

    return ietmFigureContent;
  }

  private setFigureContent(id: string): HTMLElement {
    const ietmFigureContent = document.createElement('section');
    ietmFigureContent.setAttribute('class', 'ietm-figure-content');
    ietmFigureContent.setAttribute('id', id);

    return ietmFigureContent;
  }

  private setFigureTitle(title: string): HTMLElement {
    const titleElement = document.createElement('div');
    titleElement.setAttribute('class', 'icn-title');
    titleElement.textContent = title;
    return titleElement;
  }

  setFigureStyles(parentIcnWrapper: HTMLElement) {
    const figureHeight = parentIcnWrapper.offsetHeight;
    const figureWidth = parentIcnWrapper.offsetWidth;
    let orientation = 'none';

    if (figureWidth > figureHeight) {
      orientation = 'landscape';

      return orientation;
    } else if (figureHeight > figureWidth) {
      orientation = 'portrait';

      return orientation;
    } else {
      return orientation;
    }
  }

  setOriginUrl(newOriginUrl: string) {
    this.originUrl = newOriginUrl;
  }

  setDocType(newDocType: string) {
    this.ietmPrintModalData.docType = newDocType;
  }

  onPrintEvent() {
    this.ietmPrintModalData.isPrinting = true;
    if (this.platformService.platform === PlatformType.MAUI) {  // use ietm print portal for maui
      this.printPortal = new ComponentPortal(IetmPrintPortalComponent); 
      this.bodyPortalHost.attach(this.printPortal);
    }
    else { // use ietm print action, ietm print layout, & ietm print component for web + electron
      this.router.navigate([ '/',
        {
          outlets: {
            'ietm-print-outlet': ['printing', this.ietmPrintModalData.docTittle, this.ietmPrintModalData.docType]
          }
        }
      ]);
    }
  }

  setPrintMainContainerElement(printMainContainer: HTMLElement) {
    this.printMainContainer = printMainContainer;
  }

  setPrintFigureContainerElement(printFigureContainer: HTMLElement): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.allFigures = [];
      this.printFigureContainer = printFigureContainer;

      if (this.ietmPrintModalData.icn.sheets && this.ietmPrintModalData.icn.sheets.length > 0) {
        this.handleFigureSheets().subscribe((allFigures) => {
            for (let figure of allFigures) {
              const parentIcnWrapper = figure.isIcnSvg ? figure.svgContainer : figure.imageContainer;
              parentIcnWrapper.classList.add('portrait');
              this.printFigureContainer.appendChild(parentIcnWrapper);
            }
            this.allFigures = allFigures;
            observer.next(true);
            observer.complete();
          }
        );
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }

  onDataReady() {
    setTimeout(() => {
      if (this.platformService.platform === PlatformType.MAUI) {
        this.platformService.invokePlatformMethod<boolean>(PlatformMethod.printDocument, "portrait", 0.5).subscribe(() =>  {
          this.onAfterPrint();
          this.bodyPortalHost.detach();
        });
      }
      else {
        this.window.print();
        this.onAfterPrint();
        this.router.navigate([{
          outlets: {
            'ietm-print-outlet': null
          }
        }]);
      }
    });
  }

  private onAfterPrint() {
    this.ietmPrintModalData.isPrinting = false;
    this.ietmPrintModalData.docType = null;
  }
}
