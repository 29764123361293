import { Injectable } from '@angular/core';
import { IetmRouteService } from './ietm-route.service';
import { IetmSns, IetmSnsData } from './ietm-model';
import { Observable, BehaviorSubject, Observer } from 'rxjs';
import { IetmDataService } from 'src/app/shared/services/ietm/ietm-data.service';

@Injectable({
  providedIn: 'root'
})
export class IetmSnsService {
  private _sns: BehaviorSubject<IetmSns> = new BehaviorSubject<IetmSns>(null);
  public sns$: Observable<IetmSns> = this._sns.asObservable();
  get sns(): IetmSns { return this._sns.value; }

  public status = {
    loading: false,
    error: false
  };

  constructor(
    private ietmRoute: IetmRouteService,
    private ietmDataService: IetmDataService,
  ) {
    this.ietmRoute.model$.subscribe(model => this.handleModelChange(model));
  }

  private async handleModelChange(model: string) {
    if (this.sns && this.sns.model === model) { return; }
    this.status = { loading: true, error: false };
    this.getSns(model).subscribe((data) => {
      this._sns.next(data);
      this.status.loading = false;
      this.status.error = !(this.sns);
    });
  }

  getSns(model: string): Observable<IetmSns> {
    return new Observable((observer: Observer<IetmSns>) => {
      if (this.sns && this.sns.model === model) {
        observer.next(this.sns);
        observer.complete();
      } else if (!model) {
        observer.next(null);
        observer.complete();
      } else {
        this.ietmDataService.getIetmSnsToc(model).subscribe((sns) => {
          if (sns) {
            observer.next({
              model: model,
              data: sns,
            });
            observer.complete();
          } else {
            observer.next(null);
            observer.complete();
          }
        });
      }
    });
  }
}
