// httpcancel.service.ts
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class HttpCancelService {

  private httpCancel$ = new Subject<boolean>();

  constructor() { }

  public cancelPendingRequests() {
    this.httpCancel$.next(true);
  }

  public onCancelPendingRequests(): Observable<any> {
    return this.httpCancel$.asObservable();
  }
}
