import { Component, ViewEncapsulation } from '@angular/core';
import { Banner } from '../../shared/data-model/banner';
import { BannerService } from '../../shared/services/banner/banner.service';

@Component({
  selector: 'bell-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BannerComponent {
  get banner(): Banner { return this.bannerService.banner; }
  set banner(value) {}
  get dismissed(): boolean { return this.bannerService.dismissed; }
  set dismissed(value) {}
  constructor(
    private bannerService: BannerService
  ) { }

  dismiss(): void {
    this.bannerService.dismiss();
  }
}
