<h1 mat-dialog-title class="bell-bold">Add Part to Parts List</h1>
  <p class="dialog-description">Select a parts list to add the part to.</p>
  <div #revisionTable>
    <form>
      <table>
          <tbody class="add-part-table">
            <tr class="section-header">
               <th>PARTS LIST</th>
               <th>PART NUMBER</th>
               <th>PART DESCRIPTION</th>
               <th>OLD QTY</th>
               <th>NEW QTY</th>
            </tr>
            <tr>
                <td data-label="Parts List">
                  <div class="pl-name">
                  <mat-form-field appearance="outline" floatLabel="never">
                    <mat-select placeholder="{{partsList.activePartsList.name}}" (selectionChange)="selectPartsList($event.value)">
                      <mat-option *ngFor="let list of partsList.userPartsLists; let i = index" [value]="i">{{list.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                </td>
                <td data-label="Part Number"><input class="item-input" type="text" required [(ngModel)]="part.partNumber" name="partNumber"></td>
                <td data-label="Description"><input class="item-input" type="text" required [(ngModel)]="part.description" name="description"></td>
                <td data-label="Old Qty"><input class="item-input" type="number" readonly [(ngModel)]="oldQuantity" name="oldQuantity"></td>
                <td data-label="New Qty"><input class="item-input" type="number" required [(ngModel)]="part.quantity" name="newQuantity"></td>
            </tr>
      </tbody>
      </table>

    </form>
  </div>
<div mat-dialog-actions class="add-part-actions" justify-content="flex-end">
  <button mat-button class="add-part-action" mat-dialog-close>CANCEL</button>
  <button mat-button class="add-part-action" (click)="addToPartsList()"> + ADD PART</button>
  <button mat-button class="add-part-action" (click)="addToPartsList(true)">ADD PART AND VIEW PARTS LIST<div class="icon-arrow-return add-part-action-icon"></div></button>
</div>
