import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BinderLabelsComponent } from './binderlabels/binderlabels.component';
import { RecordCardsComponent } from './record-cards/record-cards.component';
import { RevisionStatusComponent } from './revision-status/revision-status.component';
import { WeightBalanceComponent } from './weight-balance/weight-balance.component';
import { RelatedLinksComponent } from './related-links/related-links.component';

const routes: Routes = [
    { path: 'binderlabels', component: BinderLabelsComponent },
    { path: 'recordcards', component: RecordCardsComponent },
    { path: 'revisionstatus', component: RevisionStatusComponent },
    { path: 'weightbalance', component: WeightBalanceComponent },
    { path: 'relatedlinks', component: RelatedLinksComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    declarations: []
})
export class GeneralInfoRoutingModule { }
