import { ErrorHandler, Injectable } from '@angular/core';
import { PlatformMethod } from '../services/offline/platform-methods';
import { PlatformService, PlatformType } from '../services/offline/platform.service';

@Injectable()
export class TPubErrorHandler implements ErrorHandler {
  constructor(
    private platformService: PlatformService
  ) {}

  handleError(error) {
    console.log(error);
    if (this.platformService.isOfflineCapable() || this.platformService.platform === PlatformType.MAUI) {
      // TODO: remove platform == MAUI check in iOS release 2 (when offline is enabled)
      this.platformService.invokePlatformMethod(PlatformMethod.logError, error).subscribe();
    }
  }
}

