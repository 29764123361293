import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ServiceBase } from '../service-base.service';
import { HttpEventType, HttpRequest, HttpErrorResponse, HttpResponse, HttpHeaderResponse } from '@angular/common/http';
import { PartsListFileType } from '../parts-list/parts-list-file-type.enum';
import { HttpProgressEventModalData } from '../../data-model/http-utility';
import { HttpEventModalService } from '../http-event-modal/http-event-modal.service';
import { PlatformService, PlatformType } from '../offline/platform.service';
import { PlatformMethod } from '../offline/platform-methods';

@Injectable({
  providedIn: 'root'
})

export class HttpFileDownloadService extends ServiceBase {
  private httpProgressEventModalDataObj: HttpProgressEventModalData = new HttpProgressEventModalData;
  private _httpProgressEventModalData: BehaviorSubject<HttpProgressEventModalData> = new BehaviorSubject<HttpProgressEventModalData>(null);
  public httpProgressEventModalData$: Observable<HttpProgressEventModalData> = this._httpProgressEventModalData.asObservable();
  get httpProgressEventModalData(): HttpProgressEventModalData { return this._httpProgressEventModalData.value; }

  constructor(
    private httpEventModalService: HttpEventModalService,
    private platformService: PlatformService
  ) {
    super();
  }

  public getPDF(pdfId: string, filename: string, otherFile = false): Observable<any> {
    return new Observable((observer) => {
      this.downloadPDF(pdfId, filename).subscribe((data) => {
        if (data && data.type === HttpEventType.Response) {
          this.handleHttpRequestResponse(data, filename, otherFile);
          observer.next(data);
        }
      }, (error) => {
        this.handleHttpRequestError(error);
        observer.error(error);
      });
    });
  }

  public getFile(model: string, manual: string, filename: string, otherFile = false): Observable<any> {
    return new Observable((observer) => {
      this.downloadFile(model, manual, filename).subscribe((data) => {
        if (data && data.type === HttpEventType.Response) {
          this.handleHttpRequestResponse(data, filename, otherFile);
          observer.next(data);
        }
      }, (error) => {
        this.handleHttpRequestError(error);
        observer.error(error);
      });
    });
  }

  public exportPartsList(partsListName: string, fileType: PartsListFileType): Observable<any> {
    return new Observable((observer) => {
      this.downloadPartsList(partsListName, fileType).subscribe((data) => {
        if (data && data.type === HttpEventType.Response) {
          this.handleHttpRequestResponse(data, partsListName);
          observer.next(data);
        }
      }, (error) => {
        this.handleHttpRequestError(error);
        observer.error(error);
      });
    });
  }

  public async initDownload(response, fileName, otherFile = false): Promise<string> {
    if (this.platformService.platform == PlatformType.MAUI) {
      let fileContent: string;
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const dataUrl = fileReader.result as string;
        fileContent = dataUrl.split(',')[1];
        this.platformService.invokePlatformMethod<boolean>(PlatformMethod.saveAsPdf, fileContent, fileName).subscribe(); 
      };
      fileReader.readAsDataURL(response.body);
      return fileContent;
    }
    else {
      let filename = fileName;
      if(!otherFile) {
        filename = filename.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
        filename = filename.replace(/\s/g, '');
      }
      // should remove due to deprecated support of ie
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(response.body);
        return filename;
      } else {
        const blobUrl = window.URL.createObjectURL(response.body);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(blobUrl);
        link.remove();
        
        return blobUrl;
      }
    }
  }

  public handleHttpRequest(request: HttpRequest<any>) {
    if (this.httpProgressEventModalDataObj.request !== request.url) {
      this.httpProgressEventModalDataObj.request = request.url;
    }
    this.handleHttpEventModal(this.httpProgressEventModalDataObj);
  }

  public handleHttpProgressType(progressType: string) {
    if (this.httpProgressEventModalDataObj.progressType !== progressType) {
      this.httpProgressEventModalDataObj.progressType = progressType;
      this.handleHttpEventModal(this.httpProgressEventModalDataObj);
    }
  }

  public handleHttpProgressEvent(progress: number) {
    if (!this.httpProgressEventModalDataObj.progress || this.httpProgressEventModalDataObj.progress < progress) {
      this.httpProgressEventModalDataObj.progress = progress;
      this.handleHttpEventModal(this.httpProgressEventModalDataObj);
    }
  }

  public hanldeHttpErrorResponse(error: HttpErrorResponse) {
    this.httpProgressEventModalDataObj.error = error;
    this.handleHttpEventModal(this.httpProgressEventModalDataObj);
  }

  public handleHttpRequestResponse(response, filename, otherFile = false) {
    this.httpProgressEventModalDataObj.response = response;
    this.initDownload(response, filename, otherFile);
    this.handleHttpEventModal(this.httpProgressEventModalDataObj);
    this.finalizeHttpRequest();
  }

  public handleHttpRequestError(error) {
    this.httpProgressEventModalDataObj.doneWithError = true;
    this.httpProgressEventModalDataObj.error = error;
    this.handleHttpEventModal(this.httpProgressEventModalDataObj);
    this.finalizeHttpRequest();
  }

  public handleHttpEventModal(data) {
    this._httpProgressEventModalData.next(data);
    this.httpEventModalService.handleHttpEventModal(this._httpProgressEventModalData.value);
  }

  public finalizeHttpRequest() {
    const data = null;
    this.httpProgressEventModalDataObj = new HttpProgressEventModalData;
    this._httpProgressEventModalData.next(null);
  }

  public downloadPDF(pdfId: string, filename: string): Observable<any> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Download.PDF.get}${pdfId}`;
    return this.httpUtility.getDownloadFile(url);
  }

  public downloadFile(model: string, manual: string, filename: string): Observable<any> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Download.File.get}${model}/${manual}/${filename}`;
    return this.httpUtility.getDownloadFile(url);
  }

  public downloadPartsList(partsListName: string, filetype: PartsListFileType): Observable<any> {
    const url = `${this.environmentService.getBackendUrl()}${this.apiUri.Download.PartsList.get(partsListName, filetype)}`;
    return this.httpUtility.getDownloadFile(url);
  }
}
