<h3 class="page-title" bellPrintElement documentSection="header" sectionType="title">REVISION STATUS</h3>
<div class="noprint">
  <p>
    The Revision Status Listing provides the current release date status of all commercial technical publications.</p>
  <p>
    Select your model and click on the links below to download each set of
    labels.
  </p>
</div>
<div class="printable">
  <div class="rev-label">
    <section class="rev-label-selection">
      <div class="model-selection">
        <mat-form-field appearance="outline" floatLabel="never">
          <mat-select id="modelSelect" [value]="selectedModel" placeholder="Select a Model"
            (selectionChange)="changeModel($event)" bellPrintElement documentSection="header" sectionType="subTitle"
            [sectionText]="'Model: ' + selectedModel">
            <mat-option *ngFor="let model of models" [value]="model.model">{{model.model}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </section>
    <div id="download-links" class="download-links noprint" [ngStyle]="selected ? visVis : visHide">
      <bell-print class="label-download">
        <div class="icon-file"></div>Print Revision Status
      </bell-print>
      <a href="http://www.bellcustomer.com/files/#downloads" class="label-download">
        <div class="icon-download-arrow"></div>Download Latest Service Directives
      </a>
    </div>
  </div>
  <div #revisionTable class="revision-table" *ngIf="selected" bellPrintElement>
    <mat-accordion multi="true">
      <ng-container *ngFor="let manual of this.manuals">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>{{manual.publication_type}}</mat-panel-title>
          </mat-expansion-panel-header>
          <table *ngIf="manual.publications.length > 0">
            <thead>
              <tr class="section-subheader">
                <th>Name</th>
                <th>Title</th>
                <th>Basic Date</th>
                <th>Rev Level</th>
                <th>Rev Date</th>
                <th>Cancel Date</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let publication of manual.publications"> 
                <tr>
                  <td data-label="Name">{{publication.pub_name}}</td>
                  <td data-label="Title">{{publication.pub_title}}</td>
                  <td data-label="Basic Date">{{publication.basic_date}}</td>
                  <td data-label="Rev Level">{{publication.rev}}</td>
                  <td data-label="Rev Date">{{publication.rev_date}}</td>
                  <td data-label="Cancel Date">{{publication.cancel_date}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </div>
</div>