import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSliderModule } from '@angular/material/slider';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { AddPartDialogComponent } from './shared/add-part-dialog/add-part-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CCodeDialogComponent } from './shared/ccode-dialog/ccode-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatToolbarModule,
        MatSliderModule,
        MatTreeModule,
        MatTabsModule,
        MatSelectModule,
        RouterModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatTableModule,
        RouterModule,
        SharedModule,
        FormsModule,
        MatDialogModule,
        MatTooltipModule,
        MatInputModule,
        MatRadioModule
    ],
    declarations: [
        AddPartDialogComponent,
        CCodeDialogComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ViewerModule { }
