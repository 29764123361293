import { Injectable } from '@angular/core';
import { Observable, Observer, BehaviorSubject } from 'rxjs';
import { OfflineService } from '../offline/offline.service';
import { Publication } from '../../data-model/publication';
import { OfflineZipReaderService } from '../offline/offline-zip-reader.service';
import { filter } from 'rxjs/operators';
import { DataService } from '../data-services/data.service';


@Injectable({
  providedIn: 'root'
})

export class PublicationDataService {
  private loadedMetadata$: Record<string, BehaviorSubject<Publication>> = {};
  constructor(
    private offlineService: OfflineService,
    private dataService: DataService,
    private offlineZipReaderService: OfflineZipReaderService,
  ) {}

  getPublicationData(filename: string): Observable<Publication> {
    return new Observable((observer: Observer<Publication>) => {
      this.getPublicationDataSubjects(filename).pipe(filter(f => f !== undefined)).subscribe(data => {
        observer.next(data);
        observer.complete();
      });
    });
  }

  private getPublicationDataServerData(fileName: string) {
    if (this.loadedMetadata$[fileName]) {
      let metadata$: Observable<Publication>;
      if (!this.offlineService.isOfflineCapable) {
        metadata$ = this.dataService.getPublication(fileName);
      } else {
        if (this.offlineService.isOffline) {
          metadata$ = this.offlineZipReaderService.getPublication(fileName);
        } else {
          metadata$ = this.dataService.getPublication(fileName);
        }
      }
      metadata$.subscribe((data) => {
        if (data) {
          this.loadedMetadata$[fileName].next(data);
        } else {
          this.loadedMetadata$[fileName].next(null);
        }
      });
    }
  }

  private getPublicationDataSubjects(fileName: string): BehaviorSubject<Publication> {
    if (!this.loadedMetadata$[fileName]) {
      this.loadedMetadata$[fileName] = <BehaviorSubject<Publication>> new BehaviorSubject(undefined);
      this.getPublicationDataServerData(fileName);
      return this.loadedMetadata$[fileName];
    } else {
      return this.loadedMetadata$[fileName];
    }
  }
}
