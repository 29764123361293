<h3 class="page-title">BINDER LABELS</h3>
  <p>
    The following binder labels can be printed and inserted into your binder
    spine pockets. Simply add any breakdown in volumes to quickly identify and
    retrieve your documents.
  </p>
  <p>
    Select your model and click on the links below to download each set of labels.
  </p>
<div class="binder-label">
<section class="binder-label-selection">
  <div class="model-selection">
    <mat-form-field appearance="outline" floatLabel="never">
      <mat-select [value]="selectedModel" placeholder="Select a Model" (selectionChange)="changeModel($event)">
        <mat-option *ngFor="let label of binderLabels" [value]="label">{{label.model}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</section>
<div id="download-links" class="download-links" [ngStyle]="selected ? visVis : visHide">
    <div (click)="getBinderLabelFile($event, selectedModel.link_2in, selectedModel.analyticLabel_2in)" class="label-download">
      <div class="icon-download-arrow"></div>
      Download 2" Labels</div>
    <div (click)="getBinderLabelFile($event, selectedModel.link_3in, selectedModel.analyticLabel_3in)" class="label-download">
      <div class="icon-download-arrow"></div>
      Download 3" Labels</div>
  </div>
</div>
