export class Dm {
  rowid: number | undefined;
  name: string | undefined;
  code: string | undefined;
  title: string | undefined;
  momodel: string | undefined;
  idDm: number | undefined;
  idFile: number | undefined;
  idManual: number | undefined;
  s1000d: boolean | undefined;
  lastUpdate: Date;
}
