import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MyOfflineLibComponent } from './my-offline-lib/my-offline-lib.component';
import { OfflineLibComponent } from './offline-lib/offline-lib.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OfflineNotificationComponent } from './offline-notification/offline-notification.component';
import { OfflineLibUpdateModalComponent } from './my-offline-lib/offline-lib-update-modal/offline-lib-update-modal.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { OfflineDeactivateModalComponent } from './my-offline-lib/offline-deactivate-modal/offline-deactivate-modal.component';
import { OfflineSelectionComponent } from './my-offline-lib/offline-selection/offline-selection.component';
import { CheckUpdateComponent } from './check-update/check-update.component';

@NgModule({
  declarations: [
    MyOfflineLibComponent,
    OfflineLibComponent,
    OfflineNotificationComponent,
    OfflineLibUpdateModalComponent,
    OfflineDeactivateModalComponent,
    OfflineSelectionComponent,
    CheckUpdateComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatTabsModule,
    MatListModule,
    MatDividerModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatDialogModule
  ],

  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    }
  ],
  exports: [
    MyOfflineLibComponent,
    OfflineLibComponent,
    OfflineNotificationComponent,
    OfflineLibUpdateModalComponent,
    OfflineDeactivateModalComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class OfflineModule { }
