import { Injectable } from '@angular/core';
import { LoginType } from '../../enums/login-types';
import { EnvironmentService } from '../environment.service';
import { ApiUriService } from '../api-uri.service';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionInfo } from '../../data-model/session-info';
import jwt_decode from 'jwt-decode';
import { HttpClient } from '@angular/common/http';
import { OfflineService } from '../offline/offline.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    protected environmentService: EnvironmentService,
    protected apiUri: ApiUriService,
    protected router: Router,
    protected http: HttpClient,
    protected offlineService: OfflineService
  ) {
  }

  public login(type?: LoginType) {
    if (!type) {
      type = this.getPreferredLogin();
      if (!type) {
        this.router.navigate(['login']);
        return;
      }
    }
    window.location.assign(this.getLoginUrl(type));
  }

  public setPreferredLogin(type?: LoginType) {
    if (type) {
      localStorage.setItem('techpubs-login-type', type);
    } else {
      localStorage.removeItem('techpubs-login-type');
    }
  }

  public getPreferredLogin(): LoginType | null {
    let loginType = localStorage.getItem('techpubs-login-type');
    if (loginType) {
      return loginType as LoginType;
    }
    return null;
  }

  public processLoginRedirectAsync(forceSessionInfoCheck: boolean = false): Observable<boolean> {
    if (window.location.hash.toLowerCase().indexOf('bff') !== -1 || forceSessionInfoCheck) {
      return this.http.get<SessionInfo>(`${this.environmentService.getBackendUrl()}${this.apiUri.User.SessionInfo.get}`, {withCredentials: true}).pipe(map(sessionInfo => {
        if (sessionInfo) {
          sessionStorage.setItem('session_info', JSON.stringify(sessionInfo));
          return true;
        }
        return false;
      }))
    }
    // Once legacy gateway is retired, we can just return of(false);
    return of(this.processLegacyLoginRedirect());
  }

  private processLegacyLoginRedirect(): boolean {
    if (window.location.hash.toLowerCase().indexOf('access_token') !== -1) {
      const token = window.location.hash.substring(14, window.location.hash.length);
      sessionStorage.setItem('access_token', token);
      try {
        const claims = jwt_decode(token);
        let info: SessionInfo = {
          admin: (claims['admin'].toLowerCase() === 'true'),
          expiration: (claims['exp']),
          givenName: claims['given_name'],
          marketplaceAdmin: (claims['mp_a']?.toLowerCase() === 'true'),
          workViewer: false
        }
        sessionStorage.setItem('session_info', JSON.stringify(info));
      } catch (e) {
        console.log(e);
      }
      return true;
    }
    return false;
  }

  public getSessionInfo(): SessionInfo | null {
    let info = sessionStorage.getItem('session_info');
    if (!info) {
      return null;
    } else {
      return JSON.parse(info);
    }
  }

  public isUserLoggedInOrOffline(): boolean {
    return this.getSessionInfo() !== null || this.offlineService.isOffline;
  }

  public logout(): void {
    this.processLogout();
    // Navigate to logout url
    window.location.assign(this.getLogoutUrl());
  }

  protected getLoginUrl(authtype: string): string {
    const baseHref = this.environmentService.getEnvironmentBaseUrl().toString();
    const baseUrl = location.origin + (baseHref.length > 0 ? '/' + baseHref : '');
    const endpoint = this.environmentService.getLoginEndpoint();
    // tslint:disable-next-line: max-line-length
    const queryString = `response_type=token&client_id=techpubsclient&state=test&redirect_uri=${baseUrl}&scope=openid&auth_type=${authtype}`;
    const route = `${endpoint}?${queryString}`;
    return `${this.environmentService.getBackendUrl()}${route}`;
  }

  protected processLogout(): void {
    // Clear session storage
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('session_info');
    this.setPreferredLogin();
  }

  protected getLogoutUrl(): string {
    const baseHref = this.environmentService.getEnvironmentBaseUrl().toString();
    const baseUrl = location.origin + (baseHref.length > 0 ? '/' + baseHref : '');
    return `${this.environmentService.getBackendUrl()}${this.environmentService.getLogoutEndpoint()}?redirect_uri=${baseUrl}/login`;
  }
}
