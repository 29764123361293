import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

import { SideNavButtonsComponent } from './side-nav-buttons/side-nav-buttons.component';
import { MatIconModule } from '@angular/material/icon';
import { OfflineModule } from 'src/app/offline/offline.module';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [
    SideNavButtonsComponent
  ],
  declarations: [
    SideNavButtonsComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SideNavModule { }
