import { Component, OnInit, Input, Inject, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { RouteService } from '../shared/services/route.service';
import { CCODE_FROM_DIALOG } from 'src/app/viewer/shared/ccode-dialog/ccode-dialog.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bell-ccode',
  templateUrl: './ccode.component.html',
  styleUrls: ['./ccode.component.scss']
})

export class CCodeComponent implements OnInit, OnDestroy {
  @Input() cCodeSelectedDialog: string;
  cCodeSelected: string;
  isCCodeLoaded: boolean;
  isCCode: boolean;

  routerSubscription: Subscription;

  cCodeNoteLinks = [{
      PSE: [{
        label: 'Product Support Engineering',
        path: 'http://www.bellflight.com/support-and-service/support',
        target: '_blank'
      }]
    },
    {
      MSDS: [{
        label: 'Material Safety Data Sheets (MSDS)',
        path: 'http://ww1.actiocms.com/?958ea07a-5855-11e4-bc08-c81f66f24c6b',
        target: '_blank'
      }]
    },
    {
      QPL: [{
        label: 'BellOffline Instructions',
        path: 'http://quicksearch.dla.mil/',
        target: '_blank'
      }]
    },
  ];

  constructor(
    private routeService: RouteService,
    private router: Router,
    @Inject(CCODE_FROM_DIALOG) private cCodeInjected: string
  ) {}

  ngOnInit() {
    this.setCCode();
    this.routerSubscription = this.router.events.subscribe(
      (event: Event) => {
        if (event instanceof NavigationEnd) {
          this.setCCode();
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  setCCode() {
    const cCodeNumber = this.routeService.getParameter('ccode') || this.cCodeInjected;
    const cCodeWhole = 'C-' + cCodeNumber.replace('C-', '');
    this.cCodeSelected = this.cCodeSelectedDialog || this.cCodeInjected || cCodeWhole;
    this.isCCode = this.cCodeSelected !== null;
  }

  onCCodeLoaded(isLoaded: boolean) {
    this.isCCodeLoaded = isLoaded;
  }
}
