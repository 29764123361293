import { OnInit, Component, Renderer2, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PrintPreviewComponent } from './print-preview/print-preview.component';
import { DOCUMENT } from '@angular/common';
import { PlatformService, PlatformType } from '../../services/offline/platform.service';
import { PlatformMethod } from '../../services/offline/platform-methods';

@Component({
  selector: 'bell-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PrintComponent implements OnInit {
  private printHeader: HTMLElement;
  private printBody: HTMLElement;
  private printFooter: HTMLElement;
  public toPrintDocuments: HTMLElement;
  private printContainer: HTMLElement;
  private rules: any;
  private printDocuments: any;
  public isIEOrEdge: boolean;

  private readonly window: Window;

  constructor(
    @Inject(DOCUMENT) document: Document,
    private renderer: Renderer2,
    public printPreview: MatDialog,
    private platformService: PlatformService
  ) {
    this.window = document.defaultView;
  }

  ngOnInit() {
    this.isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  }

  onPrintEvent() {
    this.printDocuments = document.querySelectorAll('[data-print="true"]');
    this.setPrint();
  }

  setPrint() {
    this.printHeader = document.createElement('header');
    this.printHeader.setAttribute('id', 'printHeader');
    this.printBody = document.createElement('section');
    this.printBody.setAttribute('id', 'printBody');
    this.printFooter = document.createElement('footer');
    this.printFooter.setAttribute('id', 'printFooter');
    this.printContainer = document.createElement('div');
    this.printContainer.setAttribute('id', 'printContainer');
    document.body.appendChild(this.printContainer);

    if (this.toPrintDocuments) {
      this.renderer.removeChild(this.printContainer, this.toPrintDocuments);
    }

    this.toPrintDocuments = this.renderer.createElement('bell-document-to-print');

    if (this.printDocuments && this.printDocuments.length > 0) {
      for (let i = 0; i < this.printDocuments.length; i++) {
        const el = this.printDocuments[i];
        const toPrintEl = document.createElement(el.dataset.sectionType);

        if (el.dataset.sectionText) {
          toPrintEl.textContent = el.dataset.sectionText;
        }

        if (el.dataset.documentSection === 'header') {
          if (el.dataset.sectionType === 'H1') {
            this.printHeader.insertBefore(toPrintEl, this.printHeader.childNodes[0]);
          } else if (el.dataset.sectionType === 'H2') {
            this.printHeader.insertBefore(toPrintEl, this.printHeader.childNodes[1]);
          } else {
            this.printHeader.appendChild(toPrintEl);
          }
        }

        if (el.dataset.documentSection === 'footer') {
          this.printFooter.appendChild(toPrintEl);
        }

        if (el.dataset.documentSection === 'body') {
          const bodySection = document.createElement('section');

          if (el.dataset.sectionType === 'H3') {
            bodySection.insertBefore(toPrintEl, bodySection.childNodes[0]);
          } else if (el.dataset.sectionType === 'H4') {
            bodySection.insertBefore(toPrintEl, bodySection.childNodes[1]);
          } else {
            bodySection.appendChild(toPrintEl);

            if (!el.dataset.sectionText) {
              toPrintEl.outerHTML = el.outerHTML;
            }
          }

          this.printBody.appendChild(bodySection);
        }
      }

      this.toPrintDocuments.appendChild(this.printHeader);
      this.toPrintDocuments.appendChild(this.printBody);
      this.toPrintDocuments.appendChild(this.printFooter);
    }

    document.body.dataset.printing = 'true';

    if (this.isIEOrEdge) {
      this.openPrintPreview();
    } else {
      this.getReadyToPrint();
    }
  }

  openPrintPreview() {
    this.simulatePrintMedia();

    const printPreviewRef = this.printPreview.open(
      PrintPreviewComponent, {
        data: this.toPrintDocuments,
      }
    );

    printPreviewRef.afterClosed().subscribe(
      (print) => {
        this.restoreScreenMedia();
        if (print) {
          this.getReadyToPrint();
        } else {
          this.endPrint();
        }
      }
    );
  }

  simulatePrintMediaQuery(rules) {
    for (const rule of rules.screenRules) {
      rule.media.mediaText = 'disabled';
    }

    for (const rule of rules.printRules) {
      rule.media.mediaText = 'screen';
    }
  }

  restorePrintMediaQuery(rules) {
    for (const rule of rules.screenRules) {
      rule.media.mediaText = 'screen';
    }

    for (const rule of rules.printRules) {
      rule.media.mediaText = 'print';
    }
  }

  identifyCssRules(screenRules, printRules) {
    const styleSheets = document.styleSheets;

    for (const i of Object.keys(styleSheets)) {
      const sheet = styleSheets[i];

      if (sheet.ownerNode.tagName !== 'LINK') {
        const rules = sheet.cssRules || sheet.rules;

        for (const rule of rules) {
          if (rule.type === CSSRule.MEDIA_RULE) {
            const media = rule.conditionText || rule.media.mediaText;

            if (media === 'print') {
              printRules.push(rule);
            } else if (media === 'screen') {
              screenRules.push(rule);
            }
          }
        }
      }
    }
    return {
      printRules,
      screenRules
    };
  }

  simulatePrintMedia() {
    const screenRules = [];
    const printRules = [];
    screenRules.length = 0;
    printRules.length = 0;
    this.rules = this.identifyCssRules(screenRules, printRules);
    this.simulatePrintMediaQuery(this.rules);
  }

  restoreScreenMedia() {
    this.restorePrintMediaQuery(this.rules);
  }

  private getReadyToPrint(): void {
    this.renderer.appendChild(this.printContainer, this.toPrintDocuments);
    this.startPrint();
  }

  private startPrint(): void {
    setTimeout(
      () => {
        if (this.platformService.platform === PlatformType.MAUI) {
          this.platformService.invokePlatformMethod<boolean>(PlatformMethod.printDocument, "portrait", 0.5).subscribe(() => this.endPrint());
        }
        else {
          this.window.print();
          this.endPrint();
        }
      },
      1000
    );
  }

  private endPrint(): void {
    setTimeout(
      () => {
        if (this.toPrintDocuments && this.printContainer) {
          this.renderer.removeChild(this.printContainer, this.toPrintDocuments);
          document.body.removeChild(this.printContainer);
        }

        document.body.removeAttribute('data-printing');
      },
      1000
    );
  }
}
