import { Component, OnInit } from '@angular/core';
import { OfflineCount } from 'src/app/shared/data-model/idb';
import { OfflineStates } from 'src/app/shared/enums/offline-states';
import { OfflineFeedService } from 'src/app/shared/services/offline/offline-feed.service';
import { OfflineStatusService } from 'src/app/shared/services/offline/offline-status.service';
import { OfflineService } from 'src/app/shared/services/offline/offline.service';

@Component({
  selector: 'bell-offline-notification',
  templateUrl: './offline-notification.component.html',
  styleUrls: ['./offline-notification.component.scss']
})
export class OfflineNotificationComponent implements OnInit {
  OfflineStates: typeof OfflineStates = OfflineStates;
  isActivated = false;
  offlineCount: OfflineCount;
  constructor(
    public offlineService: OfflineService,
    private offlineFeedService: OfflineFeedService,
    public offlineStatusService: OfflineStatusService,
  ) {
  }

  ngOnInit() {
    this.offlineFeedService.isOfflineActivated.subscribe(result => {
      this.isActivated = result;
    });
    this.offlineStatusService.isStatusModified$.subscribe(result => {
      this.checkUpdates();
    });
    this.checkUpdates();
  }

  checkUpdates() {
    this.offlineStatusService.getDownloadCounts().subscribe((value: OfflineCount) => {
        this.offlineCount = value;
        if (this.offlineCount.added > 0 || this.offlineCount.willBeDeleted > 0 || this.offlineCount.willUpdate > 0) {
          this.offlineService.state = OfflineStates.UPDATE_READY;
        } else {
          this.offlineService.state = OfflineStates.UP_TO_DATE;
        }
      });
    }
}
