import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Observer, Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { OfflineStates } from 'src/app/shared/enums/offline-states';
import { OfflineFeedService } from 'src/app/shared/services/offline/offline-feed.service';
import { OfflineMergeService } from 'src/app/shared/services/offline/offline-merge.service';
import { OfflineService } from 'src/app/shared/services/offline/offline.service';
import { PlatformMethod } from 'src/app/shared/services/offline/platform-methods';
import { PlatformService } from 'src/app/shared/services/offline/platform.service';
@Component({
  selector: 'bell-check-update',
  templateUrl: './check-update.component.html',
  styleUrls: ['./check-update.component.scss']
})
export class CheckUpdateComponent implements OnInit {

  subscription: Subscription;
  updateProgress = 0;

  constructor(
    private offlineMergeService: OfflineMergeService,
    private offlineFeedService: OfflineFeedService,
    private offlineService: OfflineService,
    private platformService: PlatformService,
    private router: Router,
    private zone: NgZone
  ) {
  }

  ngOnInit(): void {
    if (!this.offlineService.isOffline) {
      this.isUpdateNeeded().subscribe(result => {
        if (result) {
          this.checkUpdates();
        } else {
          this.zone.run(() => {
             this.router.navigate(['']);
          });
        }
      });
    }
  }

  private checkUpdates() {
    this.offlineService.setstateEvent(OfflineStates.UPDATING);
    this.offlineMergeService.checkUpdates();
    this.subscription = timer(0, 1000).pipe(
      switchMap(() => this.offlineMergeService.getProgressNumber())
    ).subscribe(result => {
      this.updateProgress = result;
      this.offlineService.setUpdateProgress(result);
      if (this.updateProgress === 100) {
        this.offlineService.setstateEvent(OfflineStates.UP_TO_DATE);
        this.subscription.unsubscribe();
        this.zone.run(() => {
          this.router.navigate(['']);
        })
      }
    });
  }

  private isUpdateNeeded(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (this.offlineService.isOfflineCapable && !this.offlineService.isOffline) {
        this.platformService.invokePlatformMethod<boolean>(PlatformMethod.checkInstanceUpdateStatus).subscribe(isUpdateTriggered => {
          if (!isUpdateTriggered) {
            console.log(isUpdateTriggered);
            this.platformService.invokePlatformMethod(PlatformMethod.setInstanceUpdateStatus);
            this.offlineFeedService.isActivated().subscribe(isActivatedResult => {
              if (isActivatedResult) {
                observer.next(true);
                observer.complete();
              } else {
                this.offlineService.setstateEvent(OfflineStates.UP_TO_DATE);
                observer.next(false);
                observer.complete();
              }
            });
          } else {
            observer.next(false);
            observer.complete();
            this.offlineService.setstateEvent(OfflineStates.UP_TO_DATE);
          }
        })
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }
}
