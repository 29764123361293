import { Injectable } from '@angular/core';
import { ApiUriService } from './api-uri.service';
import { HttpUtilityService } from '../utils/http-utility.service';
import { AppInjectorService } from '../utils/app-injector.service';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceBase {
  [x: string]: any;

  protected httpUtility: HttpUtilityService;
  protected apiUri: ApiUriService;
  protected environmentService: EnvironmentService;

  constructor() {
    this.setDependencies();
  }

  setDependencies() {
    const injector = AppInjectorService.getInjector();

    this.httpUtility = injector.get(HttpUtilityService);
    this.apiUri = injector.get(ApiUriService);
    this.environmentService = injector.get(EnvironmentService);
  }
}

