import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ZipEntry } from './zip-entry ';
import { ZipTask } from './zip-task';
import { ZipTaskProgress } from './zip-task-progress';
declare const zip: any;

@Injectable({
  providedIn: 'root'
})
export class ZipService {

  constructor() {
    zip.workerScriptsPath = 'assets/lib/';
  }

  getEntries(file: Blob): Observable<Array<ZipEntry>> {
    return new Observable(subscriber => {
      const reader = new zip.BlobReader(file);
      zip.createReader(reader, (zipReader: any) => {
        zipReader.getEntries(entries => {
          subscriber.next(entries);
          subscriber.complete();
        });
      }, (message: any) => {
        subscriber.error({ message });
      });
    });
  }

  getData(entry: ZipEntry): ZipTask {
    const startTime = new Date();
    console.log(entry.filename + '- size -' + entry.uncompressedSize + '- Start -' + startTime);
    const progress = new Subject<ZipTaskProgress>();
    const data = new Observable<Blob>(subscriber => {
      let writer: any;
      const path = entry.filename.toLowerCase();
      if (path.endsWith('.png')) {
        writer = new zip.BlobWriter('image/png');
        (entry as any).getData(writer, blob => {
          subscriber.next(blob);
          console.log(entry.filename + '- End -' + (new Date()));
          console.log(entry.filename + '- EndMs -' + ((new Date()).getTime() - startTime.getTime()));
          subscriber.complete();
          progress.next(null);
        }, (current, total) => {
          progress.next({ active: true, current, total });
        });
      } else if (path.endsWith('.jpg')) {
        writer = new zip.BlobWriter('image/jpeg');
        // Using `as any` because we don't want to expose this
        // method in the interface
        (entry as any).getData(writer, blob => {
          subscriber.next(blob);
          console.log(entry.filename + '- End -' + (new Date()));
          console.log(entry.filename + '- EndMs -' + ((new Date()).getTime() - startTime.getTime()));
          subscriber.complete();
          progress.next(null);
        }, (current, total) => {
          progress.next({ active: true, current, total });
        });
      } else {
        writer = new zip.TextWriter();
        (entry as any).getData(writer, content => {
          subscriber.next(content);
          console.log(entry.filename + '- End -' + (new Date()));
          console.log(entry.filename + '- EndMs -' + ((new Date()).getTime() - startTime.getTime()));
          subscriber.complete();
          progress.next(null);
        }, (current, total) => {
          progress.next({ active: true, current, total });
        });
      }
    });
    return { progress, data };
  }
}
