import { OnInit, Component, ViewChild, ElementRef, OnDestroy, ViewEncapsulation } from '@angular/core';
import { IetmPrintService } from 'src/app/viewer/ietm/ietm-print/ietm-print-service/ietm-print.service';

@Component({
  selector: 'bell-ietm-print',
  templateUrl: './ietm-print.component.html',
  styleUrls: ['./ietm-print.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class IetmPrintComponent implements OnInit, OnDestroy   {
  @ViewChild('ietmPrintComponent', { static: true }) private ietmPrintComponent: ElementRef;

  private printMainContainer: HTMLElement;

  constructor(
    public ptps: IetmPrintService,
  ) { }

  ngOnInit() {
    this.setPrintMainContainerElement();
  }

  setPrintMainContainerElement() {
    if (!this.printMainContainer) {
      this.printMainContainer = this.createPrintMainContainer();
    }

    this.ptps.setPrintMainContainerElement(this.printMainContainer);
  }

  createPrintMainContainer() {
    const printMainContainer = document.createElement('div');
    printMainContainer.setAttribute('id', 'printMainContainer');
    document.body.appendChild(printMainContainer);
    document.body.setAttribute('id', 'bodyPrintMainContainer');

    return printMainContainer;
  }

  ngOnDestroy() {
    const ietmPrintOutlet = this.ietmPrintComponent.nativeElement.parentNode;

    if (this.printMainContainer && this.printMainContainer.parentNode) {
      this.printMainContainer.parentNode.removeChild(this.printMainContainer);
      document.body.setAttribute('id', '');
    }

    if (ietmPrintOutlet) {
      ietmPrintOutlet.parentNode.removeChild(ietmPrintOutlet);
    }
  }
}
