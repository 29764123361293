export class News {
  public lastUpdate: string;
  public lastCheck: string;
  public previousUpdate: string;
  public header: string;
  public footer: string;
  public content: Array<NewsContent>;
}

export class NewsContent {
  public modelName: string;
  public model: string;
  public publications: Array<NewsPublication>;
}

export class NewsPublication {
  public name: string;
  public title: string;
  public modelFolder: string;
  public issue: string;
  public isS1000d: boolean;
  public filename: string;
}
