import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injector, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { EnvironmentService } from './shared/services/environment.service';
import { AppRoutingModule } from './app-routing.module';
import { ViewerModule } from './viewer/viewer.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/header/header.component';
import { SideNavComponent } from './core/side-nav/side-nav.component';
import { MainComponent } from './core/main/main.component';
import { CoreComponent } from './core/core.component';
import { CCodeModule } from './ccode/ccode.module';
import { TocModule } from './toc/toc.module';
import { NewsModule } from './news/news.module';
import { HeaderModule } from './core/header/header.module';
import { CoreModule } from './core/core.module';
import { SideNavModule } from './core/side-nav/side-nav.module';
import { SharedModule } from './shared/shared.module';
import { GeneralInfoModule } from './general-info/general-info.module';
import { TocService } from './shared/services/toc/toc.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { SearchComponent } from './search/search.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoginComponent } from './login/login.component';
import { MatIconModule } from '@angular/material/icon';
import { ServiceWorkerModule } from '@angular/service-worker';
import { OfflineModule } from './offline/offline.module';
import { AppInjectorService } from './shared/utils/app-injector.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { HttpEventInterceptor } from './shared/interceptor/http-event-interceptor';
import { IdleStorageService } from './shared/services/idle/storage/idle-storage.service';
import { IdleStorageExpiryService } from './shared/services/idle/storage/idle-storage-expiry.service';
import { IdleExpiryBaseService } from './shared/services/idle/base/idle-expiry-base.service';
import { IdleService } from './shared/services/idle/idle.service';
import { SpeedTestModule } from 'ng-speed-test';
import { UnAuthorizedComponent } from './un-authorized/un-authorized.component';
import { TPubErrorHandler } from './shared/utils/techpub-logger';
import { environment } from '@environments/environment';
import { MauiModule } from './maui/maui.module';
import { LoginService } from './shared/services/user/login.service';
import { LoginServiceFactory } from './shared/services/user/login.service.provider';
import { OfflineService } from './shared/services/offline/offline.service';
import { Router } from '@angular/router';
import { ApiUriService } from './shared/services/api-uri.service';
import { MauiInterceptor } from './maui/interceptors/maui.interceptor';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { APP_BASE_HREF } from '@angular/common';
import { PlatformService } from './shared/services/offline/platform.service';
import { PlatformServiceFactory } from './shared/services/offline/platform.service.provider';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        MainComponent,
        CoreComponent,
        SideNavComponent,
        SearchComponent,
        LoginComponent,
        UnAuthorizedComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ViewerModule,
        BrowserAnimationsModule,
        HeaderModule,
        CoreModule,
        GeneralInfoModule,
        SideNavModule,
        SharedModule,
        HttpClientModule,
        CCodeModule,
        TocModule,
        OfflineModule,
        NewsModule,
        MatSnackBarModule,
        MatDialogModule,
        MatCheckboxModule,
        MatButtonModule,
        FormsModule,
        MatPaginatorModule,
        MatIconModule,
        MatProgressBarModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          registrationStrategy: 'registerImmediately'
        }),
        HammerModule,
        SpeedTestModule,
        // only load maui if built for maui
        MauiModule
    ],
    providers: [
        EnvironmentService,
        { provide: PlatformService, useFactory: PlatformServiceFactory, deps: [EnvironmentService] },
        TocService,
        environment.maui ? {provide: HTTP_INTERCEPTORS, useClass: MauiInterceptor, multi: true}: [],
        { provide: HTTP_INTERCEPTORS, useClass: HttpEventInterceptor, multi: true },
        { provide: APP_BASE_HREF, useFactory: () => { return (document.querySelector('base')?.getAttribute('href')) ?? '/';}},
        IdleStorageService,
        IdleStorageExpiryService,
        { provide: IdleExpiryBaseService, useExisting: IdleStorageExpiryService },
        IdleService,
        { provide: ErrorHandler, useClass: TPubErrorHandler },
        { provide: LoginService, useFactory: LoginServiceFactory, deps: [EnvironmentService,ApiUriService,OfflineService,Router,PlatformService,HttpClient]}
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {
  constructor(
    injector: Injector
  ) {
    AppInjectorService.setInjector(injector);
  }
}
