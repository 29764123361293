import { Injectable } from '@angular/core';
import { TpubDbService } from './tpub-db.service';
import { Dm } from '../../../data-model/idb/dm';
import { dmStoreName } from '../../../constants/indexed-store-config';
import { Observable, Observer } from 'rxjs';
import { Dexie } from 'dexie';

@Injectable({
  providedIn: 'root'
})
export class DmService {
  public table: Dexie.Table<Dm>;

  constructor(private tpubDbService: TpubDbService) {
    this.table = this.tpubDbService.table(dmStoreName);
  }

  add(data: Dm): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.table.add(data).then(result => {
        observer.next(true);
        observer.complete();
      }, (error) => {
        observer.error(error);
      });
    });
  }

  addBulk(data: Dm[]): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (data && data.length > 0) {
        this.table.bulkAdd(data).then((result) => {
          observer.next(true);
          observer.complete();
        }, (error) => {
          observer.error(error);
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

  clear(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.table.clear().then(() => {
        observer.next(true);
        observer.complete();
      }, (err) => {
        observer.error(err);
      });
    });
  }

  getAll(): Observable<Dm[]> {
    return new Observable((observer: Observer<Dm[]>) => {
      this.table.toArray().then((data: Dm[]) => {
        observer.next(data);
        observer.complete();
      }, (error) => {
        observer.error(error);
      });
    });
  }

  getByIdDms(idDms: string[]): Observable<Dm[]> {
    return new Observable((observer: Observer<Dm[]>) => {
      this.table.where('idDm').anyOfIgnoreCase(idDms).toArray().then((data: Dm[]) => {
        observer.next(data);
        observer.complete();
      }, (error) => {
        observer.error(error);
      });
    });
  }

  remove(id: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.table.delete(id).then(() => {
        observer.next(true);
        observer.complete();
      }, (err) => {
        observer.error(err);
      });
    });
  }

  removeBulk(data: Dm[]): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (data && data.length > 0) {
        const dmIds: number[] = [];
        data.forEach(dm => dmIds.push(dm.rowid));
        this.table.bulkDelete(dmIds).then(() => {
          observer.next(true);
          observer.complete();
        }, (err) => {
          observer.error(err);
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

  update(id: number, data: Dm): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.table.update(id, data).then(() => {
        observer.next(true);
        observer.complete();
      }, (err) => {
        observer.error(err);
      });
    });
  }

  updateBulk(data: Dm[]): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (data && data.length > 0) {
        this.table.bulkPut(data).then(() => {
          observer.next(true);
          observer.complete();
        }, (err) => {
          observer.error(err);
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }
}
