import { FlatTreeControl } from '@angular/cdk/tree';
import { Injectable } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import {
  IetmFigure, IetmFigureFlatNode, IetmFigureNode, IetmManual, IetmPara, IetmParaFlatNode, IetmSns, IetmTable, IetmTableFlatNode,
  ManualTreeNode, SnsTreeNode, TocFlatNode
} from './ietm-service/ietm-model';
import { IetmService } from './ietm.service';
import { IetmBreadcrumbService } from './ietm-breadcrumb.service';
import { BehaviorSubject, Observable, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IetmTocService {
  public manualTreeControl: FlatTreeControl<TocFlatNode>;
  public snsTreeControl: FlatTreeControl<TocFlatNode>;
  public moduleParaTreeControl;
  public moduleTableTreeControl;
  public moduleFigureTreeControl;

  public manualTreeDataSource: MatTreeFlatDataSource<ManualTreeNode, TocFlatNode>;
  public snsTreeDataSource: MatTreeFlatDataSource<SnsTreeNode, TocFlatNode>;
  public moduleParaTreeDataSource: MatTreeFlatDataSource<IetmPara, IetmParaFlatNode>;
  public moduleTableTreeDataSource: MatTreeFlatDataSource<IetmTable, IetmTableFlatNode>;
  public moduleFigureTreeDataSource: MatTreeFlatDataSource<IetmFigureNode, IetmFigureFlatNode>;

  private manualTreeNodes: ManualTreeNode[];
  private snsTreeNodes: SnsTreeNode[];
  public _selectedManualNode: BehaviorSubject<TocFlatNode | null> = new BehaviorSubject (null);

  public selectedSnsNode: TocFlatNode;
  public selectedManual: IetmManual;
  public selectedManualNode$: Observable<TocFlatNode | null> = this._selectedManualNode.asObservable();

  public systems: any[];
  public subSystems: any[];
  public infoCodes: any[];
  public manuals: any[];

  public systemFilter: SnsTreeNode;
  public subSystemFilter: SnsTreeNode;
  public infoCodeFilter: any;
  public manualFilter: any;

  public isModuleTabDisabled = true;
  public isSnsTabDisabled = true;
  private _model: string;
  private _pmc: string;
  private _dmc: string;

  get model(): string { return this._model; }
  set model(value: string) { }
  get pmc(): string { return this._pmc; }
  set pmc(value: string) { }
  get dmc(): string { return this._dmc; }
  set dmc(value: string) { }
  get selectedManualNode(): TocFlatNode { return this._selectedManualNode.value;}
  set selectedManualNode(value: TocFlatNode){ this._selectedManualNode.next(value)}

  public manualName: string;

  constructor(
    private ietmService: IetmService,
    private breadcrumb: IetmBreadcrumbService
  ) {
    this.manualTreeControl = new FlatTreeControl<TocFlatNode>(node => node.level, node => node.expandable);
    this.snsTreeControl = new FlatTreeControl<TocFlatNode>(node => node.level, node => node.expandable);
    this.moduleParaTreeControl = new FlatTreeControl<IetmParaFlatNode>(node => node.level, node => node.expandable);
    this.moduleTableTreeControl = new FlatTreeControl<IetmTableFlatNode>(node => node.level, node => node.expandable);
    this.moduleFigureTreeControl = new FlatTreeControl<IetmFigureFlatNode>(node => node.level, node => node.expandable);
    const flattener = new MatTreeFlattener(this.transformer, node => node.level, node => node.expandable, node => node.children);
    const ietmParaFlattener = new MatTreeFlattener(this.ietmParaTransformer,
      node => node.level, node => node.expandable, node => node.para);
    const ietmTableFlattener = new MatTreeFlattener(this.ietmTableTransformer,
      node => node.level, node => node.expandable, node => node.children);
    const ietmFigureFlattener = new MatTreeFlattener(this.ietmFigureTransformer,
      node => node.level, node => node.expandable, node => node.children);
    this.snsTreeDataSource = new MatTreeFlatDataSource(this.snsTreeControl, flattener);
    this.manualTreeDataSource = new MatTreeFlatDataSource(this.manualTreeControl, flattener);
    this.moduleParaTreeDataSource = new MatTreeFlatDataSource(this.moduleParaTreeControl, ietmParaFlattener);
    this.moduleTableTreeDataSource = new MatTreeFlatDataSource(this.moduleTableTreeControl, ietmTableFlattener);
    this.moduleFigureTreeDataSource = new MatTreeFlatDataSource(this.moduleFigureTreeControl, ietmFigureFlattener);
    this.ietmService.sns$.subscribe(sns => this.handleSnsChange(sns));
    this.ietmService.manual$.subscribe(manual => this.handleManualChange(manual));
    this.ietmService.route.dmc$.subscribe(dmc => this.handleModuleChange(dmc));
    this.ietmService.route.scrollTarget$.subscribe(scrollTarget => this.handleScrollTargetChange(scrollTarget));
  }

  handleSnsChange(newSns: IetmSns) {
    if (newSns) {
      this.isSnsTabDisabled = false;
      this._model = newSns.model;
      const snsData = newSns.data;
      this.infoCodes = snsData.infoCodes;
      this.manuals = snsData.manuals;
      this.snsTreeNodes = this.setSnsData(snsData.sns);
      this.snsTreeDataSource.data = this.snsTreeNodes;
      this.systems = this.snsTreeNodes.filter(snsItem => snsItem.type === 'sns');
    } else {
      this.isSnsTabDisabled = true;
    }
  }

  setSnsData(sns: SnsTreeNode[]): SnsTreeNode[] {
    for (let i = 0; i < sns.length; i++) {
      const node = sns[i];

      if (node.dmc && node.dmc.indexOf('00SA') >= 0) {
        node.showSnsNode = true;
      } else {
        node.showSnsNode = false;
      }

      if (node.children) {
        this.setSnsDataChildren(node.children, node.title);
      }
    }

    return sns;
  }

  setSnsDataChildren(childrenNodes: SnsTreeNode[], parentTitle?: string) {
    for (let j = 0; j < childrenNodes.length; j++) {
      let childTitle: string = null;
      const childNode = childrenNodes[j];

      if (childNode.dmc && childNode.dmc.indexOf('00SA') >= 0) {
        childNode.showSnsNode = true;
      } else {
        childNode.showSnsNode = false;
      }

      if (parentTitle !== null && childNode.title && childNode.title !== null) {
        if (parentTitle.toLowerCase() === childNode.title.toLowerCase()) {
          childNode.showSnsNodeTitle = true;
        } else {
          childNode.showSnsNodeTitle = false;
        }
      }

      if (!childNode.routerlink) {
        childNode.routerlink = this.setSnsRouterLink(childNode);
      }

      if (childNode.children) {
        if (childNode.title) {
          childTitle = childNode.title;
        }
        this.setSnsDataChildren(childNode.children, childTitle);
      }
    }
  }

  setSnsRouterLink(childNode: SnsTreeNode): Array<string> {
    let routerLink = [];

    if (childNode.type === 'dmc') {
      routerLink = ['/ietm', this._model, childNode.pmc, childNode.dmc];
      const subtitle = childNode.subtitle.toLowerCase();

      if (subtitle.indexOf('illustrated parts data') >= 0 || subtitle.indexOf('wiring diagrams') >= 0) {
        this.getIcnLinks(this._model, childNode.pmc, childNode.dmc).subscribe((icn) => {
          if (icn) {
            routerLink.push(icn);
          }
        });
      }
    } else if (childNode.title3d) {
      routerLink = this.getSnsNodeAddress3d();
    }

    return routerLink;
  }

  handleManualChange(newManual: IetmManual) {
    if (newManual) {
      this._model = newManual.model;
      this._pmc = newManual.pmc;
      this.manualName = newManual.title;
      this.selectedManual = newManual;
      this.manualTreeNodes = this.setManualData(newManual.tree);
      this.manualTreeDataSource.data = this.manualTreeNodes;
      this.handleDmc();
    } else {
      this.manualName = undefined;
    }
  }

  setManualData(manualTreeNode: ManualTreeNode[]): ManualTreeNode[] {
    for (let i = 0; i < manualTreeNode.length; i++) {
      const node = manualTreeNode[i];

      if (node.dm && node.dm.dmCode && node.dm.dmCode.dmc) {
        node.nodeId = 'toc' + node.dm.dmCode.dmc;
      } else {
        node.nodeId = 'toc' + node.title;
      }

      if (!node.routerlink) {
        node.routerlink = this.setManualRouterLink(node);
      }

      if (node.children) {
        this.setManualDataChildren(node.children);
      }
    }

    return manualTreeNode;
  }

  setManualDataChildren(manualChildren: ManualTreeNode[]) {
    for (let j = 0; j < manualChildren.length; j++) {
      const childNode = manualChildren[j];

      if (childNode.dm && childNode.dm.dmCode && childNode.dm.dmCode.dmc) {
        childNode.nodeId = 'toc' + childNode.dm.dmCode.dmc;
      } else {
        childNode.nodeId = 'toc' + childNode.title;
      }

      if (!childNode.routerlink) {
        childNode.routerlink = this.setManualRouterLink(childNode);
      }

      if (childNode.children) {
        this.setManualDataChildren(childNode.children);
      }
    }
  }

  setManualRouterLink(manualChild: ManualTreeNode): string[] {
    let dmc: string = null;
    let icn: string = null;
    let infoCode: string = null;

    if (manualChild.dm && manualChild.dm.dmCode) {
      if (manualChild.dm.dmCode.dmc) {
        dmc = manualChild.dm.dmCode.dmc;
      }

      if (manualChild.dm.dmCode.infoCode) {
        infoCode = manualChild.dm.dmCode.infoCode;
      }
    }

    if (infoCode !== null && dmc !== null) {
      if (this.isManualWireDiagram(manualChild, infoCode)) {
        icn = manualChild.dm.figures[0].sheets[0].icn;

        return ['/ietm', this._model, this._pmc, dmc, icn];
      } else if (this.isManualIPD(manualChild, infoCode) && this.selectedManual) {
        const dm = this.selectedManual.lof.find(fig => fig.dmc === dmc);

        if (dm && dm.figures) {
          icn = dm.figures[0].sheets[0].icn;

          return ['/ietm', this._model, this._pmc, dmc, icn];
        }
      }
    }

    if (manualChild.type === 'dataModule' && dmc !== null) {
      return ['/ietm', this._model, this._pmc, dmc];
    }
  }

  isManualWireDiagram(manualChild: ManualTreeNode, infoCode: string) {
    return manualChild.type === 'dataModule' && infoCode === '051' && manualChild.dm.figures &&
      manualChild.dm.figures.length > 0 && manualChild.dm.figures[0].sheets && manualChild.dm.figures[0].sheets.length > 0;
  }

  isManualIPD(manualChild: ManualTreeNode, infoCode: string) {
    return manualChild.type === 'dataModule' && infoCode === '941';
  }

  handleDmc(): void {
    const selectedDmcNode = this.manualTreeControl.dataNodes ? this.manualTreeControl.dataNodes
      .find(manualNode => manualNode.nodeInfo.dmc && manualNode.nodeInfo.dmc['dmc'] === this._dmc) : null;
    if (selectedDmcNode) {
      this.setModuleTabData(selectedDmcNode);
      this.selectedManualNode = selectedDmcNode;
      this.expandToSelectedManualNode();
    } else {
      this.clearModuleTabData();
      this.isModuleTabDisabled = true;
    }
  }

  handleModuleChange(dmc: string) {
    this._dmc = dmc;
    const selectedNode = this.snsTreeControl.dataNodes ?
      this.snsTreeControl.dataNodes.find(snsNode => snsNode.nodeInfo.dmc === this._dmc) : null;
    if (selectedNode) {
      this.selectedSnsNode = selectedNode;
      this.expandToSelectedSnsNode();
    }
    this.handleDmc();
  }

  handleScrollTargetChange(scrollTarget: string): void {
    const element = document.getElementById(scrollTarget);
    const container = document.getElementById('ietm_module_container');
    if (element && container) {
      container.scrollTop = element.offsetTop - container.offsetTop;
    }
  }

  goToTop() {
    let top = document.getElementById('ietm_module');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  clearModuleTabData(): void {
    this.moduleParaTreeDataSource.data = [];
    this.moduleTableTreeDataSource.data = [];
    this.moduleFigureTreeDataSource.data = [];
  }

  setModuleTabData(manualNode: TocFlatNode) {
    if (manualNode.nodeInfo.dm) {
      if (manualNode.nodeInfo.dm.para) {
        this.moduleParaTreeDataSource.data = manualNode.nodeInfo.dm.para;
      } else {
        this.moduleParaTreeDataSource.data = [];
      }
      if (manualNode.nodeInfo.dm.tables && manualNode.nodeInfo.dm.tables.length > 0) {
        this.moduleTableTreeDataSource.data = [
          {
            id: null,
            title: 'List of Tables',
            children: manualNode.nodeInfo.dm.tables
          }
        ];
      } else {
        this.findTablesInIetmManual();
      }
      if (manualNode.nodeInfo.dm.figures && manualNode.nodeInfo.dm.figures.length > 0) {
        this.moduleFigureTreeDataSource.data = [
          {
            icn: null,
            label: 'List of Figures',
            children: this.prepareIetmFigureNodes(manualNode.nodeInfo.dm.figures)
          }
        ];
      } else {
        this.findFiguresInIetmManual();
      }
      if (this.moduleTableTreeDataSource.data.length > 0 ||
        this.moduleFigureTreeDataSource.data.length > 0 ||
        this.moduleTableTreeDataSource.data.length > 0) {
        this.isModuleTabDisabled = false;
      } else {
        this.isModuleTabDisabled = true;
      }
    } else {
      this.clearModuleTabData();
      this.isModuleTabDisabled = true;
    }
  }

  findTablesInIetmManual() {
    if (this.selectedManual.lot && this.selectedManual.lot.length > 0) {
      const dmc = this.selectedManual.lot.find(node => node.dmc && node.dmc === this._dmc);
      if (dmc && dmc.tables && dmc.tables.length > 0) {
        this.moduleTableTreeDataSource.data = [
          {
            id: null,
            title: 'List of Tables',
            children: dmc.tables
          }
        ];
      } else {
        this.moduleTableTreeDataSource.data = [];
      }
    } else {
      this.moduleTableTreeDataSource.data = [];
    }
  }

  findFiguresInIetmManual() {
    if (this.selectedManual.lof && this.selectedManual.lof.length > 0) {
      const dmc = this.selectedManual.lof.find(node => node.dmc && node.dmc === this._dmc);
      if (dmc && dmc.figures && dmc.figures.length > 0) {
        this.moduleFigureTreeDataSource.data = [
          {
            icn: null,
            label: 'List of Figures',
            children: this.prepareIetmFigureNodes(dmc.figures)
          }
        ];
      } else {
        this.moduleFigureTreeDataSource.data = [];
      }
    } else {
      this.moduleFigureTreeDataSource.data = [];
    }
  }

  prepareIetmFigureNodes(nodes: Array<IetmFigure>): Array<IetmFigureNode> {
    const ietmFigureNodes: Array<IetmFigureNode> = [];
    nodes.forEach(node => {
      if (node.nbSheets === '1') {
        ietmFigureNodes.push({
          icn: node.sheets[0].icn,
          label: node.title
        });
      } else {
        const children: Array<IetmFigureNode> = [];
        node.sheets.forEach(sheet => {
          children.push({
            label: 'Sheet ' + sheet.position + ' of ' + node.nbSheets,
            icn: sheet.icn
          });
        });
        ietmFigureNodes.push({
          label: node.title,
          icn: null,
          children: children
        });
      }
    });
    return ietmFigureNodes;
  }

  filterBySystem(system) {
    this.systemFilter = system;
    this.subSystemFilter = undefined;
    if (!this.systemFilter) {
      this.subSystems = [];
    }
    this.applyFilters();
  }

  filterBySubSystem(subSystem) {
    this.subSystemFilter = subSystem;
    this.applyFilters();
  }

  filterByInfoCode(infoCode) {
    this.infoCodeFilter = infoCode;
    this.applyFilters();
  }

  filterByManual(manual) {
    this.manualFilter = manual;
    this.infoCodeFilter = undefined;
    this.subSystemFilter = undefined;
    this.systemFilter = undefined;
    this.applyFilters();
  }

  private async applyFilters() {
    let filterTree = this.snsTreeNodes;
    if (this.systemFilter && this.systemFilter.children) {
      filterTree = this.applySystemFilter(filterTree);
    }
    if (this.subSystemFilter) {
      filterTree = this.applySubSystemFilter();
    }
    if (this.infoCodeFilter) {
      filterTree = this.applyInfoCodeFilter(filterTree);
    }
    if (this.manualFilter) {
      filterTree = await this.applyManualFilter(filterTree);
    }
    this.snsTreeDataSource.data = filterTree;
    if (!this.systemFilter) {
      this.systems = filterTree.filter(snsItem => snsItem.type === 'sns');
    }
    this.expandToSelectedSystem();
  }

  private applySystemFilter(filterTree: SnsTreeNode[]): SnsTreeNode[] {
    this.subSystems = this.systemFilter.children.filter(child => child.type === 'sns');
    return filterTree.filter(snsItem => snsItem.id === this.systemFilter.id);
  }

  private applySubSystemFilter(): SnsTreeNode[] {
    const selectedSytemCopy = JSON.parse(JSON.stringify(this.systemFilter));
    selectedSytemCopy.children = this.systemFilter.children.filter(childNode =>
      childNode.id === this.subSystemFilter.id || childNode.type !== 'sns'
    );
    return [selectedSytemCopy];
  }

  private applyInfoCodeFilter(filterTree: SnsTreeNode[]): SnsTreeNode[] {
    const infoCode = this.infoCodeFilter.key;
    const filterFunction = function (dmc: SnsTreeNode): boolean {
      return (dmc.dmc.indexOf(infoCode) >= 0);
    };
    return filterTree.map(node => this.snsFilterHelper(node, filterFunction)).filter(node => node !== null);
  }

  private async applyManualFilter(filterTree: SnsTreeNode[]): Promise<SnsTreeNode[]> {
    const filterPmc = this.manualFilter.pmc;
    const dmcList = (await this.ietmService.getManual(this._model, filterPmc).toPromise()).dmList;
    const filterFunction = function (dmc: SnsTreeNode): boolean {
      return (dmcList.findIndex(dmcItem => dmcItem === dmc.dmc) >= 0);
    };
    return filterTree.map(node => this.snsFilterHelper(node, filterFunction)).filter(node => node !== null);
  }

  private snsFilterHelper(recurseNode: SnsTreeNode, filterFunction: (_: SnsTreeNode) => boolean): SnsTreeNode {
    if (recurseNode.children) {
      let filteredChildren = recurseNode.children.map(child => this.snsFilterHelper(child, filterFunction));
      filteredChildren = filteredChildren.filter(child => child !== null);
      if (filteredChildren.length > 0) {
        recurseNode.children = filteredChildren;
        return recurseNode;
      } else {
        return null;
      }
    } else {
      if (filterFunction(recurseNode)) {
        return recurseNode;
      } else {
        return null;
      }
    }
  }

  private ietmParaTransformer = (node: IetmPara, level: number) => {
    const ietmParaFlatNode: IetmParaFlatNode = {
      expandable: !!node.para && node.para.length > 0,
      nodeInfo: node,
      level: level
    };
    return ietmParaFlatNode;
  }

  private ietmTableTransformer = (node: IetmTable, level: number) => {
    const ietmTableFlatNode: IetmTableFlatNode = {
      expandable: !!node.children && node.children.length > 0,
      nodeInfo: node,
      level: level
    };
    return ietmTableFlatNode;
  }

  private ietmFigureTransformer = (node: IetmFigureNode, level: number) => {
    const ietmFigureFlatNode: IetmFigureFlatNode = {
      expandable: !!node.children && node.children.length > 0,
      nodeInfo: node,
      level: level
    };
    return ietmFigureFlatNode;
  }

  private transformer = (node, level: number) => {
    const flatNode: TocFlatNode = {
      expandable: !!node.children && node.children.length > 0,
      nodeInfo: null,
      level: level,
      selected: false
    };
    switch (node.type) {
      case 'entry':
        flatNode.nodeInfo = { title: node.title, id: node.id, type: 'entry' };
        break;
      case 'dataModule':
        // redefine dmCode for standardization
        Object.defineProperty(node, 'dmc',
          Object.getOwnPropertyDescriptor(node, 'dmCode'));
        delete node.dmCode;
        flatNode.nodeInfo = node;
        break;
      case 'sns':
        flatNode.nodeInfo = { title: node.title, title3d: node.title3d, id: node.id, type: 'sns' };
        break;
      case 'dmc':
        flatNode.nodeInfo = node;
    }
    return flatNode;
  }

  hasChild = (_: number, node: TocFlatNode | IetmParaFlatNode | IetmTableFlatNode | IetmFigureFlatNode) => node.expandable;

  private getParent(treeControl: FlatTreeControl<TocFlatNode>, node: TocFlatNode): TocFlatNode {
    let foundNode = null;
    const parentNodes = treeControl.dataNodes.filter(dNode => dNode.level === node.level - 1);
    if (parentNodes && parentNodes.length > 0) {
      for (const parentNode of parentNodes) {
        if (treeControl.getDescendants(parentNode).find(cNode => cNode === node)) {
          foundNode = parentNode;
          break;
        }
      }
    }
    return foundNode;
  }

  expandToSelectedSnsNode() {
    let node = this.getParent(this.snsTreeControl, this.selectedSnsNode);
    while (node) {
      this.snsTreeControl.expand(node);
      node = this.getParent(this.snsTreeControl, node);
    }
    const scrollTarget = document.getElementById('toc' + this.selectedSnsNode.nodeInfo.dmc);
    if (document.getElementsByClassName('mat-tab-body-active').length > 0) {
      const tabContentTarget = document.getElementsByClassName('mat-tab-body-active')[0]
        .getElementsByClassName('tab-content')[0].parentElement;
      if (scrollTarget && tabContentTarget) {
        window.setTimeout(() => tabContentTarget.scrollTop = scrollTarget.offsetTop - tabContentTarget.offsetTop, 50);
      }
    }
  }

  expandToSelectedManualNode() {
    let node = this.getParent(this.manualTreeControl, this.selectedManualNode);
    while (node) {
      this.manualTreeControl.expand(node);
      node = this.getParent(this.manualTreeControl, node);
    }
    if (this.selectedManualNode.nodeInfo.dm) {
      const scrollTarget = document.getElementById('toc' + this.selectedManualNode.nodeInfo.dm.dmCode.dmc);
      if (document.getElementsByClassName('mat-tab-body-active').length > 0) {
        const tabContentTarget = document.getElementsByClassName('mat-tab-body-active')[0]
          .getElementsByClassName('tab-content')[0].parentElement;
        if (scrollTarget && tabContentTarget) {
          window.setTimeout(() => tabContentTarget.scrollTop = scrollTarget.offsetTop - tabContentTarget.offsetTop, 50);
        }
      }
    }
  }

  expandToSelectedSystem() {
    if (this.systemFilter) {
      this.snsTreeControl.expand(this.snsTreeControl.dataNodes.find(
        node => node.nodeInfo.id === this.systemFilter.id && node.level === 0
      ));
    }
    if (this.subSystemFilter) {
      this.snsTreeControl.expand(this.snsTreeControl.dataNodes.find(
        node => node.nodeInfo.id === this.subSystemFilter.id && node.level === 0
      ));
    }
  }

  expandModuleTrees() {
    this.moduleParaTreeControl.expandAll();
    this.moduleTableTreeControl.expandAll();
    this.moduleFigureTreeControl.expandAll();
  }

  collapseModuleTrees() {
    this.moduleParaTreeControl.collapseAll();
    this.moduleTableTreeControl.collapseAll();
    this.moduleFigureTreeControl.collapseAll();
  }

  getSnsNodeAddress3d(): Array<string> {
    return ['/ietm', this._model, this._pmc, this._dmc];
  }

  getIcnLinks(model, pmc, dmc): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.ietmService.getManual(model, pmc).subscribe((m) => {
        let icn = null;
        if (m && m.lof && m.lof.length > 0) {
          const dmcIcn = m.lof.find(node => node.dmc && node.dmc === dmc);
          if (dmcIcn && dmcIcn.figures && dmcIcn.figures.length > 0) {
            const dmcFigures = this.prepareIetmFigureNodes(dmcIcn.figures);
            for (let i = 0; i < dmcFigures.length; i++) {
              const f = dmcFigures[i];
              if (f['icn'] !== null) {
                icn = f['icn'];
              }
              if (f['children'] && f['children'].length > 0) {
                icn = f['children'][0]['icn'];
              }
            }
          }
        }
        observer.next(icn);
        observer.complete();
      }
      );
    });
  }

  getDmcIcn(model, pmc, dmc): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.ietmService.getManual(model, pmc).subscribe((m) => {
        let dmcIcn = null;
        if (m && m.lof && m.lof.length > 0) {
          dmcIcn = m.lof.find(node => node.dmc && node.dmc === dmc);
        }
        observer.next(dmcIcn);
        observer.complete();
      });
    });
  }

  getDmcObjectFromSnsTree(dmc): TocFlatNode {
    return this.snsTreeControl.dataNodes ? this.snsTreeControl.dataNodes.find(snsNode => snsNode.nodeInfo.dmc === dmc) : null;
  }
  getDmcObjectFromManualTree(dmc): TocFlatNode {
      return this.manualTreeControl.dataNodes ? this.manualTreeControl.dataNodes.find(manualNode => manualNode.nodeInfo.dmc && manualNode.nodeInfo.dmc["dmc"] === dmc) : null;
  }

  getParaTableNodeAddress() {
    return ['/ietm', this._model, this._pmc, this._dmc];
  }

  getFigureNodeAddress(figureNode: IetmFigureFlatNode) {
    if (figureNode.nodeInfo.icn) {
      return ['/ietm', this._model, this._pmc, this._dmc, figureNode.nodeInfo.icn];
    }
  }

  clearHistory(node: TocFlatNode) {
    if (this.breadcrumb) {
      this.breadcrumb.clearHistory(node.nodeInfo.dmc);
    }
  }
}
