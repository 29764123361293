import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  getParameter(param: string): string {
    return this.lookForParam(this.route.snapshot, param);
  }

  lookForParam(ars: ActivatedRouteSnapshot, param: string): string {
    let result = ars.paramMap.get(param);
    if (!result) {
      // if not found, look into children routes
      ars.children.forEach(child => {
        if (child.paramMap.get(param)) {
          result = child.paramMap.get(param);
          // remove fragment from param (ex: /ietm/505/PMC-505-97499-07000-00/DMC-505-A-21-20-02-00A-941A-A#par-001 removes #par-001)
          const fragmentStartIndex = result.indexOf('#');
          result = result.substring(0, fragmentStartIndex === -1 ? result.length : fragmentStartIndex);
        } else {
          // loop recursively through children
          const recRes = this.lookForParam(child, param);
          if (recRes) {
            result = recRes;
          }
        }
      });
    }
    return result;
  }

  getCurrentUrl(): string {
    return this.router.url;
  }

  getQueryParameter(param: string): string {
    if (this.route.snapshot.queryParamMap) {
      return this.route.snapshot.queryParamMap.get(param);
    }
    return null;
  }

  hasQueryParameters(): boolean {
    if (this.route.snapshot.queryParamMap) {
      return this.route.snapshot.queryParamMap.keys.length > 0;
    }
    return false;
  }

}
