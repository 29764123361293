import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

export class IpcEvent {
  itemNumber: string;
  isHovering: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class IetmEventService {
  public ipcEvent: BehaviorSubject<IpcEvent>;
  public ipcEvent$: Observable<IpcEvent>;

  constructor() {
    this.ipcEvent = new BehaviorSubject<IpcEvent>({itemNumber: '', isHovering: false});
    this.ipcEvent$ = this.ipcEvent.asObservable();
  }

  emitIpcEvent(event: IpcEvent) {
    this.ipcEvent.next(event);
  }
}
