export const GeneralInfoLinks: Array<GeneralInfoLink> = [
  {
    path: '/weightbalance',
    label: 'Weight and Balance',
    target: 'component'
  },
  {
    path: '/revisionstatus',
    label: 'Revision Status',
    target: 'component'
  },
  {
    path: '/relatedlinks',
    label: 'Related Links',
    target: 'component'
  },
  {
    path: '9270',
    label: 'Consumables List',
    target: 'pdf'
  },
  {
    path: '/recordcards',
    label: 'Record Cards',
    target: 'component'
  },
  {
    path: '/binderlabels',
    label: 'Binder Labels',
    target: 'component'
  }
];

export class GeneralInfoLink {
  path: string;
  label: string;
  target: string;
}
