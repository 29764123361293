<h3 class="page-title">RECORD CARDS</h3>
<div id="content-body">
  <div id="hrc-page">
    <div class="weight-balance-description">
      <p>The following Record cards are provided for your convenience. Simply click on the links below to download or print the required card.</p>
    </div>
    <ul class="publications">
      <ng-container *ngFor="let recordCardsLinks of recordCardsLinkSections">
        <li class="pub" *ngFor="let link of recordCardsLinks; let i = index">
          <div class="publication-icon icon-download-arrow icon" (click)="getRecordCardPdf(link)"></div>
          <div class="pub-title" (click)="getRecordCardPdf(link)">{{link.label}}</div>
        </li>
        <br>
      </ng-container>
    </ul>
  </div>
</div>
