import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IetmPrintService } from 'src/app/viewer/ietm/ietm-print/ietm-print-service/ietm-print.service';

@Component({
  selector: 'bell-ietm-print-layout',
  templateUrl: './ietm-print-layout.component.html',
  styleUrls: ['./ietm-print-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class IetmPrintLayoutComponent implements OnInit {
  constructor(
    public ptps: IetmPrintService
  ) {}

  ngOnInit() { }
}
