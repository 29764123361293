import { Component, OnInit } from '@angular/core';
import { NavigatorService } from 'src/app/shared/services/navigator.service';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'bell-related-links',
  templateUrl: './related-links.component.html',
  styleUrls: ['./related-links.component.scss'],
  viewProviders: [MatExpansionPanel]
})
export class RelatedLinksComponent implements OnInit {
  linkSections = [
      { path: 'http://www.bellhelicopter.com/support-and-service', label: 'Product Support', target: '_blank', collapsed: true, children: [
        {path: 'http://www.bellcustomer.com/RADS', label: 'RADS Download Files', target: '_blank'},
        {path: 'http://quicksearch.dla.mil/', label: 'Qualified Product List', target: '_blank'},
      ]},
      { path: 'http://www.bellhelicopter.com/support-and-service/support/css-directory/customer-service-engineers',
          label: 'Customer Support Engineers', target: '_blank', collapsed: false},
      { label: 'Airworthiness Directives', collapsed: true, children: [
        {path: 'https://www.faa.gov/regulations_policies/airworthiness_directives',
          label: 'FAA (Federal Aviation Administration)', target: ''},
        {path: 'https://wwwapps.tc.gc.ca/saf-sec-sur/2/cawis-swimn/', label: 'Transport Canada', target: '_blank'},
        {path: 'http://www.easa.europa.eu/', label: 'European Aviation Safety Agency (EASA)', target: '_blank'},
      ]},
      { label: 'Engine Manufacturers', collapsed: true, children: [
        {path: 'https://www.prattwhitney.com/en/services/pwc-engine-services/technical-publications', label: 'Pratt & Whitney', target: '_blank'},
        {path: 'http://www.rolls-royce.com/customers/civil-aerospace/services/technical-publications.aspx',
           label: 'Rolls-Royce', target: ''},
        {path: 'https://tools.safran-helicopter-engines.com/pg/en/home', label: 'Safran Helicopter Engines', target: '_blank'},
      ]},
    ];
  constructor() { }

  ngOnInit() {
  }
}
