<div id="printPreview">
  <mat-dialog-content>
    <section id="printPreviewDocuments">
    </section>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="btn-container">
      <button mat-button [mat-dialog-close]="false" class="tp-button tp-button--cancel">
        Cancel
      </button>
      <button mat-button [mat-dialog-close]="true" class="tp-button tp-button--primary">
        PRINT
      </button>
    </div>
  </mat-dialog-actions>
</div>
