import { Component, OnInit } from '@angular/core';
import { NotFoundSnackbarComponent } from 'src/app/shared/components/not-found-snackbar/not-found-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpFileDownloadService } from 'src/app/shared/services/http-progress-event/http-file-download.service';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'bell-record-cards',
  templateUrl: './record-cards.component.html',
  styleUrls: ['./record-cards.component.scss']
})
export class RecordCardsComponent implements OnInit {
  recordCardsLinkSections = [
    [
      { fileId: '10125', label: 'Helicopter Component Replacement Index (English)' },
      { fileId: '9268', label: 'Helicopter Component Replacement Index (Multilingual)' },
      { fileId: '10126', label: 'Historical Service Record (English)' },
      { fileId: '9269', label: 'Historical Service Record (Multilingual)' },
    ],
    [
      { fileId: '9265', label: 'Airworthiness Directive Record' },
      { fileId: '9266', label: 'Alert Service Bulletin Record' },
      { fileId: '9267', label: 'Technical Bulletin Record' }
    ]
  ];

  constructor(
    private httpFileDownloadService: HttpFileDownloadService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
  }

  getRecordCardPdf(link: { fileId: string, label: string }) {
    this.httpFileDownloadService.getPDF(link.fileId, link.label).subscribe(
      (data) => {
        if (data && data.type === HttpEventType.Response) {
          return data;
        }
      },
      (error) => {
        this.snackBar.openFromComponent(NotFoundSnackbarComponent, {
          data: 'Error - Unable to retrieve PDF file.'
        });
      }
    );
  }
}
