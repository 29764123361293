import { Component, OnInit, Input, Output, Inject, EventEmitter, Injector } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { switchMap } from 'rxjs/operators';
import { OfflineFeedService } from 'src/app/shared/services/offline/offline-feed.service';
import { HttpCancelService } from 'src/app/shared/services/http-cancel/http-cancel.service';
import { HttpInterceptService } from 'src/app/shared/interceptor/http-intercept.service';

@Component({
  selector: 'bell-offline-lib-update-modal',
  templateUrl: './offline-lib-update-modal.component.html',
  styleUrls: ['./offline-lib-update-modal.component.scss']
})
export class OfflineLibUpdateModalComponent implements OnInit {

  activate: EventEmitter<any> = new EventEmitter();
  syncContent: EventEmitter<any> = new EventEmitter();
  cancelUpdate: EventEmitter<any> = new EventEmitter();
  resumeUpdate: EventEmitter<any> = new EventEmitter();
  updateStarted: EventEmitter<any> = new EventEmitter<any>();

  public isActivating = false;
  public updateEstimates = [];
  public downloadErrorDocs;
  public updateProgress = 0;
  isLoading = false;
  private subscription: Subscription;
  private cancelSubscription: Subscription;
  public docErrorMessage = false;

  private _totalSizeString: string;
  private _totalDocsDownloadTime;

  public get totalSizeString(): string { return this._totalSizeString};
  public get totalDocsDownloadTime(): number { return this._totalDocsDownloadTime};
  public set totalSizeString(val) { this._totalSizeString = val};
  public set totalDocsDownloadTime(val) { this._totalDocsDownloadTime = val};

  public impatient: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<OfflineLibUpdateModalComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public offlineFeedService: OfflineFeedService,
    private httpCancelService: HttpCancelService,
    private httpInterceptService: HttpInterceptService,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    if (this.data.isActivated === false) {
      this.isActivating = true;
    } else if ((this.data.dataSize && this.data.dataSize !== null
      || (this.data.dataDelete && this.data.dataDelete !== null || this.data.dataDelete !== undefined)) &&
      (!this.data.docFromError || this.data.docFromError.length === 0)) {
      this.updateEstimates.push(this.data);
    } else if (this.data.docFromError && this.data.docFromError.length > 0) {
      this.docErrorMessage = true;
      this.downloadErrorDocs = this.data.docFromError;
    }
  }

  yesActivate() {
    this.offlineFeedService.activate();
    this.isLoading = true;
    this.subscription = timer(0, 1000).pipe(
      switchMap(() => this.offlineFeedService.getProgressNumber())
    ).subscribe(result => {
      this.updateProgress = result;
      if (this.updateProgress === 100) {
        this.activate.emit();
        this.isLoading = false;
        this.subscription.unsubscribe();
        this.dialogRef.close();
      }
    });
  }

  yesLibraryUpdate() {
    this.httpInterceptService.reset();
    this.offlineFeedService.syncContent(this.data.offlineCount);
    this.updateStarted.emit();
    this.isLoading = true;
    let prev = 0;
    let impatientCount = 0;
    this.subscription = timer(0, 1000).pipe(
      switchMap(() => this.offlineFeedService.getProgressNumber())
    ).subscribe(result => {
      if (result === prev) {
        impatientCount++;
      } else {
        impatientCount = 0;
        prev = result;
      }
      if (impatientCount > 15) {
        this.impatient = true;
      }
      this.updateProgress = result;
      if (this.updateProgress === 100) {
        this.syncContent.emit();
        this.subscription.unsubscribe();
        this.isLoading = false;
        this.dialogRef.close();
      }
    });
  }

  cancelModal() {
    if (this.isLoading) {
      this.offlineFeedService.cancelLibraryUpdate();
      this.httpCancelService.cancelPendingRequests();
      this.cancelSubscription = timer(0, 1000).pipe(
        switchMap(() => this.offlineFeedService.getProgressNumber())
      ).subscribe(result => {
        this.updateProgress = result;
        if (this.updateProgress === 100) {
          this.cancelUpdate.emit();
          this.cancelSubscription.unsubscribe();
          if (this.subscription) {
            this.subscription.unsubscribe();
          }
          this.isLoading = false;
          this.dialogRef.close();
        }
      });
    } else {
      this.cancelUpdate.emit();
      this.dialogRef.close();
    }
  }

  cancelActivate() {
    this.dialogRef.close();
    this.cancelUpdate.emit();
    this.httpCancelService.cancelPendingRequests();
  }

  closeXModal() {
    this.dialog.closeAll();
    this.resumeUpdate.emit();
  }
}
