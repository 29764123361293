import { Injectable } from '@angular/core';
import { TpubDbService } from './tpub-db.service';
import { Icn } from '../../../data-model/idb/icn';
import { icnStoreName } from '../../../constants/indexed-store-config';
import { Observable, Observer } from 'rxjs';
import { Dexie } from 'dexie';

@Injectable({
  providedIn: 'root'
})
export class IcnService {
  public table: Dexie.Table<Icn>;

  constructor(private tpubDbService: TpubDbService) {
    this.table = this.tpubDbService.table(icnStoreName);
  }

  add(data: Icn): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.table.add(data).then(result => {
        observer.next(true);
        observer.complete();
      }, (error) => {
        observer.error(error);
      });
    });
  }

  addBulk(data: Icn[]): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (data && data.length > 0) {
        this.table.bulkAdd(data).then((result) => {
          observer.next(true);
          observer.complete();
        }, (error) => {
          observer.error(error);
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

  clear(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.table.clear().then(() => {
        observer.next(true);
        observer.complete();
      }, (err) => {
        observer.error(err);
      });
    });
  }

  getAll(): Observable<Icn[]> {
    return new Observable((observer: Observer<Icn[]>) => {
      this.table.toArray().then((data: Icn[]) => {
        observer.next(data);
        observer.complete();
      }, (error) => {
        observer.error(error);
      });
    });
  }

  remove(id: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.table.delete(id).then(() => {
        observer.next(true);
        observer.complete();
      }, (err) => {
        observer.error(err);
      });
    });
  }

  removeBulk(data: Icn[]): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (data && data.length > 0) {
        const icnIds: number[] = [];
        data.forEach(icn => icnIds.push(icn.rowid));
        this.table.bulkDelete(icnIds).then(() => {
          observer.next(true);
          observer.complete();
        }, (err) => {
          observer.error(err);
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

  update(id: number, data: Icn): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.table.update(id, data).then(() => {
        observer.next(true);
        observer.complete();
      }, (err) => {
        observer.error(err);
      });
    });
  }

  updateBulk(data: Icn[]): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (data && data.length > 0) {
        this.table.bulkPut(data).then(() => {
          observer.next(true);
          observer.complete();
        }, (err) => {
          observer.error(err);
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

}
