import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IetmPrintComponent } from './ietm-print.component';
import { IetmPrintLayoutComponent } from './ietm-print-layout/ietm-print-layout.component';
import { IetmPrintActionComponent } from './ietm-print-action/ietm-print-action.component';

const routes: Routes = [
  {
    path: 'printing',
    outlet: 'ietm-print-outlet',
    component: IetmPrintComponent,
    children: [{
      path: ':dn',
      component: IetmPrintLayoutComponent,
      children: [{
        path: ':docType',
        component: IetmPrintActionComponent,
      }]
    }]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class PrintRoutingModule {}
