import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'bell-expiration-date-dialog',
  templateUrl: 'expiration-date-dialog.component.html',
  styleUrls: ['expiration-date-dialog.component.scss']
})
export class ExpirationDateDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ExpirationDateDialogComponent>
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
