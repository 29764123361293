import { Injectable } from '@angular/core';
import Dexie, { Table } from 'dexie';
import { Observable, Observer, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TpubDbService extends Dexie {

  constructor() {
    super('TechPubs');
    this.version(1).stores({
      documentTree: '++rowid, &path, parent, selected, usable, updatable, remove, download, pendingAction',
      document: '++rowid, name, models, idFile, idManual, type',
      dm: '++rowid, name, code, momodel, idDm, idFile, idManual',
      icn: '++rowid, name, type, lastUpdate',
      pmc: '++rowid, pubCode',
      global: '++rowid, &key',
    });
  }

  clear(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      const tablesToClear$: Observable<boolean>[] = [];
      this.tables.forEach((t) => {
        tablesToClear$.push(this.clearTable(t));
      });
      forkJoin(tablesToClear$).subscribe((updateStatuses: boolean[]) => {
        observer.next(true);
        observer.complete();
      });
    });
  }

  clearTable(table: Table) {
    return new Observable((observer: Observer<boolean>) => {
      table.clear().then(() => {
        observer.next(true);
        observer.complete();
      });
    });
  }
}
