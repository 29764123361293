import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'bell-not-found-snackbar',
  templateUrl: './not-found-snackbar.component.html',
  styleUrls: ['./not-found-snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotFoundSnackbarComponent implements OnInit {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBarRef: MatSnackBarRef<NotFoundSnackbarComponent>
  ) { }

  ngOnInit() {
  }

}
