import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { RouteService } from '../shared/services/route.service';
import { Subscription } from 'rxjs';
import { UserProductService } from '../shared/services/user/user-product.service';

@Component({
  selector: 'bell-toc',
  templateUrl: './toc.component.html',
  styleUrls: ['./toc.component.scss']
})
export class TocComponent implements OnInit, OnDestroy {
  isTocLoaded: boolean;
  isModelToc: boolean;
  isManualToc: boolean;
  modelName: string;
  manualName: string;
  expirationDate: string;
  sortOption = 'relevancy';

  routerSubscription: Subscription;

  constructor(
    private routeService: RouteService,
    private router: Router,
    private userProductService: UserProductService
  ) { }

  ngOnInit() {
    this.setSubComponent();
    this.routerSubscription = this.router.events.subscribe(
      (event: Event) => {
        if (event instanceof NavigationEnd) {
          this.setSubComponent();
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  async setSubComponent() {
    this.modelName = this.routeService.getParameter('model');
    this.manualName = this.routeService.getParameter('manual');

    this.isManualToc = this.manualName !== null && this.manualName !== '';
    this.isModelToc = this.modelName !== null && !this.isManualToc;

    this.userProductService.getModelExpirationDate(this.modelName)
    .subscribe(data => {
      this.expirationDate = data;
    })
  }

  onTocLoaded(isLoaded: boolean) {
    this.isTocLoaded = isLoaded;
  }

  isArray(obj: any): boolean {
    return Array.isArray(obj);
  }
}
