export class IetmPrintFigureDataModel {
  model: string;
  title: string;
  sheet: string;
  icn: string;
  position: string;
  isIcnSvg: boolean;
  icnSvgData: string;
  svgContainer: HTMLElement;
  imagePath: string;
  imageContainer: HTMLElement;
  elementId: string;

  constructor() {
    this.isIcnSvg = false;
    this.icnSvgData = null;
    this.imagePath = null;
    this.svgContainer = null;    
    this.imageContainer = null;  
    this.elementId = '';
  }
}
