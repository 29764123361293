<div class="foot">
  <hr />
  <div class="copyright">Copyright 2021 Bell Textron Inc.</div>
  <div class="footer-links">
    <ng-container *ngFor="let link of footLinks">
      <a class="collapsible-link" *ngIf="link.target == 'component'" [routerLink]="link.path">{{ link.label }}</a>
      <a class="collapsible-link" *ngIf="link.target == '_blank'" href="{{link.path}}" target="_blank">{{ link.label }}</a>
    </ng-container>
    <section class="collapsed-menu">
      <a class="firstLink" *ngIf="footLinks[0].target == 'component'" [routerLink]="[footLinks[0].path]">{{ footLinks[0].label }}</a>
      <a class="firstLink" *ngIf="footLinks[0].target == '_blank'" [routerLink]="[footLinks[0].path]">{{ footLinks[0].label }}</a>
      <button mat-flat-button [matMenuTriggerFor]="collapsedlinks">
        <div class="icon-arrow-right-simple collapsed"></div>
      </button>
      <mat-menu #collapsedlinks="matMenu" class="footer">
        <button mat-menu-item class="firstLink">
          <a class="collapsible-link" *ngIf="footLinks[0].target == 'component'" [routerLink]="[footLinks[0].path]">{{ footLinks[0].label }}</a>
          <a class="collapsible-link" *ngIf="footLinks[0].target == '_blank'" [routerLink]="[footLinks[0].path]">{{ footLinks[0].label }}</a>
        </button>
        <ng-container *ngFor="let link of footLinks.slice(1)">
          <button mat-menu-item>
            <a class="collapsible-link" *ngIf="link.target == 'component'" [routerLink]="[link.path]">{{ link.label }}</a>
            <a class="collapsible-link" *ngIf="link.target == '_blank'" [routerLink]="[link.path]">{{ link.label }}</a>
          </button>
        </ng-container>
      </mat-menu>
    </section>
  </div>
</div>
